// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Crdb$BsConsole = require("../crdb.js");
var Task2$BsConsole = require("../Task2.js");
var BugBtt$BsConsole = require("./BugBtt.js");
var BugError$BsConsole = require("./data/BugError.js");
var Perforce$BsConsole = require("../project-settings/sourcecode/Perforce.js");
var BugGeneric$BsConsole = require("./BugGeneric.js");
var Repository$BsConsole = require("../project-settings/sourcecode/Repository.js");
var TriageUtils$BsConsole = require("../TriageUtils.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var AttributesContext$BsConsole = require("../context/AttributesContext.js");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");

function prepModTid(universe, project, token) {
  return (function (universe, project, token) {
      window.mod_tid_calls = (id, tid) => {
        setTimeout(() => {
          console.log(`curl '${location.origin}/api/_mod_faulting_tid?universe=${universe}&project=${project}&token=${token}' -d '{"tids":[{"id":"${id}","tid":"${tid}"}]}'`)
          console.log(`curl '${location.origin}/api/control' -d '{"action":"reload", "objects":["${id}"], "token":"${token}", "universe":"${universe}", "project":"${project}"}'`)
       })
      }
  })(universe, project, token);
}

function Bug(Props) {
  var config = Props.config;
  Props.token;
  var initialSelection = Props.initialSelection;
  var aperture = Props.aperture;
  var error = Props.error;
  var crdbRow = Props.crdbRow;
  var view = Props.view;
  var w = Props.width;
  var h = Props.height;
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var handleChangeUrl = match.handleChangeUrl;
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  var universeName = config.universe.name;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var attributes = React.useContext(AttributesContext$BsConsole.ctx).active;
  TriageUtils$BsConsole.makeQuery(/* [] */0, Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                    "fingerprint",
                    /* Equal */Block.__(0, [/* `String */[
                          -976970511,
                          BugError$BsConsole.getFingerprint(error)
                        ]])
                  ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), aperture), Crdb$BsConsole.Havings.empty, view, undefined, attributes, Crdb$BsConsole.Fold.empty);
  var onAddFilter = React.useCallback((function (filter) {
          return Curry._1(handleChangeUrl, /* ProjectDebugger2 */Block.__(19, [
                        projectName,
                        {
                          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, filter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), aperture),
                          state: initialSelection
                        }
                      ]));
        }), /* tuple */[
        handleChangeUrl,
        initialSelection,
        aperture
      ]);
  var match$1 = React.useState((function () {
          return false;
        }));
  var setHasRepositories = match$1[1];
  var hasRepositories = match$1[0];
  React.useEffect((function () {
          Task2$BsConsole.handle(token, Repository$BsConsole.fetchRepositories(projectName, config.universe.name, config), undefined, (function (repositories) {
                  if (repositories.tag) {
                    return ;
                  }
                  var repositories$1 = repositories[0];
                  return Curry._1(setHasRepositories, (function (hasRepos) {
                                if (hasRepos) {
                                  return true;
                                } else {
                                  return Belt_List.length(repositories$1) > 0;
                                }
                              }));
                }));
          Task2$BsConsole.handle(token, Perforce$BsConsole.fetchPerforceServers(projectName, config.universe.name, config), undefined, (function (servers) {
                  if (servers.tag) {
                    return ;
                  }
                  var servers$1 = servers[0];
                  return Curry._1(setHasRepositories, (function (hasRepos) {
                                if (hasRepos) {
                                  return true;
                                } else {
                                  return Belt_List.length(servers$1) > 0;
                                }
                              }));
                }));
          
        }), []);
  prepModTid(universeName, projectName, token);
  if (error.tag) {
    return React.createElement(BugGeneric$BsConsole.make, {
                config: config,
                token: token,
                onAddFilter: onAddFilter,
                initialSelection: initialSelection,
                crdbRow: crdbRow,
                aperture: aperture,
                error: error[0],
                width: w,
                height: h,
                hasRepositories: hasRepositories,
                handleChangeUrl: handleChangeUrl
              });
  } else {
    return React.createElement(BugBtt$BsConsole.make, {
                config: config,
                token: token,
                crdbRow: crdbRow,
                onAddFilter: onAddFilter,
                initialSelection: initialSelection,
                aperture: aperture,
                error: error[0],
                width: w,
                height: h,
                hasRepositories: hasRepositories,
                handleChangeUrl: handleChangeUrl
              });
  }
}

var make = Bug;

exports.prepModTid = prepModTid;
exports.make = make;
/* react Not a pure module */
