// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var Colors$BsConsole = require("../Colors.js");
var Fragment$BsConsole = require("../Fragment.js");

var component = RR$BsConsole.statelessComponent("BtListItem-BsConsole");

function make(classNameOpt, onClick, children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var wrapperClass = Css.style(/* :: */[
                    Css.padding2(Css.px(1), Css.px(12)),
                    /* :: */[
                      Css.height(Css.px(24)),
                      /* :: */[
                        Css.margin2(Css.px(2), Css.px(0)),
                        /* :: */[
                          Css.fontSize(Css.px(12)),
                          /* :: */[
                            Css.textAlign(/* left */-944764921),
                            /* :: */[
                              Css.flexShrink(0),
                              /* :: */[
                                Css.hover(/* :: */[
                                      Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]);
              var contents = ReasonReact.element(undefined, undefined, Fragment$BsConsole.make(children));
              if (onClick !== undefined) {
                return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.ButtonBase.Jsx2.make, Css.merge(/* :: */[
                                    wrapperClass,
                                    /* :: */[
                                      Css.style(/* :: */[
                                            Css.display(/* flex */-1010954439),
                                            /* :: */[
                                              Css.alignItems(/* center */98248149),
                                              /* :: */[
                                                Css.justifyContent(/* flexStart */662439529),
                                                /* [] */0
                                              ]
                                            ]
                                          ]),
                                      /* :: */[
                                        className,
                                        /* [] */0
                                      ]
                                    ]
                                  ]), undefined, onClick, undefined, undefined, undefined, [contents]));
              } else {
                return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, Css.merge(/* :: */[
                                    wrapperClass,
                                    /* :: */[
                                      Css.style(/* :: */[
                                            Css.flexGrow(1),
                                            /* [] */0
                                          ]),
                                      /* :: */[
                                        className,
                                        /* [] */0
                                      ]
                                    ]
                                  ]), [contents]));
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
