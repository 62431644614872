// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var SSAUtils$BsConsole = require("./SSAUtils.js");

var Ignore = {
  titleText: SSAUtils$BsConsole.ignoreTitleText,
  descriptionText: SSAUtils$BsConsole.ignoreDescriptionText,
  createNewTitleText: SSAUtils$BsConsole.ignoreTitleText,
  createNewFormType: /* Ignore */1
};

var IndexOnly = {
  titleText: SSAUtils$BsConsole.indexOnlyTitleText,
  descriptionText: SSAUtils$BsConsole.indexOnlyDescriptionText,
  createNewTitleText: SSAUtils$BsConsole.indexOnlyTitleText,
  createNewFormType: /* IndexOnly */0
};

var SetValue = {
  titleText: SSAUtils$BsConsole.setValuesTitleText,
  descriptionText: SSAUtils$BsConsole.setValuesDescriptionText,
  createNewTitleText: SSAUtils$BsConsole.setValueTitleText,
  createNewFormType: /* SetValue */2
};

var Union = {
  titleText: SSAUtils$BsConsole.unionValuesTitleText,
  descriptionText: SSAUtils$BsConsole.unionValuesDescriptionText,
  createNewTitleText: SSAUtils$BsConsole.unionValueTitleText,
  createNewFormType: /* Union */3
};

function toModule(param) {
  switch (param) {
    case /* Ignore */0 :
        return Ignore;
    case /* IndexOnly */1 :
        return IndexOnly;
    case /* SetValue */2 :
        return SetValue;
    case /* Union */3 :
        return Union;
    
  }
}

var options = [
  /* Ignore */0,
  /* IndexOnly */1,
  /* SetValue */2,
  /* Union */3
];

var ignoreModule = Ignore;

var indexOnlyModule = IndexOnly;

var setValueModule = SetValue;

var unionModule = Union;

exports.Ignore = Ignore;
exports.IndexOnly = IndexOnly;
exports.SetValue = SetValue;
exports.Union = Union;
exports.ignoreModule = ignoreModule;
exports.indexOnlyModule = indexOnlyModule;
exports.setValueModule = setValueModule;
exports.unionModule = unionModule;
exports.toModule = toModule;
exports.options = options;
/* SSAUtils-BsConsole Not a pure module */
