// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BtTable$BsConsole = require("../BtTable.js");
var BtTableRow$BsConsole = require("../BtTableRow.js");
var BtTableBody$BsConsole = require("../BtTableBody.js");
var BtTableCell$BsConsole = require("../BtTableCell.js");
var BtTableHead$BsConsole = require("../BtTableHead.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var BtTableContainer$BsConsole = require("../BtTableContainer.js");
var AlertsErrorLogApi$BsConsole = require("./api/AlertsErrorLogApi.js");

var threeMonthsInSeconds = 3 * 30 * 24 * 60 * 60;

function AlertsDetailViewErrorLog(Props) {
  var alert = Props.alert;
  var token = Props.token;
  var endpoint = Props.endpoint;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var match = React.useState((function () {
          return [];
        }));
  var setErrorLogs = match[1];
  var errorLogs = match[0];
  var fetchErrorLogs = function (param) {
    var nowUnix = Date.now() / 1000;
    var pastUnix = nowUnix - threeMonthsInSeconds;
    var arg = AlertsErrorLogApi$BsConsole.get(endpoint, universeName, projectName, alert.id, undefined, pastUnix, nowUnix, undefined, undefined, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setErrorLogs, (function (param) {
                                return result.values;
                              }));
                }));
  };
  React.useEffect((function () {
          fetchErrorLogs(undefined);
          
        }), /* tuple */[
        token,
        universeName,
        projectName,
        alert.id
      ]);
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.padding(Css.px(24)),
                        /* :: */[
                          Css.marginBottom(Css.px(24)),
                          /* [] */0
                        ]
                      ]
                    ])
              },
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Button */10,
                  className: Css.style(/* :: */[
                        Css.marginLeft(Css.px(5)),
                        /* :: */[
                          Css.marginRight(Css.px(5)),
                          /* :: */[
                            Css.height(Css.px(12)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  color: Colors$BsConsole.grey0,
                  children: I18N$BsConsole.show(undefined, "Error logs")
                }), React.createElement(BtTableContainer$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.borderRadius(Css.px(4)),
                        /* [] */0
                      ]),
                  children: React.createElement(BtTable$BsConsole.make, {
                        children: null
                      }, React.createElement(BtTableHead$BsConsole.make, {
                            children: React.createElement(BtTableRow$BsConsole.make, {
                                  className: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* [] */0
                                      ]),
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                      className: Css.style(/* :: */[
                                            Css.width(Css.px(180)),
                                            /* [] */0
                                          ]),
                                      children: I18N$BsConsole.show(undefined, "Time")
                                    }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                      children: I18N$BsConsole.show(undefined, "Message")
                                    }))
                          }), React.createElement(BtTableBody$BsConsole.make, {
                            children: errorLogs.length === 0 ? React.createElement(BtTableRow$BsConsole.make, {
                                    children: React.createElement(BtTableCell$BsConsole.make, {
                                          children: I18N$BsConsole.show(undefined, "No errors")
                                        })
                                  }) : Belt_Array.mapWithIndex(errorLogs, (function (index, curr) {
                                      var time = new Date(curr.timestamp * 1000);
                                      return React.createElement(BtTableRow$BsConsole.make, {
                                                  children: null,
                                                  key: String(index)
                                                }, React.createElement(BtTableCell$BsConsole.make, {
                                                      children: I18N$BsConsole.showSkip(time.toLocaleDateString() + (" " + time.toLocaleTimeString()))
                                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                                      children: React.createElement("pre", {
                                                            className: Css.style(/* :: */[
                                                                  Css.marginRight(Css.px(25)),
                                                                  /* :: */[
                                                                    Css.fontSize(Css.px(14)),
                                                                    /* :: */[
                                                                      Css.lineHeight(Css.px(14)),
                                                                      /* :: */[
                                                                        Css.fontFamily("Inconsolata"),
                                                                        /* :: */[
                                                                          Css.whiteSpace(/* preLine */538440119),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ])
                                                          }, I18N$BsConsole.dynamic(curr.message))
                                                    }));
                                    }))
                          }))
                }));
}

var make = AlertsDetailViewErrorLog;

exports.threeMonthsInSeconds = threeMonthsInSeconds;
exports.make = make;
/* Css Not a pure module */
