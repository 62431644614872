// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");

var theadStyle = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.display(/* tableHeaderGroup */606419204),
            /* :: */[
              Css.unsafe("backgroundColor", "inherit"),
              /* [] */0
            ]
          ]),
      /* :: */[
        "BtTableHead",
        /* [] */0
      ]
    ]);

function BtTableHead(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("thead", {
              className: Css.merge(/* :: */[
                    theadStyle,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var make = BtTableHead;

exports.theadStyle = theadStyle;
exports.make = make;
/* theadStyle Not a pure module */
