// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var WfBool$BsConsole = require("./WfBool.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfArray$BsConsole = require("./WfArray.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var WfArrayRow$BsConsole = require("./WfArrayRow.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");
var BeautifulDnd$BsConsole = require("../bindings/BeautifulDnd.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var FetchDescribe$BsConsole = require("../FetchDescribe.js");
var WfAttributeAutocomplete$BsConsole = require("./WfAttributeAutocomplete.js");

var defaultState_attributes = [];

var defaultState = {
  includeHostnames: false,
  showClassifiers: true,
  showCallstack: true,
  showAttributes: true,
  attributes: defaultState_attributes
};

var component = RR$BsConsole.reducerComponent("WfCustomizePayload-BsConsole");

function toJson(state) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "include_hostname",
                state.includeHostnames
              ],
              /* :: */[
                /* tuple */[
                  "display_settings",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "show_classifiers",
                          state.showClassifiers
                        ],
                        /* :: */[
                          /* tuple */[
                            "show_callstack",
                            state.showCallstack
                          ],
                          /* :: */[
                            /* tuple */[
                              "show_attributes",
                              state.showAttributes
                            ],
                            /* :: */[
                              /* tuple */[
                                "attribute_list",
                                Json_encode.array((function (prim) {
                                        return prim;
                                      }), Belt_Array.keep(Belt_Array.map(state.attributes, (function (prim) {
                                                return prim[1];
                                              })), (function (attr) {
                                            return attr !== "";
                                          })))
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                ],
                /* [] */0
              ]
            ]);
}

function fromJson(json) {
  var displaySettings = Json_decode.field("display_settings", Util$BsConsole.identity, json);
  var __x = Json_decode.field("attribute_list", (function (param) {
          return Json_decode.array((function (param) {
                        return Json_decode.optional(Json_decode.string, param);
                      }), param);
        }), displaySettings);
  return {
          includeHostnames: Json_decode.field("include_hostname", Json_decode.bool, json),
          showClassifiers: Json_decode.field("show_classifiers", Json_decode.bool, displaySettings),
          showCallstack: Json_decode.field("show_callstack", Json_decode.bool, displaySettings),
          showAttributes: Json_decode.field("show_attributes", Json_decode.bool, displaySettings),
          attributes: Belt_Array.map(__x, (function (attr) {
                  return /* tuple */[
                          WfArray$BsConsole.generateId(undefined),
                          Belt_Option.getWithDefault(attr, "")
                        ];
                }))
        };
}

function make(initialValue, onValueChange, projectName, handleTask, token, param) {
  var sendNewValue = function (param) {
    return Curry._1(onValueChange, /* Ok */Block.__(0, [toJson(param.state)]));
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: sendNewValue,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var send = param.send;
              return React.createElement("div", {
                          style: {
                            paddingTop: "24px"
                          }
                        }, ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make({
                                  title: I18N$BsConsole.get(undefined, "Display settings - Configure content"),
                                  description: I18N$BsConsole.get(undefined, "You can select which sections of the notification body you want to display. You can also specify a list of attributes you'd like to see displayed.")
                                }, undefined, "12px 17px 0", undefined, undefined, false, false, [])), ReasonReact.element(undefined, undefined, WfBool$BsConsole.make({
                                  title: I18N$BsConsole.get(undefined, "Include hostname in attributes"),
                                  description: undefined
                                }, Caml_option.some(state.includeHostnames), (function (newVal) {
                                    if (!newVal.tag) {
                                      return Curry._1(send, /* SetIncludeHostnames */Block.__(0, [Json_decode.bool(newVal[0])]));
                                    }
                                    throw [
                                          Caml_builtin_exceptions.assert_failure,
                                          /* tuple */[
                                            "WfCustomizePayload.re",
                                            148,
                                            26
                                          ]
                                        ];
                                  }), false, WfCore$BsConsole.Bool.make(false, undefined, undefined, undefined, undefined, undefined), "4px 0 0 3px", (function (prim) {
                                    
                                  }), [])), ReasonReact.element(undefined, undefined, WfBool$BsConsole.make({
                                  title: I18N$BsConsole.get(undefined, "Show classifiers section"),
                                  description: undefined
                                }, Caml_option.some(state.showClassifiers), (function (newVal) {
                                    if (!newVal.tag) {
                                      return Curry._1(send, /* SetShowClassifiers */Block.__(1, [Json_decode.bool(newVal[0])]));
                                    }
                                    throw [
                                          Caml_builtin_exceptions.assert_failure,
                                          /* tuple */[
                                            "WfCustomizePayload.re",
                                            169,
                                            26
                                          ]
                                        ];
                                  }), false, WfCore$BsConsole.Bool.make(false, undefined, undefined, undefined, undefined, undefined), "0 0 0 3px", (function (prim) {
                                    
                                  }), [])), ReasonReact.element(undefined, undefined, WfBool$BsConsole.make({
                                  title: I18N$BsConsole.get(undefined, "Show callstack"),
                                  description: undefined
                                }, Caml_option.some(state.showCallstack), (function (newVal) {
                                    if (!newVal.tag) {
                                      return Curry._1(send, /* SetShowCallstack */Block.__(2, [Json_decode.bool(newVal[0])]));
                                    }
                                    throw [
                                          Caml_builtin_exceptions.assert_failure,
                                          /* tuple */[
                                            "WfCustomizePayload.re",
                                            190,
                                            26
                                          ]
                                        ];
                                  }), false, WfCore$BsConsole.Bool.make(false, undefined, undefined, undefined, undefined, undefined), "0 0 0 3px", (function (prim) {
                                    
                                  }), [])), ReasonReact.element(undefined, undefined, WfBool$BsConsole.make({
                                  title: I18N$BsConsole.get(undefined, "Show attributes section"),
                                  description: undefined
                                }, Caml_option.some(state.showAttributes), (function (newVal) {
                                    if (!newVal.tag) {
                                      return Curry._1(send, /* SetShowAttributes */Block.__(3, [Json_decode.bool(newVal[0])]));
                                    }
                                    throw [
                                          Caml_builtin_exceptions.assert_failure,
                                          /* tuple */[
                                            "WfCustomizePayload.re",
                                            211,
                                            26
                                          ]
                                        ];
                                  }), false, WfCore$BsConsole.Bool.make(false, undefined, undefined, undefined, undefined, undefined), "0 0 12px 3px", (function (prim) {
                                    
                                  }), [])), ReasonReact.element(undefined, undefined, FetchDescribe$BsConsole.make(handleTask, token, projectName, (function (remote, param) {
                                    var attributes;
                                    attributes = typeof remote === "number" ? [] : (
                                        remote.tag ? [] : Belt_List.toArray(remote[0][0])
                                      );
                                    return ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make({
                                                    title: I18N$BsConsole.get(undefined, "Display settings - Attribute list"),
                                                    description: "If \"Show attributes section\" above is checked, and there are attributes listed here, only those attributes will be displayed. If this is empty, then all custom dictionary attributes are displayed."
                                                  }, undefined, undefined, undefined, undefined, false, false, [
                                                    ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.DragDropContext.make((function (e) {
                                                                return Curry._1(send, /* MoveAttribute */Block.__(5, [
                                                                              e.source.index,
                                                                              e.destination.index
                                                                            ]));
                                                              }), [ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.Droppable.make("droppable", (function (provided, param) {
                                                                          var __x = state.attributes;
                                                                          return React.createElement("div", {
                                                                                      ref: provided.innerRef
                                                                                    }, Belt_Array.mapWithIndex(__x, (function (index, param) {
                                                                                            var attribute = param[1];
                                                                                            var key = param[0];
                                                                                            return ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.Draggable.make(key, index, undefined, (function (provided, param) {
                                                                                                              return React.cloneElement(React.createElement("div", {
                                                                                                                              ref: provided.innerRef,
                                                                                                                              style: {
                                                                                                                                display: "flex",
                                                                                                                                padding: "4px 0"
                                                                                                                              }
                                                                                                                            }, ReasonReact.element(undefined, undefined, WfArrayRow$BsConsole.make((function (param) {
                                                                                                                                        return Curry._1(send, /* RemoveAttribute */Block.__(6, [index]));
                                                                                                                                      }), provided.dragHandleProps, [ReasonReact.element(undefined, undefined, WfAttributeAutocomplete$BsConsole.make(attributes, attribute, (function (v) {
                                                                                                                                                  return Curry._1(send, /* UpdateAttribute */Block.__(4, [
                                                                                                                                                                key,
                                                                                                                                                                v
                                                                                                                                                              ]));
                                                                                                                                                }), []))]))), provided.draggableProps);
                                                                                                            })));
                                                                                          })), provided.placeholder);
                                                                        })))])),
                                                    React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                  undefined,
                                                                  undefined,
                                                                  (function (param) {
                                                                      return Curry._1(send, /* AddNewAttribute */0);
                                                                    }),
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  /* Secondary */67972948,
                                                                  undefined,
                                                                  {
                                                                    marginTop: "4px"
                                                                  },
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [
                                                                    ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                                    I18N$BsConsole.show(undefined, "Add")
                                                                  ]
                                                                ])))
                                                  ]));
                                  }))));
            }),
          initialState: (function (param) {
              if (initialValue === undefined) {
                return defaultState;
              }
              if (initialValue.tag) {
                throw [
                      Caml_builtin_exceptions.assert_failure,
                      /* tuple */[
                        "WfCustomizePayload.re",
                        75,
                        26
                      ]
                    ];
              }
              try {
                return fromJson(initialValue[0]);
              }
              catch (exn){
                return defaultState;
              }
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            includeHostnames: state.includeHostnames,
                            showClassifiers: state.showClassifiers,
                            showCallstack: state.showCallstack,
                            showAttributes: state.showAttributes,
                            attributes: Belt_Array.concat(state.attributes, [/* tuple */[
                                    WfArray$BsConsole.generateId(undefined),
                                    ""
                                  ]])
                          },
                          sendNewValue
                        ]);
              }
              switch (action.tag | 0) {
                case /* SetIncludeHostnames */0 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                includeHostnames: action[0],
                                showClassifiers: state.showClassifiers,
                                showCallstack: state.showCallstack,
                                showAttributes: state.showAttributes,
                                attributes: state.attributes
                              },
                              sendNewValue
                            ]);
                case /* SetShowClassifiers */1 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                includeHostnames: state.includeHostnames,
                                showClassifiers: action[0],
                                showCallstack: state.showCallstack,
                                showAttributes: state.showAttributes,
                                attributes: state.attributes
                              },
                              sendNewValue
                            ]);
                case /* SetShowCallstack */2 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                includeHostnames: state.includeHostnames,
                                showClassifiers: state.showClassifiers,
                                showCallstack: action[0],
                                showAttributes: state.showAttributes,
                                attributes: state.attributes
                              },
                              sendNewValue
                            ]);
                case /* SetShowAttributes */3 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                includeHostnames: state.includeHostnames,
                                showClassifiers: state.showClassifiers,
                                showCallstack: state.showCallstack,
                                showAttributes: action[0],
                                attributes: state.attributes
                              },
                              sendNewValue
                            ]);
                case /* UpdateAttribute */4 :
                    var updatedAttribute = action[1];
                    var updatedKey = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                includeHostnames: state.includeHostnames,
                                showClassifiers: state.showClassifiers,
                                showCallstack: state.showCallstack,
                                showAttributes: state.showAttributes,
                                attributes: Belt_Array.map(state.attributes, (function (param) {
                                        var key = param[0];
                                        if (updatedKey === key) {
                                          return /* tuple */[
                                                  key,
                                                  updatedAttribute
                                                ];
                                        } else {
                                          return /* tuple */[
                                                  key,
                                                  param[1]
                                                ];
                                        }
                                      }))
                              },
                              sendNewValue
                            ]);
                case /* MoveAttribute */5 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                includeHostnames: state.includeHostnames,
                                showClassifiers: state.showClassifiers,
                                showCallstack: state.showCallstack,
                                showAttributes: state.showAttributes,
                                attributes: WfArray$BsConsole.updateArrayItemPosition(state.attributes, action[0], action[1])
                              },
                              sendNewValue
                            ]);
                case /* RemoveAttribute */6 :
                    var index = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                includeHostnames: state.includeHostnames,
                                showClassifiers: state.showClassifiers,
                                showCallstack: state.showCallstack,
                                showAttributes: state.showAttributes,
                                attributes: Belt_Array.keepWithIndex(state.attributes, (function (param, i) {
                                        return i !== index;
                                      }))
                              },
                              sendNewValue
                            ]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.defaultState = defaultState;
exports.component = component;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.make = make;
/* component Not a pure module */
