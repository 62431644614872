// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Row2$BsConsole = require("./components/Row2.js");
var Task2$BsConsole = require("./Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

function udashLoginTask(endpoint, unityProjectId, unityOrgId, unityUserId, unityToken) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "unity_project_id",
                        unityProjectId
                      ],
                      /* :: */[
                        /* tuple */[
                          "unity_org_id",
                          unityOrgId
                        ],
                        /* :: */[
                          /* tuple */[
                            "unity_user_id",
                            unityUserId
                          ],
                          /* :: */[
                            /* tuple */[
                              "unity_token",
                              unityToken
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ])]), undefined, (function (param) {
                return /* Ok */Block.__(0, [Json_decode.field("token", Json_decode.string, param.json)]);
              }), undefined, undefined);
}

function udashLogin(endpoint, unityProjectId, unityOrgId, unityUserId, unityToken, handleLoginSuccess) {
  return Task2$BsConsole.handle("", udashLoginTask(endpoint, unityProjectId, unityOrgId, unityUserId, unityToken), undefined, (function (token) {
                if (!token.tag) {
                  return Curry._1(handleLoginSuccess, token[0]);
                }
                console.log("[UDashEntry] unable to log in via /u-login", token[0]);
                
              }));
}

function isUnityUniverse(hostname) {
  var regex = new RegExp("u-(.+?).sp.backtrace.io");
  var match = regex.exec(hostname);
  return match !== null;
}

function isUnityByHostname(param) {
  return isUnityUniverse(window.location.hostname);
}

function UDashEntry(Props) {
  var handleLoginSuccess = Props.handleLoginSuccess;
  var token = Props.token;
  var email = Props.email;
  var project = Props.project;
  var regex = new RegExp("u-(.+?).sp.backtrace.io");
  var result = regex.exec(window.location.hostname);
  var universe = result !== null ? Belt_Array.get(result, 1) : undefined;
  React.useEffect((function () {
          var signupEndpoint = "https://create-unity.backtrace.io/api";
          if (signupEndpoint !== undefined) {
            if (universe !== undefined) {
              udashLogin(signupEndpoint + "/u-login", project, universe, email, token, handleLoginSuccess);
            } else {
              console.log("[UDashEntry] Could not parse universe from URL");
            }
          } else {
            console.log("[UDashEntry] No signup service endpoint found");
          }
          
        }), []);
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.vh(100)),
                      /* [] */0
                    ]
                  ]),
              children: React.createElement(CircularProgress.default, {
                    size: 150,
                    color: "primary"
                  })
            });
}

function make(handleLoginSuccess, token, email, project, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(UDashEntry, {
              handleLoginSuccess: handleLoginSuccess,
              token: token,
              email: email,
              project: project
            }, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = UDashEntry;

exports.udashLoginTask = udashLoginTask;
exports.udashLogin = udashLogin;
exports.isUnityUniverse = isUnityUniverse;
exports.isUnityByHostname = isUnityByHostname;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* Css Not a pure module */
