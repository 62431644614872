// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var NewStyles$BsConsole = require("./NewStyles.js");
var SavingDialog$BsConsole = require("./SavingDialog.js");

function SavedQueriesSave(Props) {
  var project = Props.project;
  var config = Props.config;
  var query = Props.query;
  var mode = Props.mode;
  var refetchQueries = Props.refetchQueries;
  var refetchProjectDefault = Props.refetchProjectDefault;
  var refetchUserDefault = Props.refetchUserDefault;
  var navToLoad = Props.navToLoad;
  var token = Props.token;
  var handleTask = Props.handleTask;
  var normBy = Props.normBy;
  if (query !== undefined && mode !== undefined) {
    return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                        Css.height(Css.pct(100)),
                        /* [] */0
                      ]), [
                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                              Css.style(/* :: */[
                                    Css.height(Css.px(48)),
                                    /* :: */[
                                      Css.position(/* absolute */-1013592457),
                                      /* :: */[
                                        Css.top(/* `rem */[
                                              5691738,
                                              1
                                            ]),
                                        /* :: */[
                                          Css.right(/* `rem */[
                                                5691738,
                                                1
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]),
                              undefined,
                              (function (param) {
                                  return Curry._1(navToLoad, undefined);
                                }),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              "Close current view",
                              [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Clear.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                            ])),
                    React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.padding4(Css.rem(1.5), Css.rem(1), /* zero */-789508312, Css.rem(1)),
                                /* [] */0
                              ])
                        }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(Css.style(/* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.justifyContent(/* center */98248149),
                                                  /* :: */[
                                                    Css.alignItems(/* center */98248149),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                            ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ViewCompact.Jsx2.make, Css.style(/* :: */[
                                                          Css.marginRight(Css.px(10)),
                                                          /* :: */[
                                                            Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                            /* :: */[
                                                              Css.fontSize(Css.rem(2.5)),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]), undefined, undefined, undefined, undefined, [])),
                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, {
                                                      root: Css.style(/* :: */[
                                                            Css.color(/* `hex */[
                                                                  5194459,
                                                                  Colors$BsConsole.grey0
                                                                ]),
                                                            /* :: */[
                                                              Css.fontWeight(/* `num */[
                                                                    5496390,
                                                                    400
                                                                  ]),
                                                              /* [] */0
                                                            ]
                                                          ])
                                                    }, undefined, [I18N$BsConsole.show(undefined, "Save current view")]))
                                          ]))]))),
                    ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(NewStyles$BsConsole.theme, [ReasonReact.element(undefined, undefined, SavingDialog$BsConsole.make(config.user, Belt_Option.getWithDefault(config.user.role, "") === "admin" || config.user.superuser === 1, config, (function (param) {
                                          Curry._1(navToLoad, undefined);
                                          return Curry._1(refetchQueries, undefined);
                                        }), token, handleTask, navToLoad, project, mode, query, refetchProjectDefault, refetchUserDefault, normBy, []))]))
                  ]));
  } else {
    return null;
  }
}

function make(project, config, query, mode, refetchQueries, refetchProjectDefault, refetchUserDefault, navToLoad, token, handleTask, normBy, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SavedQueriesSave, {
              project: project,
              config: config,
              query: query,
              mode: mode,
              refetchQueries: refetchQueries,
              refetchProjectDefault: refetchProjectDefault,
              refetchUserDefault: refetchUserDefault,
              navToLoad: navToLoad,
              token: token,
              handleTask: handleTask,
              normBy: normBy
            }, _children);
}

var Re = {
  make: make
};

var make$1 = SavedQueriesSave;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
