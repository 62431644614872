// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function optDefault($$default, maybe) {
  if (maybe !== undefined) {
    return Caml_option.valFromOption(maybe);
  } else {
    return $$default;
  }
}

function toKeyValueRegex(k, v) {
  var quote = "\\\\?\"";
  return [
            quote,
            k,
            quote,
            "\\s*:\\s*",
            quote,
            v,
            quote
          ].join("");
}

function toKeyRegex(k) {
  var quote = "\\\\?\"";
  return [
            quote,
            k,
            quote,
            "\\s*:\\s*"
          ].join("");
}

exports.optDefault = optDefault;
exports.toKeyValueRegex = toKeyValueRegex;
exports.toKeyRegex = toKeyRegex;
/* No side effect */
