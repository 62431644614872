// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var I18N$BsConsole = require("../../../I18N.js");
var Task2$BsConsole = require("../../../Task2.js");
var Backtrace$BsConsole = require("../../../bindings/Backtrace.js");

function statisticsOfJson(json) {
  return {
          numberOfBytesDownloaded: Json_decode.field("numberOfBytesDownloaded", Json_decode.$$float, json),
          numberOfFailuresAtDownloadStage: Json_decode.field("numberOfFailuresAtDownloadStage", Json_decode.$$float, json),
          numberOfFailuresAtSymbolConversionStage: Json_decode.field("numberOfFailuresAtSymbolConversionStage", Json_decode.$$float, json),
          numberOfSuccessAtDownloadStage: Json_decode.field("numberOfSuccessAtDownloadStage", Json_decode.$$float, json),
          numberOfSuccessAtSymbolConversionStage: Json_decode.field("numberOfSuccessAtSymbolConversionStage", Json_decode.$$float, json),
          symbolServerId: Json_decode.field("symbolServerId", Json_decode.$$int, json),
          lastUpdateDate: Json_decode.field("lastUpdateDate", Json_decode.string, json)
        };
}

function serverOfJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json),
          projectId: Json_decode.optional((function (param) {
                  return Json_decode.field("projectId", Json_decode.$$int, param);
                }), json),
          universeId: Json_decode.optional((function (param) {
                  return Json_decode.field("universeId", Json_decode.$$int, param);
                }), json),
          priority: Json_decode.field("priority", Json_decode.$$int, json),
          numberOfConcurrentDownload: Json_decode.field("numberOfConcurrentDownload", Json_decode.$$int, json),
          retryLimit: Json_decode.field("retryLimit", Json_decode.$$int, json),
          retryTimeout: Json_decode.field("retryTimeout", Json_decode.$$int, json),
          timeout: Json_decode.field("timeout", Json_decode.$$int, json),
          creationDate: Json_decode.field("creationDate", Json_decode.string, json),
          lastUpdateDate: Json_decode.field("lastUpdateDate", Json_decode.string, json),
          whiteList: Json_decode.field("whiteList", Json_decode.bool, json),
          disable: Json_decode.field("disable", Json_decode.bool, json),
          statistics: Json_decode.field("statistics", statisticsOfJson, json)
        };
}

function logOfJson(json) {
  var logType = Json_decode.field("type", Json_decode.$$int, json);
  var tmp;
  if (logType > 2 || logType < 0) {
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SymbolServer_Api/logOfJson", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `string */[
          288368849,
          "log field type: " + (String(logType) + " not accounted for in decoder.")
        ]);
    tmp = /* Unknown */3;
  } else {
    tmp = logType;
  }
  return {
          creationDate: Json_decode.field("creationDate", Json_decode.string, json),
          id: Json_decode.field("id", Json_decode.$$int, json),
          type_: tmp,
          message: Json_decode.field("message", Json_decode.string, json),
          symbolServerId: Json_decode.field("symbolServerId", Json_decode.$$int, json)
        };
}

function symbolOfJson(json) {
  return {
          creationDate: Json_decode.field("creationDate", Json_decode.string, json),
          debugId: Json_decode.optional((function (param) {
                  return Json_decode.field("debugId", Json_decode.string, param);
                }), json),
          id: Json_decode.field("id", Json_decode.$$int, json),
          symbolName: Json_decode.field("symbolName", Json_decode.string, json),
          symbolServerId: Json_decode.field("symbolServerId", Json_decode.$$int, json)
        };
}

function makeCredentialFields(password, username, usernameCase) {
  if (password === "..." && username === "...") {
    return /* :: */[
            /* tuple */[
              "type",
              0
            ],
            /* [] */0
          ];
  }
  if (username === "...") {
    return /* :: */[
            /* tuple */[
              "password",
              password
            ],
            /* :: */[
              /* tuple */[
                "type",
                0
              ],
              /* [] */0
            ]
          ];
  } else if (password === "...") {
    return /* :: */[
            /* tuple */[
              "type",
              0
            ],
            /* :: */[
              /* tuple */[
                usernameCase ? "username" : "userName",
                username
              ],
              /* [] */0
            ]
          ];
  } else {
    return /* :: */[
            /* tuple */[
              "type",
              0
            ],
            /* :: */[
              /* tuple */[
                "password",
                password
              ],
              /* :: */[
                /* tuple */[
                  usernameCase ? "username" : "userName",
                  username
                ],
                /* [] */0
              ]
            ]
          ];
  }
}

function appendFormDataOnCredentialFields(form, password, username, usernameCase) {
  if (password !== "...") {
    form.append("proxy[password]", password);
  }
  if (username === "...") {
    return ;
  }
  form.append("proxy[" + ((
          usernameCase ? "username" : "userName"
        ) + "]"), password);
  
}

function decodeProxy(json) {
  return {
          host: Json_decode.field("host", Json_decode.string, json),
          port: Json_decode.field("port", Json_decode.$$int, json),
          username: Json_decode.field("username", Json_decode.string, json),
          password: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("password", Json_decode.string, param);
                    }), json), "...")
        };
}

function boolParser(json) {
  return Json_decode.oneOf(/* :: */[
              (function (json) {
                  var match = Json_decode.$$int(json);
                  return match === 1;
                }),
              /* :: */[
                Json_decode.bool,
                /* [] */0
              ]
            ], json);
}

function decodeAWSConnection(json) {
  var match = Json_decode.field("type", Json_decode.$$int, json);
  if (match !== 1) {
    throw [
          Json_decode.DecodeError,
          "Not AWS type"
        ];
  }
  return /* Aws */Block.__(0, [Json_decode.field("awsConnection", (function (json) {
                    return {
                            lowerFile: Json_decode.field("lowerFile", boolParser, json),
                            lowerId: Json_decode.field("lowerId", boolParser, json),
                            usePdb: Json_decode.field("usePdb", boolParser, json),
                            bucketName: Json_decode.field("bucketName", Json_decode.string, json)
                          };
                  }), json)]);
}

function decodeGoogleConnection(json) {
  var match = Json_decode.field("type", Json_decode.$$int, json);
  if (match !== 2) {
    throw [
          Json_decode.DecodeError,
          "Not Google Cloud type"
        ];
  }
  return /* Google */Block.__(1, [{
              lowerFile: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("lowerFile", boolParser, param);
                        }), json), false),
              lowerId: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("lowerId", boolParser, param);
                        }), json), false),
              usePdb: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("usePdb", boolParser, param);
                        }), json), false),
              bucketName: Json_decode.field("bucketName", Json_decode.string, json),
              secretFile: undefined
            }]);
}

function decodeConnection(json) {
  return Json_decode.oneOf(/* :: */[
              decodeGoogleConnection,
              /* :: */[
                decodeAWSConnection,
                /* [] */0
              ]
            ], json);
}

function decodeServerCredentials(json) {
  return {
          userName: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("username", Json_decode.string, param);
                    }), json), "..."),
          password: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("password", Json_decode.string, param);
                    }), json), "..."),
          connection: Json_decode.optional(decodeConnection, json)
        };
}

function serverDetailsOfJson(json) {
  return {
          creationDate: Json_decode.field("creationDate", Json_decode.string, json),
          disable: Json_decode.field("disable", Json_decode.bool, json),
          id: Json_decode.field("id", Json_decode.$$int, json),
          lastUpdateDate: Json_decode.field("lastUpdateDate", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          numberOfConcurrentDownload: Json_decode.field("numberOfConcurrentDownload", Json_decode.$$int, json),
          proxy: Json_decode.optional((function (param) {
                  return Json_decode.field("proxy", decodeProxy, param);
                }), json),
          retryLimit: Json_decode.field("retryLimit", Json_decode.$$int, json),
          retryTimeout: Json_decode.field("retryTimeout", Json_decode.$$int, json),
          timeout: Json_decode.field("timeout", Json_decode.$$int, json),
          serverCredentials: Json_decode.optional((function (param) {
                  return Json_decode.field("serverCredentials", decodeServerCredentials, param);
                }), json),
          url: Json_decode.field("url", Json_decode.string, json),
          whiteList: Json_decode.field("whiteList", Json_decode.bool, json)
        };
}

function serverListGetofJson(json) {
  return Json_decode.list(serverOfJson, json);
}

function logsOfJson(json) {
  return Json_decode.list(logOfJson, json);
}

function logsOfJsonArray(json) {
  return Json_decode.array(logOfJson, json);
}

function symbolOfJson$1(json) {
  return Json_decode.list(symbolOfJson, json);
}

var serverGetofJson = serverOfJson;

function respOfJson(json) {
  return {
          statusCode: Json_decode.optional((function (param) {
                  return Json_decode.field("statusCode", Json_decode.$$int, param);
                }), json),
          message: Json_decode.optional((function (param) {
                  return Json_decode.field("message", Json_decode.string, param);
                }), json),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json),
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json)
        };
}

function serverDeleteofJson(json) {
  return {
          statusCode: Json_decode.optional((function (param) {
                  return Json_decode.field("statusCode", Json_decode.$$int, param);
                }), json),
          message: Json_decode.optional((function (param) {
                  return Json_decode.field("message", Json_decode.string, param);
                }), json),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json),
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json)
        };
}

function symbolDeleteofJson(json) {
  return {
          statusCode: Json_decode.optional((function (param) {
                  return Json_decode.field("statusCode", Json_decode.$$int, param);
                }), json),
          message: Json_decode.optional((function (param) {
                  return Json_decode.field("message", Json_decode.string, param);
                }), json),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json),
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json)
        };
}

function symbolStatusOfJson(json) {
  return {
          logs: Json_decode.field("logs", (function (param) {
                  return Json_decode.tuple2(logsOfJsonArray, Json_decode.$$int, param);
                }), json)
        };
}

function reprocessOfJson(json) {
  return {
          symbolsRequested: Json_decode.field("symbolsRequested", Json_decode.$$int, json),
          symbolsDownloaded: Json_decode.field("symbolsDownloaded", Json_decode.$$int, json),
          symbolsConverted: Json_decode.field("symbolsConverted", Json_decode.$$int, json)
        };
}

function getSymbolServers(universe, project, endpoint, takeOpt, pageOpt, param) {
  var take = takeOpt !== undefined ? takeOpt : 100;
  var page = pageOpt !== undefined ? pageOpt : 0;
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/symbolserver/universe/" + (universe + ("/project/" + (project + ("?take=" + (String(take) + ("&page=" + String(page))))))))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var maybeResultArray = Js_json.decodeArray(param.json);
                if (maybeResultArray === undefined) {
                  return /* Error */Block.__(1, [[
                              Task2$BsConsole.JsonDecodeError,
                              I18N$BsConsole.get(undefined, "Missing result array.")
                            ]]);
                }
                var j = Belt_Array.get(maybeResultArray, 0);
                if (j !== undefined) {
                  return /* Ok */Block.__(0, [Json_decode.list(serverOfJson, Caml_option.valFromOption(j))]);
                } else {
                  return /* Error */Block.__(1, [[
                              Task2$BsConsole.JsonDecodeError,
                              I18N$BsConsole.get(undefined, "Missing server list.")
                            ]]);
                }
              }), undefined, undefined);
}

function getSymbolServer(endpoint, id, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/symbolserver/details/" + String(id))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return /* Ok */Block.__(0, [serverDetailsOfJson(param.json)]);
              }), undefined, undefined);
}

function serverCredentialsToJson(serverCredentials) {
  var credentialFields = makeCredentialFields(serverCredentials.password, serverCredentials.userName, /* UserName */0);
  var match = serverCredentials.connection;
  var tmp;
  if (match !== undefined && !match.tag) {
    var creds = match[0];
    tmp = Belt_List.concat(credentialFields, /* :: */[
          /* tuple */[
            "awsConnection",
            Json_encode.object_(/* :: */[
                  /* tuple */[
                    "lowerFile",
                    creds.lowerFile
                  ],
                  /* :: */[
                    /* tuple */[
                      "lowerId",
                      creds.lowerId
                    ],
                    /* :: */[
                      /* tuple */[
                        "usePdb",
                        creds.usePdb
                      ],
                      /* :: */[
                        /* tuple */[
                          "bucketName",
                          creds.bucketName
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ])
          ],
          /* :: */[
            /* tuple */[
              "type",
              1
            ],
            /* [] */0
          ]
        ]);
  } else {
    tmp = credentialFields;
  }
  return Json_encode.object_(tmp);
}

function postSymbolServer(endpoint, universe, project, bodyType, serverId, body, param) {
  var encoded;
  var exit = 0;
  switch (bodyType) {
    case /* RequiredOnly */0 :
        encoded = Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                body.name
              ],
              /* :: */[
                /* tuple */[
                  "url",
                  body.url
                ],
                /* :: */[
                  /* tuple */[
                    "whiteList",
                    body.whiteList
                  ],
                  /* :: */[
                    /* tuple */[
                      "numberOfConcurrentDownload",
                      body.numberOfConcurrentDownload
                    ],
                    /* :: */[
                      /* tuple */[
                        "timeout",
                        body.timeout
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
        break;
    case /* RequiredPlusProxy */1 :
        encoded = Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                body.name
              ],
              /* :: */[
                /* tuple */[
                  "url",
                  body.url
                ],
                /* :: */[
                  /* tuple */[
                    "whiteList",
                    body.whiteList
                  ],
                  /* :: */[
                    /* tuple */[
                      "numberOfConcurrentDownload",
                      body.numberOfConcurrentDownload
                    ],
                    /* :: */[
                      /* tuple */[
                        "timeout",
                        body.timeout
                      ],
                      /* :: */[
                        /* tuple */[
                          "proxy",
                          Json_encode.object_(Belt_List.concat(makeCredentialFields(body.proxy.password, body.proxy.username, /* UserName */0), /* :: */[
                                    /* tuple */[
                                      "host",
                                      body.proxy.host
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "port",
                                        body.proxy.port
                                      ],
                                      /* [] */0
                                    ]
                                  ]))
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
        break;
    case /* RequiredPlusCredentials */2 :
        encoded = Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                body.name
              ],
              /* :: */[
                /* tuple */[
                  "url",
                  body.url
                ],
                /* :: */[
                  /* tuple */[
                    "whiteList",
                    body.whiteList
                  ],
                  /* :: */[
                    /* tuple */[
                      "numberOfConcurrentDownload",
                      body.numberOfConcurrentDownload
                    ],
                    /* :: */[
                      /* tuple */[
                        "timeout",
                        body.timeout
                      ],
                      /* :: */[
                        /* tuple */[
                          "serverCredentials",
                          Json_encode.object_(makeCredentialFields(body.serverCredentials.password, body.serverCredentials.userName, /* UserName */0))
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
        break;
    case /* RequiredPlusCredentialsAWS */3 :
        encoded = Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                body.name
              ],
              /* :: */[
                /* tuple */[
                  "url",
                  body.url
                ],
                /* :: */[
                  /* tuple */[
                    "whiteList",
                    body.whiteList
                  ],
                  /* :: */[
                    /* tuple */[
                      "numberOfConcurrentDownload",
                      body.numberOfConcurrentDownload
                    ],
                    /* :: */[
                      /* tuple */[
                        "timeout",
                        body.timeout
                      ],
                      /* :: */[
                        /* tuple */[
                          "serverCredentials",
                          serverCredentialsToJson(body.serverCredentials)
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
        break;
    case /* RequiredCredentialsGoogle */4 :
    case /* RequiredProxyCredentialsGoogle */5 :
        exit = 1;
        break;
    case /* RequiredProxyCredentials */6 :
    case /* RequiredProxyCredentialsAWS */7 :
        encoded = Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                body.name
              ],
              /* :: */[
                /* tuple */[
                  "url",
                  body.url
                ],
                /* :: */[
                  /* tuple */[
                    "whiteList",
                    body.whiteList
                  ],
                  /* :: */[
                    /* tuple */[
                      "numberOfConcurrentDownload",
                      body.numberOfConcurrentDownload
                    ],
                    /* :: */[
                      /* tuple */[
                        "timeout",
                        body.timeout
                      ],
                      /* :: */[
                        /* tuple */[
                          "proxy",
                          Json_encode.object_(Belt_List.concat(makeCredentialFields(body.proxy.password, body.proxy.username, /* Username */1), /* :: */[
                                    /* tuple */[
                                      "host",
                                      body.proxy.host
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "port",
                                        body.proxy.port
                                      ],
                                      /* [] */0
                                    ]
                                  ]))
                        ],
                        /* :: */[
                          /* tuple */[
                            "serverCredentials",
                            serverCredentialsToJson(body.serverCredentials)
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
        break;
    
  }
  if (exit === 1) {
    var form = new FormData();
    var match = body.serverCredentials.connection;
    if (match !== undefined && match.tag) {
      var creds = match[0];
      form.append("name", body.name);
      form.append("url", body.url);
      form.append("whiteList", body.whiteList);
      form.append("numberOfConcurrentDownload", body.numberOfConcurrentDownload);
      form.append("timeout", body.timeout);
      var secretFile = creds.secretFile;
      if (secretFile !== undefined) {
        form.append("secretFile", Caml_option.valFromOption(secretFile));
      }
      form.append("serverCredentials[type]", "2");
      form.append("serverCredentials[usePdb]", creds.usePdb);
      form.append("serverCredentials[lowerId]", creds.lowerId);
      form.append("serverCredentials[lowerFile]", creds.lowerFile);
      form.append("serverCredentials[bucketName]", creds.bucketName);
      if (bodyType !== 5) {
        
      } else {
        form.append("proxy[host]", body.proxy.host);
        form.append("proxy[port]", body.proxy.port);
        appendFormDataOnCredentialFields(form, body.proxy.password, body.proxy.username, /* Username */1);
      }
      encoded = form;
    } else {
      encoded = form;
    }
  }
  if (bodyType === 5 || bodyType === 4) {
    return Task2$BsConsole.make(serverId !== undefined ? /* Custom */Block.__(1, [endpoint + ("/symbolserver/" + String(serverId))]) : /* Custom */Block.__(1, [endpoint + ("/symbolserver/universe/" + (universe + ("/project/" + project)))]), undefined, serverId !== undefined ? /* PutForm */Block.__(4, [encoded]) : /* PostForm */Block.__(3, [encoded]), [/* tuple */[
                  "X-Coroner-Location",
                  location.origin
                ]], (function (param) {
                  try {
                    return /* Ok */Block.__(0, [respOfJson(param.json)]);
                  }
                  catch (exn){
                    return /* Error */Block.__(1, [[
                                Task2$BsConsole.JsonDecodeError,
                                undefined
                              ]]);
                  }
                }), undefined, undefined);
  } else {
    return Task2$BsConsole.make(serverId !== undefined ? /* Custom */Block.__(1, [endpoint + ("/symbolserver/" + String(serverId))]) : /* Custom */Block.__(1, [endpoint + ("/symbolserver/universe/" + (universe + ("/project/" + project)))]), undefined, serverId !== undefined ? /* Put */Block.__(1, [encoded]) : /* Post */Block.__(0, [encoded]), [/* tuple */[
                  "X-Coroner-Location",
                  location.origin
                ]], (function (param) {
                  try {
                    return /* Ok */Block.__(0, [respOfJson(param.json)]);
                  }
                  catch (exn){
                    return /* Error */Block.__(1, [[
                                Task2$BsConsole.JsonDecodeError,
                                undefined
                              ]]);
                  }
                }), undefined, undefined);
  }
}

function decodeResultsWithTotal(json, ofJsonDecoder, take) {
  var maybeResultArray = Js_json.decodeArray(json);
  if (maybeResultArray === undefined) {
    return /* Error */Block.__(1, [[
                Task2$BsConsole.JsonDecodeError,
                I18N$BsConsole.get(undefined, "Missing response array.")
              ]]);
  }
  var j = Belt_Array.get(maybeResultArray, 0);
  if (j === undefined) {
    return /* Error */Block.__(1, [[
                Task2$BsConsole.JsonDecodeError,
                I18N$BsConsole.get(undefined, "Missing results array.")
              ]]);
  }
  var v = Belt_Array.get(maybeResultArray, 1);
  var remainder = v !== undefined && Caml_int32.mod_(Json_decode.$$int(Caml_option.valFromOption(v)), take) !== 0 ? 1 : 0;
  var v$1 = Belt_Array.get(maybeResultArray, 1);
  var total = v$1 !== undefined ? Caml_int32.div(Json_decode.$$int(Caml_option.valFromOption(v$1)), take) : 0;
  return /* Ok */Block.__(0, [/* tuple */[
              Curry._1(ofJsonDecoder, Caml_option.valFromOption(j)),
              total + remainder | 0
            ]]);
}

function getSymbolStatus(endpoint, universe, project, symbolName, debugId, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/status/universe/" + (universe + ("/project/" + (project + ("/" + (symbolName + ("/" + debugId)))))))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var status = param.status;
                if (!(status >= 200 && status <= 300)) {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
                var result = symbolStatusOfJson(param.json);
                return /* Ok */Block.__(0, [result.logs]);
              }), undefined, undefined);
}

function getSymbolServerLogs(serverId, endpoint, filter, pageOpt, takeOpt, param) {
  var page = pageOpt !== undefined ? pageOpt : 0;
  var take = takeOpt !== undefined ? takeOpt : 70;
  var fullEndpoint = filter !== undefined ? endpoint + ("/logs/symbolserver/" + (String(serverId) + ("/text?take=" + (String(take) + ("&page=" + (String(page) + ("&text=" + filter))))))) : endpoint + ("/logs/symbolserver/" + (String(serverId) + ("?take=" + (String(take) + ("&page=" + String(page))))));
  return Task2$BsConsole.make(/* Custom */Block.__(1, [fullEndpoint]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return decodeResultsWithTotal(param.json, logsOfJson, take);
              }), undefined, undefined);
}

function getSymbolServerWhitelist(serverId, endpoint, pageOpt, takeOpt, param) {
  var page = pageOpt !== undefined ? pageOpt : 0;
  var take = takeOpt !== undefined ? takeOpt : 70;
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/whitelist/" + (String(serverId) + ("?take=" + (String(take) + ("&page=" + String(page))))))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return decodeResultsWithTotal(param.json, symbolOfJson$1, take);
              }), undefined, undefined);
}

function getSymbolServerBlacklist(serverId, endpoint, pageOpt, takeOpt, param) {
  var page = pageOpt !== undefined ? pageOpt : 0;
  var take = takeOpt !== undefined ? takeOpt : 70;
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/blacklist/" + (String(serverId) + ("?take=" + (String(take) + ("&page=" + String(page))))))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return decodeResultsWithTotal(param.json, symbolOfJson$1, take);
              }), undefined, undefined);
}

function getSymbolServerSkiplist(serverId, endpoint, filter, pageOpt, takeOpt, param) {
  var page = pageOpt !== undefined ? pageOpt : 0;
  var take = takeOpt !== undefined ? takeOpt : 70;
  var fullEndpoint = filter !== undefined ? endpoint + ("/skiplist/" + (String(serverId) + ("/text/" + (filter + ("?take=" + (String(take) + ("&page=" + String(page)))))))) : endpoint + ("/skiplist/" + (String(serverId) + ("?take=" + (String(take) + ("&page=" + String(page))))));
  return Task2$BsConsole.make(/* Custom */Block.__(1, [fullEndpoint]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return decodeResultsWithTotal(param.json, symbolOfJson$1, take);
              }), undefined, undefined);
}

function postWhitelist(endpoint, serverId, body, param) {
  var postEncoded = Json_encode.object_(/* :: */[
        /* tuple */[
          "model",
          Json_encode.list((function (prim) {
                  return prim;
                }), body)
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/whitelist/" + String(serverId))]), undefined, /* Post */Block.__(0, [postEncoded]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var status = param.status;
                if (status >= 200 && status <= 300) {
                  return /* Ok */Block.__(0, [param.statusText]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

function postBlacklist(endpoint, serverId, body, param) {
  var postEncoded = Json_encode.object_(/* :: */[
        /* tuple */[
          "model",
          Json_encode.list((function (prim) {
                  return prim;
                }), body)
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/blacklist/" + String(serverId))]), undefined, /* Post */Block.__(0, [postEncoded]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var status = param.status;
                if (status >= 200 && status <= 300) {
                  return /* Ok */Block.__(0, [param.statusText]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

function priorityObjectEncoder(obj) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "symbolServerId",
                obj.symbolServerId
              ],
              /* :: */[
                /* tuple */[
                  "priority",
                  obj.priority
                ],
                /* [] */0
              ]
            ]);
}

function postPriority(endpoint, universe, project, body, param) {
  var postEncoded = Json_encode.object_(/* :: */[
        /* tuple */[
          "priorities",
          Json_encode.list(priorityObjectEncoder, body)
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/symbolserver/priority/universe/" + (universe + ("/project/" + project)))]), undefined, /* Post */Block.__(0, [postEncoded]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var status = param.status;
                if (status >= 200 && status <= 300) {
                  return /* Ok */Block.__(0, [param.statusText]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

function deleteSymbolServer(endpoint, serverId, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/symbolserver/" + String(serverId))]), undefined, /* Delete */1, undefined, (function (param) {
                return /* Ok */Block.__(0, [serverDeleteofJson(param.json)]);
              }), undefined, undefined);
}

function deleteSymbolWhitelist(endpoint, symbolId, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/whitelist/" + String(symbolId))]), undefined, /* Delete */1, undefined, (function (param) {
                return /* Ok */Block.__(0, [symbolDeleteofJson(param.json)]);
              }), undefined, undefined);
}

function deleteSymbolBlacklist(endpoint, symbolId, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/blacklist/" + String(symbolId))]), undefined, /* Delete */1, undefined, (function (param) {
                return /* Ok */Block.__(0, [symbolDeleteofJson(param.json)]);
              }), undefined, undefined);
}

function deleteSymbolSkiplist(endpoint, symbolId, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/skiplist/" + String(symbolId))]), undefined, /* Delete */1, undefined, (function (param) {
                return /* Ok */Block.__(0, [symbolDeleteofJson(param.json)]);
              }), undefined, undefined);
}

function deleteAllSkiplist(endpoint, serverId, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/skiplist/" + (String(serverId) + "/all"))]), undefined, /* Delete */1, undefined, (function (param) {
                return /* Ok */Block.__(0, [symbolDeleteofJson(param.json)]);
              }), undefined, undefined);
}

function reprocessMissingSyms(endpoint, universeName, projectName, errorId, param) {
  var postEncoded = Json_encode.object_(/* :: */[
        /* tuple */[
          "universeName",
          universeName
        ],
        /* :: */[
          /* tuple */[
            "projectName",
            projectName
          ],
          /* :: */[
            /* tuple */[
              "objectId",
              errorId
            ],
            /* [] */0
          ]
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + "/reprocess"]), undefined, /* Post */Block.__(0, [postEncoded]), undefined, (function (param) {
                return /* Ok */Block.__(0, [reprocessOfJson(param.json)]);
              }), undefined, undefined);
}

exports.statisticsOfJson = statisticsOfJson;
exports.serverOfJson = serverOfJson;
exports.logOfJson = logOfJson;
exports.makeCredentialFields = makeCredentialFields;
exports.appendFormDataOnCredentialFields = appendFormDataOnCredentialFields;
exports.decodeProxy = decodeProxy;
exports.boolParser = boolParser;
exports.decodeAWSConnection = decodeAWSConnection;
exports.decodeGoogleConnection = decodeGoogleConnection;
exports.decodeConnection = decodeConnection;
exports.decodeServerCredentials = decodeServerCredentials;
exports.serverDetailsOfJson = serverDetailsOfJson;
exports.serverListGetofJson = serverListGetofJson;
exports.logsOfJson = logsOfJson;
exports.logsOfJsonArray = logsOfJsonArray;
exports.symbolOfJson = symbolOfJson$1;
exports.serverGetofJson = serverGetofJson;
exports.respOfJson = respOfJson;
exports.serverDeleteofJson = serverDeleteofJson;
exports.symbolDeleteofJson = symbolDeleteofJson;
exports.symbolStatusOfJson = symbolStatusOfJson;
exports.reprocessOfJson = reprocessOfJson;
exports.getSymbolServers = getSymbolServers;
exports.getSymbolServer = getSymbolServer;
exports.serverCredentialsToJson = serverCredentialsToJson;
exports.postSymbolServer = postSymbolServer;
exports.decodeResultsWithTotal = decodeResultsWithTotal;
exports.getSymbolStatus = getSymbolStatus;
exports.getSymbolServerLogs = getSymbolServerLogs;
exports.getSymbolServerWhitelist = getSymbolServerWhitelist;
exports.getSymbolServerBlacklist = getSymbolServerBlacklist;
exports.getSymbolServerSkiplist = getSymbolServerSkiplist;
exports.postWhitelist = postWhitelist;
exports.postBlacklist = postBlacklist;
exports.priorityObjectEncoder = priorityObjectEncoder;
exports.postPriority = postPriority;
exports.deleteSymbolServer = deleteSymbolServer;
exports.deleteSymbolWhitelist = deleteSymbolWhitelist;
exports.deleteSymbolBlacklist = deleteSymbolBlacklist;
exports.deleteSymbolSkiplist = deleteSymbolSkiplist;
exports.deleteAllSkiplist = deleteAllSkiplist;
exports.reprocessMissingSyms = reprocessMissingSyms;
/* I18N-BsConsole Not a pure module */
