// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Task2$BsConsole = require("./Task2.js");

function ofJson(json) {
  return {
          namespace: Json_decode.field("namespace", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          objectId: Json_decode.field("object_id", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          expiryTime: Json_decode.field("expiry_time", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json)
        };
}

var NextObject = {
  ofJson: ofJson
};

function ofJson$1(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          subsets: Json_decode.optional((function (param) {
                  return Json_decode.field("subsets", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json)
        };
}

var Action = {
  ofJson: ofJson$1
};

function ofJson$2(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          op: Json_decode.field("op", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.$$int, json),
          nextObject: Json_decode.field("next_object", ofJson, json)
        };
}

var Criteria = {
  ofJson: ofJson$2
};

function ofJson$3(json) {
  return {
          action: Belt_Array.getExn(Json_decode.field("actions", (function (param) {
                      return Json_decode.array(ofJson$1, param);
                    }), json), 0),
          criteria: Belt_Array.getExn(Json_decode.field("criteria", (function (param) {
                      return Json_decode.array(ofJson$2, param);
                    }), json), 0)
        };
}

var Rule = {
  ofJson: ofJson$3
};

function ofJson$4(json) {
  return {
          state: Json_decode.field("state", Json_decode.string, json),
          rules: Json_decode.field("rules", (function (param) {
                  return Json_decode.array(ofJson$3, param);
                }), json)
        };
}

var Status = {
  NextObject: NextObject,
  Action: Action,
  Criteria: Criteria,
  Rule: Rule,
  ofJson: ofJson$4
};

var component = ReasonReact.reducerComponent("RetentionPolicyStatus-BsConsole__Universe");

function make(token, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              return Curry._1(param.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var update = function (param) {
                return Curry._1(send, /* Request */0);
              };
              return Curry._2(children, param.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            remote: /* Loading */1
                          },
                          (function (self) {
                              var arg = Task2$BsConsole.make(/* Api */Block.__(0, ["/control"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "action",
                                              "rpstatus"
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "token",
                                                token
                                              ],
                                              /* [] */0
                                            ]
                                          ])]), undefined, (function (param) {
                                      return /* Ok */Block.__(0, [ofJson$4(param.json)]);
                                    }), undefined, undefined);
                              return Curry._2((function (param) {
                                              return (function (param$1, param$2) {
                                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                });
                                            })(token), undefined, (function (status) {
                                            if (!status.tag) {
                                              return Curry._1(self.send, /* Success */Block.__(0, [status[0]]));
                                            }
                                            var error = status[0];
                                            if (error[0] === Task2$BsConsole.CoronerError) {
                                              return Curry._1(self.send, /* Failure */Block.__(1, [error[1].message]));
                                            } else {
                                              return Curry._1(self.send, /* Failure */Block.__(1, ["There was an unknown error"]));
                                            }
                                          }));
                            })
                        ]);
              } else if (action.tag) {
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Instance = {
  component: component,
  make: make
};

var component$1 = ReasonReact.reducerComponent("RetentionPolicyStatus-BsConsole__Universe");

function make$1(universe, token, children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: (function (param) {
              return Curry._1(param.send, /* Request */0);
            }),
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var update = function (param) {
                return Curry._1(send, /* Request */0);
              };
              return Curry._2(children, param.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            remote: /* Loading */1
                          },
                          (function (self) {
                              var arg = Task2$BsConsole.make(/* Api */Block.__(0, ["/control"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "action",
                                              "rpstatus"
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "universe",
                                                universe
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "token",
                                                  token
                                                ],
                                                /* [] */0
                                              ]
                                            ]
                                          ])]), undefined, (function (param) {
                                      return /* Ok */Block.__(0, [ofJson$4(param.json)]);
                                    }), undefined, undefined);
                              return Curry._2((function (param) {
                                              return (function (param$1, param$2) {
                                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                });
                                            })(token), undefined, (function (status) {
                                            if (!status.tag) {
                                              return Curry._1(self.send, /* Success */Block.__(0, [status[0]]));
                                            }
                                            var error = status[0];
                                            if (error[0] === Task2$BsConsole.CoronerError) {
                                              return Curry._1(self.send, /* Failure */Block.__(1, [error[1].message]));
                                            } else {
                                              return Curry._1(self.send, /* Failure */Block.__(1, ["There was an unknown error"]));
                                            }
                                          }));
                            })
                        ]);
              } else if (action.tag) {
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var Universe = {
  component: component$1,
  make: make$1
};

var component$2 = ReasonReact.reducerComponent("RetentionPolicyStatus-BsConsole__Project");

function make$2(universe, project, token, children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: (function (param) {
              return Curry._1(param.send, /* Request */0);
            }),
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var update = function (param) {
                return Curry._1(send, /* Request */0);
              };
              return Curry._2(children, param.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            remote: /* Loading */1
                          },
                          (function (self) {
                              var arg = Task2$BsConsole.make(/* Api */Block.__(0, ["/control"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "action",
                                              "rpstatus"
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "universe",
                                                universe
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "project",
                                                  project
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    "token",
                                                    token
                                                  ],
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ])]), undefined, (function (param) {
                                      return /* Ok */Block.__(0, [ofJson$4(param.json)]);
                                    }), undefined, undefined);
                              return Curry._2((function (param) {
                                              return (function (param$1, param$2) {
                                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                });
                                            })(token), undefined, (function (status) {
                                            if (!status.tag) {
                                              return Curry._1(self.send, /* Success */Block.__(0, [status[0]]));
                                            }
                                            var error = status[0];
                                            if (error[0] === Task2$BsConsole.CoronerError) {
                                              return Curry._1(self.send, /* Failure */Block.__(1, [error[1].message]));
                                            } else {
                                              return Curry._1(self.send, /* Failure */Block.__(1, ["There was an unknown error"]));
                                            }
                                          }));
                            })
                        ]);
              } else if (action.tag) {
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var Project = {
  component: component$2,
  make: make$2
};

var RR;

exports.RR = RR;
exports.Status = Status;
exports.Instance = Instance;
exports.Universe = Universe;
exports.Project = Project;
/* component Not a pure module */
