// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var L10N_date_time_settings$BsConsole = require("./L10N_date_time_settings.js");

var Precision = { };

function clockTwelveFormat(precision, formats) {
  switch (precision) {
    case /* Minute */0 :
        return formats.clock.twelve.minute;
    case /* Second */1 :
        return formats.clock.twelve.second;
    case /* Millis */2 :
        return formats.clock.twelve.millis;
    
  }
}

function clockTwentyFourFormat(precision, formats) {
  switch (precision) {
    case /* Minute */0 :
        return formats.clock.twentyFour.minute;
    case /* Second */1 :
        return formats.clock.twentyFour.second;
    case /* Millis */2 :
        return formats.clock.twentyFour.millis;
    
  }
}

function pickFormat(param, formats) {
  var precision = param.precision;
  if (param.scheme) {
    return clockTwentyFourFormat(precision, formats);
  } else {
    return clockTwelveFormat(precision, formats);
  }
}

var Config = {
  clockTwelveFormat: clockTwelveFormat,
  clockTwentyFourFormat: clockTwentyFourFormat,
  pickFormat: pickFormat
};

function fromConfig(formats, config, jsDate) {
  return DateFns.format(pickFormat(config, L10N_date_time_settings$BsConsole.Cache.formats(formats)), jsDate);
}

function hourAndMinute(formats, jsDate) {
  return fromConfig(formats, {
              scheme: L10N_date_time_settings$BsConsole.clockScheme(Curry._1(L10N_date_time_settings$BsConsole.Cache.getExn, undefined)),
              precision: /* Minute */0
            }, jsDate);
}

function hourAndMinute24(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* TwentyFour */1,
              precision: /* Minute */0
            }, jsDate);
}

function getGMTOffset(jsDate) {
  return Belt_Option.getWithDefault(Belt_Array.get(jsDate.toTimeString().split(" "), 1), "UNKNOWN");
}

var Format = {
  fromConfig: fromConfig,
  hourAndMinute: hourAndMinute,
  hourAndMinute24: hourAndMinute24,
  getGMTOffset: getGMTOffset
};

var Formats;

var Scheme;

var Settings;

exports.Formats = Formats;
exports.Scheme = Scheme;
exports.Settings = Settings;
exports.Precision = Precision;
exports.Config = Config;
exports.Format = Format;
/* DateFns Not a pure module */
