// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Belt_MutableMapString = require("bs-platform/lib/js/belt_MutableMapString.js");

function groupThreads(threads) {
  var map = Belt_MutableMapString.make(undefined);
  Belt_Array.forEach(threads, (function (thread) {
          var key = Belt_Array.reduce(thread.user, "", (function (acc, frame) {
                  return acc + frame.symbol;
                }));
          var currThreads = Belt_MutableMapString.getWithDefault(map, key, /* [] */0);
          return Belt_MutableMapString.set(map, key, /* :: */[
                      thread,
                      currThreads
                    ]);
        }));
  return Belt_SortArray.stableSortBy(Belt_Array.map(Belt_Array.map(Belt_Array.map(Belt_MutableMapString.toArray(map), (function (prim) {
                            return prim[1];
                          })), Belt_List.reverse), Belt_List.toArray), (function (g1, g2) {
                var t1 = Belt_Array.getExn(g1, 0);
                var t2 = Belt_Array.getExn(g2, 0);
                return threads.indexOf(t1) - threads.indexOf(t2) | 0;
              }));
}

var Btt = {
  groupThreads: groupThreads
};

function groupThreads$1(threads) {
  var map = Belt_MutableMapString.make(undefined);
  Belt_Array.forEach(threads, (function (thread) {
          var key = Belt_Option.getWithDefault(Belt_Option.map(thread.stack, (function (stack) {
                      return Belt_Array.reduce(stack, "", (function (acc, frame) {
                                    var match = frame.funcName;
                                    var match$1 = frame.address;
                                    return acc + (
                                            (match == null) ? (
                                                (match$1 == null) ? "" : match$1
                                              ) : match
                                          );
                                  }));
                    })), "");
          var currThreads = Belt_MutableMapString.getWithDefault(map, key, /* [] */0);
          return Belt_MutableMapString.set(map, key, /* :: */[
                      thread,
                      currThreads
                    ]);
        }));
  return Belt_SortArray.stableSortBy(Belt_Array.map(Belt_Array.map(Belt_Array.map(Belt_MutableMapString.toArray(map), (function (prim) {
                            return prim[1];
                          })), Belt_List.reverse), Belt_List.toArray), (function (g1, g2) {
                var t1 = Belt_Array.getExn(g1, 0);
                var t2 = Belt_Array.getExn(g2, 0);
                return threads.indexOf(t1) - threads.indexOf(t2) | 0;
              }));
}

var Generic = {
  groupThreads: groupThreads$1
};

exports.Btt = Btt;
exports.Generic = Generic;
/* No side effect */
