'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = Rows;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _shape = require('@vx/shape');

var _group = require('@vx/group');

var _point = require('@vx/point');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function Rows(_ref) {
  var _ref$top = _ref.top,
      top = _ref$top === undefined ? 0 : _ref$top,
      _ref$left = _ref.left,
      left = _ref$left === undefined ? 0 : _ref$left,
      scale = _ref.scale,
      width = _ref.width,
      _ref$stroke = _ref.stroke,
      stroke = _ref$stroke === undefined ? '#eaf0f6' : _ref$stroke,
      _ref$strokeWidth = _ref.strokeWidth,
      strokeWidth = _ref$strokeWidth === undefined ? 1 : _ref$strokeWidth,
      strokeDasharray = _ref.strokeDasharray,
      className = _ref.className,
      _ref$numTicks = _ref.numTicks,
      numTicks = _ref$numTicks === undefined ? 10 : _ref$numTicks,
      lineStyle = _ref.lineStyle,
      offset = _ref.offset,
      restProps = _objectWithoutProperties(_ref, ['top', 'left', 'scale', 'width', 'stroke', 'strokeWidth', 'strokeDasharray', 'className', 'numTicks', 'lineStyle', 'offset']);

  var ticks = scale.ticks ? scale.ticks(numTicks) : scale.domain();
  return _react2.default.createElement(
    _group.Group,
    { className: (0, _classnames2.default)('vx-rows', className), top: top, left: left },
    ticks.map(function (d, i) {
      var y = offset ? scale(d) + offset : scale(d);
      var fromPoint = new _point.Point({
        x: 0,
        y: y
      });
      var toPoint = new _point.Point({
        x: width,
        y: y
      });
      return _react2.default.createElement(_shape.Line, _extends({
        key: 'row-line-' + d + '-' + i,
        from: fromPoint,
        to: toPoint,
        stroke: stroke,
        strokeWidth: strokeWidth,
        strokeDasharray: strokeDasharray,
        style: lineStyle
      }, restProps));
    })
  );
}