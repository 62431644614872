// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("../../I18N.js");
var SSAUtils$BsConsole = require("./SSAUtils.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");

function groupActions(all) {
  return Belt_Array.reduce(all, {
              ignoreGroup: [],
              setValueGroup: [],
              indexOnlyGroup: [],
              unionGroup: []
            }, (function (acc, curr) {
                var unionGroup = acc.unionGroup;
                var indexOnlyGroup = acc.indexOnlyGroup;
                var setValueGroup = acc.setValueGroup;
                var ignoreGroup = acc.ignoreGroup;
                switch (curr.tag | 0) {
                  case /* Ignore */0 :
                      return {
                              ignoreGroup: Belt_Array.concat(ignoreGroup, [curr]),
                              setValueGroup: setValueGroup,
                              indexOnlyGroup: indexOnlyGroup,
                              unionGroup: unionGroup
                            };
                  case /* IndexOnly */1 :
                      return {
                              ignoreGroup: ignoreGroup,
                              setValueGroup: setValueGroup,
                              indexOnlyGroup: Belt_Array.concat(indexOnlyGroup, [curr]),
                              unionGroup: unionGroup
                            };
                  case /* SetAttribute */2 :
                  case /* SetAssignee */3 :
                      break;
                  case /* UnionTags */4 :
                      return {
                              ignoreGroup: ignoreGroup,
                              setValueGroup: setValueGroup,
                              indexOnlyGroup: indexOnlyGroup,
                              unionGroup: Belt_Array.concat(unionGroup, [curr])
                            };
                  
                }
                return {
                        ignoreGroup: ignoreGroup,
                        setValueGroup: Belt_Array.concat(setValueGroup, [curr]),
                        indexOnlyGroup: indexOnlyGroup,
                        unionGroup: unionGroup
                      };
              }));
}

function concatActionsInOrder(allActions) {
  return Belt_Array.concatMany([
              allActions.ignoreGroup,
              allActions.indexOnlyGroup,
              allActions.setValueGroup,
              allActions.unionGroup
            ]);
}

function addNewActionToGroup(allActions, newAction) {
  var unionGroup = allActions.unionGroup;
  var indexOnlyGroup = allActions.indexOnlyGroup;
  var setValueGroup = allActions.setValueGroup;
  var ignoreGroup = allActions.ignoreGroup;
  switch (newAction.tag | 0) {
    case /* Ignore */0 :
        var newIgnore = Belt_Array.concat(ignoreGroup, [newAction]);
        return {
                ignoreGroup: newIgnore,
                setValueGroup: setValueGroup,
                indexOnlyGroup: indexOnlyGroup,
                unionGroup: unionGroup
              };
    case /* IndexOnly */1 :
        var newIndexOnly = Belt_Array.concat(indexOnlyGroup, [newAction]);
        return {
                ignoreGroup: ignoreGroup,
                setValueGroup: setValueGroup,
                indexOnlyGroup: newIndexOnly,
                unionGroup: unionGroup
              };
    case /* SetAttribute */2 :
    case /* SetAssignee */3 :
        break;
    case /* UnionTags */4 :
        var newUnion = Belt_Array.concat(unionGroup, [newAction]);
        return {
                ignoreGroup: ignoreGroup,
                setValueGroup: setValueGroup,
                indexOnlyGroup: indexOnlyGroup,
                unionGroup: newUnion
              };
    
  }
  var newSetValue = Belt_Array.concat(setValueGroup, [newAction]);
  return {
          ignoreGroup: ignoreGroup,
          setValueGroup: newSetValue,
          indexOnlyGroup: indexOnlyGroup,
          unionGroup: unionGroup
        };
}

function addNewAction(allActions, newAction) {
  return concatActionsInOrder(addNewActionToGroup(allActions, newAction));
}

function removeActionFromGroup(allActions, index, action) {
  var unionGroup = allActions.unionGroup;
  var indexOnlyGroup = allActions.indexOnlyGroup;
  var setValueGroup = allActions.setValueGroup;
  var ignoreGroup = allActions.ignoreGroup;
  switch (action.tag | 0) {
    case /* Ignore */0 :
        var newIgnore = Belt_Array.keepWithIndex(ignoreGroup, (function (param, i) {
                return index !== i;
              }));
        return {
                ignoreGroup: newIgnore,
                setValueGroup: setValueGroup,
                indexOnlyGroup: indexOnlyGroup,
                unionGroup: unionGroup
              };
    case /* IndexOnly */1 :
        var newIndexOnly = Belt_Array.keepWithIndex(indexOnlyGroup, (function (param, i) {
                return index !== i;
              }));
        return {
                ignoreGroup: ignoreGroup,
                setValueGroup: setValueGroup,
                indexOnlyGroup: newIndexOnly,
                unionGroup: unionGroup
              };
    case /* SetAttribute */2 :
    case /* SetAssignee */3 :
        break;
    case /* UnionTags */4 :
        var newUnion = Belt_Array.keepWithIndex(unionGroup, (function (param, i) {
                return index !== i;
              }));
        return {
                ignoreGroup: ignoreGroup,
                setValueGroup: setValueGroup,
                indexOnlyGroup: indexOnlyGroup,
                unionGroup: newUnion
              };
    
  }
  var newSetValue = Belt_Array.keepWithIndex(setValueGroup, (function (param, i) {
          return index !== i;
        }));
  return {
          ignoreGroup: ignoreGroup,
          setValueGroup: newSetValue,
          indexOnlyGroup: indexOnlyGroup,
          unionGroup: unionGroup
        };
}

function removeAction(allActions, index, action) {
  return concatActionsInOrder(removeActionFromGroup(allActions, index, action));
}

function hasNoActions(all) {
  if (SSAUtils$BsConsole.isEmptyArr(all.ignoreGroup) && SSAUtils$BsConsole.isEmptyArr(all.setValueGroup) && SSAUtils$BsConsole.isEmptyArr(all.indexOnlyGroup)) {
    return SSAUtils$BsConsole.isEmptyArr(all.unionGroup);
  } else {
    return false;
  }
}

function increaseActionPriority(original, index) {
  if (index <= 0) {
    return original;
  }
  try {
    var newIndex = index - 1 | 0;
    return Belt_Array.makeBy(original.length, (function (i) {
                  if (i === newIndex) {
                    return Belt_Array.getExn(original, index);
                  } else if (i === index) {
                    return Belt_Array.getExn(original, newIndex);
                  } else {
                    return Belt_Array.getExn(original, i);
                  }
                }));
  }
  catch (exn){
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.orderError));
    return original;
  }
}

function decreaseActionPriority(original, index) {
  if (index >= (original.length - 1 | 0)) {
    return original;
  }
  try {
    var newIndex = index + 1 | 0;
    return Belt_Array.makeBy(original.length, (function (i) {
                  if (i === newIndex) {
                    return Belt_Array.getExn(original, index);
                  } else if (i === index) {
                    return Belt_Array.getExn(original, newIndex);
                  } else {
                    return Belt_Array.getExn(original, i);
                  }
                }));
  }
  catch (exn){
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.orderError));
    return original;
  }
}

exports.groupActions = groupActions;
exports.concatActionsInOrder = concatActionsInOrder;
exports.addNewActionToGroup = addNewActionToGroup;
exports.addNewAction = addNewAction;
exports.removeActionFromGroup = removeActionFromGroup;
exports.removeAction = removeAction;
exports.hasNoActions = hasNoActions;
exports.increaseActionPriority = increaseActionPriority;
exports.decreaseActionPriority = decreaseActionPriority;
/* I18N-BsConsole Not a pure module */
