// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Services$BsConsole = require("../Services.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var ExporterSets$BsConsole = require("../metrics-exporter/ExporterSets.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var ExporterUtils$BsConsole = require("../metrics-exporter/ExporterUtils.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var ExporterStores$BsConsole = require("../metrics-exporter/ExporterStores.js");
var FeatureService$BsConsole = require("../FeatureService.js");
var ExporterStoreApi$BsConsole = require("../metrics-exporter/api/ExporterStoreApi.js");
var ExporterExportApi$BsConsole = require("../metrics-exporter/api/ExporterExportApi.js");
var ExporterMetricApi$BsConsole = require("../metrics-exporter/api/ExporterMetricApi.js");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

function PS_MetricsExporter$Main(Props) {
  var config = Props.config;
  var token = Props.token;
  var project = Props.project;
  var endpoint = Props.endpoint;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setStores = match[1];
  var stores = match[0];
  var match$1 = React.useState((function () {
          return /* [] */0;
        }));
  var setExports = match$1[1];
  var $$exports = match$1[0];
  var match$2 = React.useState((function () {
          return /* [] */0;
        }));
  var setMetrics = match$2[1];
  var fetchStores = function (param) {
    var arg = ExporterStoreApi$BsConsole.get(endpoint, config.universe.name, project.name, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setStores, (function (param) {
                                return result;
                              }));
                }));
  };
  var fetchExports = function (param) {
    var arg = ExporterExportApi$BsConsole.get(endpoint, config.universe.name, project.name, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setExports, (function (param) {
                                return result;
                              }));
                }));
  };
  var fetchMetrics = function (param) {
    var arg = ExporterMetricApi$BsConsole.get(endpoint, config.universe.name, project.name, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setMetrics, (function (param) {
                                return result;
                              }));
                }));
  };
  React.useEffect((function () {
          fetchStores(undefined);
          
        }), /* tuple */[
        token,
        endpoint,
        config.universe.name,
        project.name
      ]);
  React.useEffect((function () {
          fetchExports(undefined);
          
        }), /* tuple */[
        token,
        endpoint,
        config.universe.name,
        project.name
      ]);
  React.useEffect((function () {
          fetchMetrics(undefined);
          
        }), /* tuple */[
        token,
        endpoint,
        config.universe.name,
        project.name
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: featureDescription,
                  children: I18N$BsConsole.show(undefined, "Send Backtrace data out to external dashboard applications.")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        pageMarginBottom,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Metrics sets")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: featureDescription,
                  children: I18N$BsConsole.show(undefined, "Metrics sets are a collection of different aggregation queries. Create metrics sets in order to export them to other applications.")
                }), React.createElement(ExporterSets$BsConsole.make, {
                  token: token,
                  endpoint: endpoint,
                  universeName: config.universe.name,
                  projectName: project.name,
                  stores: stores,
                  exports: $$exports,
                  fetchExports: fetchExports,
                  metrics: match$2[0],
                  fetchMetrics: fetchMetrics,
                  handleChangeUrl: handleChangeUrl
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        pageMarginBottom,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Metrics stores")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: featureDescription,
                  children: I18N$BsConsole.show(undefined, "Metrics stores are responsible for forwarding a metric set to an external metric system.")
                }), React.createElement(ExporterStores$BsConsole.make, {
                  token: token,
                  config: config,
                  endpoint: endpoint,
                  universeName: config.universe.name,
                  projectName: project.name,
                  stores: stores,
                  setStores: setStores,
                  exports: $$exports
                }));
}

var Main = {
  make: PS_MetricsExporter$Main
};

function PS_MetricsExporter(Props) {
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var project = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectName;
        }));
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  var endpoint = Services$BsConsole.getEndpoint(undefined)(config.services, ExporterUtils$BsConsole.servicename);
  if (endpoint !== undefined) {
    return React.createElement(BtSettings$BsConsole.Container.make, {
                children: null
              }, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading5 */4,
                    className: Css.style(/* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ]),
                    children: I18N$BsConsole.show(undefined, "Metrics exporter")
                  }), project !== undefined ? React.createElement(PS_MetricsExporter$Main, {
                      config: config,
                      token: token,
                      project: project,
                      endpoint: endpoint,
                      handleChangeUrl: handleChangeUrl
                    }) : React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Heading6 */5,
                      className: Css.style(/* :: */[
                            pageMarginBottom,
                            /* [] */0
                          ]),
                      children: I18N$BsConsole.show(undefined, "Project not found")
                    }));
  } else {
    return React.createElement(FeatureService$BsConsole.Unavailable.make, {
                serviceName: ExporterUtils$BsConsole.servicename
              });
  }
}

function make(config, handleChangeUrl, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_MetricsExporter, {
              config: config,
              handleChangeUrl: handleChangeUrl
            }, children);
}

var Re = {
  make: make
};

var make$1 = PS_MetricsExporter;

exports.pageMarginBottom = pageMarginBottom;
exports.featureDescription = featureDescription;
exports.Main = Main;
exports.make = make$1;
exports.Re = Re;
/* pageMarginBottom Not a pure module */
