// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var Lens = require("@material-ui/icons/Lens");

function fromString(x) {
  switch (x) {
    case "bt-none-selected" :
        return /* NoneSelected */7;
    case "configuration" :
        return /* Configuration */6;
    case "http" :
        return /* Http */3;
    case "log" :
        return /* Log */1;
    case "manual" :
        return /* Manual */0;
    case "navigation" :
        return /* Navigation */2;
    case "system" :
        return /* System */4;
    case "user" :
        return /* User */5;
    default:
      return /* Unknown */[x];
  }
}

function toString(x) {
  if (typeof x !== "number") {
    return x[0];
  }
  switch (x) {
    case /* Manual */0 :
        return "manual";
    case /* Log */1 :
        return "log";
    case /* Navigation */2 :
        return "navigation";
    case /* Http */3 :
        return "http";
    case /* System */4 :
        return "system";
    case /* User */5 :
        return "user";
    case /* Configuration */6 :
        return "configuration";
    case /* NoneSelected */7 :
        return "bt-none-selected";
    
  }
}

function toFriendlyString(x) {
  if (typeof x !== "number") {
    return x[0];
  }
  switch (x) {
    case /* Manual */0 :
        return I18N$BsConsole.get(undefined, "Manual");
    case /* Log */1 :
        return I18N$BsConsole.get(undefined, "Log");
    case /* Navigation */2 :
        return I18N$BsConsole.get(undefined, "Navigation");
    case /* Http */3 :
        return I18N$BsConsole.get(undefined, "HTTP");
    case /* System */4 :
        return I18N$BsConsole.get(undefined, "System");
    case /* User */5 :
        return I18N$BsConsole.get(undefined, "User");
    case /* Configuration */6 :
        return I18N$BsConsole.get(undefined, "Configuration");
    case /* NoneSelected */7 :
        return I18N$BsConsole.get(undefined, "No types");
    
  }
}

function toColor(param) {
  if (typeof param !== "number") {
    return Colors$BsConsole.grey2;
  }
  switch (param) {
    case /* Manual */0 :
        return Colors$BsConsole.tagLightBlue;
    case /* Log */1 :
        return Colors$BsConsole.tagLightPurple;
    case /* Navigation */2 :
        return Colors$BsConsole.tagDarkPink;
    case /* Http */3 :
        return Colors$BsConsole.tagPink;
    case /* System */4 :
        return Colors$BsConsole.teal;
    case /* User */5 :
        return Colors$BsConsole.orange;
    case /* Configuration */6 :
        return Colors$BsConsole.grey4;
    case /* NoneSelected */7 :
        return Colors$BsConsole.grey2;
    
  }
}

function isUnknown(param) {
  if (typeof param === "number") {
    return false;
  } else {
    return true;
  }
}

var other = /* Unknown */["Other types"];

var $$default = [
  /* Manual */0,
  /* Log */1,
  /* Navigation */2,
  /* Http */3,
  /* System */4,
  /* User */5,
  /* Configuration */6,
  other
];

function arrayToString(arr) {
  return Belt_Array.map(arr, toFriendlyString).join(", ");
}

var Type_ = {
  fromString: fromString,
  toString: toString,
  toFriendlyString: toFriendlyString,
  toColor: toColor,
  isUnknown: isUnknown,
  other: other,
  $$default: $$default,
  arrayToString: arrayToString
};

var highlightIcon = React.createElement(Lens.default, {
      className: Css.style(/* :: */[
            Css.color(Css.hex(Colors$BsConsole.red)),
            /* :: */[
              Css.important(Css.width(Css.px(6))),
              /* :: */[
                Css.marginRight(Css.px(5)),
                /* [] */0
              ]
            ]
          ])
    });

function fromString$1(x) {
  switch (x) {
    case "bt-none-selected" :
        return /* NoneSelected */5;
    case "debug" :
        return /* Debug */4;
    case "error" :
        return /* Error */1;
    case "fatal" :
        return /* Fatal */0;
    case "info" :
        return /* Info */3;
    case "warning" :
        return /* Warning */2;
    default:
      return /* Unknown */[x];
  }
}

function toString$1(x) {
  if (typeof x !== "number") {
    return x[0];
  }
  switch (x) {
    case /* Fatal */0 :
        return "fatal";
    case /* Error */1 :
        return "error";
    case /* Warning */2 :
        return "warning";
    case /* Info */3 :
        return "info";
    case /* Debug */4 :
        return "debug";
    case /* NoneSelected */5 :
        return "bt-none-selected";
    
  }
}

function toFriendlyString$1(x) {
  if (typeof x !== "number") {
    return x[0];
  }
  switch (x) {
    case /* Fatal */0 :
        return I18N$BsConsole.get(undefined, "Fatal");
    case /* Error */1 :
        return I18N$BsConsole.get(undefined, "Error");
    case /* Warning */2 :
        return I18N$BsConsole.get(undefined, "Warning");
    case /* Info */3 :
        return I18N$BsConsole.get(undefined, "Info");
    case /* Debug */4 :
        return I18N$BsConsole.get(undefined, "Debug");
    case /* NoneSelected */5 :
        return I18N$BsConsole.get(undefined, "No levels");
    
  }
}

function toAdornment(param) {
  if (typeof param === "number" && param < 2) {
    return Caml_option.some(highlightIcon);
  }
  
}

function isUnknown$1(param) {
  if (typeof param === "number") {
    return false;
  } else {
    return true;
  }
}

var other$1 = /* Unknown */["Other levels"];

var $$default$1 = [
  /* Fatal */0,
  /* Error */1,
  /* Warning */2,
  /* Info */3,
  /* Debug */4,
  other$1
];

function arrayToString$1(arr) {
  return Belt_Array.map(arr, toFriendlyString$1).join(", ");
}

var Level = {
  fromString: fromString$1,
  toString: toString$1,
  toFriendlyString: toFriendlyString$1,
  toAdornment: toAdornment,
  isUnknown: isUnknown$1,
  other: other$1,
  $$default: $$default$1,
  arrayToString: arrayToString$1
};

exports.Type_ = Type_;
exports.highlightIcon = highlightIcon;
exports.Level = Level;
/* highlightIcon Not a pure module */
