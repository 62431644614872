// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var WfArray$BsConsole = require("./WfArray.js");
var WfArrayRow$BsConsole = require("./WfArrayRow.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");
var BeautifulDnd$BsConsole = require("../bindings/BeautifulDnd.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var FetchDescribe$BsConsole = require("../FetchDescribe.js");
var WfAttributeAutocomplete$BsConsole = require("./WfAttributeAutocomplete.js");

function emptyAction(action) {
  return {
          attribute: "",
          regExp: "",
          target: "",
          action: action
        };
}

function localizeAction(action) {
  switch (action) {
    case "filter" :
        return I18N$BsConsole.show(undefined, "Filter");
    case "mention" :
        return I18N$BsConsole.show(undefined, "Mention");
    case "submit" :
        return I18N$BsConsole.show(undefined, "Submit");
    default:
      return I18N$BsConsole.show(undefined, action);
  }
}

function toJson(state) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "_actions",
                Json_encode.array((function (action) {
                        return Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "action",
                                      action.action
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "target",
                                        action.target
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "data",
                                          action.attribute
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "expression",
                                            action.regExp
                                          ],
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]);
                      }), Belt_Array.map(state.actions, (function (prim) {
                            return prim[1];
                          })))
              ],
              /* [] */0
            ]);
}

function fromJson(json) {
  var actionsJson = Json_decode.field("_actions", (function (param) {
          return Json_decode.array(Util$BsConsole.identity, param);
        }), json);
  var actions = Belt_Array.map(Belt_Array.map(actionsJson, (function (actionJson) {
              return {
                      attribute: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                  return Json_decode.field("data", Json_decode.string, param);
                                }), actionJson), ""),
                      regExp: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                  return Json_decode.field("expression", Json_decode.string, param);
                                }), actionJson), ""),
                      target: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                  return Json_decode.field("target", Json_decode.string, param);
                                }), actionJson), ""),
                      action: Json_decode.field("action", Json_decode.string, actionJson)
                    };
            })), (function (action) {
          return /* tuple */[
                  WfArray$BsConsole.generateId(undefined),
                  action
                ];
        }));
  return {
          actions: actions
        };
}

var component = RR$BsConsole.reducerComponent("WfAdvancedSettings-BsConsole");

function make(initialValue, onValueChange, token, handleTask, projectName, advancedSettings, param) {
  var sendNewValue = function (param) {
    return Curry._1(onValueChange, /* Ok */Block.__(0, [toJson(param.state)]));
  };
  var actions = Belt_Option.getWithDefault(advancedSettings, [
        "filter",
        "mention",
        "submit"
      ]);
  var defaultAction = Belt_Array.get(actions, 0);
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: sendNewValue,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var state = param.state;
              return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make({
                                  title: I18N$BsConsole.get(undefined, "Additional rules"),
                                  description: I18N$BsConsole.get(undefined, "Rules are executed in order")
                                }, undefined, "56px 0 0 32px", undefined, undefined, false, false, [])), React.createElement("div", {
                              style: {
                                display: "flex",
                                paddingTop: "28px",
                                paddingRight: "40px",
                                paddingLeft: "32px"
                              }
                            }, React.createElement("div", {
                                  style: {
                                    paddingRight: "1rem",
                                    width: "25%"
                                  }
                                }, React.createElement("div", {
                                      style: {
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "135%"
                                      }
                                    }, I18N$BsConsole.show(undefined, "Attribute")), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Attribute to test against")])))), React.createElement("div", {
                                  style: {
                                    paddingRight: "1rem",
                                    width: "25%"
                                  }
                                }, React.createElement("div", {
                                      style: {
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "135%"
                                      }
                                    }, I18N$BsConsole.show(undefined, "Regular expression")), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Regular expression to match against.")])))), React.createElement("div", {
                                  style: {
                                    paddingRight: "1rem",
                                    width: "25%"
                                  }
                                }, React.createElement("div", {
                                      style: {
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "135%"
                                      }
                                    }, I18N$BsConsole.show(undefined, "Target")), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "User or channel to alert, is supported.")])))), React.createElement("div", {
                                  style: {
                                    width: "25%"
                                  }
                                }, React.createElement("div", {
                                      style: {
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "135%"
                                      }
                                    }, I18N$BsConsole.show(undefined, "Action")), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Action to take if criteria are met.")]))))), ReasonReact.element(undefined, undefined, FetchDescribe$BsConsole.make(handleTask, token, projectName, (function (remote, param) {
                                    var attributes;
                                    attributes = typeof remote === "number" ? [] : (
                                        remote.tag ? [] : Belt_List.toArray(remote[0][0])
                                      );
                                    return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.DragDropContext.make((function (e) {
                                                          return Curry._1(send, /* MoveAction */Block.__(1, [
                                                                        e.source.index,
                                                                        e.destination.index
                                                                      ]));
                                                        }), [ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.Droppable.make("droppable", (function (provided, param) {
                                                                    var __x = state.actions;
                                                                    return React.createElement("div", {
                                                                                ref: provided.innerRef
                                                                              }, Belt_Array.mapWithIndex(__x, (function (index, param) {
                                                                                      var action = param[1];
                                                                                      var key = param[0];
                                                                                      return ReasonReact.element(key, undefined, BeautifulDnd$BsConsole.Draggable.make(key, index, undefined, (function (provided, param) {
                                                                                                        return React.cloneElement(React.createElement("div", {
                                                                                                                        ref: provided.innerRef
                                                                                                                      }, ReasonReact.element(undefined, undefined, WfArrayRow$BsConsole.make((function (param) {
                                                                                                                                  return Curry._1(send, /* RemoveAction */Block.__(2, [index]));
                                                                                                                                }), provided.dragHandleProps, [React.createElement("div", {
                                                                                                                                      style: {
                                                                                                                                        display: "flex"
                                                                                                                                      }
                                                                                                                                    }, React.createElement("div", {
                                                                                                                                          style: {
                                                                                                                                            paddingRight: "1rem",
                                                                                                                                            width: "25%"
                                                                                                                                          }
                                                                                                                                        }, ReasonReact.element(undefined, undefined, WfAttributeAutocomplete$BsConsole.make(attributes, action.attribute, (function (v) {
                                                                                                                                                    return Curry._1(send, /* UpdateAction */Block.__(0, [
                                                                                                                                                                  key,
                                                                                                                                                                  {
                                                                                                                                                                    attribute: v,
                                                                                                                                                                    regExp: action.regExp,
                                                                                                                                                                    target: action.target,
                                                                                                                                                                    action: action.action
                                                                                                                                                                  }
                                                                                                                                                                ]));
                                                                                                                                                  }), []))), React.createElement("div", {
                                                                                                                                          style: {
                                                                                                                                            paddingRight: "1rem",
                                                                                                                                            width: "25%"
                                                                                                                                          }
                                                                                                                                        }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  (function (e) {
                                                                                                                                                      return Curry._1(send, /* UpdateAction */Block.__(0, [
                                                                                                                                                                    key,
                                                                                                                                                                    {
                                                                                                                                                                      attribute: action.attribute,
                                                                                                                                                                      regExp: e.target.value,
                                                                                                                                                                      target: action.target,
                                                                                                                                                                      action: action.action
                                                                                                                                                                    }
                                                                                                                                                                  ]));
                                                                                                                                                    }),
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  /* `String */[
                                                                                                                                                    -976970511,
                                                                                                                                                    action.regExp
                                                                                                                                                  ],
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  []
                                                                                                                                                ]))), React.createElement("div", {
                                                                                                                                          style: {
                                                                                                                                            paddingRight: "1rem",
                                                                                                                                            width: "25%"
                                                                                                                                          }
                                                                                                                                        }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  (function (e) {
                                                                                                                                                      return Curry._1(send, /* UpdateAction */Block.__(0, [
                                                                                                                                                                    key,
                                                                                                                                                                    {
                                                                                                                                                                      attribute: action.attribute,
                                                                                                                                                                      regExp: action.regExp,
                                                                                                                                                                      target: e.target.value,
                                                                                                                                                                      action: action.action
                                                                                                                                                                    }
                                                                                                                                                                  ]));
                                                                                                                                                    }),
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  /* `String */[
                                                                                                                                                    -976970511,
                                                                                                                                                    action.target
                                                                                                                                                  ],
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  []
                                                                                                                                                ]))), React.createElement("div", {
                                                                                                                                          style: {
                                                                                                                                            width: "25%"
                                                                                                                                          }
                                                                                                                                        }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  /* `String */[
                                                                                                                                                    -976970511,
                                                                                                                                                    action.action
                                                                                                                                                  ],
                                                                                                                                                  (function (e, param) {
                                                                                                                                                      return Curry._1(send, /* UpdateAction */Block.__(0, [
                                                                                                                                                                    key,
                                                                                                                                                                    {
                                                                                                                                                                      attribute: action.attribute,
                                                                                                                                                                      regExp: action.regExp,
                                                                                                                                                                      target: action.target,
                                                                                                                                                                      action: e.target.value
                                                                                                                                                                    }
                                                                                                                                                                  ]));
                                                                                                                                                    }),
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  true,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  undefined,
                                                                                                                                                  [Belt_Array.map(actions, (function (action) {
                                                                                                                                                            return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                                                                                                                                            -976970511,
                                                                                                                                                                            action
                                                                                                                                                                          ], undefined, undefined, [localizeAction(action)]));
                                                                                                                                                          }))]
                                                                                                                                                ]))))]))), provided.draggableProps);
                                                                                                      })));
                                                                                    })), provided.placeholder);
                                                                  })))])));
                                  }))), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                  undefined,
                                  undefined,
                                  (function (param) {
                                      return Curry._1(send, /* AddNewAction */0);
                                    }),
                                  undefined,
                                  undefined,
                                  undefined,
                                  /* Secondary */67972948,
                                  undefined,
                                  {
                                    marginTop: "4px",
                                    marginLeft: "12px"
                                  },
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  [I18N$BsConsole.show(undefined, "Add another rule")]
                                ])));
            }),
          initialState: (function (param) {
              if (initialValue === undefined) {
                return {
                        actions: []
                      };
              }
              if (initialValue.tag) {
                throw [
                      Caml_builtin_exceptions.assert_failure,
                      /* tuple */[
                        "WfAdvancedSettings.re",
                        82,
                        26
                      ]
                    ];
              }
              try {
                return fromJson(initialValue[0]);
              }
              catch (exn){
                return {
                        actions: []
                      };
              }
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (defaultAction !== undefined) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              actions: Belt_Array.concat(state.actions, [/* tuple */[
                                      WfArray$BsConsole.generateId(undefined),
                                      emptyAction(defaultAction)
                                    ]])
                            },
                            sendNewValue
                          ]);
                } else {
                  return /* NoUpdate */0;
                }
              }
              switch (action.tag | 0) {
                case /* UpdateAction */0 :
                    var updatedAction = action[1];
                    var updatedKey = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                actions: Belt_Array.map(state.actions, (function (param) {
                                        var key = param[0];
                                        if (key === updatedKey) {
                                          return /* tuple */[
                                                  key,
                                                  updatedAction
                                                ];
                                        } else {
                                          return /* tuple */[
                                                  key,
                                                  param[1]
                                                ];
                                        }
                                      }))
                              },
                              sendNewValue
                            ]);
                case /* MoveAction */1 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                actions: WfArray$BsConsole.updateArrayItemPosition(state.actions, action[0], action[1])
                              },
                              sendNewValue
                            ]);
                case /* RemoveAction */2 :
                    var index = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                actions: Belt_Array.keepWithIndex(state.actions, (function (param, i) {
                                        return i !== index;
                                      }))
                              },
                              sendNewValue
                            ]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.emptyAction = emptyAction;
exports.localizeAction = localizeAction;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.component = component;
exports.make = make;
/* component Not a pure module */
