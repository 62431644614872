// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Button = require("@material-ui/core/Button");
var BtTypeaheadInput$BsConsole = require("../utilities/BtTypeaheadInput.js");

function PlayTypeaheadInput(Props) {
  var match = React.useState((function () {
          return false;
        }));
  var setSettingsModal = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setSelectedUser = match$1[1];
  var selectedUser = match$1[0];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setText = match$2[1];
  var match$3 = React.useState((function () {
          return [];
        }));
  var setMultiUsers = match$3[1];
  var multiUsers = match$3[0];
  var allUsersRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Users.fetchAll, undefined);
  var users;
  users = typeof allUsersRemote === "number" ? [] : (
      allUsersRemote.tag ? [] : allUsersRemote[0][0]
    );
  var userToString = function (t) {
    return t.username;
  };
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.px(550)),
                      /* [] */0
                    ])
              },
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Typeahead Input Forms")
                }), React.createElement(Row2$BsConsole.make, {
                  children: null
                }, React.createElement(Col2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(25)),
                            /* :: */[
                              Css.width(Css.px(250)),
                              /* [] */0
                            ]
                          ]),
                      children: null
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.margin(Css.rem(1)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "BtTypeaheadInput.Single")
                        }), React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.margin(Css.px(10)),
                                /* [] */0
                              ])
                        }, React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                              selectedItem: selectedUser,
                              toString: userToString,
                              inputLabel: I18N$BsConsole.get(undefined, "User"),
                              placeholder: I18N$BsConsole.get(undefined, "Search for a user"),
                              noItemsMessage: I18N$BsConsole.get(undefined, "You don't have any users"),
                              items: users,
                              itemFilter: (function (param) {
                                  return true;
                                }),
                              pixelWidth: 250,
                              handleItemSelection: (function (item) {
                                  return Curry._1(setSelectedUser, (function (param) {
                                                return item;
                                              }));
                                })
                            })), React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.margin(Css.px(10)),
                                /* [] */0
                              ])
                        }, React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                              selectedItem: selectedUser,
                              toString: userToString,
                              inputLabel: I18N$BsConsole.get(undefined, "User (with create new button)"),
                              placeholder: I18N$BsConsole.get(undefined, "Search for a user"),
                              noItemsMessage: I18N$BsConsole.get(undefined, "You don't have any users"),
                              createNewItemMessage: I18N$BsConsole.get(undefined, "Create a new user"),
                              items: users,
                              itemFilter: (function (param) {
                                  return true;
                                }),
                              pixelWidth: 250,
                              handleItemSelection: (function (item) {
                                  return Curry._1(setSelectedUser, (function (param) {
                                                return item;
                                              }));
                                }),
                              handleCreateNewItem: (function (param) {
                                  console.log("create a user");
                                  
                                })
                            }), React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                              selectedItem: selectedUser,
                              toString: userToString,
                              inputLabel: I18N$BsConsole.get(undefined, "No items example"),
                              placeholder: I18N$BsConsole.get(undefined, "Search for a user"),
                              noItemsMessage: I18N$BsConsole.get(undefined, "You don't have any users"),
                              createNewItemMessage: I18N$BsConsole.get(undefined, "Create a new user"),
                              items: [],
                              itemFilter: (function (param) {
                                  return true;
                                }),
                              pixelWidth: 250,
                              handleItemSelection: (function (item) {
                                  return Curry._1(setSelectedUser, (function (param) {
                                                return item;
                                              }));
                                }),
                              handleCreateNewItem: (function (param) {
                                  console.log("create a user");
                                  
                                })
                            }), React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Heading6 */5,
                              className: Css.style(/* :: */[
                                    Css.margin(Css.rem(1)),
                                    /* [] */0
                                  ]),
                              children: I18N$BsConsole.show(undefined, "BtSettings.FormInput")
                            }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                              placeholder: I18N$BsConsole.get(undefined, "placeholder"),
                              title: I18N$BsConsole.get(undefined, "Title"),
                              value: match$2[0],
                              label: I18N$BsConsole.show(undefined, "Here for comparision. Should have same style."),
                              onChange: (function ($$event) {
                                  var value = $$event.target.value;
                                  return Curry._1(setText, (function (param) {
                                                return value;
                                              }));
                                }),
                              className: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ])
                            }))), React.createElement(Col2$BsConsole.make, {
                      children: null
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.margin(Css.rem(1)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "BtTypeaheadInput.Multi")
                        }), React.createElement(BtTypeaheadInput$BsConsole.Multi.make, {
                          selectedItems: multiUsers,
                          items: users,
                          toString: userToString,
                          singleItemName: I18N$BsConsole.get(undefined, "user"),
                          pluralItemName: I18N$BsConsole.get(undefined, "users"),
                          placeholder: I18N$BsConsole.get(undefined, "Search for a user"),
                          noItemsMessage: I18N$BsConsole.get(undefined, "You don't have any users"),
                          itemFilter: (function (param) {
                              return true;
                            }),
                          pixelWidth: 250,
                          handleAllItemsAfterChange: (function (items) {
                              return Curry._1(setMultiUsers, (function (param) {
                                            return items;
                                          }));
                            })
                        }))), React.createElement(Row2$BsConsole.make, {
                  children: null
                }, React.createElement(Button.default, {
                      variant: I18N$BsConsole.get(undefined, "contained"),
                      color: I18N$BsConsole.get(undefined, "primary"),
                      classes: {
                        root: Css.style(/* :: */[
                              Css.margin(Css.px(20)),
                              /* :: */[
                                Css.width(Css.px(300)),
                                /* [] */0
                              ]
                            ])
                      },
                      onClick: (function (param) {
                          return Curry._1(setSettingsModal, (function (param) {
                                        return true;
                                      }));
                        }),
                      children: I18N$BsConsole.show(undefined, "Modal")
                    }), React.createElement(BtSettings$BsConsole.Container.make, {
                      children: React.createElement(BtModal$BsConsole.Default.make, {
                            open: match[0],
                            onClose: (function (param) {
                                return Curry._1(setSettingsModal, (function (param) {
                                              return false;
                                            }));
                              }),
                            modalHeight: 350,
                            title: I18N$BsConsole.get(undefined, "This is the title"),
                            subtitle: I18N$BsConsole.get(undefined, "This is the subtitle"),
                            divider: true,
                            children: React.createElement(BtTypeaheadInput$BsConsole.Multi.make, {
                                  selectedItems: multiUsers,
                                  items: users,
                                  toString: userToString,
                                  singleItemName: I18N$BsConsole.get(undefined, "user"),
                                  pluralItemName: I18N$BsConsole.get(undefined, "users"),
                                  placeholder: I18N$BsConsole.get(undefined, "Search for a user"),
                                  noItemsMessage: I18N$BsConsole.get(undefined, "You don't have any users"),
                                  itemFilter: (function (param) {
                                      return true;
                                    }),
                                  pixelWidth: 250,
                                  handleAllItemsAfterChange: (function (items) {
                                      return Curry._1(setMultiUsers, (function (param) {
                                                    return items;
                                                  }));
                                    })
                                })
                          })
                    })));
}

var make = PlayTypeaheadInput;

exports.make = make;
/* Css Not a pure module */
