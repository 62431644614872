// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var I18N$BsConsole = require("./I18N.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var ApiDemoProject$BsConsole = require("./ApiDemoProject.js");

function handleGenerateDemoProject(platform, setLoadingDemoData, config, token, generatedFrom) {
  Curry._1(setLoadingDemoData, (function (param) {
          return true;
        }));
  return ApiDemoProject$BsConsole.DemoGenerator.generateDemoProject(config, token, platform, (function (proj) {
                if (generatedFrom) {
                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* GenerateDemoProject */Block.__(2, [ApiDemoProject$BsConsole.Platform.typeToString(platform)])]));
                } else {
                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* GenerateDemoProjectViaProjectCreation */Block.__(3, [ApiDemoProject$BsConsole.Platform.typeToString(platform)])]));
                }
                var __x = window;
                __x.location = "/p/" + proj;
                
              }), (function (param) {
                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Encountered an unknown error while generating demo project. Please try again later."));
                return Curry._1(setLoadingDemoData, (function (param) {
                              return false;
                            }));
              }));
}

exports.handleGenerateDemoProject = handleGenerateDemoProject;
/* I18N-BsConsole Not a pure module */
