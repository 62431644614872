// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Gate$BsConsole = require("../Gate.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var DataCard$BsConsole = require("../utilities/DataCard.js");
var Services$BsConsole = require("../Services.js");
var StorageFetch$BsConsole = require("../StorageFetch.js");
var SubLimitsUtil$BsConsole = require("./SubLimitsUtil.js");
var ApiBillingLimits$BsConsole = require("../ApiBillingLimits.js");
var MessageFeatureGate$BsConsole = require("../MessageFeatureGate.js");
var BillingFeatureFallback$BsConsole = require("../BillingFeatureFallback.js");

function SubStorageLimit(Props) {
  var config = Props.config;
  var token = Props.token;
  var limits = Props.limits;
  var extension = Props.extension;
  var storageLimitMaybe = ApiBillingLimits$BsConsole.getStorageLimitInBytes(limits);
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setStorageUsage = match[1];
  var storageUsage = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setData = match$1[1];
  var data = match$1[0];
  React.useEffect((function () {
          var endpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "storage");
          if (endpoint !== undefined) {
            var arg = StorageFetch$BsConsole.makeTask(endpoint + "/universe");
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (resp) {
                    if (resp.tag) {
                      var err = resp[0];
                      return Curry._1(setStorageUsage, (function (param) {
                                    return /* Failure */Block.__(1, [err]);
                                  }));
                    }
                    var res = resp[0];
                    return Curry._1(setStorageUsage, (function (param) {
                                  return /* Success */Block.__(0, [res]);
                                }));
                  }));
          }
          
        }), /* tuple */[
        config,
        token
      ]);
  React.useEffect((function () {
          if (typeof storageUsage === "number") {
            storageUsage === /* NotAsked */0;
          } else if (storageUsage.tag) {
            if (storageLimitMaybe !== undefined) {
              Curry._1(setData, (function (param) {
                      return /* tuple */[
                              undefined,
                              undefined,
                              /* Error */Block.__(0, [I18N$BsConsole.get(undefined, "Usage unknown")])
                            ];
                    }));
            } else {
              Curry._1(setData, (function (param) {
                      return /* tuple */[
                              undefined,
                              undefined,
                              /* Error */Block.__(0, [I18N$BsConsole.get(undefined, "Limit and usage unknown")])
                            ];
                    }));
            }
          } else {
            var uni = storageUsage[0];
            if (storageLimitMaybe !== undefined) {
              var totalUsed = SubLimitsUtil$BsConsole.getStorageUsed(limits, uni);
              if (storageLimitMaybe === 0) {
                Curry._1(setData, (function (param) {
                        return /* tuple */[
                                undefined,
                                totalUsed,
                                undefined
                              ];
                      }));
              } else {
                Curry._1(setData, (function (param) {
                        return /* tuple */[
                                storageLimitMaybe,
                                totalUsed,
                                undefined
                              ];
                      }));
              }
            } else {
              var totalUsed$1 = SubLimitsUtil$BsConsole.getStorageUsed(limits, uni);
              Curry._1(setData, (function (param) {
                      return /* tuple */[
                              undefined,
                              totalUsed$1,
                              undefined
                            ];
                    }));
            }
          }
          
        }), /* tuple */[
        storageUsage,
        storageLimitMaybe
      ]);
  if (data === undefined) {
    return null;
  }
  var storageLimit = data[0];
  if (storageLimit === undefined) {
    var storageUsed = data[1];
    if (storageUsed !== undefined) {
      return React.createElement(DataCard$BsConsole.UnlimitedCard.make, {
                  title: I18N$BsConsole.get(undefined, "Disk space consumed"),
                  numerator: storageUsed,
                  verb: I18N$BsConsole.get(undefined, "used"),
                  formatter: /* Bytes */1,
                  failure: data[2],
                  extra: /* Text */Block.__(0, [I18N$BsConsole.get(undefined, "Currently, your billed storage consumption will not include symbol storage")]),
                  extension: Belt_Option.getWithDefault(extension, null)
                });
    }
    
  }
  return React.createElement(DataCard$BsConsole.make, {
              title: I18N$BsConsole.get(undefined, "Disk space consumed"),
              numerator: Belt_Option.getWithDefault(data[1], 0.0),
              denominator: Belt_Option.getWithDefault(storageLimit, 0.0),
              verb: I18N$BsConsole.get(undefined, "used"),
              formatter: /* Bytes */1,
              failure: data[2],
              extra: /* Text */Block.__(0, [I18N$BsConsole.get(undefined, "Currently, your billed storage consumption will not include symbol storage")]),
              extension: React.createElement(MessageFeatureGate$BsConsole.make, {
                    showMessage: Gate$BsConsole.isBillingFeatureDisabled(/* DiskSpaceConsumed */1),
                    message: React.createElement(BillingFeatureFallback$BsConsole.make, { }),
                    children: Belt_Option.getWithDefault(extension, null)
                  })
            });
}

var make = SubStorageLimit;

exports.make = make;
/* react Not a pure module */
