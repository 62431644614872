// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var SubPriceUtil$BsConsole = require("./SubPriceUtil.js");
var NemesisFreeze$BsConsole = require("./NemesisFreeze.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var NemesisMetrics$BsConsole = require("./NemesisMetrics.js");
var Tooltip = require("@material-ui/core/Tooltip");
var SubFeatureIncrementerConfirmModal$BsConsole = require("./SubFeatureIncrementerConfirmModal.js");

function removeZeroQuantity(addons) {
  return Belt_Array.keep(addons, (function (addon) {
                var n = addon.quantity;
                if (n !== undefined) {
                  return n > 0;
                } else {
                  return true;
                }
              }));
}

function recordAction(direction, featureName, view) {
  switch (direction.tag | 0) {
    case /* Add */0 :
    case /* AddUserWithFrozenOptions */1 :
        return NemesisMetrics$BsConsole.send(/* AddFeature */Block.__(0, [
                      featureName,
                      view
                    ]));
    case /* Remove */2 :
        return NemesisMetrics$BsConsole.send(/* RemoveFeature */Block.__(1, [
                      featureName,
                      view
                    ]));
    
  }
}

function SubFeatureIncrementer(Props) {
  var token = Props.token;
  var config = Props.config;
  var limits = Props.limits;
  var refetchLimits = Props.refetchLimits;
  var plan = Props.plan;
  var plans = Props.plans;
  var planQuantity = Props.planQuantity;
  var subscription = Props.subscription;
  var addons = Props.addons;
  var featureName = Props.featureName;
  var updateSubscriptions = Props.updateSubscriptions;
  var formatter = Props.formatter;
  var invoiceMe = Props.invoiceMe;
  var subscriptionsPageOpt = Props.subscriptionsPage;
  var subscriptionsPage = subscriptionsPageOpt !== undefined ? subscriptionsPageOpt : true;
  var match = React.useState((function () {
          
        }));
  var setModal = match[1];
  var modal = match[0];
  var match$1 = React.useState((function () {
          return SubPriceUtil$BsConsole.totalPriceOfPlanPlusAddons(subscription, plans);
        }));
  var setCurrentPrice = match$1[1];
  var match$2 = React.useState((function () {
          
        }));
  var selectedUser = match$2[0];
  var trial = limits.metadata.nemesisTrial;
  var frozenUidsMaybe = trial !== undefined ? trial.frozenUids : undefined;
  React.useEffect((function () {
          var price = SubPriceUtil$BsConsole.totalPriceOfPlanPlusAddons(subscription, plans);
          Curry._1(setCurrentPrice, (function (param) {
                  return price;
                }));
          
        }), /* tuple */[
        plans,
        subscription
      ]);
  var featureMaybe = Belt_Array.get(Belt_Array.keep(plan.incrementingFeatures, (function (incrementingFeature) {
              return incrementingFeature.name === featureName;
            })), 0);
  var addOne = function (feature) {
    var match = feature.planType;
    var newPlanQuantity = match ? planQuantity + 1 | 0 : planQuantity;
    var match$1 = feature.planType;
    var newAddons;
    if (match$1) {
      newAddons = addons;
    } else {
      var featureAlreadyAdded = Belt_Option.isSome(Belt_Array.get(Belt_Array.keep(addons, (function (addon) {
                      return addon.id === feature.id;
                    })), 0));
      if (featureAlreadyAdded) {
        newAddons = Belt_Array.map(addons, (function (addon) {
                if (addon.id !== feature.id) {
                  return addon;
                }
                var n = addon.quantity;
                if (n !== undefined) {
                  return {
                          id: addon.id,
                          quantity: n + 1 | 0
                        };
                } else {
                  return addon;
                }
              }));
      } else {
        var newAddon_id = feature.id;
        var newAddon_quantity = 1;
        var newAddon = {
          id: newAddon_id,
          quantity: newAddon_quantity
        };
        newAddons = Belt_Array.concat(addons, [newAddon]);
      }
    }
    var newAddonsWithoutZero = removeZeroQuantity(newAddons);
    return /* tuple */[
            plan.id,
            newPlanQuantity,
            newAddonsWithoutZero
          ];
  };
  var removeOne = function (feature) {
    var match = feature.planType;
    var newPlanQuantity = match && planQuantity > 0 ? planQuantity - 1 | 0 : planQuantity;
    var match$1 = feature.planType;
    var newAddons = match$1 ? addons : Belt_Array.map(addons, (function (addon) {
              if (addon.id !== feature.id) {
                return addon;
              }
              var n = addon.quantity;
              if (n !== undefined) {
                return {
                        id: addon.id,
                        quantity: n > 0 ? n - 1 | 0 : n
                      };
              } else {
                return addon;
              }
            }));
    var newAddonsWithoutZero = removeZeroQuantity(newAddons);
    return /* tuple */[
            plan.id,
            newPlanQuantity,
            newAddonsWithoutZero
          ];
  };
  if (featureMaybe === undefined) {
    return null;
  }
  var s = featureMaybe.label;
  var labelString = s !== undefined ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
              /* String */Block.__(2, [
                  /* No_padding */0,
                  /* End_of_format */0
                ]),
              "%s"
            ]), s) : featureMaybe.name;
  var match$3 = featureMaybe.planType;
  var disableRemoveButtonDueToQuantity = match$3 ? planQuantity === 1 : Belt_Option.isNone(Belt_Array.get(Belt_Array.keep(addons, (function (addon) {
                    return addon.id === featureMaybe.id;
                  })), 0));
  var tooltipTitle = SubUtil$BsConsole.getPlanModificationTooltip(config, subscription, invoiceMe, subscriptionsPage);
  return React.createElement(Row2$BsConsole.make, {
              children: null,
              key: featureMaybe.id
            }, React.createElement(Tooltip.default, {
                  children: React.createElement("div", undefined, React.createElement(Button.default, {
                            color: "primary",
                            disabled: !SubUtil$BsConsole.canModifyNemesisPlans(config) || !SubUtil$BsConsole.hasValidPaymentMethod(subscription, invoiceMe),
                            onClick: (function (param) {
                                var match = addOne(featureMaybe);
                                var addons = match[2];
                                var planQuantity = match[1];
                                var planId = match[0];
                                var match$1 = featureMaybe.name;
                                if (match$1 === "users" && frozenUidsMaybe !== undefined) {
                                  if (Belt_List.length(frozenUidsMaybe) > 0) {
                                    return Curry._1(setModal, (function (param) {
                                                  return /* AddUserWithFrozenOptions */Block.__(1, [/* tuple */[
                                                              planId,
                                                              planQuantity,
                                                              addons,
                                                              frozenUidsMaybe
                                                            ]]);
                                                }));
                                  } else {
                                    return Curry._1(setModal, (function (param) {
                                                  return /* Add */Block.__(0, [/* tuple */[
                                                              planId,
                                                              planQuantity,
                                                              addons
                                                            ]]);
                                                }));
                                  }
                                }
                                return Curry._1(setModal, (function (param) {
                                              return /* Add */Block.__(0, [/* tuple */[
                                                          planId,
                                                          planQuantity,
                                                          addons
                                                        ]]);
                                            }));
                              }),
                            children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Add ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "Add %s"
                                    ]), labelString)
                          }), React.createElement(Button.default, {
                            color: "primary",
                            disabled: !SubUtil$BsConsole.canModifyNemesisPlans(config) || disableRemoveButtonDueToQuantity || !SubUtil$BsConsole.hasValidPaymentMethod(subscription, invoiceMe),
                            onClick: (function (param) {
                                var removeDetails = removeOne(featureMaybe);
                                return Curry._1(setModal, (function (param) {
                                              return /* Remove */Block.__(2, [removeDetails]);
                                            }));
                              }),
                            children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Remove ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "Remove %s"
                                    ]), labelString)
                          })),
                  title: tooltipTitle,
                  onOpen: (function (param) {
                      return NemesisMetrics$BsConsole.send(/* TooltipImpression */Block.__(2, [tooltipTitle]));
                    })
                }), React.createElement(SubFeatureIncrementerConfirmModal$BsConsole.make, {
                  modal: modal,
                  setModal: setModal,
                  onConfirm: (function (param) {
                      if (modal === undefined) {
                        return Curry._1(setModal, (function (param) {
                                      
                                    }));
                      }
                      switch (modal.tag | 0) {
                        case /* Add */0 :
                            var match = modal[0];
                            var newAddons = match[2];
                            var newPlanQuantity = match[1];
                            var planId = match[0];
                            recordAction(/* Add */Block.__(0, [/* tuple */[
                                      planId,
                                      newPlanQuantity,
                                      newAddons
                                    ]]), featureName, subscriptionsPage ? "subscriptions" : "invite-user");
                            Curry._1(setModal, (function (param) {
                                    
                                  }));
                            return Curry._3(updateSubscriptions, planId, newPlanQuantity, newAddons);
                        case /* AddUserWithFrozenOptions */1 :
                            var match$1 = modal[0];
                            var frozenUids = match$1[3];
                            var newAddons$1 = match$1[2];
                            var newPlanQuantity$1 = match$1[1];
                            var planId$1 = match$1[0];
                            recordAction(/* AddUserWithFrozenOptions */Block.__(1, [/* tuple */[
                                      planId$1,
                                      newPlanQuantity$1,
                                      newAddons$1,
                                      frozenUids
                                    ]]), featureName, subscriptionsPage ? "subscriptions" : "invite-user");
                            Curry._1(setModal, (function (param) {
                                    
                                  }));
                            if (selectedUser === undefined) {
                              return Curry._3(updateSubscriptions, planId$1, newPlanQuantity$1, newAddons$1);
                            }
                            var newFrozenUids = Belt_List.filter(frozenUids, (function (frozenUid) {
                                    return frozenUid !== selectedUser.uid;
                                  }));
                            var arg = NemesisFreeze$BsConsole.update(newFrozenUids);
                            return Curry._2((function (param) {
                                            return (function (param$1, param$2) {
                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                              });
                                          })(token), undefined, (function (resp) {
                                          if (resp.tag) {
                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SubUtil$BsConsole.unknownErrorMessage));
                                          }
                                          var reason = resp[0];
                                          if (reason) {
                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                /* String_literal */Block.__(11, [
                                                                    "Error updating accounts: ",
                                                                    /* String */Block.__(2, [
                                                                        /* No_padding */0,
                                                                        /* End_of_format */0
                                                                      ])
                                                                  ]),
                                                                "Error updating accounts: %s"
                                                              ]), reason[0]));
                                          } else {
                                            Curry._3(updateSubscriptions, planId$1, newPlanQuantity$1, newAddons$1);
                                            return Curry._1(refetchLimits, undefined);
                                          }
                                        }));
                        case /* Remove */2 :
                            var match$2 = modal[0];
                            var newAddons$2 = match$2[2];
                            var newPlanQuantity$2 = match$2[1];
                            var planId$2 = match$2[0];
                            recordAction(/* Remove */Block.__(2, [/* tuple */[
                                      planId$2,
                                      newPlanQuantity$2,
                                      newAddons$2
                                    ]]), featureName, subscriptionsPage ? "subscriptions" : "invite-user");
                            Curry._1(setModal, (function (param) {
                                    
                                  }));
                            return Curry._3(updateSubscriptions, planId$2, newPlanQuantity$2, newAddons$2);
                        
                      }
                    }),
                  currentPrice: match$1[0],
                  plan: plan,
                  formatter: formatter,
                  feature: featureMaybe,
                  selectedUser: selectedUser,
                  setSelectedUser: match$2[1]
                }));
}

var make = SubFeatureIncrementer;

exports.removeZeroQuantity = removeZeroQuantity;
exports.recordAction = recordAction;
exports.make = make;
/* react Not a pure module */
