// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Lazy = require("bs-platform/lib/js/lazy.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var CamlinternalLazy = require("bs-platform/lib/js/camlinternalLazy.js");
var BugTree$BsConsole = require("./BugTree.js");
var BugTreeNode$BsConsole = require("./BugTreeNode.js");

function BugGlobalAnnotations(Props) {
  var globalAnnotations = Props.globalAnnotations;
  var jsonTrees = React.useMemo((function () {
          return Belt_Array.concatMany(Belt_Array.map(globalAnnotations, (function (annotation) {
                            var match = annotation.key;
                            if (match[0] >= 3752319) {
                              return [BugTreeNode$BsConsole.fromJson(match[1], undefined, undefined, CamlinternalLazy.force(annotation.json))];
                            }
                            var json = CamlinternalLazy.force(annotation.json);
                            try {
                              var context = Json_decode.optional((function (param) {
                                      return Json_decode.field("context", Json_decode.string, param);
                                    }), json);
                              var jsonDict = Json_decode.dict(Util$BsConsole.identity, json);
                              if (context !== undefined) {
                                return [BugTreeNode$BsConsole.make("context", Caml_option.some(I18N$BsConsole.showSkip(context)), Belt_Array.keepMap(Js_dict.entries(jsonDict), (function (param) {
                                                    var key = param[0];
                                                    if (key === "context") {
                                                      return ;
                                                    }
                                                    var value = param[1];
                                                    return Lazy.from_fun((function (param) {
                                                                  return BugTreeNode$BsConsole.fromJson(key, 0, undefined, value);
                                                                }));
                                                  })), -1, undefined, undefined, undefined, undefined)];
                              } else {
                                return Belt_Array.map(Js_dict.entries(jsonDict), (function (param) {
                                              return BugTreeNode$BsConsole.fromJson(param[0], undefined, undefined, param[1]);
                                            }));
                              }
                            }
                            catch (exn){
                              return [BugTreeNode$BsConsole.fromJson(String(match[1]), undefined, undefined, CamlinternalLazy.force(annotation.json))];
                            }
                          })));
        }), [globalAnnotations]);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.padding(Css.px(8)),
                    /* [] */0
                  ])
            }, Belt_Array.map(jsonTrees, (function (node) {
                    return React.createElement(BugTree$BsConsole.make, {
                                node: node
                              });
                  })));
}

var make = BugGlobalAnnotations;

exports.make = make;
/* Css Not a pure module */
