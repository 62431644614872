// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Task2$BsConsole = require("../Task2.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var Configuration$BsConsole = require("../configuration.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var WatcherNewTask$BsConsole = require("../WatcherNewTask.js");
var WorkflowVersion$BsConsole = require("./WorkflowVersion.js");

var Behavior = { };

function make(integrationId, userFriendlyName, project, watcherNameOpt, behavior, formValue, ownerId, path) {
  var watcherName = watcherNameOpt !== undefined ? watcherNameOpt : project.name + ("-" + userFriendlyName);
  return {
          integrationId: integrationId,
          userFriendlyName: userFriendlyName,
          watcherName: watcherName,
          project: project,
          ownerId: ownerId,
          behavior: behavior,
          settings: formValue,
          state: undefined,
          state__: undefined,
          path: path,
          type_: undefined
        };
}

function toBpg(watcher) {
  var match = watcher.behavior;
  return {
          integration_id: watcher.integrationId,
          name: watcher.watcherName,
          project: watcher.project.pid,
          owner: watcher.ownerId,
          event_type: match !== undefined ? (
              match.eventType ? "group" : "trace"
            ) : "disabled",
          action: watcher.path,
          action_args: undefined,
          action_type: "script",
          period: Belt_Option.mapWithDefault(watcher.behavior, 0, (function (behavior) {
                  return behavior.frequency;
                })),
          sum: Belt_Option.mapWithDefault(watcher.behavior, 0, (function (behavior) {
                  return behavior.threshold;
                })),
          settings: JSON.stringify(Object.assign(watcher.settings, {
                    path: watcher.path
                  })),
          state: watcher.state,
          type_: watcher.type_,
          __state: undefined,
          __create: undefined,
          __modify: undefined
        };
}

function getUserFriendlyName(watcherName, projectName) {
  if (watcherName.includes(projectName)) {
    return watcherName.slice(projectName.length + 1 | 0);
  } else {
    return watcherName;
  }
}

function fromBpg(project, watcherNew) {
  var settings = JSON.parse(watcherNew.settings);
  if (project.pid !== watcherNew.project) {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "WfWatcher.re",
            110,
            41
          ]
        ];
  }
  var match = watcherNew.event_type;
  var behavior;
  switch (match) {
    case "disabled" :
        behavior = undefined;
        break;
    case "group" :
        behavior = {
          eventType: /* Group */1,
          frequency: watcherNew.period,
          threshold: watcherNew.sum
        };
        break;
    case "trace" :
        behavior = {
          eventType: /* Trace */0,
          frequency: watcherNew.period,
          threshold: watcherNew.sum
        };
        break;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "WfWatcher.re",
              120,
              11
            ]
          ];
  }
  return {
          integrationId: watcherNew.integration_id,
          userFriendlyName: getUserFriendlyName(watcherNew.name, project.name),
          watcherName: watcherNew.name,
          project: project,
          ownerId: watcherNew.owner,
          behavior: behavior,
          settings: settings,
          state: watcherNew.state,
          state__: Belt_Option.map(watcherNew.__state, (function (state) {
                  var f = Json_decode.field("activity", Json_decode.$$float, state);
                  return {
                          lastActivity: f !== 0 ? Caml_option.some(new Date(f * 1000)) : undefined,
                          failing: Json_decode.field("failing", Json_decode.bool, state),
                          failureLog: Json_decode.field("failure_log", (function (param) {
                                  return Json_decode.array(Util$BsConsole.identity, param);
                                }), state),
                          successes: Json_decode.optional((function (param) {
                                  return Json_decode.field("successes", Json_decode.$$int, param);
                                }), state),
                          failures: Json_decode.field("failures", Json_decode.$$int, state),
                          pending: Json_decode.field("pending", Json_decode.$$int, state)
                        };
                })),
          path: watcherNew.action,
          type_: watcherNew.type_
        };
}

function fromConfig(config, projectName) {
  var ws = Configuration$BsConsole.getWatchers(projectName, config);
  return Belt_Option.map(ws, (function (param) {
                return Belt_List.map(param[1], (function (w) {
                              return {
                                      userFriendlyName: getUserFriendlyName(w.name, projectName),
                                      watcherName: w.name,
                                      type_: w.integrationType,
                                      integrationId: undefined
                                    };
                            }));
              }));
}

function useWatchers(maybePid, token, projectName, config, onSuccess, onFailure) {
  React.useEffect((function () {
          if (!TeamsUtils$BsConsole.isGuest(config)) {
            var match = WorkflowVersion$BsConsole.current(config);
            if (typeof match === "number") {
              if (match !== 0) {
                Curry._1(onSuccess, Belt_List.toArray(Belt_Option.getWithDefault(fromConfig(config, projectName), /* [] */0)));
              } else {
                Curry._1(onFailure, "Workflow service unavailable");
              }
            } else if (maybePid !== undefined) {
              var maybeTask = WatcherNewTask$BsConsole.fetchAllWatchers(config, projectName);
              if (maybeTask !== undefined) {
                Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                        if (!param.tag) {
                          return Curry._1(onSuccess, Belt_Array.map(Belt_Array.keep(param[0], (function (watcher) {
                                                return watcher.project === maybePid;
                                              })), (function (watcher) {
                                            return {
                                                    userFriendlyName: getUserFriendlyName(watcher.name, projectName),
                                                    watcherName: watcher.name,
                                                    type_: Belt_Option.getWithDefault(watcher.type_, ""),
                                                    integrationId: watcher.integration_id
                                                  };
                                          })));
                        }
                        var err = param[0];
                        if (err[0] === Task2$BsConsole.ErrorMsg) {
                          var __x = Backtrace$BsConsole.Attributes.addString("errorType", "Task2.ErrorMsg", Backtrace$BsConsole.Attributes.addString("src_module", "WfWatcher/useWatchersFromBpg", Backtrace$BsConsole.Attributes.make(undefined)));
                          Backtrace$BsConsole.Client.send(__x, /* `string */[
                                288368849,
                                err[1]
                              ]);
                        } else if (err[0] === Task2$BsConsole.JsonDecodeError) {
                          var __x$1 = Backtrace$BsConsole.Attributes.addString("errorType", "Task2.JsonDecodeError", Backtrace$BsConsole.Attributes.addString("src_module", "WfWatcher/useWatchersFromBpg", Backtrace$BsConsole.Attributes.make(undefined)));
                          Backtrace$BsConsole.Client.send(__x$1, /* `string */[
                                288368849,
                                Belt_Option.getWithDefault(err[1], "JSONDecodeError: failed to fetch watchers.")
                              ]);
                        } else if (err[0] === Task2$BsConsole.UncaughtPromiseError) {
                          var __x$2 = Backtrace$BsConsole.Attributes.addString("errorType", "Task2.UncaughtPromiseError", Backtrace$BsConsole.Attributes.addString("src_module", "WfWatcher/useWatchersFromBpg", Backtrace$BsConsole.Attributes.make(undefined)));
                          Backtrace$BsConsole.Client.send(__x$2, /* `string */[
                                288368849,
                                Belt_Option.getWithDefault(JSON.stringify(err[1][0]), "UncaughtPromiseError: failed to fetch watchers.")
                              ]);
                        } else {
                          var __x$3 = Backtrace$BsConsole.Attributes.addString("src_module", "WfWatcher/useWatchersFromBpg", Backtrace$BsConsole.Attributes.make(undefined));
                          Backtrace$BsConsole.Client.send(__x$3, /* `string */[
                                288368849,
                                "Error: failed to fetch watchers."
                              ]);
                        }
                        return Curry._1(onFailure, undefined);
                      }));
              } else {
                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
              }
            } else {
              var __x = Backtrace$BsConsole.Attributes.addString("src_module", "WfWatcher.re/useWatchers", Backtrace$BsConsole.Attributes.make(undefined));
              Backtrace$BsConsole.Client.send(__x, /* `string */[
                    288368849,
                    "\"Missing Project ID. Failed to fetch watchers\""
                  ]);
              Curry._1(onFailure, "Undefined Project ID ");
            }
          }
          
        }), /* tuple */[
        maybePid,
        token,
        projectName,
        config
      ]);
  
}

exports.Behavior = Behavior;
exports.make = make;
exports.toBpg = toBpg;
exports.getUserFriendlyName = getUserFriendlyName;
exports.fromBpg = fromBpg;
exports.fromConfig = fromConfig;
exports.useWatchers = useWatchers;
/* react Not a pure module */
