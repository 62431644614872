// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Crdb$BsConsole = require("./crdb.js");
var Task$BsConsole = require("./task.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var ApiDescribe$BsConsole = require("./apiDescribe.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var Toolbar = require("@material-ui/core/Toolbar");
var FilterInputControl$BsConsole = require("./FilterInputControl.js");
var FilterQueryBuilder$BsConsole = require("./FilterQueryBuilder.js");
var ClickAwayListener = require("@material-ui/core/ClickAwayListener");

var toolbar = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.flexWrap(/* nowrap */867913355),
        /* :: */[
          Css.unsafe("overflow", "inherit"),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.position(/* relative */903134412),
              /* :: */[
                Css.flexGrow(1),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  toolbar: toolbar
};

function sortAttributes(removeUnused, attributes) {
  var filteredAttributes = Belt_List.keep(attributes, (function (attr) {
          var match = attr.name;
          if (match === "fingerprint;issues;assignee") {
            return Belt_Option.getWithDefault(Belt_Option.map(Belt_List.getBy(attributes, (function (attribute) {
                                  return attribute.name === "fingerprint;issues;ticket";
                                })), (function (attribute) {
                              return attribute.statistics.used;
                            })), false);
          } else if (removeUnused && !attr.custom) {
            return attr.statistics.used;
          } else {
            return true;
          }
        }));
  var sortedCustom = Belt_List.sort(Belt_List.keep(filteredAttributes, (function (attr) {
              return attr.custom;
            })), (function (a, b) {
          return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
        }));
  var sortedbuiltIns = Belt_List.sort(Belt_List.keep(filteredAttributes, (function (attr) {
              return !attr.custom;
            })), (function (a, b) {
          return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
        }));
  return Pervasives.$at(sortedCustom, sortedbuiltIns);
}

function useDescribeRemote(removeUnusedOpt, token, name, handleTask) {
  var removeUnused = removeUnusedOpt !== undefined ? removeUnusedOpt : true;
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote !== 0) {
              Curry._2(handleTask, (function (json) {
                      try {
                        var arg = ApiDescribe$BsConsole.decodeExn(TaskCallback$BsConsole.getJson(json));
                        var results = sortAttributes(removeUnused, arg);
                        return Curry._1(setRemote, (function (param) {
                                      return /* Success */Block.__(0, [results]);
                                    }));
                      }
                      catch (raw_err){
                        var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                        console.log(/* tuple */[
                              "Failed to parse describe in FilterShell",
                              err
                            ]);
                        return Curry._1(setRemote, (function (param) {
                                      return /* Failure */Block.__(1, ["Failed to parse attributes"]);
                                    }));
                      }
                    }), Task$BsConsole.makeTask(/* Describe */Block.__(3, [
                          token,
                          name
                        ])));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  return remote;
}

function FilterShell(Props) {
  var onChange = Props.onChange;
  var name = Props.name;
  var handleTask = Props.handleTask;
  var route = Props.route;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var toolbarClassNameOpt = Props.toolbarClassName;
  var filterBarClassNameOpt = Props.filterBarClassName;
  var onContainerFocusedStyle = Props.onContainerFocusedStyle;
  var token = Props.token;
  Props.shell;
  var toolbarClassName = toolbarClassNameOpt !== undefined ? toolbarClassNameOpt : "";
  var filterBarClassName = filterBarClassNameOpt !== undefined ? filterBarClassNameOpt : "";
  var describeRemote = useDescribeRemote(undefined, token, name, handleTask);
  var describeWithUnusedRemote = useDescribeRemote(false, token, name, handleTask);
  var match = React.useState((function () {
          return false;
        }));
  var setQueryBuilderOpen = match[1];
  var queryBuilderOpen = match[0];
  var match$1 = React.useState((function () {
          return /* tuple */[
                  undefined,
                  undefined,
                  undefined
                ];
        }));
  var saveRecentAttribute = function (attribute) {
    var attributeName = Curry._1(Crdb$BsConsole.Filter.getAttribute, attribute);
    var operator = Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, attribute));
    return FilterQueryBuilder$BsConsole.RecentAttributes.save({
                project: name,
                attributeName: attributeName,
                operator: operator
              });
  };
  var tmp = {
    route: route,
    describeRemote: describeRemote,
    onChange: Curry.__2(onChange),
    projectName: name,
    token: token,
    aperture: aperture,
    havings: havings,
    filterBarClassName: filterBarClassName,
    openQueryBuilder: (function (param) {
        return Curry._1(setQueryBuilderOpen, (function (param) {
                      return true;
                    }));
      }),
    queryBuilderState: match$1[0],
    queryBuilderOpen: queryBuilderOpen,
    saveRecentAttribute: saveRecentAttribute
  };
  if (onContainerFocusedStyle !== undefined) {
    tmp.onContainerFocusedStyle = Caml_option.valFromOption(onContainerFocusedStyle);
  }
  var tmp$1;
  tmp$1 = queryBuilderOpen && !(typeof describeWithUnusedRemote === "number" || describeWithUnusedRemote.tag) ? React.createElement(ClickAwayListener.default, {
          onClickAway: (function ($$event) {
              $$event.preventDefault();
              var containsNode = function (root, target) {
                try {
                  return root.contains(target);
                }
                catch (exn){
                  return false;
                }
              };
              var __x = document;
              var autocompleteRoot = __x.querySelector("#menu-");
              var __x$1 = document;
              var tooltipRoot = __x$1.querySelector("div[role=tooltip]");
              var __x$2 = document;
              var filterInputBarRoot = __x$2.querySelector(".filter-input-bar");
              var target = $$event.target;
              var containsTarget;
              if (target == null) {
                containsTarget = false;
              } else {
                var clickedAutocomplete = Belt_Option.getWithDefault(Belt_Option.map((autocompleteRoot == null) ? undefined : Caml_option.some(autocompleteRoot), (function (acRoot) {
                            return containsNode(acRoot, target);
                          })), false);
                var clickedTooltip = Belt_Option.getWithDefault(Belt_Option.map((tooltipRoot == null) ? undefined : Caml_option.some(tooltipRoot), (function (ttRoot) {
                            return containsNode(ttRoot, target);
                          })), false);
                var clickedFilterbar = Belt_Option.getWithDefault(Belt_Option.map((filterInputBarRoot == null) ? undefined : Caml_option.some(filterInputBarRoot), (function (fbRoot) {
                            return containsNode(fbRoot, target);
                          })), false);
                containsTarget = clickedAutocomplete || clickedTooltip || clickedFilterbar;
              }
              if (!containsTarget) {
                return Curry._1(setQueryBuilderOpen, (function (param) {
                              return false;
                            }));
              }
              
            }),
          children: React.createElement(FilterQueryBuilder$BsConsole.make, {
                aperture: aperture,
                havings: havings,
                token: token,
                projectName: name,
                describeAttributes: describeWithUnusedRemote[0],
                onChange: (function (aperture) {
                    return Curry._2(onChange, aperture, havings);
                  }),
                onClose: (function (param) {
                    return Curry._1(setQueryBuilderOpen, (function (param) {
                                  return false;
                                }));
                  }),
                setQueryBuilderState: match$1[1],
                saveRecentAttribute: saveRecentAttribute
              })
        }) : null;
  return React.createElement(Toolbar.default, {
              disableGutters: true,
              className: Css.merge(/* :: */[
                    toolbar,
                    /* :: */[
                      toolbarClassName,
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(FilterInputControl$BsConsole.make, tmp), tmp$1);
}

function make(onChange, name, handleTask, aperture, havings, route, token, filterBarClassName, shell, children) {
  return ReasonReactCompat.wrapReactForReasonReact(FilterShell, {
              onChange: onChange,
              name: name,
              handleTask: handleTask,
              route: route,
              aperture: aperture,
              havings: havings,
              filterBarClassName: filterBarClassName,
              token: token,
              shell: shell
            }, children);
}

var Re = {
  make: make
};

var make$1 = FilterShell;

exports.Style = Style;
exports.sortAttributes = sortAttributes;
exports.useDescribeRemote = useDescribeRemote;
exports.make = make$1;
exports.Re = Re;
/* toolbar Not a pure module */
