// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Task2$BsConsole = require("../../Task2.js");
var AlertsEvaluationsApi$BsConsole = require("./AlertsEvaluationsApi.js");

var resource = "aggregations/most_important_evaluation_per_bucket";

function bucketsFromJson(json) {
  return {
          startTime: Json_decode.field("start", Json_decode.$$int, json),
          endTime: Json_decode.field("end", Json_decode.$$int, json),
          evaluation: Json_decode.optional((function (param) {
                  return Json_decode.field("evaluation", AlertsEvaluationsApi$BsConsole.fromJson, param);
                }), json)
        };
}

function fromJson(json) {
  return {
          startTime: Json_decode.field("start_time", Json_decode.$$int, json),
          endTime: Json_decode.field("end_time", Json_decode.$$int, json),
          period: Json_decode.field("period", Json_decode.$$int, json),
          buckets: Json_decode.field("buckets", (function (param) {
                  return Json_decode.array(bucketsFromJson, param);
                }), json)
        };
}

function get(path, universeName, projectName, alertId, startTimeOpt, endTimeOpt, periodOpt, param) {
  var startTime = startTimeOpt !== undefined ? startTimeOpt : 0;
  var endTime = endTimeOpt !== undefined ? endTimeOpt : 0;
  var period = periodOpt !== undefined ? periodOpt : 0;
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + ("&alert_id=" + (String(alertId) + ("&period=" + (String(period) + ("&start_time=" + (String(startTime) + ("&end_time=" + (String(endTime) + "")))))))))))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [fromJson(respBag.json)]);
              }), undefined, undefined);
}

exports.resource = resource;
exports.bucketsFromJson = bucketsFromJson;
exports.fromJson = fromJson;
exports.get = get;
/* Task2-BsConsole Not a pure module */
