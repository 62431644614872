// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Stack = require("bs-platform/lib/js/stack.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Numeral$BsConsole = require("./Numeral.js");
var ReactFlameGraph = require("react-flame-graph");

function ofDistribution(dist, weighted, reversed) {
  var rootValue = Belt_List.reduce(dist.vals, 0, (function (acc, curr) {
          var cs = curr[0];
          if (cs !== undefined && cs.tag === /* Callstack */9 && Belt_List.length(cs[0][0]) > 0) {
            if (weighted) {
              return acc + curr[1] | 0;
            } else {
              return acc + 1 | 0;
            }
          } else {
            return acc;
          }
        }));
  var rootRawValue = Belt_List.reduce(dist.vals, 0, (function (acc, curr) {
          return acc + curr[1] | 0;
        })) + Belt_Option.getWithDefault(dist.tail, 0) | 0;
  var tree = {
    name: "root",
    tooltip: "Flamegraph root. click to reset zoom.",
    value: rootValue,
    rawValue: rootRawValue,
    children: []
  };
  Belt_List.forEach(Belt_List.map(Belt_List.keep(dist.vals, (function (param) {
                  return param[0] !== undefined;
                })), (function (param) {
              var count = param[1];
              var callstack = param[0];
              if (callstack === undefined) {
                return /* tuple */[
                        /* Callstack */Block.__(9, [/* tuple */[
                              /* [] */0,
                              ""
                            ]]),
                        count
                      ];
              }
              if (callstack.tag !== /* Callstack */9) {
                return /* tuple */[
                        /* Callstack */Block.__(9, [/* tuple */[
                              /* [] */0,
                              ""
                            ]]),
                        count
                      ];
              }
              var match = callstack[0];
              var frames = match[0];
              return /* tuple */[
                      /* Callstack */Block.__(9, [/* tuple */[
                            reversed ? frames : Belt_List.reverse(frames),
                            match[1]
                          ]]),
                      count
                    ];
            })), (function (cs) {
          var callstack = cs[0];
          if (callstack.tag === /* Callstack */9) {
            var callstack$1 = callstack[0][0];
            var delta = cs[1];
            var root = {
              contents: tree
            };
            var cs$1 = Belt_List.take(callstack$1, 50);
            var truncatedCallstack = cs$1 !== undefined ? cs$1 : callstack$1;
            return Belt_List.forEach(truncatedCallstack, (function (frame) {
                          var added = {
                            contents: false
                          };
                          Belt_Array.forEach(root.contents.children, (function (n) {
                                  if (n.name !== frame) {
                                    return ;
                                  }
                                  var value = n.value;
                                  var rawValue = n.rawValue;
                                  if (weighted) {
                                    n.value = value + delta | 0;
                                  } else {
                                    n.value = value + 1 | 0;
                                  }
                                  n.rawValue = rawValue + delta | 0;
                                  n.tooltip = String(rawValue + delta | 0) + (" errors - (" + (Numeral$BsConsole.format("0.0", Pervasives.string_of_float(rawValue / rootRawValue * 100)) + ("%)\n" + frame)));
                                  root.contents = n;
                                  added.contents = true;
                                  
                                }));
                          if (added.contents) {
                            return ;
                          }
                          var node = {
                            name: frame,
                            tooltip: String(delta) + (" errors - (" + (Numeral$BsConsole.format("0.0", Pervasives.string_of_float(delta / rootRawValue * 100)) + ("%)\n" + frame))),
                            value: weighted ? delta : 1,
                            rawValue: delta,
                            children: []
                          };
                          root.contents.children = Belt_Array.concat(root.contents.children, [node]);
                          root.contents = node;
                          
                        }));
          }
          
        }));
  return tree;
}

function findNodePath(root, node) {
  var path = {
    contents: []
  };
  var currentNode = root;
  var i = 0;
  var $$break = false;
  var stack = Stack.create(undefined);
  while(!$$break) {
    if (currentNode.name === node.name && currentNode.tooltip === node.tooltip) {
      $$break = true;
    } else {
      var node$prime = Belt_Array.get(currentNode.children, i);
      if (node$prime !== undefined) {
        Stack.push(/* tuple */[
              currentNode,
              i
            ], stack);
        currentNode = Caml_option.valFromOption(node$prime);
        i = 0;
      } else {
        var match = Stack.pop(stack);
        currentNode = match[0];
        i = match[1] + 1 | 0;
      }
    }
  };
  Stack.iter((function (param) {
          var node$prime = param[0];
          if (node$prime.name !== "root") {
            path.contents = Belt_Array.concat([node$prime.name], path.contents);
          }
          
        }), stack);
  return Belt_Array.concat(path.contents, [node.name]);
}

var Data = {
  maxFrames: 50,
  ofDistribution: ofDistribution,
  findNodePath: findNodePath
};

function make(data, height, width, onChange, children) {
  return ReasonReact.wrapJsForReason(ReactFlameGraph.FlameGraph, {
              data: data,
              height: height,
              width: width,
              onChange: onChange
            }, children);
}

exports.Data = Data;
exports.make = make;
/* ReasonReact Not a pure module */
