// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Col2$BsConsole = require("../components/Col2.js");
var LinearProgress = require("@material-ui/core/LinearProgress");

function BugLoader(Props) {
  var wOpt = Props.width;
  var children = Props.children;
  var w = wOpt !== undefined ? wOpt : 400;
  return React.createElement(Col2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* :: */[
                        Css.paddingTop(Css.px(80)),
                        /* [] */0
                      ]
                    ]
                  ]),
              children: React.createElement(Col2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    children: null
                  }, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.paddingBottom(Css.px(8)),
                              /* :: */[
                                Css.fontSize(Css.px(18)),
                                /* [] */0
                              ]
                            ])
                      }, children), React.createElement(LinearProgress.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.px(w)),
                                /* [] */0
                              ])
                        }
                      }))
            });
}

var make = BugLoader;

exports.make = make;
/* Css Not a pure module */
