// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");

function unwrap_bool(b) {
  if (b !== undefined) {
    return b;
  }
  
}

function option_map(fn, option) {
  if (option !== undefined) {
    return Caml_option.some(Curry._1(fn, Caml_option.valFromOption(option)));
  }
  
}

var component = ReasonReact.statelessComponent("AppBarSpacer");

function make(_children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("div", {
                          style: {
                            minHeight: "64px"
                          }
                        });
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var AppBarSpacer = {
  component: component,
  make: make
};

function setAnchorElRef(theRef, param) {
  param.state.anchorEl.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
  
}

var component$1 = ReasonReact.reducerComponent("Menu");

function make$1(target, menu, position, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var match = Belt_Option.getWithDefault(position, /* Right */1);
              var anchorOrigin = match ? ({
                    vertical: /* Top */4202101,
                    horizontal: /* Right */-57574468
                  }) : ({
                    vertical: /* Top */4202101,
                    horizontal: /* Left */847852583
                  });
              var match$1 = Belt_Option.getWithDefault(position, /* Right */1);
              var transformOrigin = match$1 ? ({
                    vertical: /* Top */4202101,
                    horizontal: /* Left */847852583
                  }) : ({
                    vertical: /* Top */4202101,
                    horizontal: /* Right */-57574468
                  });
              var match$2 = self.state.is;
              var tmp;
              if (match$2) {
                tmp = null;
              } else {
                var r = self.state.anchorEl.contents;
                tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Menu.Jsx2.make, true, Caml_option.some(r !== undefined ? Caml_option.valFromOption(r) : ({ })), anchorOrigin, transformOrigin, (function (_event) {
                            return Curry._1(self.send, /* Click */[undefined]);
                          }), [Curry._1(menu, (function (param) {
                                  return Curry._1(self.send, /* Click */[undefined]);
                                }))]));
              }
              return React.createElement("div", undefined, React.createElement("div", {
                              ref: Curry._1(self.handle, setAnchorElRef),
                              onClick: (function ($$event) {
                                  $$event.stopPropagation();
                                  return Curry._1(self.send, /* Click */[Caml_option.some($$event.target)]);
                                })
                            }, target), tmp);
            }),
          initialState: (function (param) {
              return {
                      is: /* Closed */1,
                      anchorEl: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              if (action[0] !== undefined) {
                return /* Update */Block.__(0, [{
                            is: /* Open */0,
                            anchorEl: state.anchorEl
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            is: /* Closed */1,
                            anchorEl: state.anchorEl
                          }]);
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var Menu = {
  setAnchorElRef: setAnchorElRef,
  component: component$1,
  make: make$1
};

function setAnchorElRef$1(theRef, param) {
  param.state.anchorEl.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
  
}

var component$2 = ReasonReact.reducerComponent("Menu");

function make$2(target, classNameOpt, anchorOrigin, transformOrigin, classes, onClose, popoverAction, content, openOnFocusOpt, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var openOnFocus = openOnFocusOpt !== undefined ? openOnFocusOpt : false;
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (self) {
              var refHandler = Curry._1(self.handle, setAnchorElRef$1);
              var openOnFocus$1 = openOnFocus ? (function ($$event) {
                    return Curry._1(self.send, /* Focus */Block.__(1, [Caml_option.some($$event.target)]));
                  }) : (function (param) {
                    
                  });
              var onClick = function ($$event) {
                $$event.stopPropagation();
                return Curry._1(self.send, /* Click */Block.__(0, [Caml_option.some($$event.target)]));
              };
              var match = self.state.is;
              var tmp;
              if (match) {
                tmp = null;
              } else {
                var r = self.state.anchorEl.contents;
                tmp = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Popover.Jsx2.make, [
                          r !== undefined ? /* `ObjectGeneric */[
                              -317959944,
                              Caml_option.valFromOption(r)
                            ] : /* `ObjectGeneric */[
                              -317959944,
                              { }
                            ],
                          anchorOrigin,
                          transformOrigin,
                          className,
                          true,
                          (function ($$event) {
                              $$event.stopPropagation();
                              Curry._1(self.send, /* Click */Block.__(0, [undefined]));
                              if (onClose !== undefined) {
                                return Curry._1(onClose, $$event);
                              }
                              
                            }),
                          popoverAction,
                          classes,
                          [Curry._1(content, (function (param) {
                                    return Curry._1(self.send, /* Click */Block.__(0, [undefined]));
                                  }))]
                        ]));
              }
              return React.createElement(React.Fragment, undefined, Curry._3(target, refHandler, openOnFocus$1, onClick), tmp);
            }),
          initialState: (function (param) {
              return {
                      is: /* Closed */1,
                      anchorEl: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, state) {
              if (action[0] !== undefined) {
                return /* Update */Block.__(0, [{
                            is: /* Open */0,
                            anchorEl: state.anchorEl
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            is: /* Closed */1,
                            anchorEl: state.anchorEl
                          }]);
              }
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var Popover = {
  setAnchorElRef: setAnchorElRef$1,
  component: component$2,
  make: make$2
};

exports.unwrap_bool = unwrap_bool;
exports.option_map = option_map;
exports.AppBarSpacer = AppBarSpacer;
exports.Menu = Menu;
exports.Popover = Popover;
/* component Not a pure module */
