// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Email$BsConsole = require("../Email.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Perforce$BsConsole = require("./sourcecode/Perforce.js");
var RepoRule$BsConsole = require("./sourcecode/RepoRule.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var Repository$BsConsole = require("./sourcecode/Repository.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var Menu = require("@material-ui/core/Menu");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Configuration$BsConsole = require("../configuration.js");
var PerforceRules$BsConsole = require("./sourcecode/PerforceRules.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");
var InputLabel = require("@material-ui/core/InputLabel");
var CardContent = require("@material-ui/core/CardContent");
var ExpandMore = require("@material-ui/icons/ExpandMore");
var ProjectFrontendSettings$BsConsole = require("../ProjectFrontendSettings.js");

function SourceCodeConfig(Props) {
  var token = Props.token;
  var config = Props.config;
  var projectName = Props.projectName;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setRepositories = match[1];
  var repositories = match[0];
  var match$1 = React.useState((function () {
          return /* [] */0;
        }));
  var setPerforceServers = match$1[1];
  var perforceServers = match$1[0];
  var match$2 = React.useState((function () {
          return 0;
        }));
  var setRefetchRepositoriesKey = match$2[1];
  var match$3 = React.useState((function () {
          return /* [] */0;
        }));
  var setRules = match$3[1];
  var rules = match$3[0];
  var match$4 = React.useState((function () {
          return /* [] */0;
        }));
  var setPerforceRules = match$4[1];
  var perforceRules = match$4[0];
  var match$5 = React.useState((function () {
          return 0;
        }));
  var setRefetchRulesKey = match$5[1];
  var match$6 = React.useState((function () {
          return 0;
        }));
  var setRefetchPerforceRulesKey = match$6[1];
  var match$7 = React.useState((function () {
          return false;
        }));
  var setDisplayNewRepoForm = match$7[1];
  var displayNewRepoForm = match$7[0];
  var match$8 = React.useState((function () {
          return false;
        }));
  var setDisplayNewDepotForm = match$8[1];
  var displayNewDepotForm = match$8[0];
  var match$9 = React.useState((function () {
          return false;
        }));
  var setShowRuleForm = match$9[1];
  var showRuleForm = match$9[0];
  var match$10 = React.useState((function () {
          return false;
        }));
  var setSupportsAutoSubmodules = match$10[1];
  var supportsAutoSubmodules = match$10[0];
  var universeName = config.universe.name;
  var fetchRepositories = function (param) {
    return Task2$BsConsole.handle(token, Repository$BsConsole.fetchRepositories(projectName, universeName, config), undefined, (function (repositories) {
                  if (repositories.tag) {
                    return ;
                  }
                  var repositories$1 = repositories[0];
                  return Curry._1(setRepositories, (function (param) {
                                return Belt_List.sort(repositories$1, (function (a, b) {
                                              var match = a.position;
                                              var match$1 = b.position;
                                              if (match !== undefined && match$1 !== undefined) {
                                                return match - match$1 | 0;
                                              } else {
                                                return 0;
                                              }
                                            }));
                              }));
                }));
  };
  var fetchPerforceServers = function (param) {
    return Task2$BsConsole.handle(token, Perforce$BsConsole.fetchPerforceServers(projectName, universeName, config), undefined, (function (servers) {
                  if (servers.tag) {
                    return ;
                  }
                  var servers$1 = servers[0];
                  return Curry._1(setPerforceServers, (function (param) {
                                return servers$1;
                              }));
                }));
  };
  React.useEffect((function () {
          var interval = setInterval(fetchRepositories, 1500);
          var interval2 = setInterval(fetchPerforceServers, 1500);
          Task2$BsConsole.handle(token, Repository$BsConsole.getServerVersion(projectName, config), undefined, (function (version) {
                  if (version.tag) {
                    return ;
                  }
                  var version$prime = Semver.valid(version[0]);
                  if (version$prime !== undefined && Semver.gte(version$prime, "1.4.4")) {
                    return Curry._1(setSupportsAutoSubmodules, (function (param) {
                                  return true;
                                }));
                  }
                  
                }));
          return (function (param) {
                    clearInterval(interval);
                    clearInterval(interval2);
                    
                  });
        }), ([]));
  React.useEffect((function () {
          fetchRepositories(undefined);
          fetchPerforceServers(undefined);
          
        }), [match$2[0]]);
  React.useEffect((function () {
          Task2$BsConsole.handle(token, RepoRule$BsConsole.fetchRules(config, universeName, projectName), undefined, (function (rules) {
                  Belt_Result.map(rules, (function (rules) {
                          return Curry._1(setRules, (function (param) {
                                        return rules;
                                      }));
                        }));
                  
                }));
          
        }), [match$5[0]]);
  React.useEffect((function () {
          Task2$BsConsole.handle(token, PerforceRules$BsConsole.fetchRules(config, universeName, projectName), undefined, (function (rules) {
                  Belt_Result.map(rules, (function (rules) {
                          return Curry._1(setPerforceRules, (function (param) {
                                        return rules;
                                      }));
                        }));
                  
                }));
          
        }), [match$6[0]]);
  var refetchRepositories = function (param) {
    return Curry._1(setRefetchRepositoriesKey, (function (oldKey) {
                  return oldKey + 1 | 0;
                }));
  };
  var refetchRepoRulesCb = function (param) {
    return Curry._1(setRefetchRulesKey, (function (oldKey) {
                  return oldKey + 1 | 0;
                }));
  };
  var refetchPerforceRulesCb = function (param) {
    return Curry._1(setRefetchPerforceRulesKey, (function (oldKey) {
                  return oldKey + 1 | 0;
                }));
  };
  var match$11 = React.useState((function () {
          
        }));
  var setAnchorEl = match$11[1];
  var anchorEl = match$11[0];
  var sourceCodeServiceAvail = Belt_Option.isSome(Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode"));
  var errorJsx = React.createElement(React.Fragment, undefined, React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.flexDirection(/* column */-963948842),
                  /* :: */[
                    Css.marginBottom(Css.rem(1.5)),
                    /* [] */0
                  ]
                ])
          }, React.createElement(CardContent.default, {
                children: null
              }, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading4 */3,
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* [] */0
                        ]),
                    color: Colors$BsConsole.grey1,
                    children: I18N$BsConsole.show(undefined, "Backtrace source code")
                  }), React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(1)),
                          /* [] */0
                        ]),
                    color: Colors$BsConsole.grey1,
                    children: null
                  }, I18N$BsConsole.show(undefined, "Backtrace source code service not found. Please contact "), React.createElement("a", {
                        className: "link",
                        href: "mailto:" + Email$BsConsole.support
                      }, I18N$BsConsole.showSkip(Email$BsConsole.support)), I18N$BsConsole.show(undefined, " or your system administrator to enable this service.")))));
  var pid = Belt_Option.map(Belt_List.getBy(config.projects, (function (p) {
              return p.name === projectName;
            })), (function (p) {
          return p.pid;
        }));
  var match$12 = React.useState((function () {
          return "";
        }));
  var setUrlInputStr = match$12[1];
  var urlInputStr = match$12[0];
  var match$13 = React.useState((function () {
          
        }));
  var setPfeUrl = match$13[1];
  var pfeUrl = match$13[0];
  var match$14 = React.useState((function () {
          return 0;
        }));
  var setPfeRefetch = match$14[1];
  var match$15 = React.useState((function () {
          return false;
        }));
  var setInputVisible = match$15[1];
  var inputVisible = match$15[0];
  React.useEffect((function () {
          if (pid !== undefined) {
            ProjectFrontendSettings$BsConsole.getParsedSettingsFromBpg(token, pid, (function (settings) {
                    if (settings === undefined) {
                      return ;
                    }
                    var endpt = Belt_Option.flatMap(Belt_Option.flatMap(settings.serviceOverrides, (function (overrides) {
                                return Belt_List.getBy(overrides, (function (o) {
                                              return o.name === "sourcecode";
                                            }));
                              })), (function (o) {
                            return o.endpoint;
                          }));
                    Curry._1(setPfeUrl, (function (param) {
                            return endpt;
                          }));
                    if (endpt !== undefined) {
                      return Curry._1(setUrlInputStr, (function (param) {
                                    return endpt;
                                  }));
                    }
                    
                  }));
          }
          
        }), [match$14[0]]);
  var editServiceUrl = function (param) {
    return React.createElement(Col2$BsConsole.make, {
                children: null
              }, React.createElement(InputLabel.default, {
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginTop(Css.px(8)),
                            /* :: */[
                              Css.marginBottom(Css.px(3)),
                              /* [] */0
                            ]
                          ])
                    },
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading4 */3,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* [] */0
                              ]),
                          color: Colors$BsConsole.grey1,
                          children: I18N$BsConsole.show(undefined, "Local service URL")
                        })
                  }), React.createElement(Row2$BsConsole.make, {
                    children: null
                  }, React.createElement(TextField.default, {
                        placeholder: I18N$BsConsole.get(undefined, "Example: http://localhost:6451/api/sourcecode"),
                        value: urlInputStr,
                        color: "primary",
                        variant: "outlined",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.ch(50)),
                                /* :: */[
                                  Css.selector("input", /* :: */[
                                        Css.important(Css.paddingTop(Css.px(6))),
                                        /* :: */[
                                          Css.important(Css.paddingBottom(Css.px(7))),
                                          /* [] */0
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.selector("div[class*='multiline']", /* :: */[
                                          Css.important(Css.paddingTop(Css.px(10))),
                                          /* [] */0
                                        ]),
                                    /* :: */[
                                      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        },
                        onChange: (function ($$event) {
                            var value = $$event.target.value;
                            return Curry._1(setUrlInputStr, (function (param) {
                                          return value;
                                        }));
                          })
                      }), React.createElement(Button.default, {
                        variant: "outlined",
                        color: "primary",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(1.5)),
                                /* :: */[
                                  Css.marginLeft(Css.rem(0.5)),
                                  /* :: */[
                                    Css.height(Css.rem(2)),
                                    /* [] */0
                                  ]
                                ]
                              ])
                        },
                        onClick: (function (param) {
                            if (pid !== undefined) {
                              var cb = function (success) {
                                if (success) {
                                  return ProjectFrontendSettings$BsConsole.updateOrCreate(token, pid, (function (settings) {
                                                var override_endpoint = urlInputStr;
                                                var override = {
                                                  name: "sourcecode",
                                                  endpoint: override_endpoint
                                                };
                                                if (settings === undefined) {
                                                  return {
                                                          defaultView: ProjectFrontendSettings$BsConsole.empty.defaultView,
                                                          bookmarkedViews: ProjectFrontendSettings$BsConsole.empty.bookmarkedViews,
                                                          serviceOverrides: /* :: */[
                                                            override,
                                                            /* [] */0
                                                          ],
                                                          versionAttribute: ProjectFrontendSettings$BsConsole.empty.versionAttribute,
                                                          normBy: ProjectFrontendSettings$BsConsole.empty.normBy,
                                                          unityCDA: ProjectFrontendSettings$BsConsole.empty.unityCDA,
                                                          firstTimeExperience: ProjectFrontendSettings$BsConsole.empty.firstTimeExperience
                                                        };
                                                }
                                                var overrides = Belt_Option.mapWithDefault(settings.serviceOverrides, /* :: */[
                                                      override,
                                                      /* [] */0
                                                    ], (function (overrides) {
                                                        return /* :: */[
                                                                override,
                                                                Belt_List.filter(overrides, (function (s) {
                                                                        return s.name !== "sourcecode";
                                                                      }))
                                                              ];
                                                      }));
                                                return {
                                                        defaultView: settings.defaultView,
                                                        bookmarkedViews: settings.bookmarkedViews,
                                                        serviceOverrides: overrides,
                                                        versionAttribute: settings.versionAttribute,
                                                        normBy: settings.normBy,
                                                        unityCDA: settings.unityCDA,
                                                        firstTimeExperience: settings.firstTimeExperience
                                                      };
                                              }), (function (param) {
                                                Curry._1(setPfeRefetch, (function (i) {
                                                        return i + 1 | 0;
                                                      }));
                                                Curry._1(setInputVisible, (function (v) {
                                                        return !v;
                                                      }));
                                                return SnackPurveyor$BsConsole.enqueue({
                                                            label: "Refresh",
                                                            onClick: (function (param) {
                                                                window.location.reload(false);
                                                                
                                                              })
                                                          }, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success. Please refresh for changes to apply"));
                                              }));
                                } else {
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Unable to contact service at the specified URL"));
                                  return Curry._1(setPfeRefetch, (function (i) {
                                                return i + 1 | 0;
                                              }));
                                }
                              };
                              var path$prime = urlInputStr.endsWith("/") ? urlInputStr.slice(0, -1) : urlInputStr;
                              var task = Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path$prime) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/repositories")))))]), undefined, /* Get */0, undefined, (function (param) {
                                      return /* Ok */Block.__(0, [Json_decode.field("repositories", (function (param) {
                                                        return Json_decode.list(Repository$BsConsole.fromJson, param);
                                                      }), param.json)]);
                                    }), undefined, undefined);
                              return Task2$BsConsole.handle(token, task, undefined, (function (repositories) {
                                            if (repositories.tag) {
                                              return Curry._1(cb, false);
                                            } else {
                                              return Curry._1(cb, true);
                                            }
                                          }));
                            }
                            
                          }),
                        children: I18N$BsConsole.show(undefined, "Submit")
                      }), Belt_Option.isSome(pfeUrl) ? React.createElement(Button.default, {
                          variant: "outlined",
                          color: "primary",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.marginBottom(Css.rem(1.5)),
                                  /* :: */[
                                    Css.marginLeft(Css.rem(0.5)),
                                    /* :: */[
                                      Css.height(Css.rem(2)),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          },
                          onClick: (function (param) {
                              var pid = Belt_Option.map(Belt_List.getBy(config.projects, (function (p) {
                                          return p.name === projectName;
                                        })), (function (p) {
                                      return p.pid;
                                    }));
                              if (pid !== undefined) {
                                return ProjectFrontendSettings$BsConsole.updateOrCreate(token, pid, (function (settings) {
                                              if (settings !== undefined) {
                                                return {
                                                        defaultView: settings.defaultView,
                                                        bookmarkedViews: settings.bookmarkedViews,
                                                        serviceOverrides: Belt_Option.map(settings.serviceOverrides, (function (overrides) {
                                                                return Belt_List.filter(overrides, (function (s) {
                                                                              return s.name !== "sourcecode";
                                                                            }));
                                                              })),
                                                        versionAttribute: settings.versionAttribute,
                                                        normBy: settings.normBy,
                                                        unityCDA: settings.unityCDA,
                                                        firstTimeExperience: settings.firstTimeExperience
                                                      };
                                              } else {
                                                return ProjectFrontendSettings$BsConsole.empty;
                                              }
                                            }), (function (param) {
                                              Curry._1(setInputVisible, (function (v) {
                                                      return !v;
                                                    }));
                                              Curry._1(setPfeRefetch, (function (i) {
                                                      return i + 1 | 0;
                                                    }));
                                              return SnackPurveyor$BsConsole.enqueue({
                                                          label: "Refresh",
                                                          onClick: (function (param) {
                                                              window.location.reload(false);
                                                              
                                                            })
                                                        }, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success. Please refresh for changes to apply"));
                                            }));
                              }
                              
                            }),
                          children: I18N$BsConsole.show(undefined, "Switch to hosted server")
                        }) : React.createElement(Button.default, {
                          variant: "outlined",
                          color: "primary",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.marginBottom(Css.rem(1.5)),
                                  /* :: */[
                                    Css.marginLeft(Css.rem(0.5)),
                                    /* :: */[
                                      Css.height(Css.rem(2)),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(setInputVisible, (function (param) {
                                            return false;
                                          }));
                            }),
                          children: I18N$BsConsole.show(undefined, "Cancel")
                        })));
  };
  var localUrlDisplay = function (param) {
    return React.createElement(Col2$BsConsole.make, {
                children: null
              }, React.createElement(InputLabel.default, {
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginTop(Css.px(8)),
                            /* :: */[
                              Css.marginBottom(Css.px(3)),
                              /* [] */0
                            ]
                          ])
                    },
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading4 */3,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* [] */0
                              ]),
                          color: Colors$BsConsole.grey1,
                          children: I18N$BsConsole.show(undefined, "Local network URL")
                        })
                  }), React.createElement(Row2$BsConsole.make, {
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(1.5)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(TextField.default, {
                        disabled: true,
                        placeholder: I18N$BsConsole.get(undefined, "Enter and validate network URL"),
                        value: urlInputStr,
                        color: "primary",
                        variant: "outlined",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.ch(50)),
                                /* :: */[
                                  Css.selector("input", /* :: */[
                                        Css.important(Css.paddingTop(Css.px(6))),
                                        /* :: */[
                                          Css.important(Css.paddingBottom(Css.px(7))),
                                          /* [] */0
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.selector("div[class*='multiline']", /* :: */[
                                          Css.important(Css.paddingTop(Css.px(10))),
                                          /* [] */0
                                        ]),
                                    /* :: */[
                                      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        },
                        onChange: (function (param) {
                            
                          })
                      }), TeamsUtils$BsConsole.isAdmin(config, projectName) ? React.createElement(Button.default, {
                          variant: "outlined",
                          color: "primary",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.marginLeft(Css.rem(0.5)),
                                  /* :: */[
                                    Css.height(Css.rem(2)),
                                    /* [] */0
                                  ]
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(setInputVisible, (function (param) {
                                            return true;
                                          }));
                            }),
                          children: I18N$BsConsole.show(undefined, "Edit")
                        }) : null));
  };
  var mainJsx = function (param) {
    var addRepoBtn = function (disabled) {
      return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      disabled: disabled,
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(1.5)),
                              /* [] */0
                            ])
                      },
                      onClick: (function ($$event) {
                          var el = $$event.currentTarget;
                          if (!(el == null)) {
                            return Curry._1(setAnchorEl, (function (param) {
                                          return Caml_option.some(el);
                                        }));
                          }
                          
                        }),
                      children: null
                    }, I18N$BsConsole.show(undefined, "Add a repository"), React.createElement(ExpandMore.default, { })), React.createElement(Menu.default, {
                      disableAutoFocusItem: true,
                      classes: {
                        paper: Css.style(/* :: */[
                              Css.marginLeft(Css.rem(0.5)),
                              /* :: */[
                                Css.marginTop(Css.rem(1)),
                                /* :: */[
                                  Css.width(Css.rem(10)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      },
                      anchorEl: anchorEl,
                      open: Belt_Option.isSome(anchorEl),
                      onClose: (function (param) {
                          return Curry._1(setAnchorEl, (function (param) {
                                        
                                      }));
                        }),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.paddingTop(Css.em(0.25)),
                                  /* :: */[
                                    Css.paddingBottom(Css.em(0.25)),
                                    /* [] */0
                                  ]
                                ])
                          },
                          onClick: (function (param) {
                              Curry._1(setDisplayNewRepoForm, (function (param) {
                                      return true;
                                    }));
                              return Curry._1(setAnchorEl, (function (param) {
                                            
                                          }));
                            }),
                          children: React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Body1 */8,
                                children: I18N$BsConsole.show(undefined, "Git repository")
                              })
                        }), React.createElement(MenuItem.default, {
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.paddingTop(Css.em(0.25)),
                                  /* :: */[
                                    Css.paddingBottom(Css.em(0.25)),
                                    /* [] */0
                                  ]
                                ])
                          },
                          onClick: (function (param) {
                              Curry._1(setDisplayNewDepotForm, (function (param) {
                                      return true;
                                    }));
                              return Curry._1(setAnchorEl, (function (param) {
                                            
                                          }));
                            }),
                          children: React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Body1 */8,
                                children: I18N$BsConsole.show(undefined, "Perforce depot")
                              })
                        })));
    };
    var tmp;
    if (Belt_List.length(repositories) > 0) {
      var addRuleBtn = function (disabled) {
        return React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    disabled: disabled,
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(1.5)),
                            /* :: */[
                              Css.display(showRuleForm ? /* none */-922086728 : /* unset */653193961),
                              /* [] */0
                            ]
                          ])
                    },
                    onClick: (function (param) {
                        return Curry._1(setShowRuleForm, (function (param) {
                                      return true;
                                    }));
                      }),
                    children: I18N$BsConsole.show(undefined, "Add a rule")
                  });
      };
      tmp = React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                variant: /* Heading4 */3,
                className: Css.style(/* :: */[
                      Css.marginBottom(Css.rem(0.5)),
                      /* [] */0
                    ]),
                color: Colors$BsConsole.grey1,
                children: I18N$BsConsole.show(undefined, "Git rules")
              }), React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body1 */8,
                className: Css.style(/* :: */[
                      Css.marginBottom(Css.rem(1)),
                      /* [] */0
                    ]),
                color: Colors$BsConsole.grey1,
                children: I18N$BsConsole.show(undefined, "If you find cases where source code is pulled from the wrong repository, then you can create rules to point the system towards the correct repository. These rules are not required, but if they are present then they will execute at the beginning of the repository selection process. If none of the rules match, or if there are no rules, then the system will check every repository for matching source code.")
              }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                className: Css.style(/* :: */[
                      Css.margin2(Css.rem(1), /* zero */-789508312),
                      /* :: */[
                        Css.height(Css.px(4)),
                        /* [] */0
                      ]
                    ])
              }), React.createElement(Col2$BsConsole.make, {
                className: Css.style(/* :: */[
                      Css.marginBottom(Css.rem(1)),
                      /* [] */0
                    ]),
                children: null
              }, TeamsUtils$BsConsole.isAdmin(config, projectName) ? addRuleBtn(false) : React.createElement(Row2$BsConsole.make, {
                      alignItems: /* baseline */287825029,
                      children: null
                    }, addRuleBtn(true), React.createElement("span", {
                          className: Css.style(/* :: */[
                                Css.marginLeft(Css.rem(0.5)),
                                /* [] */0
                              ])
                        }, I18N$BsConsole.show(undefined, "Admin privileges for this project are required to perform this operation."))), React.createElement(Row2$BsConsole.make, {
                    justifyContent: /* flexStart */662439529,
                    flexWrap: /* wrap */-822134326,
                    children: null
                  }, showRuleForm ? React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(1)),
                                /* [] */0
                              ])
                        }, React.createElement(RepoRule$BsConsole.NewRule.make, {
                              config: config,
                              token: token,
                              projectName: projectName,
                              repositories: repositories,
                              universeName: universeName,
                              ruleCreatedCb: (function (param) {
                                  Curry._1(setRefetchRulesKey, (function (oldKey) {
                                          return oldKey + 1 | 0;
                                        }));
                                  return Curry._1(setShowRuleForm, (function (param) {
                                                return false;
                                              }));
                                }),
                              close: (function (param) {
                                  return Curry._1(setShowRuleForm, (function (param) {
                                                return false;
                                              }));
                                })
                            })) : null, Belt_List.length(rules) > 0 ? React.createElement(Col2$BsConsole.make, {
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              children: React.createElement(RepoRule$BsConsole.Rules.make, {
                                    token: token,
                                    config: config,
                                    projectName: projectName,
                                    universeName: universeName,
                                    rules: rules,
                                    refetchRepoRulesCb: refetchRepoRulesCb,
                                    repositories: repositories
                                  })
                            }), React.createElement(Paper.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.padding(Css.rem(1.0)),
                                      /* :: */[
                                        Css.marginBottom(Css.rem(1.0)),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              },
                              children: React.createElement(Row2$BsConsole.make, {
                                    alignItems: /* baseline */287825029,
                                    children: null
                                  }, React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.fontSize(Css.em(1)),
                                              /* :: */[
                                                Css.lineHeight(Css.em(2)),
                                                /* :: */[
                                                  Css.marginRight(Css.rem(1)),
                                                  /* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ])
                                      }, I18N$BsConsole.show(undefined, "ELSE")), React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.fontSize(Css.em(1.1875)),
                                              /* :: */[
                                                Css.lineHeight(Css.em(2)),
                                                /* :: */[
                                                  Css.marginRight(Css.rem(1)),
                                                  /* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ])
                                      }, I18N$BsConsole.show(undefined, "Check every repository for matching source code.")))
                            })) : React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.padding(Css.rem(1.0)),
                                      /* :: */[
                                        Css.marginBottom(Css.rem(1.0)),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              },
                              children: React.createElement(Col2$BsConsole.make, {
                                    children: null
                                  }, React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.fontSize(Css.em(1.1875)),
                                              /* :: */[
                                                Css.lineHeight(Css.em(2)),
                                                /* :: */[
                                                  Css.marginRight(Css.rem(1)),
                                                  /* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ])
                                      }, I18N$BsConsole.show(undefined, "You don't have any rules set up yet.")), React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.fontSize(Css.em(1)),
                                              /* :: */[
                                                Css.lineHeight(Css.em(2)),
                                                /* :: */[
                                                  Css.marginRight(Css.rem(1)),
                                                  /* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ])
                                      }, I18N$BsConsole.show(undefined, "Backtrace will check all repositories for matching source code.")))
                            })))));
    } else {
      tmp = null;
    }
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.flexDirection(/* column */-963948842),
                          /* :: */[
                            Css.marginBottom(Css.rem(1.5)),
                            /* [] */0
                          ]
                        ])
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading4 */3,
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* [] */0
                            ]),
                        color: Colors$BsConsole.grey1,
                        children: I18N$BsConsole.show(undefined, "Repositories")
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(1)),
                              /* [] */0
                            ]),
                        color: Colors$BsConsole.grey1,
                        children: I18N$BsConsole.show(undefined, "View relevant source code side by side with your errors, when you add repositories to your Backtrace project.")
                      }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                        className: Css.style(/* :: */[
                              Css.margin2(Css.rem(1), /* zero */-789508312),
                              /* :: */[
                                Css.height(Css.px(4)),
                                /* [] */0
                              ]
                            ])
                      }), TeamsUtils$BsConsole.isAdmin(config, projectName) ? addRepoBtn(false) : React.createElement(Row2$BsConsole.make, {
                          alignItems: /* baseline */287825029,
                          children: null
                        }, addRepoBtn(true), React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.marginLeft(Css.rem(0.5)),
                                    /* [] */0
                                  ])
                            }, I18N$BsConsole.show(undefined, "Admin privileges for this project are required to perform this operation."))), React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* flexStart */662439529,
                        flexWrap: /* wrap */-822134326,
                        children: null
                      }, Belt_List.toArray(Belt_List.map(repositories, (function (repo) {
                                  return React.createElement(Paper.default, {
                                              classes: {
                                                root: Css.style(/* :: */[
                                                      Css.padding(Css.rem(1.0)),
                                                      /* :: */[
                                                        Css.marginBottom(Css.rem(1.0)),
                                                        /* :: */[
                                                          Css.width(Css.ch(50)),
                                                          /* :: */[
                                                            Css.height(Css.em(16)),
                                                            /* :: */[
                                                              Css.marginRight(Css.rem(1)),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ])
                                              },
                                              children: React.createElement(Repository$BsConsole.Show.make, {
                                                    repo: repo,
                                                    config: config,
                                                    projectName: projectName,
                                                    token: token,
                                                    refetchRepositories: refetchRepositories,
                                                    refetchRepoRules: refetchRepoRulesCb,
                                                    supportsAutoSubmodules: supportsAutoSubmodules,
                                                    key: Belt_Option.mapWithDefault(repo.id, "0", (function (prim) {
                                                            return String(prim);
                                                          }))
                                                  })
                                            });
                                }))), Belt_List.toArray(Belt_List.map(perforceServers, (function (server) {
                                  return React.createElement(Paper.default, {
                                              classes: {
                                                root: Css.style(/* :: */[
                                                      Css.padding(Css.rem(1.0)),
                                                      /* :: */[
                                                        Css.marginBottom(Css.rem(1.0)),
                                                        /* :: */[
                                                          Css.width(Css.ch(50)),
                                                          /* :: */[
                                                            Css.height(Css.em(16)),
                                                            /* :: */[
                                                              Css.marginRight(Css.rem(1)),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ])
                                              },
                                              children: React.createElement(Perforce$BsConsole.Show.make, {
                                                    server: server,
                                                    config: config,
                                                    projectName: projectName,
                                                    token: token,
                                                    refetchRepositories: refetchRepositories,
                                                    key: Belt_Option.mapWithDefault(server.id, "0", (function (prim) {
                                                            return String(prim);
                                                          }))
                                                  }),
                                              key: Belt_Option.mapWithDefault(server.id, "0", (function (prim) {
                                                      return String(prim);
                                                    }))
                                            });
                                }))))), tmp, Belt_List.length(perforceServers) > 0 ? React.createElement(PerforceRules$BsConsole.make, {
                      token: token,
                      config: config,
                      projectName: projectName,
                      universeName: universeName,
                      rules: perforceRules,
                      refetchRulesCb: refetchPerforceRulesCb,
                      servers: perforceServers
                    }) : null, React.createElement(Dialog.default, {
                    open: displayNewRepoForm,
                    classes: {
                      paper: Css.style(/* :: */[
                            Css.width(Css.pct(90)),
                            /* :: */[
                              Css.unsafe("maxWidth", "unset"),
                              /* [] */0
                            ]
                          ]),
                      container: Css.style(/* :: */[
                            Css.alignItems(/* flexStart */662439529),
                            /* [] */0
                          ]),
                      root: Css.style(/* :: */[
                            Css.userSelect(/* none */-922086728),
                            /* [] */0
                          ])
                    },
                    onClose: (function (param) {
                        return Curry._1(setDisplayNewRepoForm, (function (param) {
                                      return false;
                                    }));
                      }),
                    children: React.createElement(CardContent.default, {
                          children: React.createElement(Repository$BsConsole.Create.make, {
                                config: config,
                                projectName: projectName,
                                token: token,
                                refetchRepositories: refetchRepositories,
                                close: (function (param) {
                                    return Curry._1(setDisplayNewRepoForm, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                supportsAutoSubmodules: supportsAutoSubmodules,
                                key: "-1"
                              })
                        })
                  }), React.createElement(Dialog.default, {
                    open: displayNewDepotForm,
                    classes: {
                      paper: Css.style(/* :: */[
                            Css.width(Css.pct(90)),
                            /* :: */[
                              Css.unsafe("maxWidth", "unset"),
                              /* [] */0
                            ]
                          ]),
                      container: Css.style(/* :: */[
                            Css.alignItems(/* flexStart */662439529),
                            /* [] */0
                          ]),
                      root: Css.style(/* :: */[
                            Css.userSelect(/* none */-922086728),
                            /* [] */0
                          ])
                    },
                    onClose: (function (param) {
                        return Curry._1(setDisplayNewDepotForm, (function (param) {
                                      return false;
                                    }));
                      }),
                    children: React.createElement(CardContent.default, {
                          children: React.createElement(Perforce$BsConsole.Create.make, {
                                config: config,
                                projectName: projectName,
                                token: token,
                                refetchRepositories: refetchRepositories,
                                close: (function (param) {
                                    return Curry._1(setDisplayNewDepotForm, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                key: "-1"
                              })
                        })
                  }));
  };
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, Belt_Option.isSome(pfeUrl) && !inputVisible ? localUrlDisplay(undefined) : null, !Belt_Option.isSome(pfeUrl) && !inputVisible && TeamsUtils$BsConsole.isAdmin(config, projectName) ? React.createElement(Button.default, {
                    variant: "outlined",
                    color: "primary",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(1.5)),
                            /* [] */0
                          ])
                    },
                    onClick: (function (param) {
                        return Curry._1(setInputVisible, (function (param) {
                                      return true;
                                    }));
                      }),
                    children: I18N$BsConsole.show(undefined, "Switch to local server")
                  }) : null, inputVisible ? editServiceUrl(undefined) : null, sourceCodeServiceAvail ? mainJsx(undefined) : errorJsx);
}

function make(token, config, projectName, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SourceCodeConfig, {
              token: token,
              config: config,
              projectName: projectName
            }, _children);
}

var Re = {
  make: make
};

var make$1 = SourceCodeConfig;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
