// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var AlertsAlertConfigApi$BsConsole = require("./api/AlertsAlertConfigApi.js");

function trigFromJson(json) {
  return {
          value: Json_decode.field("value", Json_decode.$$float, json),
          level: Json_decode.field("level", Json_decode.string, json),
          trigger: Json_decode.field("trigger", AlertsAlertConfigApi$BsConsole.triggerFromJson, json)
        };
}

function fromJson(json) {
  return {
          key: Json_decode.field("key", Json_decode.string, json),
          triggers: Json_decode.field("triggers", (function (param) {
                  return Json_decode.array(trigFromJson, param);
                }), json)
        };
}

function parseGroup(str) {
  try {
    var q = Json.parse(str);
    if (q !== undefined) {
      return Json_decode.array(fromJson, Caml_option.valFromOption(q));
    } else {
      return ;
    }
  }
  catch (exn){
    return ;
  }
}

exports.trigFromJson = trigFromJson;
exports.fromJson = fromJson;
exports.parseGroup = parseGroup;
/* AlertsAlertConfigApi-BsConsole Not a pure module */
