// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Route$BsConsole = require("./route.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

function ResetScrollOnRouteUpdate(Props) {
  var route = Props.route;
  var children = Props.children;
  React.useEffect((function () {
          window.scrollTo(0, 0);
          
        }), [Route$BsConsole.toString(route)]);
  return children;
}

function make(route, children) {
  return ReasonReactCompat.wrapReactForReasonReact(ResetScrollOnRouteUpdate, {
              route: route,
              children: children
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = ResetScrollOnRouteUpdate;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* react Not a pure module */
