// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var WfTasks$BsConsole = require("../workflow/WfTasks.js");
var WfRouter$BsConsole = require("../workflow/WfRouter.js");
var WfWatcher$BsConsole = require("../workflow/WfWatcher.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var AlertsUtils$BsConsole = require("./AlertsUtils.js");
var AlertsRouter$BsConsole = require("../project-settings/alerts/AlertsRouter.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var AlertsTrigger$BsConsole = require("./AlertsTrigger.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Close = require("@material-ui/icons/Close");
var AlertsTargetApi$BsConsole = require("./api/AlertsTargetApi.js");
var AlertsCreateEdit$BsConsole = require("./AlertsCreateEdit.js");
var BtTypeaheadInput$BsConsole = require("../utilities/BtTypeaheadInput.js");
var TextField = require("@material-ui/core/TextField");
var InputLabel = require("@material-ui/core/InputLabel");
var AlertsQueryDisplay$BsConsole = require("./AlertsQueryDisplay.js");
var AlertsComparisonOperator$BsConsole = require("./AlertsComparisonOperator.js");

var failedToCreateTargetsText = I18N$BsConsole.get(undefined, "Failed to create integration targets.");

var failedToGetTargets = I18N$BsConsole.get(undefined, "Failed to get targets.");

function makeQueryTimeless(aperture, factor, fold, sort, havings) {
  return /* Aggregate */Block.__(0, [
            aperture,
            Caml_option.some(factor),
            fold,
            sort,
            undefined,
            Caml_option.some(havings)
          ]);
}

var chipLabel = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.black)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
        /* :: */[
          Css.fontSize(/* `px */[
                25096,
                12
              ]),
          /* :: */[
            Css.whiteSpace(/* nowrap */867913355),
            /* :: */[
              Css.overflow(/* hidden */-862584982),
              /* :: */[
                Css.textOverflow(/* ellipsis */166888785),
                /* :: */[
                  Css.borderRadius(Css.px(3)),
                  /* :: */[
                    Css.maxWidth(Css.px(100)),
                    /* :: */[
                      Css.height(Css.px(24)),
                      /* :: */[
                        Css.marginRight(Css.px(3)),
                        /* :: */[
                          Css.marginTop(Css.px(3)),
                          /* :: */[
                            Css.padding(Css.px(5)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function optionalTooltip(title) {
  if (title.length > 15) {
    return title;
  } else {
    return "";
  }
}

function getErrorCountTrigger(fold, t, param) {
  var __x = Curry._2(Crdb$BsConsole.Fold.add, AlertsUtils$BsConsole.errorCountAggregation, fold);
  var newAttributeIndex = Curry._3(Crdb$BsConsole.Fold.getAttributeAggregateIndex, t.attribute, t.operation, __x);
  if (newAttributeIndex === undefined) {
    return ;
  }
  var aggregation_column = t.attribute;
  var aggregation = {
    column: aggregation_column,
    index: newAttributeIndex
  };
  return {
          aggregation: aggregation,
          comparisonOperator: AlertsComparisonOperator$BsConsole.toValue(t.comparisonOperator),
          warningThreshold: t.warningThreshold,
          criticalThreshold: t.criticalThreshold
        };
}

function AlertsCreateAlertModal$Target(Props) {
  var watchers = Props.watchers;
  var selectedWatcher = Props.selectedWatcher;
  var setSelectedWatcher = Props.setSelectedWatcher;
  var disableRemove = Props.disableRemove;
  var remove = Props.remove;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  return React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* flexStart */662439529,
                  justifyContent: /* spaceBetween */516682146,
                  children: null
                }, React.createElement("div", undefined, React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                          selectedItem: selectedWatcher,
                          toString: (function (item) {
                              return item.watcherName;
                            }),
                          inputLabel: "",
                          placeholder: selectedWatcher !== undefined ? selectedWatcher.watcherName : I18N$BsConsole.get(undefined, "Select integration"),
                          noItemsMessage: I18N$BsConsole.get(undefined, "No existing integrations"),
                          createNewItemMessage: I18N$BsConsole.get(undefined, "Create a new integration"),
                          items: watchers,
                          itemFilter: (function (param) {
                              return true;
                            }),
                          pixelWidth: 330,
                          handleItemSelection: Curry.__1(setSelectedWatcher),
                          handleCreateNewItem: (function (param) {
                              return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                            projectName,
                                            WfRouter$BsConsole.root,
                                            undefined
                                          ]));
                            })
                        })), disableRemove ? null : React.createElement(Mui$BsConsole.IconButton.make, {
                        ariaLabel: "Remove integration",
                        onClick: (function (param) {
                            return Curry._1(remove, undefined);
                          }),
                        children: React.createElement(Close.default, { })
                      })));
}

var Target = {
  make: AlertsCreateAlertModal$Target
};

function AlertsCreateAlertModal(Props) {
  var config = Props.config;
  var _open = Props.open;
  var onClose = Props.onClose;
  var init = Props.init;
  var handleSave = Props.handleSave;
  var afterSave = Props.afterSave;
  var endpoint = Props.endpoint;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var existingAlerts = Props.existingAlerts;
  var showPreviewInExploreOpt = Props.showPreviewInExplore;
  var showPreviewInExplore = showPreviewInExploreOpt !== undefined ? showPreviewInExploreOpt : true;
  var sort = AlertsCreateEdit$BsConsole.getSort(init);
  var havings = AlertsCreateEdit$BsConsole.getHavings(init);
  var aperture = AlertsCreateEdit$BsConsole.getAperture(init);
  var getSimilarAlerts = function (trigs) {
    return Belt_List.keep(existingAlerts, (function (existingAlert) {
                  try {
                    var q = Json.parse(existingAlert.query);
                    if (q === undefined) {
                      return false;
                    }
                    var f = Caml_option.valFromOption(q).fold;
                    return Belt_List.length(Belt_List.keepMap(trigs, (function (trig) {
                                      if (trig !== undefined) {
                                        return Js_dict.get(f, trig.attribute);
                                      }
                                      
                                    }))) > 0;
                  }
                  catch (exn){
                    return false;
                  }
                }));
  };
  var maybeProject = Belt_List.get(Belt_List.filter(config.projects, (function (project) {
              return projectName === project.name;
            })), 0);
  var maybePid = maybeProject !== undefined ? maybeProject.pid : undefined;
  var match = React.useState((function () {
          return AlertsCreateEdit$BsConsole.getFactor(init);
        }));
  var setFactor = match[1];
  var factor = match[0];
  var match$1 = React.useState((function () {
          return AlertsCreateEdit$BsConsole.getFilters(init);
        }));
  var setFilters = match$1[1];
  var match$2 = React.useState((function () {
          return AlertsCreateEdit$BsConsole.getFold(init);
        }));
  var setFold = match$2[1];
  var fold = match$2[0];
  var match$3 = React.useState((function () {
          return AlertsCreateEdit$BsConsole.getName(init);
        }));
  var setName = match$3[1];
  var name = match$3[0];
  var match$4 = React.useState((function () {
          return AlertsCreateEdit$BsConsole.getInitialTriggerConditions(init);
        }));
  var setTriggerConditions = match$4[1];
  var triggerConditions = match$4[0];
  var match$5 = React.useState((function () {
          return AlertsCreateEdit$BsConsole.getQueryPeriod(init) / 60 | 0;
        }));
  var setQueryPeriodMinutes = match$5[1];
  var queryPeriodMinutes = match$5[0];
  var match$6 = React.useState((function () {
          return /* [] */0;
        }));
  var setSimilarAlerts = match$6[1];
  var match$7 = React.useState((function () {
          return AlertsCreateEdit$BsConsole.getMinNotificationInterval(init) / 60 | 0;
        }));
  var setMinNotificationIntervalMinutes = match$7[1];
  var minNotificationIntervalMinutes = match$7[0];
  var match$8 = React.useState((function () {
          return AlertsCreateEdit$BsConsole.getInitialActions(init);
        }));
  var setActions = match$8[1];
  var actions = match$8[0];
  var match$9 = React.useState((function () {
          return [];
        }));
  var setWatchers = match$9[1];
  var watchers = match$9[0];
  var match$10 = React.useState((function () {
          return [];
        }));
  var setAllAvailableIntegrations = match$10[1];
  var allAvailableIntegraitons = match$10[0];
  var match$11 = React.useState((function () {
          return /* [] */0;
        }));
  var setAllProjectAttributes = match$11[1];
  var allProjectAttributes = match$11[0];
  var match$12 = React.useState((function () {
          return false;
        }));
  var setAddAttributeError = match$12[1];
  var onSuccess = function (ws) {
    return Curry._1(setWatchers, (function (param) {
                  return ws;
                }));
  };
  var onFailure = function (_err) {
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to fetch watchers."));
  };
  WfWatcher$BsConsole.useWatchers(maybePid, token, projectName, config, onSuccess, onFailure);
  var match$13 = React.useState((function () {
          
        }));
  var setInvalidTriggerError = match$13[1];
  var invalidTriggerError = match$13[0];
  React.useEffect((function () {
          var invalidCount = Belt_List.length(AlertsUtils$BsConsole.getInvalidTriggerConditions(triggerConditions));
          var match = invalidCount === 0;
          if (invalidTriggerError !== undefined) {
            if (match) {
              Curry._1(setInvalidTriggerError, (function (param) {
                      
                    }));
            }
            
          } else if (match) {
            
          } else {
            Curry._1(setInvalidTriggerError, (function (param) {
                    return I18N$BsConsole.get(undefined, "Critical value must be equal to or more severe than the warning value for each aggregation.");
                  }));
          }
          
        }), [triggerConditions]);
  React.useEffect((function () {
          var newFactor = AlertsCreateEdit$BsConsole.getFactor(init);
          Curry._1(setFactor, (function (param) {
                  return newFactor;
                }));
          var newFilters = AlertsCreateEdit$BsConsole.getFilters(init);
          Curry._1(setFilters, (function (param) {
                  return newFilters;
                }));
          var newFold = AlertsCreateEdit$BsConsole.getFold(init);
          Curry._1(setFold, (function (param) {
                  return newFold;
                }));
          var newName = AlertsCreateEdit$BsConsole.getName(init);
          Curry._1(setName, (function (param) {
                  return newName;
                }));
          var newTriggerConditions = AlertsCreateEdit$BsConsole.getInitialTriggerConditions(init);
          Curry._1(setTriggerConditions, (function (param) {
                  return newTriggerConditions;
                }));
          var newQueryPeriod = AlertsCreateEdit$BsConsole.getQueryPeriod(init) / 60 | 0;
          Curry._1(setQueryPeriodMinutes, (function (param) {
                  return newQueryPeriod;
                }));
          var newMinNotificationInterval = AlertsCreateEdit$BsConsole.getMinNotificationInterval(init) / 60 | 0;
          Curry._1(setMinNotificationIntervalMinutes, (function (param) {
                  return newMinNotificationInterval;
                }));
          var newActions = AlertsCreateEdit$BsConsole.getInitialActions(init);
          Curry._1(setActions, (function (param) {
                  return newActions;
                }));
          
        }), /* tuple */[
        token,
        universeName,
        projectName,
        init
      ]);
  React.useEffect((function () {
          var similarAlerts = getSimilarAlerts(triggerConditions);
          Curry._1(setSimilarAlerts, (function (param) {
                  return similarAlerts;
                }));
          
        }), /* tuple */[
        token,
        universeName,
        projectName,
        triggerConditions,
        existingAlerts
      ]);
  React.useEffect((function () {
          var updatedActions = AlertsCreateEdit$BsConsole.getUpdatedActions(watchers, actions, init);
          Curry._1(setActions, (function (param) {
                  return updatedActions;
                }));
          
        }), /* tuple */[
        watchers,
        init
      ]);
  React.useEffect((function () {
          var arg = AlertsUtils$BsConsole.getAllAttributes(projectName, undefined);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), false, (function (results) {
                  if (results.tag) {
                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to fetch project attributes."));
                    return Curry._1(setAddAttributeError, (function (param) {
                                  return true;
                                }));
                  }
                  var attributes = results[0];
                  return Curry._1(setAllProjectAttributes, (function (param) {
                                return attributes;
                              }));
                }));
          
        }), /* tuple */[
        token,
        universeName,
        projectName
      ]);
  React.useEffect((function () {
          var onSuccess = function (availableIntegrations) {
            return Curry._1(setAllAvailableIntegrations, (function (param) {
                          return availableIntegrations;
                        }));
          };
          var onFailure = function (_err) {
            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error fetching all available integrations"));
          };
          var maybeTask = WfTasks$BsConsole.QueryWFIntegrations.queryWFIntegrationsTask(config);
          if (maybeTask !== undefined) {
            Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                    return WfTasks$BsConsole.QueryWFIntegrations.responseCb(onSuccess, onFailure, param);
                  }));
          } else {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
          }
          
        }), ([]));
  var close = function (param) {
    Curry._1(onClose, undefined);
    if (!init.tag) {
      return ;
    }
    Curry._1(setName, (function (param) {
            return "";
          }));
    var updatedTriggerConditions = Belt_List.map(triggerConditions, (function (trig) {
            if (trig !== undefined) {
              return {
                      attribute: trig.attribute,
                      operation: trig.operation,
                      comparisonOperator: /* NoThreshold */2,
                      warningThreshold: 0,
                      criticalThreshold: 0
                    };
            }
            
          }));
    Curry._1(setTriggerConditions, (function (param) {
            return updatedTriggerConditions;
          }));
    return Curry._1(setActions, (function (param) {
                  return /* [] */0;
                }));
  };
  var similarLength = Belt_List.length(match$6[0]);
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: _open,
              onClose: close,
              modalWidth: 1000,
              title: AlertsCreateEdit$BsConsole.getTitle(init),
              subtitle: "",
              divider: true,
              maxWidth: "sm",
              fullWidth: true,
              children: React.createElement(Col2$BsConsole.make, {
                    justifyContent: /* spaceBetween */516682146,
                    children: null
                  }, similarLength > 0 ? React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              color: Colors$BsConsole.grey0,
                              children: I18N$BsConsole.show(undefined, "This project has alerts similar to this query.")
                            }), React.createElement(Button.default, {
                              variant: "text",
                              color: "primary",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.marginLeft(Css.px(12)),
                                      /* [] */0
                                    ])
                              },
                              onClick: (function (param) {
                                  return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                                projectName,
                                                {
                                                  tab: AlertsRouter$BsConsole.typeToString(/* ErrorThresholdAlerts */0),
                                                  id: undefined
                                                }
                                              ]));
                                }),
                              children: I18N$BsConsole.show(undefined, "View alerts in settings")
                            })) : null, React.createElement(AlertsQueryDisplay$BsConsole.make, {
                        factor: factor,
                        setFactor: setFactor,
                        factorOptions: Belt_List.keepMap(allProjectAttributes, (function (att) {
                                if (att.group !== true) {
                                  return ;
                                }
                                var str = att.name;
                                var tmp;
                                switch (str) {
                                  case "fingerprint" :
                                  case "identity" :
                                      tmp = /* Identity */0;
                                      break;
                                  default:
                                    tmp = /* Custom */[str];
                                }
                                return tmp;
                              })),
                        filters: match$1[0],
                        fold: fold,
                        havings: havings
                      }), React.createElement("div", undefined, React.createElement("div", undefined, React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.marginBottom(Css.rem(0.05)),
                                      /* [] */0
                                    ])
                              }, React.createElement(InputLabel.default, {
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(0.5)),
                                            /* [] */0
                                          ])
                                    },
                                    children: React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Button */10,
                                          color: Colors$BsConsole.grey0,
                                          children: I18N$BsConsole.show(undefined, "Alert name")
                                        })
                                  })), React.createElement(BtSettings$BsConsole.FormInput.make, {
                                placeholder: "",
                                value: name,
                                onChange: (function (e) {
                                    var value = BtSettings$BsConsole.getInputValue(e);
                                    return Curry._1(setName, (function (param) {
                                                  return value;
                                                }));
                                  }),
                                disabled: false,
                                className: Css.style(/* :: */[
                                      Css.width(Css.px(330)),
                                      /* :: */[
                                        Css.marginTop(Css.px(8)),
                                        /* :: */[
                                          Css.marginLeft(/* auto */-1065951377),
                                          /* :: */[
                                            Css.marginRight(/* auto */-1065951377),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ])
                              })), React.createElement(InputLabel.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginBottom(Css.rem(0.5)),
                                    /* [] */0
                                  ])
                            },
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Button */10,
                                  color: Colors$BsConsole.grey0,
                                  children: I18N$BsConsole.show(undefined, "Thresholds")
                                })
                          }), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            className: Css.style(/* :: */[
                                  Css.marginBottom(Css.rem(1.0)),
                                  /* [] */0
                                ]),
                            children: null
                          }, React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.marginRight(Css.px(12)),
                                      /* [] */0
                                    ])
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    color: Colors$BsConsole.grey0,
                                    children: I18N$BsConsole.show(undefined, "Over the last")
                                  })), React.createElement(TextField.default, {
                                autoFocus: true,
                                placeholder: "",
                                value: String(queryPeriodMinutes),
                                color: "primary",
                                type: "number",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.width(Css.px(50)),
                                        /* [] */0
                                      ])
                                },
                                inputProps: {
                                  min: 1,
                                  step: 1
                                },
                                onChange: (function ($$event) {
                                    var value;
                                    try {
                                      value = Caml_format.caml_int_of_string($$event.target.value);
                                    }
                                    catch (exn){
                                      value = 0;
                                    }
                                    return Curry._1(setQueryPeriodMinutes, (function (param) {
                                                  return value;
                                                }));
                                  })
                              }), React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.marginLeft(Css.px(5)),
                                      /* [] */0
                                    ])
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    color: Colors$BsConsole.grey0,
                                    children: I18N$BsConsole.show(undefined, "minutes")
                                  }))), queryPeriodMinutes < 1 ? React.createElement(Row2$BsConsole.make, {
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.px(8)),
                                    /* [] */0
                                  ]),
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    color: Colors$BsConsole.redDark,
                                    children: I18N$BsConsole.show(undefined, "Query period must be at least 1 minute.")
                                  })
                            }) : null, React.createElement(Row2$BsConsole.make, {
                            children: React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.marginRight(Css.px(12)),
                                        /* :: */[
                                          Css.marginBottom(Css.px(24)),
                                          /* [] */0
                                        ]
                                      ])
                                }, React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      color: Colors$BsConsole.grey0,
                                      children: I18N$BsConsole.show(undefined, "Trigger an alert when...")
                                    }))
                          }), Belt_List.toArray(Belt_List.mapWithIndex(triggerConditions, (function (index, t) {
                                  return React.createElement(React.Fragment, {
                                              children: React.createElement(AlertsTrigger$BsConsole.make, {
                                                    triggerMaybe: t,
                                                    triggerIndex: index,
                                                    updateTrigger: (function (attribute, comparisonOperator, warningThreshold, criticalThreshold, param) {
                                                        var updated = Belt_List.mapWithIndex(triggerConditions, (function (i, t) {
                                                                if (i !== index) {
                                                                  return t;
                                                                }
                                                                if (t !== undefined) {
                                                                  if (comparisonOperator !== undefined && comparisonOperator >= 2) {
                                                                    return {
                                                                            attribute: t.attribute,
                                                                            operation: t.operation,
                                                                            comparisonOperator: /* NoThreshold */2,
                                                                            warningThreshold: 0,
                                                                            criticalThreshold: 0
                                                                          };
                                                                  }
                                                                  return {
                                                                          attribute: t.attribute,
                                                                          operation: t.operation,
                                                                          comparisonOperator: comparisonOperator !== undefined ? comparisonOperator : t.comparisonOperator,
                                                                          warningThreshold: warningThreshold !== undefined ? warningThreshold : t.warningThreshold,
                                                                          criticalThreshold: criticalThreshold !== undefined ? criticalThreshold : t.criticalThreshold
                                                                        };
                                                                }
                                                                if (attribute === undefined) {
                                                                  return t;
                                                                }
                                                                var newFold = AlertsUtils$BsConsole.newFoldWithAttOpAdded(fold, attribute, /* Unique */4, undefined);
                                                                Curry._1(setFold, (function (param) {
                                                                        return newFold;
                                                                      }));
                                                                return {
                                                                        attribute: attribute,
                                                                        operation: "unique",
                                                                        comparisonOperator: /* NoThreshold */2,
                                                                        warningThreshold: 0,
                                                                        criticalThreshold: 0
                                                                      };
                                                              }));
                                                        return Curry._1(setTriggerConditions, (function (param) {
                                                                      return updated;
                                                                    }));
                                                      }),
                                                    removeTriggerCondition: (function (param) {
                                                        var newFold = t !== undefined ? AlertsUtils$BsConsole.newFoldWithAttOpRemoved(fold, t.attribute, t.operation, undefined) : fold;
                                                        var updated = Belt_List.filterWithIndex(triggerConditions, (function (_t, i) {
                                                                return i !== index;
                                                              }));
                                                        Curry._1(setTriggerConditions, (function (param) {
                                                                return updated;
                                                              }));
                                                        return Curry._1(setFold, (function (param) {
                                                                      return newFold;
                                                                    }));
                                                      }),
                                                    allAttributes: allProjectAttributes,
                                                    allSelectedAttributes: Belt_List.map(Belt_List.keepMap(triggerConditions, (function (t) {
                                                                return t;
                                                              })), (function (t) {
                                                            return t.attribute;
                                                          })),
                                                    hideCloseButton: Belt_List.length(triggerConditions) === 1
                                                  }),
                                              key: String(index)
                                            });
                                }))), React.createElement(AlertsUtils$BsConsole.AlertWarning.make, {
                            message: invalidTriggerError
                          }), match$12[0] ? null : React.createElement(Button.default, {
                              variant: "text",
                              color: "primary",
                              onClick: (function (param) {
                                  var updated = Belt_List.concat(triggerConditions, /* :: */[
                                        undefined,
                                        /* [] */0
                                      ]);
                                  return Curry._1(setTriggerConditions, (function (param) {
                                                return updated;
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Add aggregation")
                            })), React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.marginTop(Css.px(12)),
                              /* [] */0
                            ])
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Button */10,
                            color: Colors$BsConsole.grey0,
                            children: I18N$BsConsole.show(undefined, "Notification interval")
                          }), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            className: Css.style(/* :: */[
                                  Css.marginBottom(Css.rem(1.0)),
                                  /* [] */0
                                ]),
                            children: null
                          }, React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.marginRight(Css.px(8)),
                                      /* [] */0
                                    ])
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    color: Colors$BsConsole.grey0,
                                    children: I18N$BsConsole.show(undefined, "Space notifications by ")
                                  })), React.createElement(TextField.default, {
                                autoFocus: true,
                                placeholder: "",
                                value: String(minNotificationIntervalMinutes),
                                color: "primary",
                                type: "number",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.width(Css.px(45)),
                                        /* :: */[
                                          Css.marginRight(Css.px(10)),
                                          /* [] */0
                                        ]
                                      ])
                                },
                                inputProps: {
                                  min: 1
                                },
                                onChange: (function ($$event) {
                                    var value;
                                    try {
                                      value = Caml_format.caml_int_of_string($$event.target.value);
                                    }
                                    catch (exn){
                                      value = 0;
                                    }
                                    return Curry._1(setMinNotificationIntervalMinutes, (function (param) {
                                                  return value;
                                                }));
                                  })
                              }), React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.marginLeft(Css.px(5)),
                                      /* [] */0
                                    ])
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    color: Colors$BsConsole.grey0,
                                    children: I18N$BsConsole.show(undefined, "minutes")
                                  }))), minNotificationIntervalMinutes < 1 ? React.createElement(Row2$BsConsole.make, {
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.px(8)),
                                    /* [] */0
                                  ]),
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    color: Colors$BsConsole.redDark,
                                    children: I18N$BsConsole.show(undefined, "Minimum notification interval must be at least 1 minute.")
                                  })
                            }) : null), React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Button */10,
                            color: Colors$BsConsole.grey0,
                            children: I18N$BsConsole.show(undefined, "Integrations")
                          })), Belt_List.toArray(Belt_List.mapWithIndex(actions, (function (index, action) {
                              return React.createElement(AlertsCreateAlertModal$Target, {
                                          watchers: Belt_Array.keep(watchers, (function (watcher) {
                                                  var matchedIntegration = Belt_Array.getBy(allAvailableIntegraitons, (function (availableIntegration) {
                                                          return availableIntegration.description.type_ === watcher.type_;
                                                        }));
                                                  if (matchedIntegration !== undefined) {
                                                    return Belt_Option.getWithDefault(matchedIntegration.description.alert, true);
                                                  } else {
                                                    return false;
                                                  }
                                                })),
                                          selectedWatcher: action,
                                          setSelectedWatcher: (function (newWatcher) {
                                              var updated = Belt_List.mapWithIndex(actions, (function (i, action) {
                                                      if (i === index) {
                                                        return newWatcher;
                                                      } else {
                                                        return action;
                                                      }
                                                    }));
                                              return Curry._1(setActions, (function (param) {
                                                            return updated;
                                                          }));
                                            }),
                                          disableRemove: Belt_List.length(actions) === 1 && AlertsCreateEdit$BsConsole.isQuery(init),
                                          remove: (function (param) {
                                              var updatedBegin = Belt_List.take(actions, index);
                                              var updatedEnd = Belt_List.drop(actions, index + 1 | 0);
                                              var updated = updatedBegin !== undefined && updatedEnd !== undefined ? Belt_List.concat(updatedBegin, updatedEnd) : /* [] */0;
                                              return Curry._1(setActions, (function (param) {
                                                            return updated;
                                                          }));
                                            }),
                                          handleChangeUrl: handleChangeUrl,
                                          projectName: projectName
                                        });
                            }))), React.createElement(Button.default, {
                        variant: "text",
                        color: "primary",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginTop(Css.px(8)),
                                /* :: */[
                                  Css.marginBottom(Css.px(8)),
                                  /* [] */0
                                ]
                              ])
                        },
                        onClick: (function (param) {
                            var updated = Belt_List.concat(actions, /* :: */[
                                  undefined,
                                  /* [] */0
                                ]);
                            return Curry._1(setActions, (function (param) {
                                          return updated;
                                        }));
                          }),
                        children: I18N$BsConsole.show(undefined, "Add integration")
                      }), React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.marginTop(Css.px(12)),
                              /* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ]
                            ]),
                        children: null
                      }, React.createElement("div", undefined, React.createElement(Button.default, {
                                variant: "contained",
                                color: "primary",
                                disabled: name === "" || !AlertsUtils$BsConsole.isValidName(name) || queryPeriodMinutes < 1 || minNotificationIntervalMinutes < 1 || Belt_List.length(AlertsUtils$BsConsole.getInvalidTriggerConditions(triggerConditions)) > 0 || Belt_List.length(AlertsUtils$BsConsole.getValidTriggerConditions(triggerConditions)) === 0,
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginRight(Css.px(8)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    var triggers = Belt_List.keep(Belt_List.keepMap(Belt_List.keepMap(triggerConditions, (function (t) {
                                                    return t;
                                                  })), (function (t) {
                                                var attributeIndex = Curry._3(Crdb$BsConsole.Fold.getAttributeAggregateIndex, t.attribute, t.operation, fold);
                                                if (attributeIndex === undefined) {
                                                  return getErrorCountTrigger(fold, t, undefined);
                                                }
                                                var match = t.attribute === AlertsUtils$BsConsole.errorCountAttribute;
                                                var match$1 = t.operation === AlertsUtils$BsConsole.errorCountOperation;
                                                var match$2 = t.comparisonOperator === /* NoThreshold */2;
                                                if (match && match$1 && !match$2) {
                                                  return getErrorCountTrigger(fold, t, undefined);
                                                }
                                                if (match$2) {
                                                  return ;
                                                }
                                                var aggregation_column = t.attribute;
                                                var aggregation = {
                                                  column: aggregation_column,
                                                  index: attributeIndex
                                                };
                                                return {
                                                        aggregation: aggregation,
                                                        comparisonOperator: AlertsComparisonOperator$BsConsole.toValue(t.comparisonOperator),
                                                        warningThreshold: t.warningThreshold,
                                                        criticalThreshold: t.criticalThreshold
                                                      };
                                              })), (function (t) {
                                            return t.comparisonOperator !== AlertsComparisonOperator$BsConsole.toValue(/* NoThreshold */2);
                                          }));
                                    var targetObjects = Belt_List.keepMap(actions, (function (a) {
                                            return a;
                                          }));
                                    var targetCreatePromises = Belt_List.toArray(Belt_List.map(targetObjects, (function (t) {
                                                return new Promise((function (resolve, reject) {
                                                              var arg = AlertsTargetApi$BsConsole.create(endpoint, universeName, projectName, t.watcherName, t.watcherName, undefined);
                                                              return Curry._2((function (param) {
                                                                              return (function (param$1, param$2) {
                                                                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                                });
                                                                            })(token), undefined, (function (resp) {
                                                                            if (!resp.tag) {
                                                                              return resolve("ok");
                                                                            }
                                                                            var err = resp[0];
                                                                            if (Task2$BsConsole.renderError(undefined, err).toLowerCase().includes("unique constraint")) {
                                                                              return resolve("ok");
                                                                            } else {
                                                                              return reject([
                                                                                          Task2$BsConsole.TaskError,
                                                                                          err
                                                                                        ]);
                                                                            }
                                                                          }));
                                                            }));
                                              })));
                                    var __x = Promise.all(targetCreatePromises);
                                    var __x$1 = __x.then((function (_results) {
                                            return Promise.resolve(undefined);
                                          }));
                                    __x$1.catch((function (exn) {
                                            if (exn[0] === Task2$BsConsole.TaskError) {
                                              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(failedToCreateTargetsText + (" " + I18N$BsConsole.get(undefined, Task2$BsConsole.renderError(undefined, exn[1])))));
                                            }
                                            return Promise.resolve(undefined);
                                          }));
                                    var arg = AlertsTargetApi$BsConsole.get(endpoint, universeName, projectName, undefined);
                                    return Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                      });
                                                  })(token), undefined, (function (resp) {
                                                  if (resp.tag) {
                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(failedToGetTargets + (" " + I18N$BsConsole.get(undefined, Task2$BsConsole.renderError(undefined, resp[0])))));
                                                    return close(undefined);
                                                  }
                                                  var targetIds = Belt_List.map(Belt_List.keep(resp[0].values, (function (target) {
                                                              return Belt_List.length(Belt_List.keep(targetObjects, (function (t) {
                                                                                return t.watcherName === target.name;
                                                                              }))) > 0;
                                                            })), (function (target) {
                                                          return target.id;
                                                        }));
                                                  var newFold = Belt_List.length(Belt_List.keep(Belt_List.keepMap(triggerConditions, (function (t) {
                                                                  return t;
                                                                })), (function (t) {
                                                              if (t.attribute === AlertsUtils$BsConsole.errorCountAttribute) {
                                                                return t.operation === AlertsUtils$BsConsole.errorCountOperation;
                                                              } else {
                                                                return false;
                                                              }
                                                            }))) > 0 ? Curry._2(Crdb$BsConsole.Fold.add, AlertsUtils$BsConsole.errorCountAggregation, fold) : fold;
                                                  var queryTimeless = makeQueryTimeless(aperture, factor, newFold, sort, havings);
                                                  var alertSettings;
                                                  if (init.tag) {
                                                    alertSettings = /* CreateAlert */Block.__(0, [
                                                        name,
                                                        triggers,
                                                        queryTimeless,
                                                        Caml_int32.imul(queryPeriodMinutes, 60),
                                                        0.0,
                                                        Caml_int32.imul(minNotificationIntervalMinutes, 60),
                                                        true,
                                                        targetIds
                                                      ]);
                                                  } else {
                                                    var alert = init[0];
                                                    alertSettings = /* EditAlert */Block.__(1, [
                                                        alert.id,
                                                        name,
                                                        triggers,
                                                        queryTimeless,
                                                        Caml_int32.imul(queryPeriodMinutes, 60),
                                                        alert.muteUntil,
                                                        Caml_int32.imul(minNotificationIntervalMinutes, 60),
                                                        alert.enabled,
                                                        targetIds
                                                      ]);
                                                  }
                                                  Curry._8(handleSave, token, endpoint, universeName, projectName, handleChangeUrl, alertSettings, afterSave, undefined);
                                                  return close(undefined);
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Save")
                              }), showPreviewInExplore ? React.createElement(Button.default, {
                                  variant: "outlined",
                                  color: "primary",
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.marginRight(Css.px(8)),
                                          /* [] */0
                                        ])
                                  },
                                  onClick: (function (param) {
                                      return Util$BsConsole.openUrl(Route$BsConsole.pathForRoute(Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, undefined, Caml_option.some(havings), factor, Caml_option.some(AlertsUtils$BsConsole.foldWithoutErrorCountAgg(fold)), undefined, undefined, undefined, undefined)));
                                    }),
                                  children: I18N$BsConsole.show(undefined, "Preview aggregations in explore")
                                }) : null), React.createElement(Button.default, {
                            variant: "text",
                            color: "primary",
                            onClick: close,
                            children: I18N$BsConsole.show(undefined, "Cancel")
                          })))
            });
}

var chipLength = 15;

var make = AlertsCreateAlertModal;

exports.failedToCreateTargetsText = failedToCreateTargetsText;
exports.failedToGetTargets = failedToGetTargets;
exports.makeQueryTimeless = makeQueryTimeless;
exports.chipLabel = chipLabel;
exports.chipLength = chipLength;
exports.optionalTooltip = optionalTooltip;
exports.getErrorCountTrigger = getErrorCountTrigger;
exports.Target = Target;
exports.make = make;
/* failedToCreateTargetsText Not a pure module */
