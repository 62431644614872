// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function initializeRouter(param) {
  var $$window = typeof window === "undefined" ? undefined : window;
  if ($$window !== undefined) {
    $$window.dispatchEvent(new Event("popstate"));
    return ;
  }
  
}

function removeTokenFromLocalStorage(param) {
  localStorage.removeItem("token");
  
}

function writeTokenToLocalStorage(token) {
  localStorage.setItem("token", token);
  
}

function removeReferrerFromLocalStorage(param) {
  localStorage.removeItem("referrer");
  
}

function getReferrerFromLocalStorage(param) {
  return Caml_option.null_to_opt(localStorage.getItem("referrer"));
}

function writeReferrerToLocalStorage(referrer) {
  localStorage.setItem("referrer", Belt_Option.getWithDefault(referrer, ""));
  
}

exports.initializeRouter = initializeRouter;
exports.removeTokenFromLocalStorage = removeTokenFromLocalStorage;
exports.writeTokenToLocalStorage = writeTokenToLocalStorage;
exports.removeReferrerFromLocalStorage = removeReferrerFromLocalStorage;
exports.getReferrerFromLocalStorage = getReferrerFromLocalStorage;
exports.writeReferrerToLocalStorage = writeReferrerToLocalStorage;
/* No side effect */
