// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

var I18n_ptBR = {
  required: "Campo obrigatório",
  email: "Email inválido"
};

var I18n_en = {
  required: "Field is required",
  email: "Invalid email"
};

var I18n = {
  ptBR: I18n_ptBR,
  en: I18n_en
};

function getValidationError(param, values, value, i18n) {
  var validator = param[1];
  if (typeof validator === "number") {
    if (validator !== 0) {
      if (/\S+@\S+\.\S+/.test(value)) {
        return ;
      } else {
        return i18n.email;
      }
    } else if (value.length < 1) {
      return i18n.required;
    } else {
      return ;
    }
  } else {
    return Curry._1(validator[0], values);
  }
}

exports.I18n = I18n;
exports.getValidationError = getValidationError;
/* No side effect */
