// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Nemesis$BsConsole = require("./Nemesis.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var BillingLimitsFetch$BsConsole = require("../BillingLimitsFetch.js");
var SubSettingsWithNemesis$BsConsole = require("./SubSettingsWithNemesis.js");

function SettingsSubscriptions(Props) {
  var config = Props.config;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  Props.handleTask;
  var match = BillingLimitsFetch$BsConsole.use(config.universe.name, token);
  var limitsRemote = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setValidNemesisVersion = match$1[1];
  var validNemesisVersion = match$1[0];
  React.useEffect((function () {
          var arg = Nemesis$BsConsole.fetchServiceVersion(undefined);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "Error fetching subscription service version: ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* End_of_format */0
                                              ])
                                          ]),
                                        "Error fetching subscription service version: %s"
                                      ]), Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var versionString = resp[0];
                  return Curry._1(setValidNemesisVersion, (function (param) {
                                return Semver.gte(versionString, Nemesis$BsConsole.minimumRequiredVersion);
                              }));
                }));
          
        }), /* tuple */[
        config,
        token
      ]);
  var tmp;
  if (typeof limitsRemote === "number" || limitsRemote.tag) {
    tmp = null;
  } else {
    var limits = limitsRemote[0];
    if (limits !== undefined) {
      var match$2 = limits.metadata.manualOverride;
      tmp = match$2 !== undefined && match$2 ? I18N$BsConsole.show(undefined, "Please contact support for more information.") : I18N$BsConsole.show(undefined, "View your Backtrace subscription details. Admins can make changes to plans and payment information.");
    } else {
      tmp = null;
    }
  }
  var tmp$1;
  if (typeof limitsRemote === "number" || limitsRemote.tag) {
    tmp$1 = null;
  } else {
    var limitsMaybe = limitsRemote[0];
    tmp$1 = limitsMaybe !== undefined && validNemesisVersion !== undefined && validNemesisVersion ? React.createElement(SubSettingsWithNemesis$BsConsole.make, {
            token: token,
            config: config,
            handleChangeUrl: handleChangeUrl,
            limits: limitsMaybe,
            refetchLimits: match[1]
          }) : null;
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: SubUtil$BsConsole.Style.marginBottom,
                  children: I18N$BsConsole.show(undefined, "Subscriptions")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Curry._1(SubUtil$BsConsole.Style.merge, /* :: */[
                        SubUtil$BsConsole.Style.featureDescription,
                        /* :: */[
                          SubUtil$BsConsole.Style.marginBottom,
                          /* [] */0
                        ]
                      ]),
                  children: tmp
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: SubUtil$BsConsole.Style.marginBottom
                }), tmp$1);
}

function make(config, token, handleChangeUrl, handleTask, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SettingsSubscriptions, {
              config: config,
              token: token,
              handleChangeUrl: handleChangeUrl,
              handleTask: handleTask
            }, children);
}

var Re = {
  make: make
};

var make$1 = SettingsSubscriptions;

exports.make = make$1;
exports.Re = Re;
/* react Not a pure module */
