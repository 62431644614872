// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Colors$BsConsole = require("../Colors.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");

var stateIcon = Css.style(/* :: */[
      Css.borderRadius(Css.pct(50)),
      /* :: */[
        Css.width(Css.px(24)),
        /* :: */[
          Css.height(Css.px(24)),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.justifyContent(/* center */98248149),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.white)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var component = ReasonReact.statelessComponent("InboxStateIcon-BsConsole");

function make(classNameOpt, state, contentOpt, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var content = contentOpt !== undefined ? Caml_option.valFromOption(contentOpt) : null;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("div", {
                          className: stateIcon + (" " + className),
                          style: {
                            backgroundColor: Issues$BsConsole.State.toColor(state)
                          },
                          onClick: (function (e) {
                              e.stopPropagation();
                              
                            })
                        }, content);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.stateIcon = stateIcon;
exports.component = component;
exports.make = make;
/* stateIcon Not a pure module */
