// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var BtSelect$BsConsole = require("./BtSelect.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BtFilterSelect$BsConsole = require("./BtFilterSelect.js");
var BtOutlineSelect$BsConsole = require("./BtOutlineSelect.js");
var MenuItem = require("@material-ui/core/MenuItem");

var jsMapperConstantArray = [
  /* tuple */[
    -834597217,
    "Aggregate"
  ],
  /* tuple */[
    35181051,
    "Flame Graph"
  ],
  /* tuple */[
    436526339,
    "Similarity"
  ],
  /* tuple */[
    848054398,
    "List"
  ]
];

function currentViewToJs(param) {
  return Js_mapperRt.binarySearch(4, param, jsMapperConstantArray);
}

function currentViewFromJs(param) {
  return Js_mapperRt.revSearch(4, jsMapperConstantArray, param);
}

function currentViewToRoute(aperture, projectName, currentView) {
  if (currentView >= 436526339) {
    if (currentView >= 848054398) {
      return /* ProjectInstances */Block.__(15, [
                projectName,
                Route$BsConsole.getInstancesParams(projectName, Caml_option.some(aperture), undefined)
              ]);
    } else {
      return /* ProjectSimilarity */Block.__(21, [
                projectName,
                Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), undefined, projectName, undefined, undefined, undefined, undefined, undefined, undefined)
              ]);
    }
  } else if (currentView >= 35181051) {
    return /* ProjectFlamegraph */Block.__(20, [
              projectName,
              Route$BsConsole.getFlamegraphParams(undefined, undefined, projectName, Caml_option.some(aperture), undefined)
            ]);
  } else {
    return /* ProjectQueryBuilder */Block.__(13, [
              projectName,
              Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), undefined, projectName, undefined, undefined, undefined, undefined, undefined, undefined)
            ]);
  }
}

var link = Css.style(/* :: */[
      Css.padding2(Css.px(12), Css.px(16)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var iconContainer = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* [] */0
      ]
    ]);

var Style = {
  link: link,
  iconContainer: iconContainer
};

var component = RR$BsConsole.statelessComponent("ExploreModeSelect-BsConsole");

function make(currentView, handleChangeUrl, aperture, name, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var init = Route$BsConsole.getQueryBuilderParams(undefined, undefined, name, undefined, undefined, undefined, undefined, undefined, undefined);
              var init$1 = Route$BsConsole.getInstancesParams(name, undefined, undefined);
              var init$2 = Route$BsConsole.getFlamegraphParams(undefined, undefined, name, undefined, undefined);
              var init$3 = Route$BsConsole.getQueryBuilderParams(undefined, undefined, name, undefined, undefined, undefined, undefined, undefined, undefined);
              return ReasonReact.element(undefined, undefined, BtSelect$BsConsole.make((function (param, param$1) {
                                
                              }), /* `String */[
                              -976970511,
                              currentViewToJs(currentView)
                            ], undefined, I18N$BsConsole.get(undefined, "View as"), (function (value$prime) {
                                return React.createElement("span", undefined, I18N$BsConsole.showSkip(value$prime));
                              }), undefined, undefined, undefined, undefined, undefined, [
                              ReasonReact.element("Aggregate" + name, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                        -976970511,
                                        currentViewToJs(/* Aggregate */-834597217)
                                      ], undefined, undefined, [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectQueryBuilder */Block.__(13, [
                                                    name,
                                                    {
                                                      aperture: aperture,
                                                      factor: init.factor,
                                                      havings: init.havings,
                                                      fold: init.fold,
                                                      sort: init.sort,
                                                      normBy: init.normBy,
                                                      highlightExporter: init.highlightExporter
                                                    }
                                                  ]), undefined, handleChangeUrl, undefined, undefined, link, [I18N$BsConsole.show(undefined, "Aggregate")]))])),
                              ReasonReact.element("List" + name, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                        -976970511,
                                        currentViewToJs(/* List */848054398)
                                      ], undefined, undefined, [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectInstances */Block.__(15, [
                                                    name,
                                                    {
                                                      aperture: aperture,
                                                      select: init$1.select,
                                                      sort: init$1.sort
                                                    }
                                                  ]), undefined, handleChangeUrl, undefined, undefined, link, [I18N$BsConsole.show(undefined, "List")]))])),
                              ReasonReact.element("Flame-Graph" + name, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                        -976970511,
                                        currentViewToJs(/* FlameGraph */35181051)
                                      ], undefined, undefined, [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectFlamegraph */Block.__(20, [
                                                    name,
                                                    {
                                                      aperture: aperture,
                                                      weighted: init$2.weighted,
                                                      reversed: init$2.reversed
                                                    }
                                                  ]), undefined, handleChangeUrl, undefined, undefined, link, [I18N$BsConsole.show(undefined, "Flame graph")]))])),
                              ReasonReact.element("Similarity" + name, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                        -976970511,
                                        currentViewToJs(/* Similarity */436526339)
                                      ], undefined, undefined, [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectSimilarity */Block.__(21, [
                                                    name,
                                                    {
                                                      aperture: aperture,
                                                      factor: init$3.factor,
                                                      havings: init$3.havings,
                                                      fold: init$3.fold,
                                                      sort: init$3.sort,
                                                      normBy: init$3.normBy,
                                                      highlightExporter: init$3.highlightExporter
                                                    }
                                                  ]), undefined, handleChangeUrl, undefined, undefined, link, [I18N$BsConsole.show(undefined, "Similarity")]))]))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

function ExploreModeSelect$Jsx3(Props) {
  var projectName = Props.projectName;
  var aperture = Props.aperture;
  var currentView = Props.currentView;
  var handleChangeUrl = Props.handleChangeUrl;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var handleOnChange = function ($$event, _jsEvent) {
    var value = $$event.target.value;
    if (value === currentView) {
      return ;
    }
    var func = function (param, param$1) {
      return currentViewToRoute(aperture, param, param$1);
    };
    return Curry._1(handleChangeUrl, Curry._2(func, projectName, value));
  };
  return React.createElement(BtOutlineSelect$BsConsole.make, {
              value: currentView,
              name: "explore-view-as",
              renderValue: (function (value) {
                  return React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              children: null
                            }, React.createElement(Row2$BsConsole.make, {
                                  alignItems: /* center */98248149,
                                  className: iconContainer,
                                  children: React.createElement(MuiIcons$BsConsole.OutlinedList.make, {
                                        color: "inherit",
                                        fontSize: "inherit"
                                      })
                                }), I18N$BsConsole.show(undefined, currentViewToJs(value)));
                }),
              onChange: handleOnChange,
              label: "View as",
              className: className,
              children: null
            }, React.createElement(MenuItem.default, {
                  disableRipple: true,
                  classes: BtFilterSelect$BsConsole.Style.menuItemClasses,
                  value: /* Aggregate */-834597217,
                  children: I18N$BsConsole.show(undefined, "Aggregate")
                }), React.createElement(MenuItem.default, {
                  disableRipple: true,
                  classes: BtFilterSelect$BsConsole.Style.menuItemClasses,
                  value: /* List */848054398,
                  children: I18N$BsConsole.show(undefined, "List")
                }), React.createElement(MenuItem.default, {
                  disableRipple: true,
                  classes: BtFilterSelect$BsConsole.Style.menuItemClasses,
                  value: /* FlameGraph */35181051,
                  children: I18N$BsConsole.show(undefined, "Flame graph")
                }), React.createElement(MenuItem.default, {
                  disableRipple: true,
                  classes: BtFilterSelect$BsConsole.Style.menuItemClasses,
                  value: /* Similarity */436526339,
                  children: I18N$BsConsole.show(undefined, "Similarity")
                }));
}

var Jsx3 = {
  make: ExploreModeSelect$Jsx3
};

exports.currentViewToJs = currentViewToJs;
exports.currentViewFromJs = currentViewFromJs;
exports.currentViewToRoute = currentViewToRoute;
exports.Style = Style;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* link Not a pure module */
