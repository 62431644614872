// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

var $$window = window;

var component = ReasonReact.reducerComponent("WindowSize");

function make(children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var send = param.send;
              var cb = function (param) {
                return Curry._1(send, /* SetDimensions */[
                            $$window.innerWidth,
                            $$window.innerHeight
                          ]);
              };
              $$window.addEventListener("resize", cb);
              return Curry._1(param.onUnmount, (function (param) {
                            $$window.removeEventListener("resize", cb);
                            
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var match = param.state;
              return Curry._2(children, match.width, match.height);
            }),
          initialState: (function (param) {
              return {
                      width: $$window.innerWidth,
                      height: $$window.innerHeight
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, param) {
              return /* Update */Block.__(0, [{
                          width: action[0],
                          height: action[1]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.$$window = $$window;
exports.component = component;
exports.make = make;
/* window Not a pure module */
