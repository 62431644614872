// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../../Uncaught.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var WorkflowVersion$BsConsole = require("../../workflow/WorkflowVersion.js");
var BacktraceParserGenerator$BsConsole = require("../../BacktraceParserGenerator.js");

var ParseError = Caml_exceptions.create("ApiAlerts-BsConsole.ParseError");

var ServiceError = Caml_exceptions.create("ApiAlerts-BsConsole.ServiceError");

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = error[0] === ServiceError ? error[1] : "Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

var bpgType = "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table;

var cacheKey = "bpg-" + bpgType;

function fromJson(json) {
  return {
          error: Json_decode.field("error", Json_decode.string, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var $$Error = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          attribute: Json_decode.field("attribute", Json_decode.string, json),
          filter: Json_decode.field("filter", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

function toJson(filters) {
  if (filters !== undefined) {
    return Belt_Array.map(filters, (function (filter) {
                  return Json_encode.object_(/* :: */[
                              /* tuple */[
                                "type",
                                filter.type_
                              ],
                              /* :: */[
                                /* tuple */[
                                  "attribute",
                                  filter.attribute
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "filter",
                                    filter.filter
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]);
                }));
  } else {
    return [];
  }
}

var Filters = {
  fromJson: fromJson$1,
  toJson: toJson
};

function fromJson$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var Integrations = {
  fromJson: fromJson$2
};

function fromJson$3(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          timeFrame: Json_decode.optional((function (param) {
                  return Json_decode.field("timeFrame", Json_decode.$$float, param);
                }), json),
          value: Json_decode.optional((function (param) {
                  return Json_decode.field("value", Json_decode.$$int, param);
                }), json)
        };
}

function toJson$1(condition) {
  var match = condition.timeFrame;
  var match$1 = condition.value;
  return Json_encode.object_(match !== undefined && match$1 !== undefined ? /* :: */[
                /* tuple */[
                  "name",
                  condition.name
                ],
                /* :: */[
                  /* tuple */[
                    "timeFrame",
                    match
                  ],
                  /* :: */[
                    /* tuple */[
                      "value",
                      match$1
                    ],
                    /* [] */0
                  ]
                ]
              ] : /* :: */[
                /* tuple */[
                  "name",
                  condition.name
                ],
                /* [] */0
              ]);
}

var availableOptions = [
  /* Group */0,
  /* Trace */1,
  /* ErrorsPerFingerprint */2,
  /* UsersPerFingerprint */3
];

function stringToType(name) {
  switch (name) {
    case "errorsPerFingerprint" :
        return /* ErrorsPerFingerprint */2;
    case "group" :
        return /* Group */0;
    case "trace" :
        return /* Trace */1;
    case "usersPerFingerprint" :
        return /* UsersPerFingerprint */3;
    default:
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiAlerts/Conditions/Name/stringToType", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `string */[
            288368849,
            "\"Condition name " + (String(name) + " not accounted for\"")
          ]);
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "ApiAlerts.re",
              134,
              8
            ]
          ];
  }
}

function typeToString(param) {
  switch (param) {
    case /* Group */0 :
        return "group";
    case /* Trace */1 :
        return "trace";
    case /* ErrorsPerFingerprint */2 :
        return "errorsPerFingerprint";
    case /* UsersPerFingerprint */3 :
        return "usersPerFingerprint";
    
  }
}

function typeToMenuItem(param) {
  switch (param) {
    case /* Group */0 :
        return "A new fingerprint (error group) is detected";
    case /* Trace */1 :
        return "A new error is detected";
    case /* ErrorsPerFingerprint */2 :
        return "Number of errors in a fingerprint is greater than {value} in {time}";
    case /* UsersPerFingerprint */3 :
        return "Number of users affected by fingerprint is greater than {value} in {time}";
    
  }
}

var Name = {
  availableOptions: availableOptions,
  stringToType: stringToType,
  typeToString: typeToString,
  typeToMenuItem: typeToMenuItem
};

var availableOptions$1 = [
  /* OneHour */0,
  /* OneDay */1,
  /* OneWeek */2,
  /* OneMonth */3
];

function typeToString$1(param) {
  switch (param) {
    case /* OneHour */0 :
        return "1 hour";
    case /* OneDay */1 :
        return "1 day";
    case /* OneWeek */2 :
        return "1 week";
    case /* OneMonth */3 :
        return "30 days";
    
  }
}

function typeToMs(param) {
  switch (param) {
    case /* OneHour */0 :
        return 3600000;
    case /* OneDay */1 :
        return 86400000;
    case /* OneWeek */2 :
        return 604800000;
    case /* OneMonth */3 :
        return 2592000000;
    
  }
}

function msToType(ms) {
  if (ms < 604800000) {
    if (ms === 3600000) {
      return /* OneHour */0;
    }
    if (ms === 86400000) {
      return /* OneDay */1;
    }
    
  } else {
    if (ms === 604800000) {
      return /* OneWeek */2;
    }
    if (ms === 2592000000) {
      return /* OneMonth */3;
    }
    
  }
  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiAlerts/Conditions/TimeFrame/msToType", Backtrace$BsConsole.Attributes.make(undefined));
  Backtrace$BsConsole.Client.send(__x, /* `string */[
        288368849,
        "\"Condition timeFrame " + (String(ms) + " not accounted for\"")
      ]);
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "ApiAlerts.re",
          191,
          8
        ]
      ];
}

var TimeFrame = {
  availableOptions: availableOptions$1,
  typeToString: typeToString$1,
  typeToMs: typeToMs,
  msToType: msToType
};

var Condition = {
  fromJson: fromJson$3,
  toJson: toJson$1,
  Name: Name,
  TimeFrame: TimeFrame
};

function fromJson$4(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          condition: Json_decode.field("condition", fromJson$3, json),
          filters: Json_decode.optional((function (param) {
                  return Json_decode.field("filters", (function (param) {
                                return Json_decode.array(fromJson$1, param);
                              }), param);
                }), json),
          frequency: Json_decode.field("frequency", Json_decode.$$int, json),
          executionDelay: Json_decode.field("executionDelay", Json_decode.$$int, json),
          integrations: Json_decode.field("integrations", (function (param) {
                  return Json_decode.array(fromJson$2, param);
                }), json),
          address: Json_decode.field("address", Json_decode.string, json),
          hash: Json_decode.field("hash", Json_decode.string, json),
          id: Json_decode.field("id", Json_decode.$$int, json),
          project: Json_decode.field("project", Json_decode.string, json),
          state: Json_decode.field("state", Json_decode.string, json),
          threshold: Json_decode.field("threshold", Json_decode.$$int, json),
          universe: Json_decode.field("universe", Json_decode.string, json)
        };
}

function integrationsToJson(integrations) {
  return Belt_Array.map(integrations, (function (integration) {
                return integration;
              }));
}

var Alert = {
  fromJson: fromJson$4,
  integrationsToJson: integrationsToJson
};

function fromJson$5(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json)
        };
}

var Connection = {
  fromJson: fromJson$5
};

function fromJson$6(json) {
  return {
          integrationId: Json_decode.optional((function (param) {
                  return Json_decode.field("integrationId", Json_decode.$$int, param);
                }), json),
          project: Json_decode.optional((function (param) {
                  return Json_decode.field("project", Json_decode.string, param);
                }), json),
          universe: Json_decode.optional((function (param) {
                  return Json_decode.field("universe", Json_decode.string, param);
                }), json),
          watcherName: Json_decode.optional((function (param) {
                  return Json_decode.field("watcherName", Json_decode.string, param);
                }), json),
          triggeringType: Json_decode.optional((function (param) {
                  return Json_decode.field("triggeringType", Json_decode.string, param);
                }), json),
          lastTx: Json_decode.optional((function (param) {
                  return Json_decode.field("lastTx", Json_decode.$$int, param);
                }), json),
          lastReportUpdate: Json_decode.optional((function (param) {
                  return Json_decode.field("lastReportUpdate", Json_decode.string, param);
                }), json)
        };
}

var Trigger = {
  fromJson: fromJson$6
};

function fromJson$7(json) {
  return {
          showAttributes: Json_decode.field("showAttributes", Json_decode.bool, json),
          attributeList: Json_decode.field("attributeList", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

var DisplaySettings = {
  fromJson: fromJson$7
};

function fromJson$8(json) {
  return {
          projectId: Json_decode.optional((function (param) {
                  return Json_decode.field("projectId", Json_decode.string, param);
                }), json),
          instanceId: Json_decode.optional((function (param) {
                  return Json_decode.field("instanceId", Json_decode.$$int, param);
                }), json),
          issueTypeId: Json_decode.optional((function (param) {
                  return Json_decode.field("issueTypeId", Json_decode.string, param);
                }), json),
          contentKey: Json_decode.optional((function (param) {
                  return Json_decode.field("contentKey", Json_decode.string, param);
                }), json),
          backtraceSync: Json_decode.optional((function (param) {
                  return Json_decode.field("backtraceSync", Json_decode.bool, param);
                }), json),
          displaySettings: Json_decode.optional((function (param) {
                  return Json_decode.field("displaySettings", fromJson$7, param);
                }), json),
          summaryTemplate: Json_decode.optional((function (param) {
                  return Json_decode.field("summaryTemplate", Json_decode.string, param);
                }), json)
        };
}

var Options = {
  fromJson: fromJson$8
};

function fromJson$9(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          connectionId: Json_decode.optional((function (param) {
                  return Json_decode.field("connectionId", Json_decode.$$int, param);
                }), json),
          universe: Json_decode.field("universe", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.string, json),
          watcherName: Json_decode.field("watcherName", Json_decode.string, json),
          pluginId: Json_decode.field("pluginId", Json_decode.string, json),
          address: Json_decode.optional((function (param) {
                  return Json_decode.field("address", Json_decode.string, param);
                }), json),
          viewAddress: Json_decode.optional((function (param) {
                  return Json_decode.field("viewAddress", Json_decode.string, param);
                }), json),
          options: Json_decode.optional((function (param) {
                  return Json_decode.field("options", fromJson$8, param);
                }), json),
          synchronizeIssues: Json_decode.optional((function (param) {
                  return Json_decode.field("synchronizeIssues", Json_decode.bool, param);
                }), json),
          state: Json_decode.field("state", Json_decode.string, json),
          trigger: Json_decode.optional((function (param) {
                  return Json_decode.field("trigger", fromJson$6, param);
                }), json),
          connection: Json_decode.optional((function (param) {
                  return Json_decode.field("connection", fromJson$5, param);
                }), json)
        };
}

var Integration = {
  Connection: Connection,
  Trigger: Trigger,
  DisplaySettings: DisplaySettings,
  Options: Options,
  fromJson: fromJson$9
};

function fromJson$10(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$9, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success = {
  fromJson: fromJson$10
};

function queryIntegrationsTask(projectName, config) {
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var universe = config.universe.name;
  var url = baseUrl[0] + ("/universes/" + (universe + ("/projects/" + (projectName + "/integrations/"))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$10(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiAlerts/QueryIntegrations.queryIntegrationsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryIntegrations = {
  Success: Success,
  queryIntegrationsTask: queryIntegrationsTask
};

function fromJson$11(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$4, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$1 = {
  fromJson: fromJson$11
};

function queryAlertsTask(config, projectName) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + ("/projects/" + (projectName + "/alerts"))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$11(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiAlert/QueryAlerts.queryAlertsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryAlerts = {
  Success: Success$1,
  queryAlertsTask: queryAlertsTask
};

function fromJson$12(json) {
  return {
          body: Json_decode.field("body", fromJson$4, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$2 = {
  fromJson: fromJson$12
};

function createAlertTask(alertName, condition, filters, frequency, executionDelay, integrations, config, projectName) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          alertName
        ],
        /* :: */[
          /* tuple */[
            "condition",
            toJson$1(condition)
          ],
          /* :: */[
            /* tuple */[
              "filters",
              toJson(filters)
            ],
            /* :: */[
              /* tuple */[
                "frequency",
                frequency
              ],
              /* :: */[
                /* tuple */[
                  "executionDelay",
                  executionDelay
                ],
                /* :: */[
                  /* tuple */[
                    "integrations",
                    Belt_Array.map(integrations, (function (integration) {
                            return integration;
                          }))
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]);
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + ("/projects/" + (projectName + "/alerts"))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$12(json);
                      Task2$BsConsole.invalidateCache(cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiAlert/CreateAlert.createAlertTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var CreateAlert = {
  Success: Success$2,
  createAlertTask: createAlertTask
};

function fromJson$13(json) {
  return {
          body: Json_decode.field("body", fromJson$4, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$3 = {
  fromJson: fromJson$13
};

function editAlertTask(alertId, alertName, condition, filters, frequency, executionDelay, integrations, config, projectName) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          alertName
        ],
        /* :: */[
          /* tuple */[
            "condition",
            toJson$1(condition)
          ],
          /* :: */[
            /* tuple */[
              "filters",
              toJson(filters)
            ],
            /* :: */[
              /* tuple */[
                "frequency",
                frequency
              ],
              /* :: */[
                /* tuple */[
                  "executionDelay",
                  executionDelay
                ],
                /* :: */[
                  /* tuple */[
                    "integrations",
                    Belt_Array.map(integrations, (function (integration) {
                            return integration;
                          }))
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]);
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + ("/projects/" + (projectName + ("/alerts/" + alertId)))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$13(json);
                      Task2$BsConsole.invalidateCache(cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiAlert/EditAlert.editAlertTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var EditAlert = {
  Success: Success$3,
  editAlertTask: editAlertTask
};

function fromJson$14(json) {
  return {
          body: Json_decode.field("body", fromJson$4, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$4 = {
  fromJson: fromJson$14
};

function deleteAlertTask(config, projectName, alertId) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + ("/projects/" + (projectName + ("/alerts/" + alertId)))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Delete */1, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$14(json);
                      Task2$BsConsole.invalidateCache(cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiAlert/CreateAlert.createAlertTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var DeleteAlert = {
  Success: Success$4,
  deleteAlertTask: deleteAlertTask
};

exports.ParseError = ParseError;
exports.ServiceError = ServiceError;
exports.responseCb = responseCb;
exports.bpgType = bpgType;
exports.cacheKey = cacheKey;
exports.$$Error = $$Error;
exports.Filters = Filters;
exports.Integrations = Integrations;
exports.Condition = Condition;
exports.Alert = Alert;
exports.Integration = Integration;
exports.QueryIntegrations = QueryIntegrations;
exports.QueryAlerts = QueryAlerts;
exports.CreateAlert = CreateAlert;
exports.EditAlert = EditAlert;
exports.DeleteAlert = DeleteAlert;
/* Task2-BsConsole Not a pure module */
