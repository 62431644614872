// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var FormatUtils$BsConsole = require("./FormatUtils.js");
var Add = require("@material-ui/icons/Add");
var BtTableColumn$BsConsole = require("./BtTableColumn.js");
var Popover = require("@material-ui/core/Popover");
var BtEnhancedTable$BsConsole = require("./BtEnhancedTable.js");
var I18N_Attributes$BsConsole = require("./i18n/I18N_Attributes.js");
var MenuItem = require("@material-ui/core/MenuItem");
var MoreVert = require("@material-ui/icons/MoreVert");

var iconButtonLabel = Css.style(/* :: */[
      Css.unsafe("fontSize", "inherit"),
      /* :: */[
        Css.unsafe("color", "inherit"),
        /* [] */0
      ]
    ]);

function iconButtonRoot(param) {
  return Css.style(/* :: */[
              Css.fontSize(Css.rem(1)),
              /* :: */[
                Css.important(Css.padding(Css.px(4))),
                /* [] */0
              ]
            ]);
}

function iconButtonClasses(param) {
  return {
          root: iconButtonRoot(undefined),
          label: iconButtonLabel
        };
}

var Styles = {
  iconButtonLabel: iconButtonLabel,
  iconButtonRoot: iconButtonRoot,
  iconButtonClasses: iconButtonClasses
};

function EnhancedCustomAttributesTable$ActionMenu(Props) {
  var onEdit = Props.onEdit;
  var onDelete = Props.onDelete;
  var item = Props.item;
  var editTextOpt = Props.editText;
  var deleteTextOpt = Props.deleteText;
  var editText = editTextOpt !== undefined ? editTextOpt : "Edit";
  var deleteText = deleteTextOpt !== undefined ? deleteTextOpt : "Delete";
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var onClose = function (param) {
    return Curry._1(setAnchorEl, (function (param) {
                  
                }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Mui$BsConsole.IconButton.make, {
                  disableRipple: true,
                  ariaLabel: "Custom attribute settings",
                  classes: iconButtonClasses(undefined),
                  onClick: (function ($$event) {
                      var el = $$event.currentTarget;
                      if (!(el == null)) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      return Caml_option.some(el);
                                    }));
                      }
                      
                    }),
                  children: React.createElement(MoreVert.default, { })
                }), anchorEl !== undefined ? React.createElement(Popover.default, {
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    },
                    anchorEl: Caml_option.valFromOption(anchorEl),
                    open: true,
                    onClose: onClose,
                    children: null
                  }, React.createElement(MenuItem.default, {
                        disableRipple: true,
                        onClick: (function (param) {
                            Curry._1(onEdit, item);
                            return Curry._1(setAnchorEl, (function (param) {
                                          
                                        }));
                          }),
                        children: I18N$BsConsole.show(undefined, editText)
                      }), React.createElement(MenuItem.default, {
                        disableRipple: true,
                        onClick: (function (param) {
                            Curry._1(onDelete, item);
                            return Curry._1(setAnchorEl, (function (param) {
                                          
                                        }));
                          }),
                        children: I18N$BsConsole.show(undefined, deleteText)
                      })) : null);
}

var ActionMenu = {
  Styles: Styles,
  make: EnhancedCustomAttributesTable$ActionMenu
};

function EnhancedCustomAttributesTable$ActiveAttributesTable(Props) {
  var attributes = Props.attributes;
  var additionalColumnsOpt = Props.additionalColumns;
  var update = Props.update;
  var fixedRightColumn = Props.fixedRightColumn;
  var shouldTableUpdate = Props.shouldTableUpdate;
  var onTableUpdate = Props.onTableUpdate;
  var additionalColumns = additionalColumnsOpt !== undefined ? additionalColumnsOpt : [];
  React.useEffect((function () {
          if (shouldTableUpdate) {
            Curry._1(update, undefined);
            Curry._1(onTableUpdate, undefined);
          }
          
        }), [shouldTableUpdate]);
  var getColumns = function (param) {
    return [
            BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Name"), "name", (function (row) {
                    return /* `String */[
                            -976970511,
                            row.name
                          ];
                  }), undefined, undefined, undefined, undefined, undefined, 250, undefined, undefined, undefined, undefined),
            BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Description"), "description", (function (row) {
                    return /* `String */[
                            -976970511,
                            row.description
                          ];
                  }), undefined, undefined, undefined, undefined, undefined, 350, undefined, undefined, undefined, undefined),
            BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Format"), "format", (function (row) {
                    return /* `Element */[
                            -744106340,
                            I18N_Attributes$BsConsole.Formats.showFromJs(row.format)
                          ];
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Type"), "type", (function (row) {
                    return /* `Element */[
                            -744106340,
                            I18N_Attributes$BsConsole.Types.show(row.type_)
                          ];
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ];
  };
  var columns = React.useMemo((function () {
          return Belt_Array.concat(getColumns(undefined), additionalColumns);
        }), ([]));
  var sortingState = {
    columnName: "name",
    direction: /* Desc */759138929
  };
  var sortedRows = React.useMemo((function () {
          return BtEnhancedTable$BsConsole.defaultRowSort(Belt_List.toArray(attributes), columns, sortingState);
        }), [attributes]);
  var tmp = {
    columns: columns,
    rows: sortedRows,
    getRowId: (function (row) {
        return row.name;
      }),
    showGrid: false,
    sortingState: sortingState,
    resizeableColumns: true,
    fixedLeftColumns: ["name"]
  };
  if (fixedRightColumn !== undefined) {
    tmp.fixedRightColumn = Caml_option.valFromOption(fixedRightColumn);
  }
  return React.createElement(BtEnhancedTable$BsConsole.make, tmp);
}

function make(attributes, update, fixedRightColumn, shouldTableUpdate, onTableUpdate, children) {
  var tmp = {
    attributes: attributes,
    update: update,
    shouldTableUpdate: shouldTableUpdate,
    onTableUpdate: onTableUpdate
  };
  if (fixedRightColumn !== undefined) {
    tmp.fixedRightColumn = Caml_option.valFromOption(fixedRightColumn);
  }
  return ReasonReactCompat.wrapReactForReasonReact(EnhancedCustomAttributesTable$ActiveAttributesTable, tmp, children);
}

var Jsx2 = {
  make: make
};

var ActiveAttributesTable = {
  make: EnhancedCustomAttributesTable$ActiveAttributesTable,
  Jsx2: Jsx2
};

function EnhancedCustomAttributesTable$EnhancedCustomAttributesTable(Props) {
  var attributes = Props.attributes;
  var update = Props.update;
  var shouldTableUpdate = Props.shouldTableUpdate;
  var onTableUpdate = Props.onTableUpdate;
  var onDelete = Props.onDelete;
  var onEdit = Props.onEdit;
  React.useEffect((function () {
          if (shouldTableUpdate) {
            Curry._1(update, undefined);
            Curry._1(onTableUpdate, undefined);
          }
          
        }), [shouldTableUpdate]);
  var additionalColumns = [BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Used"), "used", (function (row) {
            return /* `Bool */[
                    737456202,
                    row.statistics.used
                  ];
          }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)];
  var fixedRightColumn = BtTableColumn$BsConsole.make("", "action", (function (row) {
          return /* `Element */[
                  -744106340,
                  React.createElement(EnhancedCustomAttributesTable$ActionMenu, {
                        onEdit: onEdit,
                        onDelete: onDelete,
                        item: row
                      })
                ];
        }), true, undefined, undefined, undefined, undefined, 50, undefined, undefined, /* Right */-57574468, undefined);
  return React.createElement(EnhancedCustomAttributesTable$ActiveAttributesTable, {
              attributes: attributes,
              additionalColumns: additionalColumns,
              update: update,
              fixedRightColumn: fixedRightColumn,
              shouldTableUpdate: shouldTableUpdate,
              onTableUpdate: onTableUpdate
            });
}

function make$1(attributes, update, shouldTableUpdate, onTableUpdate, onDelete, onEdit, children) {
  return ReasonReactCompat.wrapReactForReasonReact(EnhancedCustomAttributesTable$EnhancedCustomAttributesTable, {
              attributes: attributes,
              update: update,
              shouldTableUpdate: shouldTableUpdate,
              onTableUpdate: onTableUpdate,
              onDelete: onDelete,
              onEdit: onEdit
            }, children);
}

var Jsx2$1 = {
  make: make$1
};

var EnhancedCustomAttributesTable = {
  make: EnhancedCustomAttributesTable$EnhancedCustomAttributesTable,
  Jsx2: Jsx2$1
};

function EnhancedCustomAttributesTable$RecommendedAttributesTable(Props) {
  var attributes = Props.attributes;
  var onAddAttribute = Props.onAddAttribute;
  var update = Props.update;
  var shouldTableUpdate = Props.shouldTableUpdate;
  var onTableUpdate = Props.onTableUpdate;
  React.useEffect((function () {
          if (shouldTableUpdate) {
            Curry._1(update, undefined);
            Curry._1(onTableUpdate, undefined);
          }
          
        }), [shouldTableUpdate]);
  var fixedRightColumn = BtTableColumn$BsConsole.make("", "action", (function (row) {
          return /* `Element */[
                  -744106340,
                  React.createElement(Mui$BsConsole.IconButton.make, {
                        ariaLabel: "Add attribute",
                        color: "primary",
                        className: Css.style(/* :: */[
                              Css.padding(Css.px(4)),
                              /* [] */0
                            ]),
                        onClick: (function (_e) {
                            return Curry._1(onAddAttribute, row.name);
                          }),
                        children: React.createElement(Add.default, { })
                      })
                ];
        }), undefined, undefined, undefined, undefined, 50, undefined, undefined, undefined, /* Right */-57574468, undefined);
  var columns = React.useMemo((function () {
          return [
                  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Name"), "name", (function (row) {
                          return /* `String */[
                                  -976970511,
                                  row.name
                                ];
                        }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined),
                  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Value"), "value", (function (row) {
                          return /* `String */[
                                  -976970511,
                                  row.value
                                ];
                        }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined),
                  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Seen"), "seen", (function (row) {
                          return /* `String */[
                                  -976970511,
                                  FormatUtils$BsConsole.formatDateToAgoString(new Date(row.seen * 1000))
                                ];
                        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
                ];
        }), ([]));
  var sortingState = {
    columnName: "name",
    direction: /* Desc */759138929
  };
  var sortedRows = React.useMemo((function () {
          return BtEnhancedTable$BsConsole.defaultRowSort(Belt_List.toArray(attributes), columns, sortingState);
        }), [attributes]);
  return React.createElement(BtEnhancedTable$BsConsole.make, {
              columns: columns,
              rows: sortedRows,
              getRowId: (function (row) {
                  return row.name;
                }),
              showGrid: false,
              sortingState: sortingState,
              resizeableColumns: true,
              fixedLeftColumns: ["name"],
              fixedRightColumn: fixedRightColumn
            });
}

function make$2(attributes, update, onAddAttribute, shouldTableUpdate, onTableUpdate, children) {
  return ReasonReactCompat.wrapReactForReasonReact(EnhancedCustomAttributesTable$RecommendedAttributesTable, {
              attributes: attributes,
              onAddAttribute: onAddAttribute,
              update: update,
              shouldTableUpdate: shouldTableUpdate,
              onTableUpdate: onTableUpdate
            }, children);
}

var Jsx2$2 = {
  make: make$2
};

var RecommendedAttributesTable = {
  make: EnhancedCustomAttributesTable$RecommendedAttributesTable,
  Jsx2: Jsx2$2
};

exports.ActionMenu = ActionMenu;
exports.ActiveAttributesTable = ActiveAttributesTable;
exports.EnhancedCustomAttributesTable = EnhancedCustomAttributesTable;
exports.RecommendedAttributesTable = RecommendedAttributesTable;
/* iconButtonLabel Not a pure module */
