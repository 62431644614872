// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var scriptStr = "(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src=\"https://www.clarity.ms/tag/\"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, \"clarity\", \"script\", \"cjgvpc3wh0\");";

function appendScriptToHead(param) {
  var scriptTag = document.createElement("script");
  scriptTag.setAttribute("type", "text/javascript");
  scriptTag.textContent = scriptStr;
  var __x = document;
  var headEl = __x.querySelector("head");
  if (!(headEl == null)) {
    headEl.appendChild(scriptTag);
    return ;
  }
  
}

function initialize(param) {
  if (process.env.NODE_ENV === "production") {
    return appendScriptToHead(undefined);
  }
  
}

exports.scriptStr = scriptStr;
exports.appendScriptToHead = appendScriptToHead;
exports.initialize = initialize;
/* No side effect */
