// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var InboxUtils$BsConsole = require("./inbox/InboxUtils.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var InboxDescribe$BsConsole = require("./inbox/InboxDescribe.js");
var Popper = require("@material-ui/core/Popper");
var BtAutocomplete$BsConsole = require("./BtAutocomplete.js");
var Tooltip = require("@material-ui/core/Tooltip");
var AttributeTypeahead$BsConsole = require("./typeahead/AttributeTypeahead.js");
var ClickAwayListener = require("@material-ui/core/ClickAwayListener");

var paper = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.padding(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var paperLayout = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var popoverTitle = Css.style(/* :: */[
      Css.marginBottom(Css.rem(1)),
      /* [] */0
    ]);

var listClassName = Css.style(/* :: */[
      Css.selector("li.BtAutocomplete-option", /* :: */[
            Css.padding2(Css.px(4), Css.px(8)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var selectedAttribute = Css.style(/* :: */[
      Css.cursor(/* pointer */-786317123),
      /* :: */[
        Css.padding(Css.px(4)),
        /* :: */[
          Css.backgroundColor(Css.hex(Colors$BsConsole.blue)),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.white)),
            /* :: */[
              Css.borderRadius(Css.px(4)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  paper: paper,
  paperLayout: paperLayout,
  popoverTitle: popoverTitle,
  listClassName: listClassName,
  selectedAttribute: selectedAttribute
};

function getOperationsFromAttribute(query, attr) {
  var f = Curry._1(Crdb$BsConsole.Query.getFold, query);
  var existingAggregations;
  if (f !== undefined) {
    var __x = Curry._1(Crdb$BsConsole.Fold.toArray, Caml_option.valFromOption(f));
    existingAggregations = Belt_Array.map(__x, (function (agg) {
            return /* tuple */[
                    Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg),
                    Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg))
                  ];
          }));
  } else {
    existingAggregations = [];
  }
  return Belt_List.toArray(Belt_List.keep(Belt_List.keep(Belt_List.map(InboxDescribe$BsConsole.aggregate(attr), (function (a) {
                            if (a === "histogram") {
                              return "distribution";
                            } else {
                              return a;
                            }
                          })), (function (a) {
                        return !(attr.name === "timestamp" && a === "bin");
                      })), (function (operation) {
                    var match = Belt_Array.getBy(existingAggregations, (function (param) {
                            return param[0] === attr.name;
                          }));
                    if (match !== undefined) {
                      return operation !== match[1];
                    } else {
                      return true;
                    }
                  })));
}

function containsNode(root, target) {
  try {
    return root.contains(target);
  }
  catch (exn){
    return false;
  }
}

function AddFold(Props) {
  var query = Props.query;
  var onChange = Props.onChange;
  var attributes = Props.attributes;
  var popperTitleOpt = Props.popperTitle;
  var tooltipTitleOpt = Props.tooltipTitle;
  var children = Props.children;
  var popperTitle = popperTitleOpt !== undefined ? popperTitleOpt : I18N$BsConsole.get(undefined, "Add aggregation");
  var tooltipTitle = tooltipTitleOpt !== undefined ? tooltipTitleOpt : I18N$BsConsole.get(undefined, "Add aggregation");
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setPopperKey = match$1[1];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setOpen_ = match$2[1];
  var match$3 = React.useState((function () {
          
        }));
  var setAttribute = match$3[1];
  var attribute = match$3[0];
  var match$4 = React.useState((function () {
          
        }));
  var setInitialValue = match$4[1];
  React.useEffect((function () {
          Curry._1(setPopperKey, (function (k) {
                  return k + 1 | 0;
                }));
          
        }), [attribute]);
  var handleKeyDown = React.useCallback((function ($$event) {
          var keyName = $$event.key;
          if (keyName === "Escape") {
            return Curry._1(setOpen_, (function (param) {
                          return false;
                        }));
          }
          
        }), [setOpen_]);
  var tmp;
  if (attribute !== undefined) {
    var considerations = getOperationsFromAttribute(query, attribute);
    tmp = React.createElement(Row2$BsConsole.make, {
          alignItems: /* center */98248149,
          children: null
        }, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              children: null
            }, React.createElement("span", {
                  className: selectedAttribute,
                  role: "button",
                  onClick: (function (_event) {
                      return Curry._1(setAttribute, (function (param) {
                                    
                                  }));
                    })
                }, I18N$BsConsole.showSkip(attribute.name)), React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.margin2(Css.zero, Css.em(0.5)),
                        /* [] */0
                      ])
                }, I18N$BsConsole.showSkip("-"))), React.createElement(BtAutocomplete$BsConsole.make, {
              toString: (function (op) {
                  return op;
                }),
              considerations: considerations,
              onDelete: (function (param) {
                  return Curry._1(setAttribute, (function (param) {
                                
                              }));
                }),
              onChange: (function (_raw, op) {
                  var op$prime = Belt_Option.flatMap(op, Crdb$BsConsole.Operation.fromString);
                  if (op$prime !== undefined) {
                    return Curry._1(onChange, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                    attribute.name,
                                    op$prime
                                  ]));
                  }
                  
                }),
              placeholder: I18N$BsConsole.get(undefined, "Aggregation operation"),
              autoFocus: true,
              inputAriaLabel: I18N$BsConsole.get(undefined, "Aggregation operation"),
              renderInput: (function (param) {
                  var onFocus = param.onFocus;
                  var onChange = param.onChange;
                  var onFocus$prime = Curry.__1(onFocus);
                  var onChange$prime = Curry.__1(onChange);
                  return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                              onFocus: onFocus$prime,
                              className: Css.style(/* :: */[
                                    Css.important(Css.marginBottom(/* zero */-789508312)),
                                    /* [] */0
                                  ]),
                              placeholder: param.placeholder,
                              value: param.value,
                              onChange: onChange$prime,
                              inputRef: (function (input) {
                                  if (input == null) {
                                    return ;
                                  }
                                  var domElement = ReactDom.findDOMNode(input);
                                  setTimeout((function (param) {
                                          return domElement.focus();
                                        }), 100);
                                  
                                })
                            });
                }),
              listClassName: listClassName,
              rootClassName: "AddFold-autocomplete"
            }));
  } else {
    var tmp$1 = {
      toString: (function (attribute$prime) {
          return attribute$prime.name;
        }),
      considerations: Belt_List.toArray(attributes),
      onDelete: (function (param) {
          return Curry._1(setOpen_, (function (param) {
                        return false;
                      }));
        }),
      onChange: (function (_raw, attribute$prime) {
          Curry._1(setInitialValue, (function (param) {
                  return Belt_Option.flatMap(attribute$prime, (function (a) {
                                return a.name;
                              }));
                }));
          return Curry._1(setAttribute, (function (param) {
                        return attribute$prime;
                      }));
        }),
      placeholder: I18N$BsConsole.get(undefined, "Attribute"),
      autoFocus: true,
      inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
      renderListOption: (function (attr, idx) {
          return React.createElement(AttributeTypeahead$BsConsole.AttributeItem.make, {
                      name: attr.name,
                      description: attr.description,
                      custom: attr.custom,
                      triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                      used: attr.statistics.used,
                      key: attr.name + ("__" + String(idx))
                    });
        }),
      renderInput: (function (param) {
          var onFocus = param.onFocus;
          var onChange = param.onChange;
          var onFocus$prime = Curry.__1(onFocus);
          var onChange$prime = Curry.__1(onChange);
          return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                      onFocus: onFocus$prime,
                      className: Css.style(/* :: */[
                            Css.important(Css.marginBottom(/* zero */-789508312)),
                            /* [] */0
                          ]),
                      placeholder: param.placeholder,
                      value: param.value,
                      onChange: onChange$prime,
                      inputRef: (function (input) {
                          if (input == null) {
                            return ;
                          }
                          var domElement = ReactDom.findDOMNode(input);
                          setTimeout((function (param) {
                                  return domElement.focus();
                                }), 100);
                          
                        })
                    });
        }),
      listClassName: listClassName,
      rootClassName: "AddFold-autocomplete"
    };
    var tmp$2 = match$4[0];
    if (tmp$2 !== undefined) {
      tmp$1.defaultValue = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(BtAutocomplete$BsConsole.make, tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.default, {
                  placement: "top",
                  children: React.createElement("div", {
                        ref: (function (el) {
                            return Curry._1(setAnchorEl, (function (param) {
                                          if (el == null) {
                                            return ;
                                          } else {
                                            return Caml_option.some(el);
                                          }
                                        }));
                          }),
                        className: Css.style(/* :: */[
                              Css.display(/* inlineBlock */-147785676),
                              /* [] */0
                            ]),
                        onKeyDown: handleKeyDown,
                        onClick: (function (param) {
                            return Curry._1(setOpen_, (function (param) {
                                          return true;
                                        }));
                          })
                      }, children),
                  title: tooltipTitle
                }), React.createElement(Popper.default, {
                  anchorEl: match[0],
                  open: match$2[0],
                  placement: "bottom-start",
                  children: React.createElement(ClickAwayListener.default, {
                        onClickAway: (function ($$event) {
                            var __x = document;
                            var autocompleteRoot = __x.querySelector(".AddFold-autocomplete");
                            var target = $$event.target;
                            var containsTarget = !(autocompleteRoot == null) && !(target == null) ? containsNode(autocompleteRoot, target) : false;
                            if (!containsTarget) {
                              return Curry._1(setOpen_, (function (param) {
                                            return false;
                                          }));
                            }
                            
                          }),
                        children: React.createElement(Paper.default, {
                              classes: {
                                root: paper
                              },
                              children: React.createElement("div", {
                                    className: paperLayout,
                                    onKeyDown: handleKeyDown
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Heading6 */5,
                                        className: popoverTitle,
                                        children: I18N$BsConsole.showSkip(popperTitle)
                                      }), tmp)
                            })
                      }),
                  key: String(match$1[0])
                }));
}

var make = AddFold;

exports.Styles = Styles;
exports.getOperationsFromAttribute = getOperationsFromAttribute;
exports.containsNode = containsNode;
exports.make = make;
/* paper Not a pure module */
