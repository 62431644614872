// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");

function current(config) {
  var services = config.services;
  var shouldBlockOldWF = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.62.0");
  var wfVersion;
  if (services !== undefined) {
    var wfService = Belt_List.getBy(services, (function (service) {
            return service.name === "workflows";
          }));
    if (wfService !== undefined) {
      var endpoint = wfService.endpoint;
      wfVersion = endpoint !== undefined ? /* NewWF */[endpoint] : /* WFServiceUnavailable */0;
    } else {
      wfVersion = shouldBlockOldWF ? /* WFServiceUnavailable */0 : /* OldWF */1;
    }
  } else {
    wfVersion = shouldBlockOldWF ? /* WFServiceUnavailable */0 : /* OldWF */1;
  }
  if (wfVersion === /* WFServiceUnavailable */0) {
    var universe = config.universe.name;
    var coronderdVersion = window._BACKTRACE_CORONER_VERSION;
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "WorkflowVersion/current/wfVersion", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `string */[
          288368849,
          "\"Workflow Service is unavailable for " + (String(universe) + (" running Coronerd version " + (String((coronderdVersion == null) ? undefined : Caml_option.some(coronderdVersion)) + ".\"")))
        ]);
  }
  return wfVersion;
}

exports.current = current;
/* Backtrace-BsConsole Not a pure module */
