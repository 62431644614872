// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ApiWf$BsConsole = require("./ApiWf.js");
var Task2$BsConsole = require("../../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../../Uncaught.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var WorkflowVersion$BsConsole = require("../WorkflowVersion.js");

function fromJson(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          clientKey: Json_decode.optional((function (param) {
                  return Json_decode.field("clientKey", Json_decode.string, param);
                }), json),
          username: Json_decode.optional((function (param) {
                  return Json_decode.field("username", Json_decode.string, param);
                }), json),
          consumerKey: Json_decode.optional((function (param) {
                  return Json_decode.field("consumerKey", Json_decode.string, param);
                }), json),
          publicKey: Json_decode.optional((function (param) {
                  return Json_decode.field("publicKey", Json_decode.string, param);
                }), json),
          generateKeys: Json_decode.optional((function (param) {
                  return Json_decode.field("generateKeys", Json_decode.bool, param);
                }), json),
          authorized: Json_decode.optional((function (param) {
                  return Json_decode.field("authorized", Json_decode.bool, param);
                }), json),
          url: Json_decode.optional((function (param) {
                  return Json_decode.field("url", Json_decode.string, param);
                }), json)
        };
}

var AuthOptions = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return {
          authorizationOptions: Json_decode.field("authorizationOptions", fromJson, json),
          baseUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("baseUrl", Json_decode.string, param);
                }), json),
          dontVerifySsl: Json_decode.optional((function (param) {
                  return Json_decode.field("dontVerifySsl", Json_decode.bool, param);
                }), json),
          retryOnHttpNotFound: Json_decode.optional((function (param) {
                  return Json_decode.field("retryOnHttpNotFound", Json_decode.bool, param);
                }), json)
        };
}

var Options = {
  fromJson: fromJson$1
};

function fromJson$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          creatorUserId: Json_decode.field("creatorUserId", Json_decode.$$int, json),
          instanceUrl: Json_decode.field("instanceUrl", Json_decode.string, json),
          lastModifierUserId: Json_decode.field("lastModifierUserId", Json_decode.$$int, json),
          location: Json_decode.field("location", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          options: Json_decode.field("options", fromJson$1, json),
          pluginId: Json_decode.field("pluginId", Json_decode.string, json),
          universe: Json_decode.field("universe", Json_decode.string, json),
          creationDate: Json_decode.optional((function (param) {
                  return Json_decode.field("creationDate", Json_decode.string, param);
                }), json),
          updateDate: Json_decode.optional((function (param) {
                  return Json_decode.field("updateDate", Json_decode.string, param);
                }), json)
        };
}

var Connection = {
  AuthOptions: AuthOptions,
  Options: Options,
  fromJson: fromJson$2
};

function fromJson$3(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$2, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success = {
  fromJson: fromJson$3
};

function queryConnectionsTask(config, projectName, pluginId) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + "/connections?limit=1000"))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$3(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_obj){
                      var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
                      if (obj[0] === Js_exn.$$Error) {
                        var obj$1 = obj[1];
                        Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/QueryConnections.queryConnectionsTask", obj$1);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ParseError,
                                    obj$1
                                  ]]);
                      }
                      if (obj[0] === Json_decode.DecodeError) {
                        var error = new Error(obj[1]);
                        Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/QueryConnections.queryConnectionsTask", error);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ParseError,
                                    error
                                  ]]);
                      }
                      var error$1 = Uncaught$BsConsole.castToJsExn(obj);
                      Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/QueryConnections.queryConnectionsTask", error$1);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error$1
                                ]]);
                    }
                  }), undefined, undefined));
}

function queryConnectionsByUniverseTask(config) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + "/connections?limit=1000"));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$3(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_obj){
                      var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
                      if (obj[0] === Js_exn.$$Error) {
                        var obj$1 = obj[1];
                        Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/QueryConnectionsByUniverse.queryConnectionsTask", obj$1);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ParseError,
                                    obj$1
                                  ]]);
                      }
                      if (obj[0] === Json_decode.DecodeError) {
                        var error = new Error(obj[1]);
                        Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/QueryConnectionsByUniverse.queryConnectionsTask", error);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ParseError,
                                    error
                                  ]]);
                      }
                      var error$1 = Uncaught$BsConsole.castToJsExn(obj);
                      Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/QueryConnectionsByUniverse.queryConnectionsTask", error$1);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error$1
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryConnections = {
  Success: Success,
  queryConnectionsTask: queryConnectionsTask,
  queryConnectionsByUniverseTask: queryConnectionsByUniverseTask
};

function fromJson$4(json) {
  return {
          body: Json_decode.field("body", fromJson$2, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$1 = {
  fromJson: fromJson$4
};

function queryConnectionTask(config, projectName, pluginId, connectionId) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + String(connectionId))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$4(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_obj){
                      var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
                      if (obj[0] === Js_exn.$$Error) {
                        var obj$1 = obj[1];
                        Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/QueryConnection.queryConnectionTask", obj$1);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ParseError,
                                    obj$1
                                  ]]);
                      }
                      if (obj[0] === Json_decode.DecodeError) {
                        var error = new Error(obj[1]);
                        Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/QueryConnection.queryConnectionTask", error);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ParseError,
                                    error
                                  ]]);
                      }
                      var error$1 = Uncaught$BsConsole.castToJsExn(obj);
                      Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/QueryConnection.queryConnectionTask", error$1);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error$1
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryConnection = {
  Success: Success$1,
  queryConnectionTask: queryConnectionTask
};

function fromJson$5(json) {
  return {
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$2 = {
  fromJson: fromJson$5
};

function deleteConnectionTask(config, connectionId) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + ("/connections/" + String(connectionId))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Delete */1, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$5(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      Backtrace$BsConsole.Client.reportWithSourceModule("ApiWfConnections/DeleteConnection.deleteConnectionTask", error);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var DeleteConnection = {
  Success: Success$2,
  deleteConnectionTask: deleteConnectionTask
};

function fromJson$6(json) {
  return {
          address: Json_decode.field("address", Json_decode.string, json),
          location: Json_decode.field("location", Json_decode.string, json),
          universe: Json_decode.field("universe", Json_decode.string, json)
        };
}

var Instance = {
  fromJson: fromJson$6
};

function fromJson$7(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          clientKey: Json_decode.optional((function (param) {
                  return Json_decode.field("clientKey", Json_decode.string, param);
                }), json)
        };
}

var AutorizationOptions = {
  fromJson: fromJson$7
};

function fromJson$8(json) {
  return {
          authorizationOptions: Json_decode.field("authorizationOptions", fromJson$7, json),
          baseUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("baseUrl", Json_decode.string, param);
                }), json),
          dontVerifySsl: Json_decode.optional((function (param) {
                  return Json_decode.field("dontVerifySsl", Json_decode.bool, param);
                }), json),
          retryOnHttpNotFound: Json_decode.optional((function (param) {
                  return Json_decode.field("retryOnHttpNotFound", Json_decode.bool, param);
                }), json)
        };
}

var Options$1 = {
  fromJson: fromJson$8
};

function fromJson$9(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          instance: Json_decode.field("instance", fromJson$6, json),
          name: Json_decode.field("name", Json_decode.string, json),
          options: Json_decode.field("options", fromJson$8, json)
        };
}

var Data = {
  fromJson: fromJson$9
};

function fromJson$10(json) {
  return {
          action: Json_decode.field("action", Json_decode.string, json),
          data: Json_decode.field("data", fromJson$9, json),
          pluginId: Json_decode.field("pluginId", Json_decode.string, json)
        };
}

var Body = {
  fromJson: fromJson$10
};

function fromJson$11(json) {
  return {
          body: Json_decode.field("body", fromJson$10, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$3 = {
  Instance: Instance,
  AutorizationOptions: AutorizationOptions,
  Options: Options$1,
  Data: Data,
  Body: Body,
  fromJson: fromJson$11
};

function nonceCallbackTask(nonce, config, projectName) {
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/callback?nonce=" + nonce);
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* [] */0)]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$11(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/NonceCallback.nonceCallbackTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var NonceCallback = {
  Success: Success$3,
  nonceCallbackTask: nonceCallbackTask
};

function fromJson$12(json) {
  return {
          body: Json_decode.field("body", fromJson$2, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$4 = {
  fromJson: fromJson$12
};

function editConnectionTask(config, pluginId, connectionId, connectionName) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          connectionName
        ],
        /* [] */0
      ]);
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + String(connectionId))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [jsonObj]), undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$12(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/EditConnection/editConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var EditConnection = {
  Success: Success$4,
  editConnectionTask: editConnectionTask
};

function fromJson$13(json) {
  return {
          body: Json_decode.field("body", fromJson$2, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$5 = {
  fromJson: fromJson$13
};

function typeToString(param) {
  switch (param) {
    case /* Basic */0 :
        return "Basic";
    case /* OAuth1 */1 :
        return "OAuth 1.0";
    case /* OAuth2 */2 :
        return "OAuth 2.0";
    
  }
}

function typeToApiString(param) {
  switch (param) {
    case /* Basic */0 :
        return "basic";
    case /* OAuth1 */1 :
        return "oauth1";
    case /* OAuth2 */2 :
        return "oauth2";
    
  }
}

function apiStringToType(apiString) {
  switch (apiString) {
    case "basic" :
        return /* Basic */0;
    case "oauth1" :
        return /* OAuth1 */1;
    case "oauth2" :
        return /* OAuth2 */2;
    default:
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/AuthType/apiStringToType", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `string */[
            288368849,
            "Api returned" + (apiString + " which is an authType that is not currently supported.")
          ]);
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "ApiWfConnections.re",
              554,
              8
            ]
          ];
  }
}

var AuthType = {
  typeToString: typeToString,
  typeToApiString: typeToApiString,
  apiStringToType: apiStringToType
};

function createConnectionTask(config, pluginId, connectionName, jiraServerUrl, authType, privateKey, username, password, generateKeys, clientId, clientSecret, projectName, consumerKey) {
  var jsonObj;
  switch (authType) {
    case /* Basic */0 :
        if (username !== undefined) {
          if (password !== undefined) {
            jsonObj = Json_encode.object_(/* :: */[
                  /* tuple */[
                    "name",
                    connectionName
                  ],
                  /* :: */[
                    /* tuple */[
                      "options",
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "baseUrl",
                              jiraServerUrl
                            ],
                            /* :: */[
                              /* tuple */[
                                "authorizationOptions",
                                Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        typeToApiString(authType)
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "username",
                                          username
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "password",
                                            password
                                          ],
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              ],
                              /* [] */0
                            ]
                          ])
                    ],
                    /* [] */0
                  ]
                ]);
          } else {
            var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/createConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x, /* `string */[
                  288368849,
                  "AuthType Basic missing password."
                ]);
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "ApiWfConnections.re",
                    732,
                    10
                  ]
                ];
          }
        } else {
          if (password !== undefined) {
            var __x$1 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/createConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x$1, /* `string */[
                  288368849,
                  "AuthType Basic missing username."
                ]);
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "ApiWfConnections.re",
                    722,
                    10
                  ]
                ];
          }
          var __x$2 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/createConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
          Backtrace$BsConsole.Client.send(__x$2, /* `string */[
                288368849,
                "AuthType Basic missing username and password."
              ]);
          throw [
                Caml_builtin_exceptions.assert_failure,
                /* tuple */[
                  "ApiWfConnections.re",
                  742,
                  10
                ]
              ];
        }
        break;
    case /* OAuth1 */1 :
        if (generateKeys) {
          jsonObj = Json_encode.object_(/* :: */[
                /* tuple */[
                  "name",
                  connectionName
                ],
                /* :: */[
                  /* tuple */[
                    "options",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "baseUrl",
                            jiraServerUrl
                          ],
                          /* :: */[
                            /* tuple */[
                              "authorizationOptions",
                              Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "type",
                                      typeToApiString(authType)
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "consumerKey",
                                        consumerKey
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "generateKeys",
                                          generateKeys
                                        ],
                                        /* [] */0
                                      ]
                                    ]
                                  ])
                            ],
                            /* [] */0
                          ]
                        ])
                  ],
                  /* [] */0
                ]
              ]);
        } else if (privateKey !== undefined) {
          jsonObj = Json_encode.object_(/* :: */[
                /* tuple */[
                  "name",
                  connectionName
                ],
                /* :: */[
                  /* tuple */[
                    "options",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "baseUrl",
                            jiraServerUrl
                          ],
                          /* :: */[
                            /* tuple */[
                              "authorizationOptions",
                              Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "type",
                                      typeToApiString(authType)
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "consumerKey",
                                        "backtrace-workflows"
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "privateKey",
                                          privateKey
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "generateKeys",
                                            generateKeys
                                          ],
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            ],
                            /* [] */0
                          ]
                        ])
                  ],
                  /* [] */0
                ]
              ]);
        } else {
          var __x$3 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/createConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
          Backtrace$BsConsole.Client.send(__x$3, /* `string */[
                288368849,
                "AuthType OAuth 1.0 missing private key."
              ]);
          throw [
                Caml_builtin_exceptions.assert_failure,
                /* tuple */[
                  "ApiWfConnections.re",
                  629,
                  10
                ]
              ];
        }
        break;
    case /* OAuth2 */2 :
        if (clientId !== undefined) {
          if (clientSecret !== undefined) {
            jsonObj = Json_encode.object_(/* :: */[
                  /* tuple */[
                    "name",
                    connectionName
                  ],
                  /* :: */[
                    /* tuple */[
                      "options",
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "baseUrl",
                              jiraServerUrl
                            ],
                            /* :: */[
                              /* tuple */[
                                "authorizationOptions",
                                Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        typeToApiString(authType)
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "clientId",
                                          clientId
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "clientSecret",
                                            clientSecret
                                          ],
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              ],
                              /* [] */0
                            ]
                          ])
                    ],
                    /* [] */0
                  ]
                ]);
          } else {
            var __x$4 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/createConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x$4, /* `string */[
                  288368849,
                  "AuthType OAuth 2.0 missing client secret."
                ]);
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "ApiWfConnections.re",
                    674,
                    10
                  ]
                ];
          }
        } else {
          if (clientSecret !== undefined) {
            var __x$5 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/createConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x$5, /* `string */[
                  288368849,
                  "AuthType OAuth 2.0 missing client id."
                ]);
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "ApiWfConnections.re",
                    664,
                    10
                  ]
                ];
          }
          var __x$6 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/createConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
          Backtrace$BsConsole.Client.send(__x$6, /* `string */[
                288368849,
                "AuthType OAuth 2.0 missing both client id and client secret."
              ]);
          throw [
                Caml_builtin_exceptions.assert_failure,
                /* tuple */[
                  "ApiWfConnections.re",
                  688,
                  10
                ]
              ];
        }
        break;
    
  }
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + "/connections"))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$13(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/createConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

function editConnectionTask$1(config, pluginId, connectionId, connectionName, jiraServerUrl, authType, privateKey, username, password, generateKeys, clientId, clientSecret, projectName, consumerKey) {
  var jsonObj;
  switch (authType) {
    case /* Basic */0 :
        if (username !== undefined) {
          if (password !== undefined) {
            jsonObj = Json_encode.object_(/* :: */[
                  /* tuple */[
                    "name",
                    connectionName
                  ],
                  /* :: */[
                    /* tuple */[
                      "options",
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "baseUrl",
                              jiraServerUrl
                            ],
                            /* :: */[
                              /* tuple */[
                                "authorizationOptions",
                                Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        typeToApiString(authType)
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "username",
                                          username
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "password",
                                            password
                                          ],
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              ],
                              /* [] */0
                            ]
                          ])
                    ],
                    /* [] */0
                  ]
                ]);
          } else {
            var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/editConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x, /* `string */[
                  288368849,
                  "AuthType Basic missing password."
                ]);
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "ApiWfConnections.re",
                    960,
                    10
                  ]
                ];
          }
        } else {
          if (password !== undefined) {
            var __x$1 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/editConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x$1, /* `string */[
                  288368849,
                  "AuthType Basic missing username."
                ]);
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "ApiWfConnections.re",
                    950,
                    10
                  ]
                ];
          }
          var __x$2 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/editConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
          Backtrace$BsConsole.Client.send(__x$2, /* `string */[
                288368849,
                "AuthType Basic missing username and password."
              ]);
          throw [
                Caml_builtin_exceptions.assert_failure,
                /* tuple */[
                  "ApiWfConnections.re",
                  970,
                  10
                ]
              ];
        }
        break;
    case /* OAuth1 */1 :
        if (generateKeys) {
          jsonObj = Json_encode.object_(/* :: */[
                /* tuple */[
                  "name",
                  connectionName
                ],
                /* :: */[
                  /* tuple */[
                    "options",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "baseUrl",
                            jiraServerUrl
                          ],
                          /* :: */[
                            /* tuple */[
                              "authorizationOptions",
                              Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "type",
                                      typeToApiString(authType)
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "consumerKey",
                                        consumerKey
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "generateKeys",
                                          generateKeys
                                        ],
                                        /* [] */0
                                      ]
                                    ]
                                  ])
                            ],
                            /* [] */0
                          ]
                        ])
                  ],
                  /* [] */0
                ]
              ]);
        } else if (privateKey !== undefined) {
          jsonObj = Json_encode.object_(/* :: */[
                /* tuple */[
                  "name",
                  connectionName
                ],
                /* :: */[
                  /* tuple */[
                    "options",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "baseUrl",
                            jiraServerUrl
                          ],
                          /* :: */[
                            /* tuple */[
                              "authorizationOptions",
                              Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "type",
                                      typeToApiString(authType)
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "consumerKey",
                                        "backtrace-workflows"
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "privateKey",
                                          privateKey
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "generateKeys",
                                            generateKeys
                                          ],
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            ],
                            /* [] */0
                          ]
                        ])
                  ],
                  /* [] */0
                ]
              ]);
        } else {
          var __x$3 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/editConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
          Backtrace$BsConsole.Client.send(__x$3, /* `string */[
                288368849,
                "AuthType OAuth 1.0 missing private key."
              ]);
          throw [
                Caml_builtin_exceptions.assert_failure,
                /* tuple */[
                  "ApiWfConnections.re",
                  859,
                  10
                ]
              ];
        }
        break;
    case /* OAuth2 */2 :
        if (clientId !== undefined) {
          if (clientSecret !== undefined) {
            jsonObj = Json_encode.object_(/* :: */[
                  /* tuple */[
                    "name",
                    connectionName
                  ],
                  /* :: */[
                    /* tuple */[
                      "options",
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "baseUrl",
                              jiraServerUrl
                            ],
                            /* :: */[
                              /* tuple */[
                                "authorizationOptions",
                                Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        typeToApiString(authType)
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "clientId",
                                          clientId
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "clientSecret",
                                            clientSecret
                                          ],
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              ],
                              /* [] */0
                            ]
                          ])
                    ],
                    /* [] */0
                  ]
                ]);
          } else {
            var __x$4 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/editConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x$4, /* `string */[
                  288368849,
                  "AuthType OAuth 2.0 missing client secret."
                ]);
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "ApiWfConnections.re",
                    903,
                    10
                  ]
                ];
          }
        } else {
          if (clientSecret !== undefined) {
            var __x$5 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/editConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x$5, /* `string */[
                  288368849,
                  "AuthType OAuth 2.0 missing client id."
                ]);
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "ApiWfConnections.re",
                    893,
                    10
                  ]
                ];
          }
          var __x$6 = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/editConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
          Backtrace$BsConsole.Client.send(__x$6, /* `string */[
                288368849,
                "AuthType OAuth 2.0 missing both client id and client secret."
              ]);
          throw [
                Caml_builtin_exceptions.assert_failure,
                /* tuple */[
                  "ApiWfConnections.re",
                  916,
                  10
                ]
              ];
        }
        break;
    
  }
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + connectionId)))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$13(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfConnections/JiraServerConnection/editConnectionTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var JiraServerConnection = {
  Success: Success$5,
  AuthType: AuthType,
  createConnectionTask: createConnectionTask,
  editConnectionTask: editConnectionTask$1
};

exports.Connection = Connection;
exports.QueryConnections = QueryConnections;
exports.QueryConnection = QueryConnection;
exports.DeleteConnection = DeleteConnection;
exports.NonceCallback = NonceCallback;
exports.EditConnection = EditConnection;
exports.JiraServerConnection = JiraServerConnection;
/* Task2-BsConsole Not a pure module */
