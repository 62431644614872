// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Href$BsConsole = require("./Href.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Notifications = require("@material-ui/icons/Notifications");

function Changelog(Props) {
  var onClick = function (param) {
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Changelog_Click */8]));
    return Util$BsConsole.openUrl(Href$BsConsole.changelog);
  };
  return React.createElement("div", {
              onClick: onClick
            }, React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                  ariaLabel: "View changelog",
                  className: "walkthrough--project_toolbar--notifications_button " + Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* :: */[
                          Css.marginRight(Css.px(5)),
                          /* [] */0
                        ]
                      ]),
                  title_tooltip: I18N$BsConsole.get(undefined, "View changelog"),
                  children: React.createElement(Notifications.default, { })
                }));
}

var component = ReasonReact.statelessComponent("Changelog");

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(Changelog, { }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = Changelog;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
