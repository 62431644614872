// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var Reopen$BsConsole = require("../reopen/Reopen.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var ReopenGate$BsConsole = require("../reopen/ReopenGate.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var Paper = require("@material-ui/core/Paper");
var Button = require("@material-ui/core/Button");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var CircularProgress = require("@material-ui/core/CircularProgress");

var listClasses = {
  root: Css.style(/* :: */[
        Css.padding2(Css.px(3), Css.px(0)),
        /* [] */0
      ])
};

var listItemClasses = {
  root: Css.style(/* :: */[
        Css.padding2(Css.px(1), Css.px(12)),
        /* :: */[
          Css.height(Css.px(24)),
          /* :: */[
            Css.margin2(Css.px(2), Css.px(0)),
            /* :: */[
              Css.fontSize(Css.px(12)),
              /* :: */[
                Css.textAlign(/* left */-944764921),
                /* :: */[
                  Css.flexShrink(0),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ])
};

var chevronDownIcon = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
      /* :: */[
        Css.fontSize(Css.rem(0.8)),
        /* [] */0
      ]
    ]);

var icon = Css.style(/* :: */[
      Css.justifySelf(/* flexEnd */924268066),
      /* [] */0
    ]);

function getStatusStringAndTooltip(state, invariant) {
  if (state !== undefined) {
    switch (state) {
      case /* Open */0 :
          var statusString = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Open */0));
          return /* tuple */[
                  statusString,
                  undefined
                ];
      case /* InProgress */1 :
          var statusString$1 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* InProgress */1));
          return /* tuple */[
                  statusString$1,
                  undefined
                ];
      case /* Resolved */2 :
          if (invariant !== undefined) {
            var statusString$2 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Resolved */2) + "*");
            return /* tuple */[
                    statusString$2,
                    Caml_option.some(Caml_option.valFromOption(invariant))
                  ];
          }
          var statusString$3 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Resolved */2));
          return /* tuple */[
                  statusString$3,
                  undefined
                ];
      case /* Muted */3 :
          if (invariant !== undefined) {
            var statusString$4 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Muted */3) + "*");
            return /* tuple */[
                    statusString$4,
                    Caml_option.some(Caml_option.valFromOption(invariant))
                  ];
          }
          var statusString$5 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Muted */3));
          return /* tuple */[
                  statusString$5,
                  undefined
                ];
      
    }
  } else {
    var statusString$6 = I18N$BsConsole.showEmpty(undefined);
    return /* tuple */[
            statusString$6,
            undefined
          ];
  }
}

function TriageTopBarMultiState$Header(Props) {
  var iconRef = Props.iconRef;
  var title = Props.title;
  var icon = Props.icon;
  var onClick = Props.onClick;
  var ariaLabel = Props.ariaLabel;
  var tmp = {
    className: Css.style(/* :: */[
          Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
          /* :: */[
            Css.marginRight(Css.rem(0.5)),
            /* :: */[
              Css.selector("svg", /* :: */[
                    Css.width(Css.rem(1)),
                    /* :: */[
                      Css.height(Css.rem(1)),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ])
  };
  if (iconRef !== undefined) {
    tmp.ref = Caml_option.valFromOption(iconRef);
  }
  return React.createElement(Button.default, {
              disableRipple: true,
              variant: "text",
              className: Css.style(/* :: */[
                    Css.padding(/* zero */-789508312),
                    /* :: */[
                      Css.selector(":focus-visible", /* :: */[
                            Css.outline(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.green)),
                            /* [] */0
                          ]),
                      /* :: */[
                        Css.selector(":hover", /* :: */[
                              Css.unsafe("background-color", "unset"),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]),
              onClick: Curry.__1(onClick),
              title: ariaLabel,
              children: React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.cursor(/* pointer */-786317123),
                            /* :: */[
                              Css.position(/* relative */903134412),
                              /* :: */[
                                Css.marginBottom(Css.rem(0.25)),
                                /* :: */[
                                  Css.hover(/* :: */[
                                        Css.selector("svg,div", /* :: */[
                                              Css.unsafe("filter", "brightness(0%) saturate(0%) invert(34%) sepia(77%) saturate(536%) hue-rotate(118deg) brightness(93%) contrast(101%)"),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]),
                    children: React.createElement(Row2$BsConsole.make, {
                          alignItems: /* baseline */287825029,
                          children: null
                        }, React.createElement("span", tmp, icon), React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                                    /* :: */[
                                      Css.fontSize(Css.rem(0.875)),
                                      /* :: */[
                                        Css.lineHeight(Css.px(12)),
                                        /* :: */[
                                          Css.fontWeight(Css.medium),
                                          /* :: */[
                                            Css.marginRight(Css.rem(0.5)),
                                            /* :: */[
                                              Css.minWidth(Css.px(60)),
                                              /* :: */[
                                                Css.textAlign(/* left */-944764921),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ])
                            }, I18N$BsConsole.showSkip(title)), React.createElement("span", {
                              className: chevronDownIcon
                            }, React.createElement(MuiIcons$BsConsole.ChevronDown.make, {
                                  color: "inherit",
                                  fontSize: "inherit"
                                })))
                  })
            });
}

var Header = {
  make: TriageTopBarMultiState$Header
};

function TriageTopBarMultiState$InboxContent(Props) {
  var loading = Props.loading;
  var children = Props.children;
  return React.createElement(Row2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.rem(1.25)),
                        /* :: */[
                          Css.flexShrink(0),
                          /* [] */0
                        ]
                      ])
                }, loading ? React.createElement(CircularProgress.default, {
                        size: 14
                      }) : null), React.createElement("div", {
                  className: Css.style(/* :: */[
                        loading ? Css.opacity(0.5) : Css.marginBottom(Css.zero),
                        /* :: */[
                          Css.width(/* `calc */[
                                -1044768619,
                                /* tuple */[
                                  /* sub */5745024,
                                  Css.pct(100),
                                  Css.px(14)
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, children));
}

var InboxContent = {
  make: TriageTopBarMultiState$InboxContent
};

function TriageTopBarMultiState$Topbar(Props) {
  var setAnchorEl = Props.setAnchorEl;
  var state = Props.state;
  var invariant = Props.invariant;
  var labelStyle = Props.labelStyle;
  var match = getStatusStringAndTooltip(state, invariant);
  var toolTipInvariant = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  onClick: (function ($$event) {
                      return Curry._1(setAnchorEl, (function (param) {
                                    return Caml_option.some($$event.currentTarget);
                                  }));
                    })
                }, React.createElement(TriageTopBarMultiState$Header, {
                      title: I18N$BsConsole.get(undefined, "State"),
                      icon: React.createElement(MuiIcons$BsConsole.CapabilityStatus.make, {
                            fontSize: "inherit"
                          }),
                      onClick: (function (param) {
                          
                        }),
                      ariaLabel: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                /* String_literal */Block.__(11, [
                                    "Set state. Current state: ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ]),
                                "Set state. Current state: %s"
                              ]), Belt_Option.getWithDefault(Belt_Option.map(state, Issues$BsConsole.State.toStringAsAdjectiveCapitalized), ""))
                    })), React.createElement(TriageTopBarMultiState$InboxContent, {
                  loading: false,
                  children: React.createElement(Row2$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.fontSize(Css.em(0.825)),
                              /* :: */[
                                Css.color(Css.hex(Colors$BsConsole.grey3)),
                                /* [] */0
                              ]
                            ]),
                        children: React.createElement(Tooltip.default, {
                              classes: {
                                popper: Css.style(/* :: */[
                                      Css.opacity(1),
                                      /* [] */0
                                    ]),
                                tooltip: Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.padding(Css.zero),
                                        /* :: */[
                                          Css.background(Css.none),
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              },
                              interactive: true,
                              placement: "bottom-end",
                              enterDelay: 300,
                              children: React.createElement("div", {
                                    className: Css.merge(/* :: */[
                                          Css.style(/* :: */[
                                                Css.borderRadius(Css.px(2)),
                                                /* :: */[
                                                  Css.minHeight(Css.px(40)),
                                                  /* :: */[
                                                    Css.cursor(/* pointer */-786317123),
                                                    /* :: */[
                                                      Css.display(/* flex */-1010954439),
                                                      /* :: */[
                                                        Css.justifyContent(/* spaceBetween */516682146),
                                                        /* :: */[
                                                          Css.alignItems(/* center */98248149),
                                                          /* :: */[
                                                            Css.selector(".cell-action", /* :: */[
                                                                  Css.color(Css.hex(Colors$BsConsole.accent)),
                                                                  /* :: */[
                                                                    Css.display(/* none */-922086728),
                                                                    /* :: */[
                                                                      Css.paddingTop(Css.px(4)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]
                                                                ]),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]),
                                          /* :: */[
                                            Css.style(/* :: */[
                                                  Css.fontSize(Css.rem(2)),
                                                  /* [] */0
                                                ]),
                                            /* :: */[
                                              labelStyle,
                                              /* [] */0
                                            ]
                                          ]
                                        ])
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body2 */9,
                                        className: Css.style(/* :: */[
                                              Css.fontSize(Css.rem(1)),
                                              /* :: */[
                                                Css.lineHeight(Css.rem(1.25)),
                                                /* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.grey95)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]),
                                        children: match[0]
                                      })),
                              leaveDelay: 300,
                              title: toolTipInvariant !== undefined ? React.createElement(Paper.default, {
                                      classes: {
                                        root: Css.style(/* :: */[
                                              Css.fontSize(Css.px(13)),
                                              /* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.flexDirection(/* column */-963948842),
                                                  /* :: */[
                                                    Css.alignItems(/* flexStart */662439529),
                                                    /* :: */[
                                                      Css.padding2(Css.px(8), Css.px(12)),
                                                      /* :: */[
                                                        Css.color(Css.hex(Colors$BsConsole.grey0)),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ])
                                      },
                                      children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                /* String_literal */Block.__(11, [
                                                    "Will reopen if ",
                                                    /* String */Block.__(2, [
                                                        /* No_padding */0,
                                                        /* End_of_format */0
                                                      ])
                                                  ]),
                                                "Will reopen if %s"
                                              ]), Issues$BsConsole.Invariant.toDescriptiveString(toolTipInvariant))
                                    }) : null
                            })
                      })
                }));
}

var Topbar = {
  make: TriageTopBarMultiState$Topbar
};

function TriageTopBarMultiState(Props) {
  var config = Props.config;
  var handleSetState = Props.handleSetState;
  var aperture = Props.aperture;
  var state = Props.state;
  var invariant = Props.invariant;
  var projectName = Props.projectName;
  var sort = Props.sort;
  var labelStyleOpt = Props.labelStyle;
  var labelStyle = labelStyleOpt !== undefined ? labelStyleOpt : "";
  var match = React.useState((function () {
          
        }));
  var setClickedState = match[1];
  var clickedState = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setAnchorEl = match$1[1];
  var anchorEl = match$1[0];
  var tmp;
  var exit = 0;
  if (ReopenGate$BsConsole.allow && clickedState !== undefined) {
    tmp = React.createElement(Reopen$BsConsole.make, {
          config: config,
          nextState: clickedState,
          handleSetState: handleSetState,
          projectName: Belt_Option.getWithDefault(projectName, ""),
          onClose: (function (param) {
              Curry._1(setClickedState, (function (param) {
                      
                    }));
              Curry._1(setAnchorEl, (function (param) {
                      
                    }));
              
            }),
          aperture: aperture,
          sort: sort
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var tmp$1;
    if (CoronerdGate$BsConsole.isStateMachineDisabled) {
      if (state !== undefined) {
        switch (state) {
          case /* Open */0 :
              tmp$1 = [
                /* InProgress */1,
                /* Muted */3,
                /* Resolved */2
              ];
              break;
          case /* InProgress */1 :
              tmp$1 = [
                /* Open */0,
                /* Muted */3,
                /* Resolved */2
              ];
              break;
          case /* Resolved */2 :
              tmp$1 = [
                /* Open */0,
                /* InProgress */1,
                /* Muted */3
              ];
              break;
          case /* Muted */3 :
              tmp$1 = [
                /* Open */0,
                /* InProgress */1,
                /* Resolved */2
              ];
              break;
          
        }
      } else {
        tmp$1 = [
          /* Open */0,
          /* InProgress */1,
          /* Muted */3,
          /* Resolved */2
        ];
      }
    } else {
      tmp$1 = state !== undefined ? (
          state !== 2 ? (
              state >= 3 ? [
                  /* Open */0,
                  /* Resolved */2
                ] : [
                  /* Muted */3,
                  /* Resolved */2
                ]
            ) : [
              /* Open */0,
              /* Muted */3
            ]
        ) : [
          /* Open */0,
          /* Muted */3,
          /* Resolved */2
        ];
    }
    tmp = React.createElement(MenuList.default, {
          classes: listClasses,
          children: Belt_Array.map(tmp$1, (function (s) {
                  if (s >= 2) {
                    if (ReopenGate$BsConsole.allow) {
                      return React.createElement(MenuItem.default, {
                                  classes: listItemClasses,
                                  onClick: (function (_e) {
                                      if (s !== 2) {
                                        if (s >= 3) {
                                          return Curry._1(setClickedState, (function (param) {
                                                        return /* Muted */3;
                                                      }));
                                        } else {
                                          Curry._2(handleSetState, s, /* NoInvariant */0);
                                          return Curry._1(setAnchorEl, (function (param) {
                                                        
                                                      }));
                                        }
                                      } else {
                                        return Curry._1(setClickedState, (function (param) {
                                                      return /* Resolved */2;
                                                    }));
                                      }
                                    }),
                                  children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    " until...",
                                                    /* End_of_format */0
                                                  ])
                                              ]),
                                            "%s until..."
                                          ]), Issues$BsConsole.State.toStringCapitalized(s))
                                });
                    } else {
                      return React.createElement(MenuItem.default, {
                                  classes: listItemClasses,
                                  onClick: (function (param) {
                                      Curry._2(handleSetState, s, /* NoInvariant */0);
                                      return Curry._1(setAnchorEl, (function (param) {
                                                    
                                                  }));
                                    }),
                                  children: I18N$BsConsole.show(undefined, Issues$BsConsole.State.toStringCapitalized(s))
                                });
                    }
                  } else {
                    return React.createElement(MenuItem.default, {
                                classes: listItemClasses,
                                onClick: (function (_event) {
                                    Curry._2(handleSetState, s, /* NoInvariant */0);
                                    return Curry._1(setAnchorEl, (function (param) {
                                                  
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, Issues$BsConsole.State.toStringCapitalized(s))
                              });
                  }
                }))
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(TriageTopBarMultiState$Topbar, {
                  setAnchorEl: setAnchorEl,
                  state: state,
                  invariant: invariant,
                  labelStyle: labelStyle
                }), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: anchorEl,
                  open: Belt_Option.isSome(anchorEl),
                  onClose: (function (param) {
                      Curry._1(setClickedState, (function (param) {
                              
                            }));
                      return Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                    }),
                  children: tmp
                }));
}

function make(config, handleSetState, aperture, state, invariant, projectName, sort, children) {
  var tmp = {
    config: config,
    handleSetState: handleSetState,
    aperture: aperture,
    invariant: invariant
  };
  if (state !== undefined) {
    tmp.state = Caml_option.valFromOption(state);
  }
  if (projectName !== undefined) {
    tmp.projectName = Caml_option.valFromOption(projectName);
  }
  if (sort !== undefined) {
    tmp.sort = Caml_option.valFromOption(sort);
  }
  return ReasonReactCompat.wrapReactForReasonReact(TriageTopBarMultiState, tmp, children);
}

var Re = {
  make: make
};

var make$1 = TriageTopBarMultiState;

exports.listClasses = listClasses;
exports.listItemClasses = listItemClasses;
exports.chevronDownIcon = chevronDownIcon;
exports.icon = icon;
exports.getStatusStringAndTooltip = getStatusStringAndTooltip;
exports.Header = Header;
exports.InboxContent = InboxContent;
exports.Topbar = Topbar;
exports.make = make$1;
exports.Re = Re;
/* listClasses Not a pure module */
