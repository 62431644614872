// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");

var metricsNamespace = "metrics_exporter/";

function toEvent(action) {
  switch (action) {
    case /* SetCreate */0 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/set_create",
                    payload: undefined
                  }]);
    case /* SetUpdate */1 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/set_update",
                    payload: undefined
                  }]);
    case /* SetDelete */2 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/set_delete",
                    payload: undefined
                  }]);
    case /* StoreCreate */3 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/store_create",
                    payload: undefined
                  }]);
    case /* StoreUpdate */4 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/store_update",
                    payload: undefined
                  }]);
    case /* StoreDelete */5 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/store_delete",
                    payload: undefined
                  }]);
    case /* ExportCreate */6 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/export_create",
                    payload: undefined
                  }]);
    case /* ExportUpdate */7 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/export_update",
                    payload: undefined
                  }]);
    case /* ExportDelete */8 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/export_delete",
                    payload: undefined
                  }]);
    case /* MetricsCreate */9 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/metrics_create",
                    payload: undefined
                  }]);
    case /* MetricsUpdate */10 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/metrics_update",
                    payload: undefined
                  }]);
    case /* MetricsDelete */11 :
        return /* GenericEvent */Block.__(2, [{
                    name: "metrics_exporter/metrics_delete",
                    payload: undefined
                  }]);
    
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.metricsNamespace = metricsNamespace;
exports.toEvent = toEvent;
exports.send = send;
/* MetricsEvent-BsConsole Not a pure module */
