// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var Icon = require("@material-ui/core/Icon");
var BtTypography$BsConsole = require("../BtTypography.js");
var StorageFetch$BsConsole = require("../StorageFetch.js");
var SymbolsFetch$BsConsole = require("../SymbolsFetch.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var Paper = require("@material-ui/core/Paper");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var Button = require("@material-ui/core/Button");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");
var SettingsProjectsPage$BsConsole = require("../settingsProjectsPage.js");
var SystemUsageDistribution$BsConsole = require("../charts/SystemUsageDistribution.js");

function PS_Overview(Props) {
  var handleTask = Props.handleTask;
  var config = Props.config;
  var refetchShell = Props.refetchShell;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var handleChangeUrl = match.handleChangeUrl;
  var match$1 = StorageFetch$BsConsole.use(token, config);
  var storageRemote = match$1[0];
  var match$2 = SymbolsFetch$BsConsole.use(token, projectName);
  var symbolsRemote = match$2[0];
  var allUsersRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Users.fetchAll, undefined);
  var match$3 = React.useState((function () {
          
        }));
  var updateDialog = match$3[1];
  var project = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectName;
        }));
  var owner;
  if (project !== undefined && !(typeof allUsersRemote === "number" || allUsersRemote.tag)) {
    var owner$1 = project.owner;
    owner = Belt_Array.getBy(allUsersRemote[0][0], (function (user) {
            return user.uid === owner$1;
          }));
  } else {
    owner = undefined;
  }
  var tmp;
  tmp = typeof symbolsRemote === "number" || symbolsRemote.tag || symbolsRemote[0].missingSymbols.count <= 0 ? null : React.createElement(Paper.default, {
          classes: {
            root: Css.style(/* :: */[
                  Css.display(/* flex */-1010954439),
                  /* :: */[
                    Css.justifyContent(/* spaceBetween */516682146),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* :: */[
                        Css.padding(Css.rem(1.0)),
                        /* :: */[
                          Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.redDark)),
                          /* :: */[
                            Css.marginBottom(Css.rem(1)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.redDark)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ])
          },
          elevation: 0,
          children: null
        }, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Subtitle1 */6,
              children: I18N$BsConsole.show(undefined, "Warning: the current project contains missing symbols.")
            }), React.createElement("div", undefined, React.createElement(Button.default, {
                  variant: "flat",
                  className: Css.style(/* :: */[
                        Css.marginRight(Css.rem(0.5)),
                        /* [] */0
                      ]),
                  onClick: (function (_event) {
                      return Curry._1(handleChangeUrl, /* ProjectSettingsMissingSymbols */Block.__(41, [projectName]));
                    }),
                  children: I18N$BsConsole.show(undefined, "Search missing symbols")
                }), React.createElement(Button.default, {
                  variant: "contained",
                  onClick: (function (_event) {
                      return Curry._1(handleChangeUrl, /* ProjectSettingsSymbolsUpload */Block.__(37, [projectName]));
                    }),
                  children: I18N$BsConsole.show(undefined, "Upload symbols")
                })));
  var tmp$1;
  if (typeof storageRemote === "number" || storageRemote.tag) {
    tmp$1 = null;
  } else {
    var uni = storageRemote[0];
    var project$1 = Belt_Array.getBy(uni.projects, (function (project) {
            return project.name === projectName;
          }));
    if (project$1 !== undefined) {
      var data = [
        /* tuple */[
          "reports",
          project$1.reports
        ],
        /* tuple */[
          "attachments",
          project$1.attachments
        ],
        /* tuple */[
          "symbols",
          project$1.symbols
        ],
        /* tuple */[
          "database",
          project$1.database
        ]
      ];
      tmp$1 = React.createElement(BtSettings$BsConsole.Paper.make, {
            className: Css.style(/* :: */[
                  Css.marginTop(Css.rem(1.0)),
                  /* :: */[
                    Css.padding(Css.rem(1.0)),
                    /* [] */0
                  ]
                ]),
            children: null
          }, React.createElement(BtTypography$BsConsole.make, {
                variant: /* Heading5 */4,
                className: Css.style(/* :: */[
                      Css.marginBottom(Css.rem(1.0)),
                      /* [] */0
                    ]),
                children: I18N$BsConsole.show(undefined, "Storage usage")
              }), React.createElement(SystemUsageDistribution$BsConsole.make, {
                data: data,
                timestamp: uni.timestamp,
                onItemClick: (function (param) {
                    
                  })
              }));
    } else {
      tmp$1 = null;
    }
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, tmp, React.createElement(BtSettings$BsConsole.Paper.make, {
                  className: Css.style(/* :: */[
                        Css.padding(Css.rem(1.0)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Heading5 */4,
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(1.0)),
                            /* [] */0
                          ]),
                      children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                /* String_literal */Block.__(11, [
                                    "Project overview: ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ]),
                                "Project overview: %s"
                              ]), projectName)
                    }), owner !== undefined ? React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.alignItems(/* center */98248149),
                                /* [] */0
                              ]
                            ])
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body2 */9,
                            children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Owner: ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "Owner: %s"
                                    ]), owner.username)
                          }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                            ariaLabel: "Transfer ownership",
                            size: "small",
                            onClick: (function (param) {
                                return Curry._1(updateDialog, (function (param) {
                                              return /* TransferOwnership */0;
                                            }));
                              }),
                            title_tooltip: I18N$BsConsole.get(undefined, "Transfer ownership"),
                            children: React.createElement(Icon.default, {
                                  fontSize: "small",
                                  children: I18N$BsConsole.show(undefined, "edit")
                                })
                          })) : null), tmp$1, match$3[0] !== undefined && project !== undefined ? React.createElement(SettingsProjectsPage$BsConsole.EditProjectDialogJsx3.make, {
                    project: project,
                    token: token,
                    handleTask: handleTask,
                    onClose: (function (_event) {
                        return Curry._1(updateDialog, (function (param) {
                                      
                                    }));
                      }),
                    onSuccess: (function (_project) {
                        Curry._1(refetchShell, undefined);
                        return Curry._1(updateDialog, (function (param) {
                                      
                                    }));
                      })
                  }) : null);
}

function make(config, handleTask, refetchShell, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_Overview, {
              handleTask: handleTask,
              config: config,
              refetchShell: refetchShell
            }, children);
}

var Re = {
  make: make
};

var make$1 = PS_Overview;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
