// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var PrModal$BsConsole = require("../progressive-reveal/PrModal.js");
var Charts__Bin$BsConsole = require("./Charts__Bin.js");
var PrModalLayout$BsConsole = require("../progressive-reveal/PrModalLayout.js");
var QbExpandedAggregation$BsConsole = require("../query-builder/QbExpandedAggregation.js");

var component = RR$BsConsole.reducerComponent("BinForDetailCard-BsConsole");

function make(attribute, bin, width, handleAddFilters, handleChangeUrl, factor, aperture, token, name, group, handleTask, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var dialog = param.state.dialog;
              var send = param.send;
              var tmp;
              if (dialog !== undefined) {
                var group$1 = dialog[2];
                var attribute$1 = dialog[0];
                var factorName = group$1.factor.desc.name;
                var factorValue = group$1.factor.value;
                tmp = ReasonReact.element(undefined, undefined, PrModalLayout$BsConsole.make(factorName, factorValue, attribute$1, "bin", (function (_event) {
                            return Curry._1(send, /* Dialog */[undefined]);
                          }), (function (dialogWidth, dialogMaxHeight) {
                            return ReasonReact.element(undefined, undefined, QbExpandedAggregation$BsConsole.make(undefined, (function (param) {
                                              return Curry._1(send, /* Dialog */[undefined]);
                                            }), factor, handleChangeUrl, handleAddFilters, aperture, attribute$1, handleTask, token, name, dialogWidth, dialogMaxHeight, []));
                          })));
              } else {
                tmp = React.createElement("div", undefined);
              }
              return React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.cursor(/* pointer */-786317123),
                                    /* :: */[
                                      Css.marginLeft(Css.px(0)),
                                      /* [] */0
                                    ]
                                  ]),
                              onClick: (function (_event) {
                                  return Curry._1(send, /* Dialog */[/* ExpandedAggregation */[
                                                attribute,
                                                undefined,
                                                group
                                              ]]);
                                })
                            }, ReasonReact.element(undefined, undefined, Charts__Bin$BsConsole.make(undefined, undefined, 1, undefined, 60, width, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, bin, []))), ReasonReact.element(undefined, undefined, PrModal$BsConsole.make(Belt_Option.isSome(dialog), (function (_event) {
                                    return Curry._1(send, /* Dialog */[undefined]);
                                  }), [tmp])));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
