// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Styles$BsConsole = require("../utilities/Styles.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var NewStyles$BsConsole = require("./NewStyles.js");
var SortOrder$BsConsole = require("../bs-crdb-query/src/SortOrder.js");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");
var L10N_date_time_calendar$BsConsole = require("../L10N_date_time_calendar.js");

var chipRoot = Css.style(/* :: */[
      Css.margin(/* `px */[
            25096,
            2
          ]),
      /* :: */[
        Css.backgroundColor(/* `hex */[
              5194459,
              Colors$BsConsole.grey4
            ]),
        /* :: */[
          Css.height(/* `px */[
                25096,
                26
              ]),
          /* :: */[
            Css.border(/* `px */[
                  25096,
                  1
                ], /* solid */12956715, /* `hex */[
                  5194459,
                  Colors$BsConsole.grey4
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var sectionContainer = Css.style(/* :: */[
      Css.marginTop(/* `em */[
            22632,
            1
          ]),
      /* [] */0
    ]);

var chipLabel = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.fontSize(/* `px */[
              25096,
              12
            ]),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.overflow(/* hidden */-862584982),
            /* :: */[
              Css.textOverflow(/* ellipsis */166888785),
              /* :: */[
                Css.maxWidth(/* `px */[
                      25096,
                      150
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var buttonChip = Css.style(/* :: */[
      Css.textTransform(/* unset */653193961),
      /* :: */[
        Css.unsafe("minHeight", "unset"),
        /* [] */0
      ]
    ]);

var buttonLabel = Css.style(/* :: */[
      Css.color(/* `hex */[
            5194459,
            Colors$BsConsole.grey4
          ]),
      /* :: */[
        Css.lineHeight(/* `abs */[
              4845682,
              1
            ]),
        /* [] */0
      ]
    ]);

var sectionTitle = Css.style(/* :: */[
      Css.color(/* `hex */[
            5194459,
            Colors$BsConsole.grey3
          ]),
      /* [] */0
    ]);

function QueryDetails(Props) {
  var query = Props.query;
  var mode = SavedQueriesModel$BsConsole.getMode(query);
  var query$1 = SavedQueriesModel$BsConsole.getQuery(query);
  var filters = Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, Curry._1(Crdb$BsConsole.Query.getAperture, query$1)));
  var __x = Curry._1(Crdb$BsConsole.Query.getHavings, query$1);
  var havings = Curry._1(Crdb$BsConsole.Havings.toArray, Belt_Option.getWithDefault(__x, Crdb$BsConsole.Havings.empty));
  var modeString;
  switch (mode) {
    case /* Inbox */0 :
        modeString = "Triage";
        break;
    case /* Aggregate */1 :
        modeString = "Explore";
        break;
    case /* Instance */2 :
        modeString = "Instance";
        break;
    
  }
  var timeAperture = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(Crdb$BsConsole.Query.getAperture, query$1));
  var start = Curry._1(Crdb$BsConsole.TimeAperture.getStart, timeAperture);
  var finish = Curry._1(Crdb$BsConsole.TimeAperture.getFinish, timeAperture);
  var timeType = Curry._1(Crdb$BsConsole.TimeAperture.getString, timeAperture);
  var maybeFold = Curry._1(Crdb$BsConsole.Query.getFold, query$1);
  var maybeSelect;
  maybeSelect = query$1.tag ? query$1[1] : undefined;
  var factor = Curry._1(Crdb$BsConsole.Query.getFactor, query$1);
  var sort = Curry._1(Crdb$BsConsole.Query.getSort, query$1);
  var tmp;
  if (query.tag) {
    tmp = null;
  } else {
    var normBy = query[0].normBy;
    tmp = normBy !== undefined ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(sectionContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, sectionTitle, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Normalized by")])),
                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                          /* Small */311976103,
                          NewStyles$BsConsole.outlinedButton,
                          undefined,
                          undefined,
                          undefined,
                          /* Outlined */-28821822,
                          undefined,
                          undefined,
                          undefined,
                          true,
                          undefined,
                          undefined,
                          false,
                          true,
                          undefined,
                          undefined,
                          [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, buttonLabel, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(normBy)]))]
                        ]))
              ])) : null;
  }
  var tmp$1;
  switch (timeType) {
    case "custom" :
        tmp$1 = ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, undefined, [
                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(L10N_date_time_calendar$BsConsole.Format.numericDay(undefined, start))])),
                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(" - ")])),
                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(L10N_date_time_calendar$BsConsole.Format.numericDay(undefined, finish))]))
                ]));
        break;
    case "custom-first-seen" :
        tmp$1 = Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "first seen from ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* String_literal */Block.__(11, [
                              " - ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ])
                        ])
                    ]),
                  "first seen from %s - %s"
                ]), L10N_date_time_calendar$BsConsole.Format.numericDay(undefined, start), L10N_date_time_calendar$BsConsole.Format.numericDay(undefined, finish));
        break;
    case "first-seen-day" :
        tmp$1 = I18N$BsConsole.show(undefined, "first seen in the last day");
        break;
    case "first-seen-hour" :
        tmp$1 = I18N$BsConsole.show(undefined, "first seen in the last hour");
        break;
    case "first-seen-month" :
        tmp$1 = I18N$BsConsole.show(undefined, "first seen in the last 30 days");
        break;
    case "first-seen-quarter" :
        tmp$1 = I18N$BsConsole.show(undefined, "first seen in the last 90 days");
        break;
    case "first-seen-week" :
        tmp$1 = I18N$BsConsole.show(undefined, "first seen in the last 7 days");
        break;
    case "first-seen-year" :
        tmp$1 = I18N$BsConsole.show(undefined, "first seen in the last year");
        break;
    default:
      tmp$1 = Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                /* String_literal */Block.__(11, [
                    "last ",
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* End_of_format */0
                      ])
                  ]),
                "last %s"
              ]), timeType);
  }
  var tmp$2;
  if (maybeFold !== undefined) {
    var fold = Caml_option.valFromOption(maybeFold);
    tmp$2 = Curry._1(Crdb$BsConsole.Fold.toArray, fold).length !== 0 ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(sectionContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, sectionTitle, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Columns")])),
                ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [$$Array.map((function (agg) {
                                  var match = Curry._1(Crdb$BsConsole.Aggregation.getId, agg);
                                  var operation = match[1];
                                  var attribute = match[0];
                                  return ReasonReact.element(attribute + ("__" + operation), undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                                  undefined,
                                                  {
                                                    root: chipRoot
                                                  },
                                                  Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, chipLabel, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                                I18N$BsConsole.showSkip(attribute),
                                                                I18N$BsConsole.showSkip("  "),
                                                                I18N$BsConsole.showSkip(operation)
                                                              ]))),
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  []
                                                ]));
                                }), Curry._1(Crdb$BsConsole.Fold.toArray, fold))]))
              ])) : null;
  } else {
    tmp$2 = null;
  }
  var tmp$3;
  if (sort !== undefined) {
    var sort$1 = Curry._1(Crdb$BsConsole.Sort.getAttribute, sort);
    var range;
    range = sort.tag === /* Range */4 ? (
        sort[1] ? (
            sort$1 === "timestamp" ? " - last seen" : " - max"
          ) : (
            sort$1 === "timestamp" ? " - first seen" : " - min"
          )
      ) : "";
    var order = SortOrder$BsConsole.Ordering.toString(Curry._1(Crdb$BsConsole.Sort.getOrdering, sort));
    tmp$3 = I18N$BsConsole.showSkip(sort$1 + (range + (" - " + order)));
  } else {
    tmp$3 = null;
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.padding2(Css.rem(1), Css.rem(1)),
                    /* :: */[
                      Css.marginTop(Css.rem(0.5)),
                      /* :: */[
                        Css.width(/* `percent */[
                              -119887163,
                              100
                            ]),
                        /* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                          /* :: */[
                            Css.flex(/* `num */[
                                  5496390,
                                  2
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.lineHeight(/* `rem */[
                              5691738,
                              1.5
                            ]),
                        /* :: */[
                          Css.marginBottom(/* `rem */[
                                5691738,
                                0.5
                              ]),
                          /* [] */0
                        ]
                      ])
                }, I18N$BsConsole.show(undefined, "Summary of view")), ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(Css.style(/* :: */[
                          Css.minHeight(/* `vh */[
                                26418,
                                30
                              ]),
                          /* [] */0
                        ]), undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, /* Column */-81804554, undefined, undefined, [
                      ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, sectionTitle, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Mode")])),
                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                          /* Small */311976103,
                                          NewStyles$BsConsole.outlinedButton,
                                          undefined,
                                          undefined,
                                          undefined,
                                          /* Outlined */-28821822,
                                          undefined,
                                          undefined,
                                          undefined,
                                          true,
                                          undefined,
                                          undefined,
                                          false,
                                          true,
                                          undefined,
                                          undefined,
                                          [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, buttonLabel, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(modeString)]))]
                                        ]))
                              ])),
                      tmp,
                      filters.length !== 0 ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(sectionContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, sectionTitle, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Filter")])),
                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                            $$Array.map((function (filter) {
                                                    return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                        className: Styles$BsConsole.$$Text.tooltipTitle
                                                                      }, Curry._3(I18N$BsConsole.showSkipf(/* Format */[
                                                                                /* String */Block.__(2, [
                                                                                    /* No_padding */0,
                                                                                    /* Char_literal */Block.__(12, [
                                                                                        /* " " */32,
                                                                                        /* String */Block.__(2, [
                                                                                            /* No_padding */0,
                                                                                            /* Char_literal */Block.__(12, [
                                                                                                /* " " */32,
                                                                                                /* String */Block.__(2, [
                                                                                                    /* No_padding */0,
                                                                                                    /* End_of_format */0
                                                                                                  ])
                                                                                              ])
                                                                                          ])
                                                                                      ])
                                                                                  ]),
                                                                                "%s %s %s"
                                                                              ]), Curry._1(Crdb$BsConsole.Filter.getAttribute, filter), Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter)), Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter)))))), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                                                              undefined,
                                                                              {
                                                                                root: chipRoot
                                                                              },
                                                                              Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, chipLabel, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                                                            I18N$BsConsole.showSkip(Curry._1(Crdb$BsConsole.Filter.getAttribute, filter)),
                                                                                            I18N$BsConsole.showSkip("  "),
                                                                                            I18N$BsConsole.showSkip(Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))),
                                                                                            I18N$BsConsole.showSkip("  "),
                                                                                            React.createElement("label", {
                                                                                                  className: Css.style(/* :: */[
                                                                                                        Css.textOverflow(/* ellipsis */166888785),
                                                                                                        /* :: */[
                                                                                                          Css.overflow(/* hidden */-862584982),
                                                                                                          /* :: */[
                                                                                                            Css.whiteSpace(/* nowrap */867913355),
                                                                                                            /* [] */0
                                                                                                          ]
                                                                                                        ]
                                                                                                      ])
                                                                                                }, I18N$BsConsole.showSkip(Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))))))
                                                                                          ]))),
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              []
                                                                            ]))]));
                                                  }), filters),
                                            havings.length !== 0 ? $$Array.map((function (having) {
                                                      var att = Curry._1(Crdb$BsConsole.Having.getAttribute, having);
                                                      var att$1 = att !== undefined && att !== "" ? att : "count";
                                                      var match = Curry._1(Crdb$BsConsole.Having.getPipe, having);
                                                      var tmp;
                                                      var exit = 0;
                                                      if (att$1 === "count" && match === "count") {
                                                        tmp = "count";
                                                      } else {
                                                        exit = 1;
                                                      }
                                                      if (exit === 1) {
                                                        tmp = att$1 + (" | " + Curry._1(Crdb$BsConsole.Having.getPipe, having));
                                                      }
                                                      var match$1 = Curry._1(Crdb$BsConsole.Having.getPipe, having);
                                                      var tmp$1;
                                                      var exit$1 = 0;
                                                      if (att$1 === "count" && match$1 === "count") {
                                                        tmp$1 = "count";
                                                      } else {
                                                        exit$1 = 1;
                                                      }
                                                      if (exit$1 === 1) {
                                                        tmp$1 = att$1 + (" | " + Curry._1(Crdb$BsConsole.Having.getPipe, having));
                                                      }
                                                      return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                          className: Styles$BsConsole.$$Text.tooltipTitle
                                                                        }, Curry._3(I18N$BsConsole.showSkipf(/* Format */[
                                                                                  /* String */Block.__(2, [
                                                                                      /* No_padding */0,
                                                                                      /* Char_literal */Block.__(12, [
                                                                                          /* " " */32,
                                                                                          /* String */Block.__(2, [
                                                                                              /* No_padding */0,
                                                                                              /* Char_literal */Block.__(12, [
                                                                                                  /* " " */32,
                                                                                                  /* String */Block.__(2, [
                                                                                                      /* No_padding */0,
                                                                                                      /* End_of_format */0
                                                                                                    ])
                                                                                                ])
                                                                                            ])
                                                                                        ])
                                                                                    ]),
                                                                                  "%s %s %s"
                                                                                ]), tmp, Crdb$BsConsole.HavingOperation.toString(Curry._1(Crdb$BsConsole.Having.getOperation, having)), Crdb$BsConsole.HavingOperation.getValue(Curry._1(Crdb$BsConsole.Having.getOperation, having)))), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                                                                undefined,
                                                                                {
                                                                                  root: chipRoot
                                                                                },
                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, chipLabel, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                                                              Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                                                                        /* String */Block.__(2, [
                                                                                                            /* No_padding */0,
                                                                                                            /* Char_literal */Block.__(12, [
                                                                                                                /* " " */32,
                                                                                                                /* String */Block.__(2, [
                                                                                                                    /* No_padding */0,
                                                                                                                    /* Char_literal */Block.__(12, [
                                                                                                                        /* " " */32,
                                                                                                                        /* End_of_format */0
                                                                                                                      ])
                                                                                                                  ])
                                                                                                              ])
                                                                                                          ]),
                                                                                                        "%s %s "
                                                                                                      ]), tmp$1, Crdb$BsConsole.HavingOperation.toString(Curry._1(Crdb$BsConsole.Having.getOperation, having))),
                                                                                              React.createElement("label", {
                                                                                                    className: Css.style(/* :: */[
                                                                                                          Css.textOverflow(/* ellipsis */166888785),
                                                                                                          /* :: */[
                                                                                                            Css.overflow(/* hidden */-862584982),
                                                                                                            /* :: */[
                                                                                                              Css.whiteSpace(/* nowrap */867913355),
                                                                                                              /* [] */0
                                                                                                            ]
                                                                                                          ]
                                                                                                        ])
                                                                                                  }, I18N$BsConsole.showSkip(Crdb$BsConsole.HavingOperation.getValue(Curry._1(Crdb$BsConsole.Having.getOperation, having))))
                                                                                            ]))),
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                []
                                                                              ]))]));
                                                    }), havings) : null
                                          ]))
                                ])) : null,
                      ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(sectionContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, sectionTitle, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Time frame")])),
                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                          /* Small */311976103,
                                          buttonChip,
                                          undefined,
                                          undefined,
                                          undefined,
                                          /* Outlined */-28821822,
                                          undefined,
                                          undefined,
                                          undefined,
                                          true,
                                          undefined,
                                          undefined,
                                          false,
                                          true,
                                          undefined,
                                          undefined,
                                          [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, buttonLabel, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [tmp$1]))]
                                        ]))
                              ])),
                      tmp$2,
                      maybeSelect !== undefined ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(sectionContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, sectionTitle, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Columns")])),
                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [$$Array.map((function (column) {
                                                    return ReasonReact.element("__" + column, undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                                                    undefined,
                                                                    {
                                                                      root: chipRoot
                                                                    },
                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, chipLabel, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(column)]))),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    []
                                                                  ]));
                                                  }), $$Array.of_list(maybeSelect))]))
                                ])) : null,
                      mode === /* Inbox */0 || factor === undefined ? null : (
                          factor ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(sectionContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, sectionTitle, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Group by")])),
                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                /* Small */311976103,
                                                buttonChip,
                                                undefined,
                                                undefined,
                                                undefined,
                                                /* Outlined */-28821822,
                                                undefined,
                                                undefined,
                                                undefined,
                                                true,
                                                undefined,
                                                undefined,
                                                false,
                                                true,
                                                undefined,
                                                undefined,
                                                [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, buttonLabel, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(factor[0])]))]
                                              ]))
                                    ])) : ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(sectionContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, sectionTitle, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Group by")])),
                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                /* Small */311976103,
                                                buttonChip,
                                                undefined,
                                                undefined,
                                                undefined,
                                                /* Outlined */-28821822,
                                                undefined,
                                                undefined,
                                                undefined,
                                                true,
                                                undefined,
                                                undefined,
                                                false,
                                                true,
                                                undefined,
                                                undefined,
                                                [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, buttonLabel, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "fingerprint")]))]
                                              ]))
                                    ]))
                        ),
                      ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(sectionContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, sectionTitle, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Sort order")])),
                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                          /* Small */311976103,
                                          buttonChip,
                                          undefined,
                                          undefined,
                                          undefined,
                                          /* Outlined */-28821822,
                                          undefined,
                                          undefined,
                                          undefined,
                                          true,
                                          undefined,
                                          undefined,
                                          false,
                                          true,
                                          undefined,
                                          undefined,
                                          [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, buttonLabel, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [tmp$3]))]
                                        ]))
                              ]))
                    ])));
}

function make(query, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(QueryDetails, {
              query: query
            }, _children);
}

var Re = {
  make: make
};

var make$1 = QueryDetails;

exports.chipRoot = chipRoot;
exports.sectionContainer = sectionContainer;
exports.chipLabel = chipLabel;
exports.buttonChip = buttonChip;
exports.buttonLabel = buttonLabel;
exports.sectionTitle = sectionTitle;
exports.make = make$1;
exports.Re = Re;
/* chipRoot Not a pure module */
