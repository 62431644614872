// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

function font(family, weight, fSize) {
  return Css.style(/* :: */[
              Css.fontFamily(family),
              /* :: */[
                Css.fontWeight(/* `num */[
                      5496390,
                      weight
                    ]),
                /* :: */[
                  Css.fontSize(Css.rem(fSize)),
                  /* :: */[
                    Css.margin(/* zero */-789508312),
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

var heading1 = font("Roboto", 400, 3);

var heading2 = font("Roboto", 400, 2.4);

var heading3 = font("Roboto", 400, 2);

var heading4 = font("Roboto", 400, 1.625);

var heading5 = font("Roboto", 400, 1.4375);

var heading6 = font("Roboto", 400, 1.1875);

var subtitle1 = font("Roboto", 400, 1);

var subtitle2 = font("Roboto", 500, 0.8125);

var body1 = font("Roboto", 400, 1);

var body2 = font("Roboto", 400, 0.8125);

var button = font("Roboto", 500, 0.8125);

var caption = font("Roboto", 400, 0.75);

var overline = font("Roboto", 400, 0.75);

var tableTitle = font("Inconsolata", 500, 0.8125);

var tableBody = font("Inconsolata", 400, 0.8125);

var chip = font("Inconsolata", 400, 0.8125);

function BtTypography(Props) {
  var variant = Props.variant;
  var textAlignOpt = Props.textAlign;
  var classNameOpt = Props.className;
  var color = Props.color;
  var title = Props.title;
  var noWrapOpt = Props.noWrap;
  var children = Props.children;
  var textAlign = textAlignOpt !== undefined ? textAlignOpt : /* Inherit */3;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var noWrap = noWrapOpt !== undefined ? noWrapOpt : false;
  var align;
  switch (textAlign) {
    case /* Left */0 :
        align = Css.style(/* :: */[
              Css.textAlign(/* left */-944764921),
              /* [] */0
            ]);
        break;
    case /* Center */1 :
        align = Css.style(/* :: */[
              Css.textAlign(/* center */98248149),
              /* [] */0
            ]);
        break;
    case /* Right */2 :
        align = Css.style(/* :: */[
              Css.textAlign(/* right */-379319332),
              /* [] */0
            ]);
        break;
    case /* Inherit */3 :
        align = Css.style(/* :: */[
              Css.textAlign(/* inherit_ */-601204732),
              /* [] */0
            ]);
        break;
    
  }
  var wrap = noWrap ? Css.style(/* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.overflow(/* hidden */-862584982),
            /* :: */[
              Css.textOverflow(/* ellipsis */166888785),
              /* :: */[
                Css.whiteSpace(/* nowrap */867913355),
                /* [] */0
              ]
            ]
          ]
        ]) : "";
  var color$1 = Belt_Option.mapWithDefault(color, "", (function (c) {
          return Css.style(/* :: */[
                      Css.color(Css.hex(c)),
                      /* [] */0
                    ]);
        }));
  var baseClasses_001 = /* :: */[
    className,
    /* :: */[
      wrap,
      /* :: */[
        color$1,
        /* [] */0
      ]
    ]
  ];
  var baseClasses = /* :: */[
    align,
    baseClasses_001
  ];
  switch (variant) {
    case /* Heading1 */0 :
        var tmp = {
          className: Css.merge(/* :: */[
                heading1,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp.title = Caml_option.valFromOption(title);
        }
        return React.createElement("h1", tmp, children);
    case /* Heading2 */1 :
        var tmp$1 = {
          className: Css.merge(/* :: */[
                heading2,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$1.title = Caml_option.valFromOption(title);
        }
        return React.createElement("h2", tmp$1, children);
    case /* Heading3 */2 :
        var tmp$2 = {
          className: Css.merge(/* :: */[
                heading3,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$2.title = Caml_option.valFromOption(title);
        }
        return React.createElement("h3", tmp$2, children);
    case /* Heading4 */3 :
        var tmp$3 = {
          className: Css.merge(/* :: */[
                heading4,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$3.title = Caml_option.valFromOption(title);
        }
        return React.createElement("h4", tmp$3, children);
    case /* Heading5 */4 :
        var tmp$4 = {
          className: Css.merge(/* :: */[
                heading5,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$4.title = Caml_option.valFromOption(title);
        }
        return React.createElement("h5", tmp$4, children);
    case /* Heading6 */5 :
        var tmp$5 = {
          className: Css.merge(/* :: */[
                heading6,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$5.title = Caml_option.valFromOption(title);
        }
        return React.createElement("h6", tmp$5, children);
    case /* Subtitle1 */6 :
        var tmp$6 = {
          className: Css.merge(/* :: */[
                subtitle1,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$6.title = Caml_option.valFromOption(title);
        }
        return React.createElement("h6", tmp$6, children);
    case /* Subtitle2 */7 :
        var tmp$7 = {
          className: Css.merge(/* :: */[
                subtitle2,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$7.title = Caml_option.valFromOption(title);
        }
        return React.createElement("h6", tmp$7, children);
    case /* Body1 */8 :
        var tmp$8 = {
          className: Css.merge(/* :: */[
                body1,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$8.title = Caml_option.valFromOption(title);
        }
        return React.createElement("p", tmp$8, children);
    case /* Body2 */9 :
        var tmp$9 = {
          className: Css.merge(/* :: */[
                body2,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$9.title = Caml_option.valFromOption(title);
        }
        return React.createElement("p", tmp$9, children);
    case /* Button */10 :
    case /* TableTitle */13 :
        var tmp$10 = {
          className: Css.merge(/* :: */[
                button,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$10.title = Caml_option.valFromOption(title);
        }
        return React.createElement("span", tmp$10, children);
    case /* Caption */11 :
    case /* TableBody */14 :
        var tmp$11 = {
          className: Css.merge(/* :: */[
                caption,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$11.title = Caml_option.valFromOption(title);
        }
        return React.createElement("span", tmp$11, children);
    case /* Overline */12 :
    case /* Chip */15 :
        var tmp$12 = {
          className: Css.merge(/* :: */[
                overline,
                baseClasses
              ])
        };
        if (title !== undefined) {
          tmp$12.title = Caml_option.valFromOption(title);
        }
        return React.createElement("span", tmp$12, children);
    
  }
}

function make(variant, textAlignOpt, classNameOpt, noWrapOpt, color, title, children) {
  var textAlign = textAlignOpt !== undefined ? textAlignOpt : /* Inherit */3;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var noWrap = noWrapOpt !== undefined ? noWrapOpt : false;
  var tmp = {
    variant: variant,
    textAlign: textAlign,
    className: className,
    noWrap: noWrap,
    children: children
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  return ReasonReactCompat.wrapReactForReasonReact(BtTypography, tmp, children);
}

var Re = {
  make: make
};

var make$1 = BtTypography;

exports.font = font;
exports.heading1 = heading1;
exports.heading2 = heading2;
exports.heading3 = heading3;
exports.heading4 = heading4;
exports.heading5 = heading5;
exports.heading6 = heading6;
exports.subtitle1 = subtitle1;
exports.subtitle2 = subtitle2;
exports.body1 = body1;
exports.body2 = body2;
exports.button = button;
exports.caption = caption;
exports.overline = overline;
exports.tableTitle = tableTitle;
exports.tableBody = tableBody;
exports.chip = chip;
exports.make = make$1;
exports.Re = Re;
/* heading1 Not a pure module */
