// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Autocomplete$BsConsole = require("../components/Autocomplete.js");

function getLabel(attribute) {
  return attribute.name;
}

var AttributeAutocomplete = Autocomplete$BsConsole.Make({
      getLabel: getLabel
    });

var component = RR$BsConsole.statelessComponent("WfAttributeAutocomplete-BsConsole");

function make(attributes, value, onChange, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Curry._6(AttributeAutocomplete.make, undefined, attributes, value, onChange, (function (attr) {
                                return Curry._1(onChange, attr.name);
                              }), []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.AttributeAutocomplete = AttributeAutocomplete;
exports.component = component;
exports.make = make;
/* AttributeAutocomplete Not a pure module */
