// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Parse$BsConsole = require("./bs-crdb-response/src/Parse.js");
var Task2$BsConsole = require("./Task2.js");
var Unpack$BsConsole = require("./bs-crdb-response/src/Unpack.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var ResponseSelect$BsConsole = require("./bs-crdb-response/src/ResponseSelect.js");

var ParseError = Caml_exceptions.create("QueryPaginationTask-BsConsole.ParseError");

function makeBaseTask(queryJson, projectName, decodeResp) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + (String(projectName) + "")]), undefined, /* Post */Block.__(0, [queryJson]), undefined, decodeResp, undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === Task2$BsConsole.JsonDecodeError) {
    tmp = Belt_Option.getWithDefault(error[1], "JSONDecodeError");
  } else if (error[0] === Task2$BsConsole.UncaughtPromiseError) {
    tmp = Belt_Option.getWithDefault(JSON.stringify(error[1][0]), "UncaughtPromiseError");
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function Make(PA) {
  var makeTask = function (query, projectName, tx, page) {
    var queryJson = Curry._1(PA.encodeReq, Curry._2(PA.setPagination, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), Curry._2(PA.setTxFilter, tx, query)));
    return makeBaseTask(queryJson, projectName, Curry._1(PA.decodeResp, query));
  };
  var use = function (token, projectName, query, reportName) {
    var match = React.useState((function () {
            return query;
          }));
    var setQuery = match[1];
    var query$prime = match[0];
    var match$1 = React.useState((function () {
            return 0;
          }));
    var setPage = match$1[1];
    var page = match$1[0];
    var match$2 = React.useState((function () {
            return /* NotAsked */0;
          }));
    var setValue = match$2[1];
    var value = match$2[0];
    var match$3 = React.useState((function () {
            
          }));
    var setTx = match$3[1];
    var tx = match$3[0];
    var match$4 = React.useState((function () {
            return projectName;
          }));
    var setRetainedProjectName = match$4[1];
    var retainedProjectName = match$4[0];
    React.useEffect((function () {
            if (!Curry._2(PA.eq, query, query$prime)) {
              Curry._1(setQuery, (function (param) {
                      return query;
                    }));
              Curry._1(setPage, (function (param) {
                      return 0;
                    }));
              Curry._1(setValue, (function (remote) {
                      if (typeof remote === "number" || remote.tag) {
                        return /* NotAsked */0;
                      } else {
                        return /* Success */Block.__(0, [/* tuple */[
                                    remote[0][0],
                                    /* Loading */1
                                  ]]);
                      }
                    }));
            }
            
          }), [query]);
    React.useEffect((function () {
            if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
              Curry._1(setRetainedProjectName, (function (param) {
                      return projectName;
                    }));
              Curry._1(setPage, (function (param) {
                      return 0;
                    }));
              Curry._1(setTx, (function (param) {
                      
                    }));
              Curry._1(setValue, (function (param) {
                      return /* NotAsked */0;
                    }));
            }
            
          }), [projectName]);
    React.useEffect((function () {
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
            
          }), [page]);
    React.useEffect((function () {
            var onSuccess = function (resp) {
              var __x = Curry._1(PA.getFactor, query);
              MetricsEvent$BsConsole.send(/* QueryJsonWithAttrs */Block.__(6, [
                      reportName,
                      Curry._1(PA.encodePrivateJson, query),
                      Curry._1(PA.getTimes, resp),
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "query-count",
                              Belt_Option.getWithDefault(Curry._1(PA.getQueryCount, resp), 0)
                            ],
                            /* :: */[
                              /* tuple */[
                                "query-group",
                                JSON.stringify(Json_encode.nullable(Crdb$BsConsole.Factor.toJson, __x))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "query-granularity",
                                  Curry._1(Crdb$BsConsole.TimeAperture.getString, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(PA.getAperture, query)))
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "query-time-range-start",
                                    Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getStart, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(PA.getAperture, query))))
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "query-time-range-end",
                                      Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getFinish, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(PA.getAperture, query))))
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "query-full-private",
                                        JSON.stringify(Curry._1(PA.encodePrivateJson, query))
                                      ],
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    ]));
              Curry._1(setValue, (function (param) {
                      return /* Success */Block.__(0, [/* tuple */[
                                  resp,
                                  /* NotAsked */0
                                ]]);
                    }));
              return Curry._1(setTx, (function (param) {
                            return Curry._1(PA.getTx, resp);
                          }));
            };
            var onFailure = function (errorMessage) {
              return Curry._1(setValue, (function (param) {
                            return /* Failure */Block.__(1, [errorMessage]);
                          }));
            };
            var task = makeTask(query$prime, projectName, tx, page);
            if (typeof value === "number") {
              if (value === /* NotAsked */0) {
                Curry._1(setValue, (function (param) {
                        return /* Loading */1;
                      }));
              } else {
                Task2$BsConsole.handle(token, task, undefined, (function (param) {
                        return responseCb(onSuccess, onFailure, param);
                      }));
              }
            } else if (!value.tag) {
              var remote$prime = value[0][1];
              if (typeof remote$prime === "number" && remote$prime !== 0) {
                Task2$BsConsole.handle(token, task, undefined, (function (param) {
                        return responseCb(onSuccess, onFailure, param);
                      }));
              }
              
            }
            
          }), [value]);
    return /* tuple */[
            value,
            page,
            setPage
          ];
  };
  return {
          decodeResp: PA.decodeResp,
          makeTask: makeTask,
          use: use
        };
}

var getColumns = Parse$BsConsole.parseColumns;

function getRows(factor, columns, json) {
  return Parse$BsConsole.decodeExn(/* tuple */[
              Curry._1(Crdb$BsConsole.Factor.toCrdbString, factor),
              undefined
            ], columns, json);
}

function getMetadata(json) {
  return Parse$BsConsole.Aggregate.metadata(json);
}

var AggregateUtils = {
  getColumns: getColumns,
  getRows: getRows,
  getMetadata: getMetadata
};

var getColumns$1 = ResponseSelect$BsConsole.columns;

function getRows$1(columns, json) {
  return Unpack$BsConsole.response2PointOh(json, columns);
}

var getMetadata$1 = ResponseSelect$BsConsole.metadata;

var SelectUtils = {
  getColumns: getColumns$1,
  getRows: getRows$1,
  getMetadata: getMetadata$1
};

function MakeAggregate(Q) {
  var decodeResp = function (query, response) {
    try {
      var factor = Belt_Option.getWithDefault(Curry._1(Q.getFactor, query), /* Custom */["*"]);
      var columns = Parse$BsConsole.parseColumns(response.json);
      var arg = response.json;
      var rows = (function (param) {
              return (function (param$1) {
                  return getRows(param, param$1, arg);
                });
            })(factor)(columns);
      var metadata = Parse$BsConsole.Aggregate.metadata(response.json);
      return /* Ok */Block.__(0, [/* tuple */[
                  columns,
                  rows,
                  metadata,
                  query
                ]]);
    }
    catch (raw_err){
      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
      var error = Uncaught$BsConsole.castToJsExn(err);
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "QueryPaginationTask/MakeAggregate.decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `exn */[
            5049499,
            error
          ]);
      return /* Error */Block.__(1, [[
                  ParseError,
                  error
                ]]);
    }
  };
  var encodeReq = Q.encodeReq;
  var encodePrivateJson = Q.encodePrivateJson;
  var setTxFilter = Q.setTxFilter;
  var setPagination = Q.setPagination;
  var getTx = function (param) {
    return param[2].tx;
  };
  var getTimes = function (param) {
    return param[2].times;
  };
  var eq = Q.eq;
  var getFactor = Q.getFactor;
  var getAperture = Q.getAperture;
  var getQueryCount = function (param) {
    return param[2].instances;
  };
  var makeTask = function (query, projectName, tx, page) {
    var queryJson = Curry._1(encodeReq, Curry._2(setPagination, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), Curry._2(setTxFilter, tx, query)));
    return makeBaseTask(queryJson, projectName, (function (param) {
                  return decodeResp(query, param);
                }));
  };
  var use = function (token, projectName, query, reportName) {
    var match = React.useState((function () {
            return query;
          }));
    var setQuery = match[1];
    var query$prime = match[0];
    var match$1 = React.useState((function () {
            return 0;
          }));
    var setPage = match$1[1];
    var page = match$1[0];
    var match$2 = React.useState((function () {
            return /* NotAsked */0;
          }));
    var setValue = match$2[1];
    var value = match$2[0];
    var match$3 = React.useState((function () {
            
          }));
    var setTx = match$3[1];
    var tx = match$3[0];
    var match$4 = React.useState((function () {
            return projectName;
          }));
    var setRetainedProjectName = match$4[1];
    var retainedProjectName = match$4[0];
    React.useEffect((function () {
            if (!Curry._2(eq, query, query$prime)) {
              Curry._1(setQuery, (function (param) {
                      return query;
                    }));
              Curry._1(setPage, (function (param) {
                      return 0;
                    }));
              Curry._1(setValue, (function (remote) {
                      if (typeof remote === "number" || remote.tag) {
                        return /* NotAsked */0;
                      } else {
                        return /* Success */Block.__(0, [/* tuple */[
                                    remote[0][0],
                                    /* Loading */1
                                  ]]);
                      }
                    }));
            }
            
          }), [query]);
    React.useEffect((function () {
            if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
              Curry._1(setRetainedProjectName, (function (param) {
                      return projectName;
                    }));
              Curry._1(setPage, (function (param) {
                      return 0;
                    }));
              Curry._1(setTx, (function (param) {
                      
                    }));
              Curry._1(setValue, (function (param) {
                      return /* NotAsked */0;
                    }));
            }
            
          }), [projectName]);
    React.useEffect((function () {
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
            
          }), [page]);
    React.useEffect((function () {
            var onSuccess = function (resp) {
              var __x = Curry._1(getFactor, query);
              MetricsEvent$BsConsole.send(/* QueryJsonWithAttrs */Block.__(6, [
                      reportName,
                      Curry._1(encodePrivateJson, query),
                      getTimes(resp),
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "query-count",
                              Belt_Option.getWithDefault(getQueryCount(resp), 0)
                            ],
                            /* :: */[
                              /* tuple */[
                                "query-group",
                                JSON.stringify(Json_encode.nullable(Crdb$BsConsole.Factor.toJson, __x))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "query-granularity",
                                  Curry._1(Crdb$BsConsole.TimeAperture.getString, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture, query)))
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "query-time-range-start",
                                    Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getStart, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture, query))))
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "query-time-range-end",
                                      Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getFinish, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture, query))))
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "query-full-private",
                                        JSON.stringify(Curry._1(encodePrivateJson, query))
                                      ],
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    ]));
              Curry._1(setValue, (function (param) {
                      return /* Success */Block.__(0, [/* tuple */[
                                  resp,
                                  /* NotAsked */0
                                ]]);
                    }));
              return Curry._1(setTx, (function (param) {
                            return getTx(resp);
                          }));
            };
            var onFailure = function (errorMessage) {
              return Curry._1(setValue, (function (param) {
                            return /* Failure */Block.__(1, [errorMessage]);
                          }));
            };
            var task = makeTask(query$prime, projectName, tx, page);
            if (typeof value === "number") {
              if (value === /* NotAsked */0) {
                Curry._1(setValue, (function (param) {
                        return /* Loading */1;
                      }));
              } else {
                Task2$BsConsole.handle(token, task, undefined, (function (param) {
                        return responseCb(onSuccess, onFailure, param);
                      }));
              }
            } else if (!value.tag) {
              var remote$prime = value[0][1];
              if (typeof remote$prime === "number" && remote$prime !== 0) {
                Task2$BsConsole.handle(token, task, undefined, (function (param) {
                        return responseCb(onSuccess, onFailure, param);
                      }));
              }
              
            }
            
          }), [value]);
    return /* tuple */[
            value,
            page,
            setPage
          ];
  };
  return {
          decodeResp: decodeResp,
          makeTask: makeTask,
          use: use
        };
}

function MakeSelect(Q) {
  var decodeResp = function (query, response) {
    try {
      var columns = ResponseSelect$BsConsole.columns(response.json);
      var arg = response.json;
      var rows = (function (param) {
            return Unpack$BsConsole.response2PointOh(arg, param);
          })(columns);
      var metadata = ResponseSelect$BsConsole.metadata(response.json);
      return /* Ok */Block.__(0, [/* tuple */[
                  columns,
                  rows,
                  metadata,
                  query
                ]]);
    }
    catch (raw_err){
      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
      var error = Uncaught$BsConsole.castToJsExn(err);
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "QueryPaginationTask/MakeSelect.decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `exn */[
            5049499,
            error
          ]);
      return /* Error */Block.__(1, [[
                  ParseError,
                  error
                ]]);
    }
  };
  var encodeReq = Q.encodeReq;
  var encodePrivateJson = Q.encodePrivateJson;
  var setTxFilter = Q.setTxFilter;
  var setPagination = Q.setPagination;
  var getTx = function (param) {
    return param[2].tx;
  };
  var getTimes = function (param) {
    return param[2].times;
  };
  var eq = Q.eq;
  var getFactor = Q.getFactor;
  var getAperture = Q.getAperture;
  var getQueryCount = function (param) {
    return param[2].instances;
  };
  var makeTask = function (query, projectName, tx, page) {
    var queryJson = Curry._1(encodeReq, Curry._2(setPagination, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), Curry._2(setTxFilter, tx, query)));
    return makeBaseTask(queryJson, projectName, (function (param) {
                  return decodeResp(query, param);
                }));
  };
  var use = function (token, projectName, query, reportName) {
    var match = React.useState((function () {
            return query;
          }));
    var setQuery = match[1];
    var query$prime = match[0];
    var match$1 = React.useState((function () {
            return 0;
          }));
    var setPage = match$1[1];
    var page = match$1[0];
    var match$2 = React.useState((function () {
            return /* NotAsked */0;
          }));
    var setValue = match$2[1];
    var value = match$2[0];
    var match$3 = React.useState((function () {
            
          }));
    var setTx = match$3[1];
    var tx = match$3[0];
    var match$4 = React.useState((function () {
            return projectName;
          }));
    var setRetainedProjectName = match$4[1];
    var retainedProjectName = match$4[0];
    React.useEffect((function () {
            if (!Curry._2(eq, query, query$prime)) {
              Curry._1(setQuery, (function (param) {
                      return query;
                    }));
              Curry._1(setPage, (function (param) {
                      return 0;
                    }));
              Curry._1(setValue, (function (remote) {
                      if (typeof remote === "number" || remote.tag) {
                        return /* NotAsked */0;
                      } else {
                        return /* Success */Block.__(0, [/* tuple */[
                                    remote[0][0],
                                    /* Loading */1
                                  ]]);
                      }
                    }));
            }
            
          }), [query]);
    React.useEffect((function () {
            if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
              Curry._1(setRetainedProjectName, (function (param) {
                      return projectName;
                    }));
              Curry._1(setPage, (function (param) {
                      return 0;
                    }));
              Curry._1(setTx, (function (param) {
                      
                    }));
              Curry._1(setValue, (function (param) {
                      return /* NotAsked */0;
                    }));
            }
            
          }), [projectName]);
    React.useEffect((function () {
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
            
          }), [page]);
    React.useEffect((function () {
            var onSuccess = function (resp) {
              var __x = Curry._1(getFactor, query);
              MetricsEvent$BsConsole.send(/* QueryJsonWithAttrs */Block.__(6, [
                      reportName,
                      Curry._1(encodePrivateJson, query),
                      getTimes(resp),
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "query-count",
                              Belt_Option.getWithDefault(getQueryCount(resp), 0)
                            ],
                            /* :: */[
                              /* tuple */[
                                "query-group",
                                JSON.stringify(Json_encode.nullable(Crdb$BsConsole.Factor.toJson, __x))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "query-granularity",
                                  Curry._1(Crdb$BsConsole.TimeAperture.getString, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture, query)))
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "query-time-range-start",
                                    Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getStart, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture, query))))
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "query-time-range-end",
                                      Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getFinish, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture, query))))
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "query-full-private",
                                        JSON.stringify(Curry._1(encodePrivateJson, query))
                                      ],
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    ]));
              Curry._1(setValue, (function (param) {
                      return /* Success */Block.__(0, [/* tuple */[
                                  resp,
                                  /* NotAsked */0
                                ]]);
                    }));
              return Curry._1(setTx, (function (param) {
                            return getTx(resp);
                          }));
            };
            var onFailure = function (errorMessage) {
              return Curry._1(setValue, (function (param) {
                            return /* Failure */Block.__(1, [errorMessage]);
                          }));
            };
            var task = makeTask(query$prime, projectName, tx, page);
            if (typeof value === "number") {
              if (value === /* NotAsked */0) {
                Curry._1(setValue, (function (param) {
                        return /* Loading */1;
                      }));
              } else {
                Task2$BsConsole.handle(token, task, undefined, (function (param) {
                        return responseCb(onSuccess, onFailure, param);
                      }));
              }
            } else if (!value.tag) {
              var remote$prime = value[0][1];
              if (typeof remote$prime === "number" && remote$prime !== 0) {
                Task2$BsConsole.handle(token, task, undefined, (function (param) {
                        return responseCb(onSuccess, onFailure, param);
                      }));
              }
              
            }
            
          }), [value]);
    return /* tuple */[
            value,
            page,
            setPage
          ];
  };
  return {
          decodeResp: decodeResp,
          makeTask: makeTask,
          use: use
        };
}

var encodeReq = Crdb$BsConsole.QueryManager.toCrdb;

var encodePrivateJson = Crdb$BsConsole.Query.toPrivateJson;

var setPagination = Crdb$BsConsole.Query.setPagination;

function setTxFilter(tx, query) {
  if (tx === undefined) {
    return query;
  }
  var ap = Curry._1(Crdb$BsConsole.Query.getAperture, query);
  var filters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
            "_tx",
            /* AtMost */Block.__(9, [/* `ObjectID */[
                  -589436806,
                  tx
                ]])
          ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, ap));
  var __x = Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, ap);
  return Curry._2(Crdb$BsConsole.Query.setAperture, __x, query);
}

var eq = Crdb$BsConsole.Query.eq;

var getFactor = Crdb$BsConsole.Query.getFactor;

var getAperture = Crdb$BsConsole.Query.getAperture;

function decodeResp(query, response) {
  try {
    var factor = Belt_Option.getWithDefault(Curry._1(getFactor, query), /* Custom */["*"]);
    var columns = Parse$BsConsole.parseColumns(response.json);
    var arg = response.json;
    var rows = (function (param) {
            return (function (param$1) {
                return getRows(param, param$1, arg);
              });
          })(factor)(columns);
    var metadata = Parse$BsConsole.Aggregate.metadata(response.json);
    return /* Ok */Block.__(0, [/* tuple */[
                columns,
                rows,
                metadata,
                query
              ]]);
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "QueryPaginationTask/MakeAggregate.decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */Block.__(1, [[
                ParseError,
                error
              ]]);
  }
}

function getTx(param) {
  return param[2].tx;
}

function getTimes(param) {
  return param[2].times;
}

function getQueryCount(param) {
  return param[2].instances;
}

function makeTask(query, projectName, tx, page) {
  var queryJson = Curry._1(encodeReq, Curry._2(setPagination, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), setTxFilter(tx, query)));
  return makeBaseTask(queryJson, projectName, (function (param) {
                return decodeResp(query, param);
              }));
}

function use(token, projectName, query, reportName) {
  var match = React.useState((function () {
          return query;
        }));
  var setQuery = match[1];
  var query$prime = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setValue = match$2[1];
  var value = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setTx = match$3[1];
  var tx = match$3[0];
  var match$4 = React.useState((function () {
          return projectName;
        }));
  var setRetainedProjectName = match$4[1];
  var retainedProjectName = match$4[0];
  React.useEffect((function () {
          if (!Curry._2(eq, query, query$prime)) {
            Curry._1(setQuery, (function (param) {
                    return query;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
          }
          
        }), [query]);
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
            Curry._1(setRetainedProjectName, (function (param) {
                    return projectName;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setTx, (function (param) {
                    
                  }));
            Curry._1(setValue, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [projectName]);
  React.useEffect((function () {
          Curry._1(setValue, (function (remote) {
                  if (typeof remote === "number" || remote.tag) {
                    return /* NotAsked */0;
                  } else {
                    return /* Success */Block.__(0, [/* tuple */[
                                remote[0][0],
                                /* Loading */1
                              ]]);
                  }
                }));
          
        }), [page]);
  React.useEffect((function () {
          var onSuccess = function (resp) {
            var __x = Curry._1(getFactor, query);
            MetricsEvent$BsConsole.send(/* QueryJsonWithAttrs */Block.__(6, [
                    reportName,
                    Curry._1(encodePrivateJson, query),
                    getTimes(resp),
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "query-count",
                            Belt_Option.getWithDefault(getQueryCount(resp), 0)
                          ],
                          /* :: */[
                            /* tuple */[
                              "query-group",
                              JSON.stringify(Json_encode.nullable(Crdb$BsConsole.Factor.toJson, __x))
                            ],
                            /* :: */[
                              /* tuple */[
                                "query-granularity",
                                Curry._1(Crdb$BsConsole.TimeAperture.getString, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture, query)))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "query-time-range-start",
                                  Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getStart, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture, query))))
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "query-time-range-end",
                                    Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getFinish, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture, query))))
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "query-full-private",
                                      JSON.stringify(Curry._1(encodePrivateJson, query))
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  ]));
            Curry._1(setValue, (function (param) {
                    return /* Success */Block.__(0, [/* tuple */[
                                resp,
                                /* NotAsked */0
                              ]]);
                  }));
            return Curry._1(setTx, (function (param) {
                          return getTx(resp);
                        }));
          };
          var onFailure = function (errorMessage) {
            return Curry._1(setValue, (function (param) {
                          return /* Failure */Block.__(1, [errorMessage]);
                        }));
          };
          var task = makeTask(query$prime, projectName, tx, page);
          if (typeof value === "number") {
            if (value === /* NotAsked */0) {
              Curry._1(setValue, (function (param) {
                      return /* Loading */1;
                    }));
            } else {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
          } else if (!value.tag) {
            var remote$prime = value[0][1];
            if (typeof remote$prime === "number" && remote$prime !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          }
          
        }), [value]);
  return /* tuple */[
          value,
          page,
          setPage
        ];
}

var CrdbAggregate = {
  decodeResp: decodeResp,
  makeTask: makeTask,
  use: use
};

var encodeReq$1 = Crdb$BsConsole.Query.toCrdb_;

var encodePrivateJson$1 = Crdb$BsConsole.Query.toPrivateJson;

var setPagination$1 = Crdb$BsConsole.Query.setPagination;

function setTxFilter$1(tx, query) {
  if (tx === undefined) {
    return query;
  }
  var ap = Curry._1(Crdb$BsConsole.Query.getAperture, query);
  var filters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
            "_tx",
            /* AtMost */Block.__(9, [/* `ObjectID */[
                  -589436806,
                  tx
                ]])
          ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, ap));
  var __x = Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, ap);
  return Curry._2(Crdb$BsConsole.Query.setAperture, __x, query);
}

var eq$1 = Crdb$BsConsole.Query.eq;

var getFactor$1 = Crdb$BsConsole.Query.getFactor;

var getAperture$1 = Crdb$BsConsole.Query.getAperture;

function decodeResp$1(query, response) {
  try {
    var columns = ResponseSelect$BsConsole.columns(response.json);
    var arg = response.json;
    var rows = (function (param) {
          return Unpack$BsConsole.response2PointOh(arg, param);
        })(columns);
    var metadata = ResponseSelect$BsConsole.metadata(response.json);
    return /* Ok */Block.__(0, [/* tuple */[
                columns,
                rows,
                metadata,
                query
              ]]);
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "QueryPaginationTask/MakeSelect.decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */Block.__(1, [[
                ParseError,
                error
              ]]);
  }
}

function getTx$1(param) {
  return param[2].tx;
}

function getTimes$1(param) {
  return param[2].times;
}

function getQueryCount$1(param) {
  return param[2].instances;
}

function makeTask$1(query, projectName, tx, page) {
  var queryJson = Curry._1(encodeReq$1, Curry._2(setPagination$1, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), setTxFilter$1(tx, query)));
  return makeBaseTask(queryJson, projectName, (function (param) {
                return decodeResp$1(query, param);
              }));
}

function use$1(token, projectName, query, reportName) {
  var match = React.useState((function () {
          return query;
        }));
  var setQuery = match[1];
  var query$prime = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setValue = match$2[1];
  var value = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setTx = match$3[1];
  var tx = match$3[0];
  var match$4 = React.useState((function () {
          return projectName;
        }));
  var setRetainedProjectName = match$4[1];
  var retainedProjectName = match$4[0];
  React.useEffect((function () {
          if (!Curry._2(eq$1, query, query$prime)) {
            Curry._1(setQuery, (function (param) {
                    return query;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
          }
          
        }), [query]);
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
            Curry._1(setRetainedProjectName, (function (param) {
                    return projectName;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setTx, (function (param) {
                    
                  }));
            Curry._1(setValue, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [projectName]);
  React.useEffect((function () {
          Curry._1(setValue, (function (remote) {
                  if (typeof remote === "number" || remote.tag) {
                    return /* NotAsked */0;
                  } else {
                    return /* Success */Block.__(0, [/* tuple */[
                                remote[0][0],
                                /* Loading */1
                              ]]);
                  }
                }));
          
        }), [page]);
  React.useEffect((function () {
          var onSuccess = function (resp) {
            var __x = Curry._1(getFactor$1, query);
            MetricsEvent$BsConsole.send(/* QueryJsonWithAttrs */Block.__(6, [
                    reportName,
                    Curry._1(encodePrivateJson$1, query),
                    getTimes$1(resp),
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "query-count",
                            Belt_Option.getWithDefault(getQueryCount$1(resp), 0)
                          ],
                          /* :: */[
                            /* tuple */[
                              "query-group",
                              JSON.stringify(Json_encode.nullable(Crdb$BsConsole.Factor.toJson, __x))
                            ],
                            /* :: */[
                              /* tuple */[
                                "query-granularity",
                                Curry._1(Crdb$BsConsole.TimeAperture.getString, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$1, query)))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "query-time-range-start",
                                  Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getStart, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$1, query))))
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "query-time-range-end",
                                    Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getFinish, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$1, query))))
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "query-full-private",
                                      JSON.stringify(Curry._1(encodePrivateJson$1, query))
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  ]));
            Curry._1(setValue, (function (param) {
                    return /* Success */Block.__(0, [/* tuple */[
                                resp,
                                /* NotAsked */0
                              ]]);
                  }));
            return Curry._1(setTx, (function (param) {
                          return getTx$1(resp);
                        }));
          };
          var onFailure = function (errorMessage) {
            return Curry._1(setValue, (function (param) {
                          return /* Failure */Block.__(1, [errorMessage]);
                        }));
          };
          var task = makeTask$1(query$prime, projectName, tx, page);
          if (typeof value === "number") {
            if (value === /* NotAsked */0) {
              Curry._1(setValue, (function (param) {
                      return /* Loading */1;
                    }));
            } else {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
          } else if (!value.tag) {
            var remote$prime = value[0][1];
            if (typeof remote$prime === "number" && remote$prime !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          }
          
        }), [value]);
  return /* tuple */[
          value,
          page,
          setPage
        ];
}

var CrdbSelect = {
  decodeResp: decodeResp$1,
  makeTask: makeTask$1,
  use: use$1
};

var encodeReq$2 = Crdb$BsConsole.MetricsQuery.toCrdb;

var encodePrivateJson$2 = Crdb$BsConsole.MetricsQuery.toPrivateJson;

var setPagination$2 = Crdb$BsConsole.MetricsQuery.setPagination;

var eq$2 = Crdb$BsConsole.MetricsQuery.eq;

var getFactor$2 = Crdb$BsConsole.MetricsQuery.getFactor;

var getAperture$2 = Crdb$BsConsole.MetricsQuery.getAperture;

function decodeResp$2(query, response) {
  try {
    var factor = Belt_Option.getWithDefault(Curry._1(getFactor$2, query), /* Custom */["*"]);
    var columns = Parse$BsConsole.parseColumns(response.json);
    var arg = response.json;
    var rows = (function (param) {
            return (function (param$1) {
                return getRows(param, param$1, arg);
              });
          })(factor)(columns);
    var metadata = Parse$BsConsole.Aggregate.metadata(response.json);
    return /* Ok */Block.__(0, [/* tuple */[
                columns,
                rows,
                metadata,
                query
              ]]);
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "QueryPaginationTask/MakeAggregate.decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */Block.__(1, [[
                ParseError,
                error
              ]]);
  }
}

function getTx$2(param) {
  return param[2].tx;
}

function getTimes$2(param) {
  return param[2].times;
}

function getQueryCount$2(param) {
  return param[2].instances;
}

function makeTask$2(query, projectName, tx, page) {
  var queryJson = Curry._1(encodeReq$2, Curry._2(setPagination$2, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), query));
  return makeBaseTask(queryJson, projectName, (function (param) {
                return decodeResp$2(query, param);
              }));
}

function use$2(token, projectName, query, reportName) {
  var match = React.useState((function () {
          return query;
        }));
  var setQuery = match[1];
  var query$prime = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setValue = match$2[1];
  var value = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setTx = match$3[1];
  var tx = match$3[0];
  var match$4 = React.useState((function () {
          return projectName;
        }));
  var setRetainedProjectName = match$4[1];
  var retainedProjectName = match$4[0];
  React.useEffect((function () {
          if (!Curry._2(eq$2, query, query$prime)) {
            Curry._1(setQuery, (function (param) {
                    return query;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
          }
          
        }), [query]);
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
            Curry._1(setRetainedProjectName, (function (param) {
                    return projectName;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setTx, (function (param) {
                    
                  }));
            Curry._1(setValue, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [projectName]);
  React.useEffect((function () {
          Curry._1(setValue, (function (remote) {
                  if (typeof remote === "number" || remote.tag) {
                    return /* NotAsked */0;
                  } else {
                    return /* Success */Block.__(0, [/* tuple */[
                                remote[0][0],
                                /* Loading */1
                              ]]);
                  }
                }));
          
        }), [page]);
  React.useEffect((function () {
          var onSuccess = function (resp) {
            var __x = Curry._1(getFactor$2, query);
            MetricsEvent$BsConsole.send(/* QueryJsonWithAttrs */Block.__(6, [
                    reportName,
                    Curry._1(encodePrivateJson$2, query),
                    getTimes$2(resp),
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "query-count",
                            Belt_Option.getWithDefault(getQueryCount$2(resp), 0)
                          ],
                          /* :: */[
                            /* tuple */[
                              "query-group",
                              JSON.stringify(Json_encode.nullable(Crdb$BsConsole.Factor.toJson, __x))
                            ],
                            /* :: */[
                              /* tuple */[
                                "query-granularity",
                                Curry._1(Crdb$BsConsole.TimeAperture.getString, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$2, query)))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "query-time-range-start",
                                  Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getStart, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$2, query))))
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "query-time-range-end",
                                    Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getFinish, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$2, query))))
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "query-full-private",
                                      JSON.stringify(Curry._1(encodePrivateJson$2, query))
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  ]));
            Curry._1(setValue, (function (param) {
                    return /* Success */Block.__(0, [/* tuple */[
                                resp,
                                /* NotAsked */0
                              ]]);
                  }));
            return Curry._1(setTx, (function (param) {
                          return getTx$2(resp);
                        }));
          };
          var onFailure = function (errorMessage) {
            return Curry._1(setValue, (function (param) {
                          return /* Failure */Block.__(1, [errorMessage]);
                        }));
          };
          var task = makeTask$2(query$prime, projectName, tx, page);
          if (typeof value === "number") {
            if (value === /* NotAsked */0) {
              Curry._1(setValue, (function (param) {
                      return /* Loading */1;
                    }));
            } else {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
          } else if (!value.tag) {
            var remote$prime = value[0][1];
            if (typeof remote$prime === "number" && remote$prime !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          }
          
        }), [value]);
  return /* tuple */[
          value,
          page,
          setPage
        ];
}

var MetricsAggregate = {
  decodeResp: decodeResp$2,
  makeTask: makeTask$2,
  use: use$2
};

var encodeReq$3 = Crdb$BsConsole.UniqueAggregationsQuery.toCrdb;

var encodePrivateJson$3 = Crdb$BsConsole.UniqueAggregationsQuery.toPrivateJson;

var setPagination$3 = Crdb$BsConsole.UniqueAggregationsQuery.setPagination;

var eq$3 = Crdb$BsConsole.UniqueAggregationsQuery.eq;

var getFactor$3 = Crdb$BsConsole.UniqueAggregationsQuery.getFactor;

var getAperture$3 = Crdb$BsConsole.UniqueAggregationsQuery.getAperture;

function decodeResp$3(query, response) {
  try {
    var columns = ResponseSelect$BsConsole.columns(response.json);
    var arg = response.json;
    var rows = (function (param) {
          return Unpack$BsConsole.response2PointOh(arg, param);
        })(columns);
    var metadata = ResponseSelect$BsConsole.metadata(response.json);
    return /* Ok */Block.__(0, [/* tuple */[
                columns,
                rows,
                metadata,
                query
              ]]);
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "QueryPaginationTask/MakeSelect.decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */Block.__(1, [[
                ParseError,
                error
              ]]);
  }
}

function getTx$3(param) {
  return param[2].tx;
}

function getTimes$3(param) {
  return param[2].times;
}

function getQueryCount$3(param) {
  return param[2].instances;
}

function makeTask$3(query, projectName, tx, page) {
  var queryJson = Curry._1(encodeReq$3, Curry._2(setPagination$3, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), query));
  return makeBaseTask(queryJson, projectName, (function (param) {
                return decodeResp$3(query, param);
              }));
}

function use$3(token, projectName, query, reportName) {
  var match = React.useState((function () {
          return query;
        }));
  var setQuery = match[1];
  var query$prime = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setValue = match$2[1];
  var value = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setTx = match$3[1];
  var tx = match$3[0];
  var match$4 = React.useState((function () {
          return projectName;
        }));
  var setRetainedProjectName = match$4[1];
  var retainedProjectName = match$4[0];
  React.useEffect((function () {
          if (!Curry._2(eq$3, query, query$prime)) {
            Curry._1(setQuery, (function (param) {
                    return query;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
          }
          
        }), [query]);
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
            Curry._1(setRetainedProjectName, (function (param) {
                    return projectName;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setTx, (function (param) {
                    
                  }));
            Curry._1(setValue, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [projectName]);
  React.useEffect((function () {
          Curry._1(setValue, (function (remote) {
                  if (typeof remote === "number" || remote.tag) {
                    return /* NotAsked */0;
                  } else {
                    return /* Success */Block.__(0, [/* tuple */[
                                remote[0][0],
                                /* Loading */1
                              ]]);
                  }
                }));
          
        }), [page]);
  React.useEffect((function () {
          var onSuccess = function (resp) {
            var __x = Curry._1(getFactor$3, query);
            MetricsEvent$BsConsole.send(/* QueryJsonWithAttrs */Block.__(6, [
                    reportName,
                    Curry._1(encodePrivateJson$3, query),
                    getTimes$3(resp),
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "query-count",
                            Belt_Option.getWithDefault(getQueryCount$3(resp), 0)
                          ],
                          /* :: */[
                            /* tuple */[
                              "query-group",
                              JSON.stringify(Json_encode.nullable(Crdb$BsConsole.Factor.toJson, __x))
                            ],
                            /* :: */[
                              /* tuple */[
                                "query-granularity",
                                Curry._1(Crdb$BsConsole.TimeAperture.getString, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$3, query)))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "query-time-range-start",
                                  Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getStart, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$3, query))))
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "query-time-range-end",
                                    Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getFinish, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$3, query))))
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "query-full-private",
                                      JSON.stringify(Curry._1(encodePrivateJson$3, query))
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  ]));
            Curry._1(setValue, (function (param) {
                    return /* Success */Block.__(0, [/* tuple */[
                                resp,
                                /* NotAsked */0
                              ]]);
                  }));
            return Curry._1(setTx, (function (param) {
                          return getTx$3(resp);
                        }));
          };
          var onFailure = function (errorMessage) {
            return Curry._1(setValue, (function (param) {
                          return /* Failure */Block.__(1, [errorMessage]);
                        }));
          };
          var task = makeTask$3(query$prime, projectName, tx, page);
          if (typeof value === "number") {
            if (value === /* NotAsked */0) {
              Curry._1(setValue, (function (param) {
                      return /* Loading */1;
                    }));
            } else {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
          } else if (!value.tag) {
            var remote$prime = value[0][1];
            if (typeof remote$prime === "number" && remote$prime !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          }
          
        }), [value]);
  return /* tuple */[
          value,
          page,
          setPage
        ];
}

var UniqueAggregateSelect = {
  decodeResp: decodeResp$3,
  makeTask: makeTask$3,
  use: use$3
};

var encodeReq$4 = Crdb$BsConsole.UniqueAggregationsQuery.toCrdb;

var encodePrivateJson$4 = Crdb$BsConsole.UniqueAggregationsQuery.toPrivateJson;

var setPagination$4 = Crdb$BsConsole.UniqueAggregationsQuery.setPagination;

var eq$4 = Crdb$BsConsole.UniqueAggregationsQuery.eq;

var getFactor$4 = Crdb$BsConsole.UniqueAggregationsQuery.getFactor;

var getAperture$4 = Crdb$BsConsole.UniqueAggregationsQuery.getAperture;

function decodeResp$4(query, response) {
  try {
    var factor = Belt_Option.getWithDefault(Curry._1(getFactor$4, query), /* Custom */["*"]);
    var columns = Parse$BsConsole.parseColumns(response.json);
    var arg = response.json;
    var rows = (function (param) {
            return (function (param$1) {
                return getRows(param, param$1, arg);
              });
          })(factor)(columns);
    var metadata = Parse$BsConsole.Aggregate.metadata(response.json);
    return /* Ok */Block.__(0, [/* tuple */[
                columns,
                rows,
                metadata,
                query
              ]]);
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "QueryPaginationTask/MakeAggregate.decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */Block.__(1, [[
                ParseError,
                error
              ]]);
  }
}

function getTx$4(param) {
  return param[2].tx;
}

function getTimes$4(param) {
  return param[2].times;
}

function getQueryCount$4(param) {
  return param[2].instances;
}

function makeTask$4(query, projectName, tx, page) {
  var queryJson = Curry._1(encodeReq$4, Curry._2(setPagination$4, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), query));
  return makeBaseTask(queryJson, projectName, (function (param) {
                return decodeResp$4(query, param);
              }));
}

function use$4(token, projectName, query, reportName) {
  var match = React.useState((function () {
          return query;
        }));
  var setQuery = match[1];
  var query$prime = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setValue = match$2[1];
  var value = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setTx = match$3[1];
  var tx = match$3[0];
  var match$4 = React.useState((function () {
          return projectName;
        }));
  var setRetainedProjectName = match$4[1];
  var retainedProjectName = match$4[0];
  React.useEffect((function () {
          if (!Curry._2(eq$4, query, query$prime)) {
            Curry._1(setQuery, (function (param) {
                    return query;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
          }
          
        }), [query]);
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
            Curry._1(setRetainedProjectName, (function (param) {
                    return projectName;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setTx, (function (param) {
                    
                  }));
            Curry._1(setValue, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [projectName]);
  React.useEffect((function () {
          Curry._1(setValue, (function (remote) {
                  if (typeof remote === "number" || remote.tag) {
                    return /* NotAsked */0;
                  } else {
                    return /* Success */Block.__(0, [/* tuple */[
                                remote[0][0],
                                /* Loading */1
                              ]]);
                  }
                }));
          
        }), [page]);
  React.useEffect((function () {
          var onSuccess = function (resp) {
            var __x = Curry._1(getFactor$4, query);
            MetricsEvent$BsConsole.send(/* QueryJsonWithAttrs */Block.__(6, [
                    reportName,
                    Curry._1(encodePrivateJson$4, query),
                    getTimes$4(resp),
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "query-count",
                            Belt_Option.getWithDefault(getQueryCount$4(resp), 0)
                          ],
                          /* :: */[
                            /* tuple */[
                              "query-group",
                              JSON.stringify(Json_encode.nullable(Crdb$BsConsole.Factor.toJson, __x))
                            ],
                            /* :: */[
                              /* tuple */[
                                "query-granularity",
                                Curry._1(Crdb$BsConsole.TimeAperture.getString, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$4, query)))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "query-time-range-start",
                                  Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getStart, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$4, query))))
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "query-time-range-end",
                                    Json_encode.date(Curry._1(Crdb$BsConsole.TimeAperture.getFinish, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(getAperture$4, query))))
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "query-full-private",
                                      JSON.stringify(Curry._1(encodePrivateJson$4, query))
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  ]));
            Curry._1(setValue, (function (param) {
                    return /* Success */Block.__(0, [/* tuple */[
                                resp,
                                /* NotAsked */0
                              ]]);
                  }));
            return Curry._1(setTx, (function (param) {
                          return getTx$4(resp);
                        }));
          };
          var onFailure = function (errorMessage) {
            return Curry._1(setValue, (function (param) {
                          return /* Failure */Block.__(1, [errorMessage]);
                        }));
          };
          var task = makeTask$4(query$prime, projectName, tx, page);
          if (typeof value === "number") {
            if (value === /* NotAsked */0) {
              Curry._1(setValue, (function (param) {
                      return /* Loading */1;
                    }));
            } else {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
          } else if (!value.tag) {
            var remote$prime = value[0][1];
            if (typeof remote$prime === "number" && remote$prime !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          }
          
        }), [value]);
  return /* tuple */[
          value,
          page,
          setPage
        ];
}

var UniqueAggregationsAggregate = {
  decodeResp: decodeResp$4,
  makeTask: makeTask$4,
  use: use$4
};

exports.ParseError = ParseError;
exports.makeBaseTask = makeBaseTask;
exports.responseCb = responseCb;
exports.Make = Make;
exports.AggregateUtils = AggregateUtils;
exports.SelectUtils = SelectUtils;
exports.MakeAggregate = MakeAggregate;
exports.MakeSelect = MakeSelect;
exports.CrdbAggregate = CrdbAggregate;
exports.CrdbSelect = CrdbSelect;
exports.MetricsAggregate = MetricsAggregate;
exports.UniqueAggregateSelect = UniqueAggregateSelect;
exports.UniqueAggregationsAggregate = UniqueAggregationsAggregate;
/* react Not a pure module */
