// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReactWindow = require("react-window");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BugStyles$BsConsole = require("./BugStyles.js");

var col1 = Css.style(/* :: */[
      Css.textAlign(/* right */-379319332),
      /* :: */[
        Css.width(Css.px(115)),
        /* :: */[
          Css.flexShrink(0),
          /* [] */0
        ]
      ]
    ]);

var col3 = Css.style(/* :: */[
      Css.paddingLeft(Css.px(16)),
      /* :: */[
        Css.width(Css.px(70)),
        /* :: */[
          Css.flexShrink(0),
          /* [] */0
        ]
      ]
    ]);

var col4 = Css.style(/* :: */[
      Css.width(Css.auto),
      /* :: */[
        Css.whiteSpace(Css.nowrap),
        /* [] */0
      ]
    ]);

var Styles = {
  col1: col1,
  col2: col1,
  col3: col3,
  col4: col4
};

function BugMemoryMap(Props) {
  var selectedMemoryAddress = Props.selectedMemoryAddress;
  var memoryMap = Props.memoryMap;
  var height = Props.height;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    BugStyles$BsConsole.monospaceFont,
                    /* [] */0
                  ])
            }, React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.borderBottom(Css.px(1), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                        /* :: */[
                          Css.fontWeight(Css.medium),
                          /* :: */[
                            Css.fontFamily("Roboto"),
                            /* :: */[
                              Css.padding2(Css.px(5), Css.px(4)),
                              /* :: */[
                                Css.fontSize(Css.px(12)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement("div", {
                      className: col1
                    }, I18N$BsConsole.show(undefined, "Low")), React.createElement("div", {
                      className: col1
                    }, I18N$BsConsole.show(undefined, "High")), React.createElement("div", {
                      className: col3
                    }, I18N$BsConsole.show(undefined, "Mode")), React.createElement("div", {
                      className: col4
                    }, I18N$BsConsole.show(undefined, "Path"))), React.createElement(ReactWindow.FixedSizeList, {
                  height: (height - 24 | 0) - 1 | 0,
                  itemCount: memoryMap.length,
                  itemSize: 20,
                  children: (function (params) {
                      var entry = Belt_Array.getExn(memoryMap, params.index);
                      var isSelected;
                      if (selectedMemoryAddress !== undefined) {
                        var addressInt = parseInt(selectedMemoryAddress, 16);
                        var lowInt = parseInt(entry.low, 16);
                        var highInt = parseInt(entry.high, 16);
                        isSelected = addressInt >= lowInt && addressInt < highInt;
                      } else {
                        isSelected = false;
                      }
                      return React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.display(/* flex */-1010954439),
                                        /* :: */[
                                          Css.alignItems(Css.center),
                                          /* :: */[
                                            Css.padding2(Css.zero, Css.px(4)),
                                            /* :: */[
                                              Css.margin2(Css.px(4), Css.zero),
                                              /* :: */[
                                                Css.fontSize(Css.px(12)),
                                                /* :: */[
                                                  Css.flexShrink(0),
                                                  /* :: */[
                                                    isSelected ? Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)) : Css.background(Css.none),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]),
                                  style: params.style
                                }, React.createElement("div", {
                                      className: col1
                                    }, I18N$BsConsole.showSkip(entry.low)), React.createElement("div", {
                                      className: col1
                                    }, I18N$BsConsole.showSkip(entry.high)), React.createElement("div", {
                                      className: col3
                                    }, I18N$BsConsole.showSkip(entry.mode)), React.createElement("div", {
                                      className: col4,
                                      title: entry.path
                                    }, I18N$BsConsole.showSkip(entry.path)));
                    })
                }));
}

var make = BugMemoryMap;

exports.Styles = Styles;
exports.make = make;
/* col1 Not a pure module */
