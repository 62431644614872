// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");

function toEvent(action) {
  switch (action) {
    case /* MergeSuccess */0 :
        return /* GenericEvent */Block.__(2, [{
                    name: "merge-success",
                    payload: undefined
                  }]);
    case /* MergeFailure */1 :
        return /* GenericEvent */Block.__(2, [{
                    name: "merge-failure",
                    payload: undefined
                  }]);
    case /* UnmergeSuccess */2 :
        return /* GenericEvent */Block.__(2, [{
                    name: "unmerge-success",
                    payload: undefined
                  }]);
    case /* UnmergeFailure */3 :
        return /* GenericEvent */Block.__(2, [{
                    name: "unmerge-failure",
                    payload: undefined
                  }]);
    
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.toEvent = toEvent;
exports.send = send;
/* MetricsEvent-BsConsole Not a pure module */
