// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Animejs = require("animejs");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Flipped$BsConsole = require("../bindings/Flipped.js");
var Flipper$BsConsole = require("../bindings/Flipper.js");
var WfIntegrationSelectItem$BsConsole = require("./WfIntegrationSelectItem.js");

var component = RR$BsConsole.reducerComponent("WfIntegrationSelect-BsConsole");

function make(onApplicationSelected, integrations, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var activeTabIndex = param.state.activeTabIndex;
              var send = param.send;
              var filteredIntegrations;
              switch (activeTabIndex) {
                case 0 :
                    filteredIntegrations = Belt_Array.keep(integrations, (function (integration) {
                            return integration.description.category.type_ === /* IssueTracker */2;
                          }));
                    break;
                case 1 :
                    filteredIntegrations = Belt_Array.keep(integrations, (function (integration) {
                            return integration.description.category.type_ === /* Monitoring */1;
                          }));
                    break;
                case 2 :
                    filteredIntegrations = Belt_Array.keep(integrations, (function (integration) {
                            return integration.description.category.type_ === /* MessagingPlatform */0;
                          }));
                    break;
                case 3 :
                    filteredIntegrations = Belt_Array.keep(integrations, (function (integration) {
                            return integration.description.category.type_ === /* Other */3;
                          }));
                    break;
                case 4 :
                    filteredIntegrations = integrations;
                    break;
                default:
                  filteredIntegrations = integrations;
              }
              return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tabs.Jsx2.make, activeTabIndex, {
                                  root: Css.style(/* :: */[
                                        Css.backgroundColor(Css.hex(Colors$BsConsole.grey725)),
                                        /* [] */0
                                      ])
                                }, (function (param, tabIndex) {
                                    return Curry._1(send, /* SelectTab */[tabIndex]);
                                  }), undefined, undefined, true, undefined, [
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, {
                                            selected: Css.style(/* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.npLightBlue)),
                                                  /* [] */0
                                                ])
                                          }, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Issue tracking")), undefined, [])),
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, {
                                            selected: Css.style(/* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.npLightBlue)),
                                                  /* [] */0
                                                ])
                                          }, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Monitoring")), undefined, [])),
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, {
                                            selected: Css.style(/* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.npLightBlue)),
                                                  /* [] */0
                                                ])
                                          }, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Messaging platform")), undefined, [])),
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, {
                                            selected: Css.style(/* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.npLightBlue)),
                                                  /* [] */0
                                                ])
                                          }, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Other")), undefined, [])),
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, {
                                            selected: Css.style(/* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.npLightBlue)),
                                                  /* [] */0
                                                ])
                                          }, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "All")), undefined, []))
                                ])), ReasonReact.element(undefined, undefined, Flipper$BsConsole.make(String(activeTabIndex), [React.createElement("div", undefined, React.createElement("div", {
                                            style: {
                                              display: "flex",
                                              padding: "16px 16px",
                                              flexWrap: "wrap"
                                            }
                                          }, Belt_Array.map(Belt_SortArray.stableSortBy(filteredIntegrations, (function (i1, i2) {
                                                      return Caml_primitive.caml_string_compare(i1.description.title.toLowerCase(), i2.description.title.toLowerCase());
                                                    })), (function (integration) {
                                                  return ReasonReact.element(undefined, undefined, Flipped$BsConsole.make(integration.description.title, undefined, (function (el) {
                                                                    return Animejs({
                                                                                targets: el,
                                                                                duration: 1500,
                                                                                opacity: /* tuple */[
                                                                                  0,
                                                                                  1
                                                                                ]
                                                                              });
                                                                  }), undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                        style: {
                                                                          backgroundColor: Colors$BsConsole.octothorpe(Colors$BsConsole.whiteA30),
                                                                          minWidth: "124px",
                                                                          width: "14.285%"
                                                                        }
                                                                      }, ReasonReact.element(undefined, undefined, WfIntegrationSelectItem$BsConsole.make((function (param) {
                                                                                  return Curry._1(onApplicationSelected, integration);
                                                                                }), integration, [])))]));
                                                }))))])));
            }),
          initialState: (function (param) {
              return {
                      activeTabIndex: 4
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              return /* Update */Block.__(0, [{
                          activeTabIndex: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
