// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("./RR.js");
var Colors$BsConsole = require("./Colors.js");

var hoverBg = Css.style(/* :: */[
      Css.hover(/* :: */[
            Css.background(Css.hex(Colors$BsConsole.blackA08)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var component = RR$BsConsole.reducerComponent("ControllableHoverBg-BsConsole");

function make(style, onMouseEnter, onMouseLeave, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var tmp = {
                className: param.state.hoverDisabled ? "" : hoverBg
              };
              if (style !== undefined) {
                tmp.style = Caml_option.valFromOption(style);
              }
              if (onMouseEnter !== undefined) {
                tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
              }
              if (onMouseLeave !== undefined) {
                tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
              }
              return React.createElement("div", tmp, Curry._1(children, (function (disabled) {
                                return Curry._1(send, /* UpdateDisabled */[disabled]);
                              })));
            }),
          initialState: (function (param) {
              return {
                      hoverDisabled: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          hoverDisabled: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.hoverBg = hoverBg;
exports.component = component;
exports.make = make;
/* hoverBg Not a pure module */
