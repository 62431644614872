// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var I18N$BsConsole = require("./I18N.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WfTasks$BsConsole = require("./workflow/WfTasks.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var WfActions$BsConsole = require("./workflow/manual-actions/WfActions.js");
var ReopenGate$BsConsole = require("./reopen/ReopenGate.js");
var TriageGroup$BsConsole = require("./TriageGroup.js");
var BtIconButton$BsConsole = require("./components/BtIconButton.js");
var InboxMetrics$BsConsole = require("./inbox/InboxMetrics.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var InboxMultiTags$BsConsole = require("./inbox/InboxMultiTags.js");
var InboxMergeButton$BsConsole = require("./inbox/InboxMergeButton.js");
var InboxMultiAssign$BsConsole = require("./inbox/InboxMultiAssign.js");
var InboxMultiTicket$BsConsole = require("./inbox/InboxMultiTicket.js");
var TriageMultiState$BsConsole = require("./inbox/TriageMultiState.js");
var TriageIssueRemote$BsConsole = require("./TriageIssueRemote.js");

var row = Css.style(/* :: */[
      Css.height(Css.px(48)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.accent)),
        /* :: */[
          Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground)),
          /* :: */[
            Css.position(/* absolute */-1013592457),
            /* :: */[
              Css.bottom(/* zero */-789508312),
              /* :: */[
                Css.left(/* zero */-789508312),
                /* :: */[
                  Css.right(/* zero */-789508312),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var container = Css.style(/* :: */[
      Css.top(/* zero */-789508312),
      /* :: */[
        Css.bottom(/* zero */-789508312),
        /* :: */[
          Css.left(/* zero */-789508312),
          /* :: */[
            Css.right(/* zero */-789508312),
            /* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.zIndex(1000),
                /* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.addAlpha(Colors$BsConsole.white, 0.65))),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  row: row,
  container: container
};

var component = RR$BsConsole.statelessComponent("TriageHeader-BsConsole_MultiSelectRow");

function make(selection, setSelection, aperture, config, projectUsers, allTags, token, fetchIssues, projectName, triageGroups, goToDetails, workflows, handleChangeUrl, refreshAllTags, sort, _children) {
  var selectedTriageGroups = Belt_List.fromArray(Belt_Array.keep(triageGroups, (function (group) {
              return selection.includes(group.fingerprint);
            })));
  var handleSetIssues = function (actions, postFlightAll, postFlight, param) {
    var __x = TriageIssueRemote$BsConsole.setIssues((function (settingSets) {
            var shouldSet = Belt_List.reduce(settingSets, false, (function (acc, param) {
                    if (acc) {
                      return acc;
                    } else {
                      return Belt_List.length(param[1]) > 0;
                    }
                  }));
            if (shouldSet) {
              return true;
            } else {
              return false;
            }
          }), postFlight, postFlightAll, token, projectName, selectedTriageGroups, actions, undefined);
    return __x.then((function (resSets) {
                  var results = Belt_Array.map(resSets, (function (result) {
                          if (result !== undefined) {
                            return result[1];
                          }
                          
                        }));
                  TriageIssueRemote$BsConsole.$$Response.toastN(results);
                  Curry._1(fetchIssues, selection);
                  return Promise.resolve(undefined);
                }));
  };
  var handleSetAssignee = function (projectUser, param, param$1) {
    Promise.all(Belt_List.toArray(Belt_List.map(selectedTriageGroups, (function (selectedGroup) {
                      return TriageGroup$BsConsole.QuickActions.assignTickets((function (param, param$1) {
                                    return TriageIssueRemote$BsConsole.setIssue((function (param) {
                                                  return true;
                                                }), (function (fingerprint, _result) {
                                                  Curry._1(fetchIssues, [fingerprint]);
                                                  
                                                }), token, projectName, selectedGroup, param, param$1);
                                  }), Belt_Option.flatMap(Issues$BsConsole.Tickets.getLinked(selectedGroup.issues.tickets), (function (tickets) {
                                        return Belt_List.map(tickets, (function (ticket) {
                                                      switch (ticket.tag | 0) {
                                                        case /* Linked */1 :
                                                            var ticket$1 = ticket[0];
                                                            return /* tuple */[
                                                                    /* tuple */[
                                                                      ticket$1.short,
                                                                      ticket$1.watcher
                                                                    ],
                                                                    true
                                                                  ];
                                                        case /* Unlinked */0 :
                                                        case /* InteralAssigneeLink */2 :
                                                            throw [
                                                                  Caml_builtin_exceptions.assert_failure,
                                                                  /* tuple */[
                                                                    "TriageHeader.re",
                                                                    121,
                                                                    30
                                                                  ]
                                                                ];
                                                        
                                                      }
                                                    }));
                                      })), projectUser, true, undefined);
                    })))).then((function (resSets) {
            var results = Belt_Array.map(resSets, (function (r) {
                    return Belt_Option.flatMap(r, (function (param) {
                                  return param[1];
                                }));
                  }));
            TriageIssueRemote$BsConsole.$$Response.toastN(results);
            return Promise.resolve(resSets);
          }));
    
  };
  var handleWfAssociateTicket = function (workflow, cb) {
    return WfActions$BsConsole.createTicket(config.user.uid, "", workflow, projectName, (function (createTicketParams) {
                  Promise.all(Belt_List.toArray(Belt_List.map(Belt_List.fromArray(selection), (function (fingerprint) {
                                    return new Promise((function (resolve, _r) {
                                                  if (createTicketParams !== undefined) {
                                                    Belt_Option.map(WfTasks$BsConsole.createTicket(config, {
                                                              fingerprint: fingerprint,
                                                              projectName: createTicketParams.projectName,
                                                              watcherName: createTicketParams.watcherName,
                                                              payload: createTicketParams.payload,
                                                              reporting_user: createTicketParams.reporting_user
                                                            }), (function (task) {
                                                            return Task2$BsConsole.handle(token, task, undefined, (function (resp) {
                                                                          return resolve(resp);
                                                                        }));
                                                          }));
                                                    return ;
                                                  }
                                                  
                                                }));
                                  })))).then((function (param) {
                          Curry._1(cb, undefined);
                          Curry._1(fetchIssues, selection);
                          return Promise.resolve(undefined);
                        }));
                  
                }), undefined);
  };
  var setTickets = function (ticketValue) {
    var action = /* AddUnlinkedTicket */Block.__(5, [ticketValue]);
    handleSetIssues(/* :: */[
          action,
          /* [] */0
        ], (function (prim) {
            
          }), (function (fingerprint, param) {
            console.log(fingerprint);
            
          }), undefined);
    
  };
  var addRemoveTags = function (addTags, removeTags) {
    var maybeAction = addTags ? (
        removeTags ? /* AddRemoveTags */Block.__(9, [
              addTags,
              removeTags
            ]) : /* AddTags */Block.__(8, [addTags])
      ) : (
        removeTags ? /* RemoveTags */Block.__(7, [removeTags]) : undefined
      );
    if (maybeAction !== undefined) {
      handleSetIssues(/* :: */[
            maybeAction,
            /* [] */0
          ], (function (param) {
              return Curry._1(refreshAllTags, undefined);
            }), (function (fingerprint, param) {
              console.log(fingerprint);
              
            }), undefined);
      return ;
    }
    
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var numSelected = selection.length;
              if (numSelected > 0) {
                return React.createElement("div", {
                            className: container
                          }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, /* center */98248149, undefined, undefined, row, [
                                    ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                                    className: Css.style(/* :: */[
                                                          Css.marginLeft(Css.rem(1)),
                                                          /* :: */[
                                                            Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                            /* [] */0
                                                          ]
                                                        ])
                                                  }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                            /* Int */Block.__(4, [
                                                                /* Int_d */0,
                                                                /* No_padding */0,
                                                                /* No_precision */0,
                                                                /* String_literal */Block.__(11, [
                                                                    " groups selected",
                                                                    /* End_of_format */0
                                                                  ])
                                                              ]),
                                                            "%d groups selected"
                                                          ]), numSelected))])),
                                    ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                                              ReasonReact.element(undefined, undefined, TriageMultiState$BsConsole.Re.make(config, (function (s, i) {
                                                          var stateAction = /* SetState */Block.__(1, [s]);
                                                          var invariantAction = /* SetInvariant */Block.__(2, [i]);
                                                          handleSetIssues(ReopenGate$BsConsole.allow ? /* :: */[
                                                                  stateAction,
                                                                  /* :: */[
                                                                    invariantAction,
                                                                    /* [] */0
                                                                  ]
                                                                ] : /* :: */[
                                                                  stateAction,
                                                                  /* [] */0
                                                                ], (function (prim) {
                                                                  
                                                                }), (function (fingerprint, param) {
                                                                  console.log(fingerprint);
                                                                  
                                                                }), undefined);
                                                          
                                                        }), aperture, /* TriageMultiIcon */-120082594, undefined, undefined, projectName, sort, [])),
                                              ReasonReact.element(undefined, undefined, InboxMultiAssign$BsConsole.make(handleSetAssignee, (function (refHandler, onFocus, onClick) {
                                                          return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Set assignee for groups"), undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                                ref: refHandler,
                                                                                className: Css.merge(/* :: */[
                                                                                      Css.style(/* :: */[
                                                                                            Css.marginRight(Css.rem(1)),
                                                                                            /* [] */0
                                                                                          ]),
                                                                                      /* :: */[
                                                                                        "smaller-icon",
                                                                                        /* [] */0
                                                                                      ]
                                                                                    ]),
                                                                                onFocus: onFocus,
                                                                                onClick: onClick
                                                                              }, ReasonReact.element(undefined, undefined, BtIconButton$BsConsole.make(undefined, undefined, undefined, undefined, undefined, /* Primary */-791844958, (function (param) {
                                                                                          
                                                                                        }), "Set assignee for groups", [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.PersonAdd.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))])))]));
                                                        }), projectUsers, undefined, undefined, undefined, Belt_List.flatten(Belt_List.map(selectedTriageGroups, (function (group) {
                                                                  return Belt_Option.getWithDefault(group.issues.tickets, /* [] */0);
                                                                }))), Belt_List.flatten(Belt_List.map(selectedTriageGroups, (function (group) {
                                                                  return Belt_Option.getWithDefault(group.issues.assignees, /* [] */0);
                                                                }))), config, [])),
                                              ReasonReact.element(undefined, undefined, InboxMultiTicket$BsConsole.make(undefined, undefined, handleWfAssociateTicket, workflows, projectName, (function (refHandler, onFocus, onClick) {
                                                          return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Assign ticket to groups"), undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                                ref: refHandler,
                                                                                className: Css.merge(/* :: */[
                                                                                      Css.style(/* :: */[
                                                                                            Css.marginRight(Css.rem(1)),
                                                                                            /* [] */0
                                                                                          ]),
                                                                                      /* :: */[
                                                                                        "smaller-icon",
                                                                                        /* [] */0
                                                                                      ]
                                                                                    ]),
                                                                                onFocus: onFocus,
                                                                                onClick: onClick
                                                                              }, ReasonReact.element(undefined, undefined, BtIconButton$BsConsole.make(undefined, undefined, undefined, undefined, undefined, /* Primary */-791844958, (function (param) {
                                                                                          
                                                                                        }), "Assign ticket to groups", [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Receipt.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))])))]));
                                                        }), fetchIssues, (function (ticket, cb) {
                                                          setTickets(ticket);
                                                          return Curry._1(cb, undefined);
                                                        }), undefined, undefined, handleChangeUrl, config, token, selection, undefined, [])),
                                              ReasonReact.element(undefined, undefined, InboxMultiTags$BsConsole.make(undefined, undefined, allTags, (function (refHandler, onFocus, onClick) {
                                                          return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Set tags for groups"), undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                                ref: refHandler,
                                                                                className: Css.merge(/* :: */[
                                                                                      Css.style(/* :: */[
                                                                                            Css.marginRight(Css.rem(1)),
                                                                                            /* [] */0
                                                                                          ]),
                                                                                      /* :: */[
                                                                                        "smaller-icon",
                                                                                        /* [] */0
                                                                                      ]
                                                                                    ]),
                                                                                onFocus: onFocus,
                                                                                onClick: onClick
                                                                              }, ReasonReact.element(undefined, undefined, BtIconButton$BsConsole.make(undefined, undefined, undefined, undefined, undefined, /* Primary */-791844958, (function (param) {
                                                                                          
                                                                                        }), "Set tags for groups", [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.LocalOffer.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))])))]));
                                                        }), Belt_List.keepMap(selectedTriageGroups, (function (group) {
                                                              return group.issues.tags;
                                                            })), Belt_List.length(selectedTriageGroups), addRemoveTags, undefined, [])),
                                              ReasonReact.element(undefined, undefined, InboxMergeButton$BsConsole.Jsx2.make(selection, (function (group) {
                                                          InboxMetrics$BsConsole.send(/* MergeSuccess */0);
                                                          Curry._1(setSelection, []);
                                                          return Curry._1(goToDetails, group);
                                                        }), config, projectName, token, /* Icon */1, Css.merge(/* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.marginRight(Css.rem(1)),
                                                                  /* [] */0
                                                                ]),
                                                            /* :: */[
                                                              "smaller-icon",
                                                              /* [] */0
                                                            ]
                                                          ]), undefined, []))
                                            ]))
                                  ])));
              } else {
                return null;
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (r) {
        return make(r.selection, r.setSelection, r.aperture, r.config, r.projectUsers, r.allTags, r.token, r.fetchIssues, r.projectName, r.triageGroups, r.goToDetails, r.workflows, r.handleChangeUrl, r.refreshAllTags, r.sort, []);
      }));

var Jsx3 = {
  make: make$1
};

var MultiSelectRow = {
  component: component,
  make: make,
  Jsx3: Jsx3
};

exports.Style = Style;
exports.MultiSelectRow = MultiSelectRow;
/* row Not a pure module */
