// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");

var tbodyStyle = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.display(/* tableRowGroup */-836725517),
            /* :: */[
              Css.unsafe("backgroundColor", "inherit"),
              /* [] */0
            ]
          ]),
      /* :: */[
        "BtTableBody",
        /* [] */0
      ]
    ]);

function BtTableBody(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("tbody", {
              className: Css.merge(/* :: */[
                    tbodyStyle,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var make = BtTableBody;

exports.tbodyStyle = tbodyStyle;
exports.make = make;
/* tbodyStyle Not a pure module */
