// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var I18N$BsConsole = require("../I18N.js");
var SymbolServers_StatsUsage$BsConsole = require("../project-settings/symbols/SymbolServers/SymbolServers_StatsUsage.js");

function millions(count) {
  if (count < 1000000) {
    return String(count);
  }
  try {
    var c = count / 1000000 | 0;
    return Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* String_literal */Block.__(11, [
                            " million",
                            /* End_of_format */0
                          ])
                      ]),
                    "%s million"
                  ]), String(c));
  }
  catch (exn){
    return String(count);
  }
}

function toString(num, formatter) {
  if (formatter === undefined) {
    return Pervasives.string_of_float(num);
  }
  switch (formatter) {
    case /* Millions */0 :
        return millions(num | 0);
    case /* Bytes */1 :
        return SymbolServers_StatsUsage$BsConsole.bytesFormatted(num);
    case /* NoDecimal */2 :
        return String(num | 0);
    
  }
}

var million = 1000000;

var bytes = SymbolServers_StatsUsage$BsConsole.bytesFormatted;

exports.million = million;
exports.millions = millions;
exports.bytes = bytes;
exports.toString = toString;
/* I18N-BsConsole Not a pure module */
