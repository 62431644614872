// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Col2$BsConsole = require("../../components/Col2.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Colors$BsConsole = require("../../Colors.js");
var SSAUtils$BsConsole = require("./SSAUtils.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var Button = require("@material-ui/core/Button");
var BugBreadcrumbsUtils$BsConsole = require("../../debugger2/breadcrumbs/BugBreadcrumbsUtils.js");
var SSAStringComparison$BsConsole = require("./SSAStringComparison.js");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

function SSARenderElements$Section(Props) {
  var titleText = Props.titleText;
  var descriptionText = Props.descriptionText;
  var tableComponent = Props.tableComponent;
  var createButtonText = Props.createButtonText;
  var createAction = Props.createAction;
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    pageMarginBottom,
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle1 */6,
                  className: Css.style(/* :: */[
                        pageMarginBottom,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.showSkip(titleText)
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: featureDescription,
                  children: I18N$BsConsole.showSkip(descriptionText)
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ])
                  },
                  onClick: createAction,
                  children: I18N$BsConsole.showSkip(createButtonText)
                }), tableComponent);
}

var Section = {
  make: SSARenderElements$Section
};

function SSARenderElements$Target(Props) {
  var source = Props.source;
  if (typeof source === "number") {
    if (source !== 0) {
      return I18N$BsConsole.showSkip(SSAUtils$BsConsole.callstackText);
    } else {
      return I18N$BsConsole.showSkip(SSAUtils$BsConsole.moduleText);
    }
  } else {
    return React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
                text: SSAUtils$BsConsole.attributeText + (": " + source[0])
              });
  }
}

var Target = {
  make: SSARenderElements$Target
};

function SSARenderElements$Condition(Props) {
  var conditions = Props.conditions;
  return React.createElement(Row2$BsConsole.make, {
              children: Belt_Array.map(conditions, (function (condition) {
                      if (condition.tag) {
                        return React.createElement(React.Fragment, undefined, React.createElement("span", {
                                        className: Css.style(/* :: */[
                                              Css.marginRight(Css.px(3)),
                                              /* [] */0
                                            ])
                                      }, I18N$BsConsole.showSkip(SSAStringComparison$BsConsole.toLabel(condition[0]))), React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
                                        text: condition[1]
                                      }));
                      } else {
                        return React.createElement(React.Fragment, undefined, React.createElement("span", {
                                        className: Css.style(/* :: */[
                                              Css.marginRight(Css.px(3)),
                                              /* [] */0
                                            ])
                                      }, I18N$BsConsole.showSkip(SSAUtils$BsConsole.regexText + ": ")), React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
                                        text: condition[0] + (
                                          condition[1] ? " (" + (SSAUtils$BsConsole.inverseText + ")") : ""
                                        ),
                                        className: Css.style(/* :: */[
                                              Css.marginRight(Css.px(10)),
                                              /* [] */0
                                            ])
                                      }));
                      }
                    }))
            });
}

var Condition = {
  make: SSARenderElements$Condition
};

exports.pageMarginBottom = pageMarginBottom;
exports.featureDescription = featureDescription;
exports.Section = Section;
exports.Target = Target;
exports.Condition = Condition;
/* pageMarginBottom Not a pure module */
