// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("../crdb.js");
var Row2$BsConsole = require("../components/Row2.js");
var Route$BsConsole = require("../route.js");
var Colors$BsConsole = require("../Colors.js");
var BtPaper$BsConsole = require("../BtPaper.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ViewNav$BsConsole = require("../ViewNav.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var DescribeTask$BsConsole = require("../DescribeTask.js");
var Paper = require("@material-ui/core/Paper");
var BacktraceHooks$BsConsole = require("../BacktraceHooks.js");
var ExploreModeSelect$BsConsole = require("../ExploreModeSelect.js");
var SavedQueriesModel$BsConsole = require("../saved-queries/SavedQueriesModel.js");
var SimilarityProject$BsConsole = require("./SimilarityProject.js");
var SavedQueriesContainer$BsConsole = require("../saved-queries/SavedQueriesContainer.js");
var ErrorsOverTimeBinChart$BsConsole = require("../ErrorsOverTimeBinChart.js");
var DistributionValueSelect$BsConsole = require("../DistributionValueSelect.js");
var SavedQueryBookmarkSelect$BsConsole = require("../saved-queries/SavedQueryBookmarkSelect.js");

var paper = Css.merge(/* :: */[
      BtPaper$BsConsole.mainViewMargins,
      /* [] */0
    ]);

var Styles = {
  paper: paper
};

function SimilarityProjectView(Props) {
  var config = Props.config;
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleTask = Props.handleTask;
  var route = Props.route;
  var apertureForQuery = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var match = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match[0];
  var match$1 = DescribeTask$BsConsole.use(token, projectName, "metrics", undefined);
  var metricsDescribeRemote = match$1[0];
  var metricsAttributes = React.useMemo((function () {
          if (typeof metricsDescribeRemote === "number" || metricsDescribeRemote.tag) {
            return /* [] */0;
          } else {
            return metricsDescribeRemote[0];
          }
        }), [metricsDescribeRemote]);
  var handleSetAperture = function (aperture$prime) {
    return Curry._1(handleChangeUrl, /* ProjectSimilarity */Block.__(21, [
                  projectName,
                  Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture$prime), undefined, projectName, undefined, undefined, undefined, undefined, undefined, undefined)
                ]));
  };
  var tmp;
  if (typeof describeRemote === "number" || describeRemote.tag) {
    tmp = null;
  } else {
    var attributes = describeRemote[0];
    tmp = React.createElement(React.Fragment, undefined, DistributionValueSelect$BsConsole.hasAttribute(attributes, "error.type") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                attribute: "error.type",
                label: "Error type",
                aperture: aperture,
                token: token,
                projectName: projectName,
                handleSetAperture: handleSetAperture,
                className: Css.style(/* :: */[
                      Css.marginRight(Css.px(8)),
                      /* [] */0
                    ]),
                icon: React.createElement(MuiIcons$BsConsole.OctagonalError.make, {
                      color: "inherit",
                      fontSize: "inherit"
                    }),
                view: "list"
              }) : null, DistributionValueSelect$BsConsole.hasAttribute(attributes, "uname.sysname") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                attribute: "uname.sysname",
                label: "Platform",
                aperture: aperture,
                token: token,
                projectName: projectName,
                handleSetAperture: handleSetAperture,
                icon: React.createElement(MuiIcons$BsConsole.Platform.make, {
                      color: "inherit",
                      fontSize: "inherit"
                    }),
                view: "list"
              }) : null);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                  className: Css.merge(/* :: */[
                        BtPaper$BsConsole.mainViewMargins,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]),
                  elevation: BtPaper$BsConsole.elevation,
                  children: null
                }, React.createElement(ViewNav$BsConsole.make, {
                      config: config,
                      aperture: aperture,
                      route: route,
                      handleChangeUrl: handleChangeUrl,
                      projectName: projectName,
                      className: Css.style(/* :: */[
                            Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                            /* [] */0
                          ])
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                              /* :: */[
                                Css.padding2(Css.px(4), Css.px(16)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: React.createElement(SavedQueriesContainer$BsConsole.make, {
                            name: projectName,
                            token: token,
                            handleTask: handleTask,
                            handleChangeUrl: handleChangeUrl,
                            config: config,
                            query: undefined,
                            mode: undefined,
                            normBy: undefined,
                            renderTrigger: (function (param) {
                                var handleOpen = param.handleOpen;
                                return React.createElement(React.Fragment, undefined, React.createElement(SavedQueryBookmarkSelect$BsConsole.make, {
                                                projectBookmarkedViews: param.projectBookmarkedViews,
                                                userBookmarkedViews: param.userBookmarkedViews,
                                                projectDefault: param.projectDefault,
                                                userDefault: param.userDefault,
                                                queries: param.queries,
                                                handleOpen: (function (param) {
                                                    return Curry._2(handleOpen, undefined, undefined);
                                                  }),
                                                handleChangeUrl: handleChangeUrl,
                                                currentMode: SavedQueriesModel$BsConsole.modeOfStringExn("instance"),
                                                projectName: projectName
                                              }));
                              })
                          })
                    })), React.createElement(Paper.default, {
                  className: paper,
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.padding4(Css.px(16), Css.px(16), /* zero */-789508312, Css.px(16)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(Row2$BsConsole.make, {
                          children: tmp
                        }), React.createElement(ExploreModeSelect$BsConsole.Jsx3.make, {
                          projectName: projectName,
                          aperture: aperture,
                          currentView: /* Similarity */436526339,
                          handleChangeUrl: handleChangeUrl
                        })), React.createElement(ErrorsOverTimeBinChart$BsConsole.make, {
                      token: token,
                      projectName: projectName,
                      aperture: apertureForQuery,
                      onRangeChange: (function (range) {
                          return Curry._1(handleChangeUrl, /* ProjectSimilarity */Block.__(21, [
                                        projectName,
                                        Route$BsConsole.getQueryBuilderParams(Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture)), undefined, projectName, undefined, undefined, undefined, undefined, undefined, undefined)
                                      ]));
                        }),
                      className: Css.style(/* :: */[
                            Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                            /* [] */0
                          ]),
                      metricsAttributes: metricsAttributes
                    }), React.createElement(SimilarityProject$BsConsole.make, {
                      config: config,
                      token: token,
                      projectName: projectName,
                      handleChangeUrl: handleChangeUrl,
                      aperture: aperture
                    })));
}

function make(config, aperture, projectName, token, handleChangeUrl, route, handleTask, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SimilarityProjectView, {
              config: config,
              aperture: aperture,
              projectName: projectName,
              token: token,
              handleChangeUrl: handleChangeUrl,
              handleTask: handleTask,
              route: route
            }, _children);
}

var Re = {
  make: make
};

var make$1 = SimilarityProjectView;

exports.Styles = Styles;
exports.make = make$1;
exports.Re = Re;
/* paper Not a pure module */
