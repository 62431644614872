'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _resizeObserverPolyfill = require('resize-observer-polyfill');

var _resizeObserverPolyfill2 = _interopRequireDefault(_resizeObserverPolyfill);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ParentSize = function (_React$Component) {
  _inherits(ParentSize, _React$Component);

  function ParentSize(props) {
    _classCallCheck(this, ParentSize);

    var _this = _possibleConstructorReturn(this, (ParentSize.__proto__ || Object.getPrototypeOf(ParentSize)).call(this, props));

    _this.state = { width: 0, height: 0, top: 0, left: 0 };
    _this.resize = _this.resize.bind(_this);
    _this.setTarget = _this.setTarget.bind(_this);
    return _this;
  }

  _createClass(ParentSize, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      this.ro = new _resizeObserverPolyfill2.default(function (entries, observer) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = entries[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var entry = _step.value;
            var _entry$contentRect = entry.contentRect,
                left = _entry$contentRect.left,
                top = _entry$contentRect.top,
                width = _entry$contentRect.width,
                height = _entry$contentRect.height;

            _this2.resize({
              width: width,
              height: height,
              top: top,
              left: left
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      });
      this.ro.observe(this.target);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.ro.disconnect();
    }
  }, {
    key: 'resize',
    value: function resize(_ref) {
      var width = _ref.width,
          height = _ref.height,
          top = _ref.top,
          left = _ref.left;

      this.setState(function () {
        return {
          width: width,
          height: height,
          top: top,
          left: left
        };
      });
    }
  }, {
    key: 'setTarget',
    value: function setTarget(ref) {
      this.target = ref;
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          children = _props.children;

      return _react2.default.createElement(
        'div',
        {
          style: { width: '100%', height: '100%' },
          ref: this.setTarget,
          className: className
        },
        children(_extends({}, this.state, {
          ref: this.target,
          resize: this.resize
        }))
      );
    }
  }]);

  return ParentSize;
}(_react2.default.Component);

exports.default = ParentSize;


ParentSize.propTypes = {
  className: _propTypes2.default.string,
  children: _propTypes2.default.func.isRequired
};