// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");

function httpd_error_of_int(i) {
  switch (i) {
    case 0 :
        return /* General */0;
    case 1 :
        return /* NoSuchUser */1;
    case 2 :
        return /* NoSuchUniverse */2;
    case 3 :
        return /* NoSuchProject */3;
    case 4 :
        return /* NoSuchObject */4;
    case 5 :
        return /* InvalidToken */5;
    case 6 :
        return /* AccessDenied */6;
    case 7 :
        return /* DeletedObject */[undefined];
    case 8 :
        return /* WrongAuthMethod */7;
    default:
      return ;
  }
}

function decode(json) {
  var error;
  try {
    var error$1 = httpd_error_of_int(Json_decode.at(/* :: */[
                "error",
                /* :: */[
                  "code",
                  /* [] */0
                ]
              ], Json_decode.$$int)(json));
    error = {
      code: error$1 !== undefined && !(typeof error$1 === "number" || error$1[0] !== undefined) ? /* DeletedObject */[Json_decode.optional((function (param) {
                  return Json_decode.field("X-Object-Fingerprint", Json_decode.string, param);
                }), json)] : error$1,
      message: Json_decode.at(/* :: */[
              "error",
              /* :: */[
                "message",
                /* [] */0
              ]
            ], Json_decode.string)(json)
    };
  }
  catch (raw__msg){
    var _msg = Caml_js_exceptions.internalToOCamlException(raw__msg);
    if (_msg[0] === Json_decode.DecodeError) {
      return ;
    }
    throw _msg;
  }
  return error;
}

var HTTPD = {
  httpd_error_of_int: httpd_error_of_int,
  decode: decode
};

exports.HTTPD = HTTPD;
/* No side effect */
