// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Regex$BsConsole = require("./Regex.js");

function toParts(t) {
  var translateCaptures = function (hour, minute) {
    if (hour !== undefined) {
      if (minute !== undefined) {
        return /* tuple */[
                Caml_format.caml_float_of_string(hour),
                Caml_format.caml_float_of_string(minute)
              ];
      } else {
        return /* tuple */[
                Caml_format.caml_float_of_string(hour),
                0.0
              ];
      }
    } else if (minute !== undefined) {
      return /* tuple */[
              0.0,
              Caml_format.caml_float_of_string(minute)
            ];
    } else {
      return /* tuple */[
              0.0,
              0.0
            ];
    }
  };
  var parseCaptures = function (captures) {
    var _otherwise = Belt_Array.map(captures, (function (prim) {
            if (prim == null) {
              return ;
            } else {
              return Caml_option.some(prim);
            }
          }));
    if (_otherwise.length !== 3) {
      return translateCaptures(undefined, undefined);
    }
    var hour = _otherwise[1];
    var minute = _otherwise[2];
    return translateCaptures(hour, minute);
  };
  return Belt_Option.map(Regex$BsConsole.timeStringParser(t), parseCaptures);
}

var TimeString = {
  toParts: toParts
};

function makeOnChange(userFn, $$event) {
  var target = $$event.target;
  return Curry._1(userFn, toParts(target.value));
}

var component = ReasonReact.statelessComponent("ClockSelect-BsConsole");

function make(labelOpt, value, onChange, _children) {
  var label = labelOpt !== undefined ? Caml_option.valFromOption(labelOpt) : I18N$BsConsole.showSkip("");
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (param) {
                                  return makeOnChange(onChange, param);
                                }),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              "time",
                              /* `String */[
                                -976970511,
                                value
                              ],
                              undefined,
                              undefined,
                              Caml_option.some(label),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              []
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.TimeString = TimeString;
exports.makeOnChange = makeOnChange;
exports.component = component;
exports.make = make;
/* component Not a pure module */
