// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../../bindings/Mui.js");
var I18N$BsConsole = require("../../../I18N.js");
var Colors$BsConsole = require("../../../Colors.js");
var Numeral$BsConsole = require("../../../Numeral.js");
var L10N_date_time$BsConsole = require("../../../L10N_date_time.js");
var SymbolServers_SymbolStatus$BsConsole = require("./SymbolServers_SymbolStatus.js");

function bytesFormatted(bytes) {
  var suffix = [
    "B",
    "KB",
    "MB",
    "GB",
    "TB"
  ];
  var index = 0;
  var curr = bytes;
  while(curr > 1000 && index < 5) {
    curr = curr / 1000;
    index = index + 1 | 0;
  };
  var s = Belt_Array.get(suffix, index);
  if (s !== undefined) {
    return curr.toFixed(2) + s;
  } else {
    return "";
  }
}

var containerStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* spaceBetween */516682146),
        /* :: */[
          Css.width(Css.px(250)),
          /* :: */[
            Css.marginBottom(Css.px(10)),
            /* [] */0
          ]
        ]
      ]
    ]);

var labelStyle = Css.style(/* :: */[
      Css.fontWeight(/* medium */-20425611),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.fontFamily("Roboto"),
          /* [] */0
        ]
      ]
    ]);

var sublabelStyle = Css.style(/* :: */[
      Css.fontWeight(/* normal */812216871),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey4)),
        /* :: */[
          Css.fontSize(Css.px(13)),
          /* :: */[
            Css.fontFamily("Roboto"),
            /* :: */[
              Css.marginLeft(Css.px(20)),
              /* :: */[
                Css.fontStyle(/* italic */107228912),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var valueStyle = Css.style(/* :: */[
      Css.fontWeight(/* medium */-20425611),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey4)),
        /* :: */[
          Css.fontSize(Css.px(13)),
          /* :: */[
            Css.fontFamily("Roboto"),
            /* [] */0
          ]
        ]
      ]
    ]);

var component = ReasonReact.statelessComponent("SymbolServers_StatsUsage-BsConsole");

function make(server, token, endpoint, universe, project, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.fontSize(Css.px(14)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(25)),
                                      /* [] */0
                                    ]
                                  ])
                            }, React.createElement("div", {
                                  className: containerStyle
                                }, React.createElement("div", {
                                      className: labelStyle
                                    }, I18N$BsConsole.show(undefined, "Total bytes downloaded")), React.createElement("div", {
                                      className: valueStyle
                                    }, I18N$BsConsole.showSkip(bytesFormatted(server.statistics.numberOfBytesDownloaded)))), React.createElement("div", {
                                  className: containerStyle
                                }, React.createElement("div", {
                                      className: labelStyle
                                    }, I18N$BsConsole.show(undefined, "Total symbol downloads")), React.createElement("div", {
                                      className: valueStyle
                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Pervasives.string_of_float(server.statistics.numberOfSuccessAtDownloadStage + server.statistics.numberOfSuccessAtSymbolConversionStage))))), React.createElement("div", {
                                  className: containerStyle
                                }, React.createElement("div", {
                                      className: sublabelStyle
                                    }, I18N$BsConsole.show(undefined, "At download stage")), React.createElement("div", {
                                      className: valueStyle
                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Pervasives.string_of_float(server.statistics.numberOfSuccessAtDownloadStage))))), React.createElement("div", {
                                  className: containerStyle
                                }, React.createElement("div", {
                                      className: sublabelStyle
                                    }, I18N$BsConsole.show(undefined, "At symbol conversion stage")), React.createElement("div", {
                                      className: valueStyle
                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Pervasives.string_of_float(server.statistics.numberOfSuccessAtSymbolConversionStage))))), React.createElement("div", {
                                  className: containerStyle
                                }, React.createElement("div", {
                                      className: labelStyle
                                    }, I18N$BsConsole.show(undefined, "Total failed downloads")), React.createElement("div", {
                                      className: valueStyle
                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Pervasives.string_of_float(server.statistics.numberOfFailuresAtDownloadStage + server.statistics.numberOfFailuresAtSymbolConversionStage))))), React.createElement("div", {
                                  className: containerStyle
                                }, React.createElement("div", {
                                      className: sublabelStyle
                                    }, I18N$BsConsole.showSkip("At download stage")), React.createElement("div", {
                                      className: valueStyle
                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Pervasives.string_of_float(server.statistics.numberOfFailuresAtDownloadStage))))), React.createElement("div", {
                                  className: containerStyle
                                }, React.createElement("div", {
                                      className: sublabelStyle
                                    }, I18N$BsConsole.show(undefined, "At symbol conversion stage")), React.createElement("div", {
                                      className: valueStyle
                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Pervasives.string_of_float(server.statistics.numberOfFailuresAtSymbolConversionStage)))))), React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    sublabelStyle,
                                    /* :: */[
                                      Css.style(/* :: */[
                                            Css.marginLeft(Css.px(0)),
                                            /* :: */[
                                              Css.marginBottom(Css.px(10)),
                                              /* [] */0
                                            ]
                                          ]),
                                      /* [] */0
                                    ]
                                  ])
                            }, I18N$BsConsole.show(undefined, "Statistics accurate as of "), I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, DateFns.parseString(undefined, server.statistics.lastUpdateDate)))), ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])), React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.marginTop(Css.px(25)),
                                    /* [] */0
                                  ])
                            }, ReasonReact.element(undefined, undefined, SymbolServers_SymbolStatus$BsConsole.make(endpoint, universe, token, project, []))));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.bytesFormatted = bytesFormatted;
exports.containerStyle = containerStyle;
exports.labelStyle = labelStyle;
exports.sublabelStyle = sublabelStyle;
exports.valueStyle = valueStyle;
exports.component = component;
exports.make = make;
/* containerStyle Not a pure module */
