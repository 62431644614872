// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("../../crdb.js");
var Task2$BsConsole = require("../../Task2.js");
var ExporterMetrics$BsConsole = require("../ExporterMetrics.js");

var resource = "metrics";

function metricFromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          setId: Json_decode.field("metrics_set_id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          query: Json_decode.field("query", Json_decode.string, json)
        };
}

var scalars = /* :: */[
  "count",
  /* :: */[
    "head",
    /* :: */[
      "tail",
      /* :: */[
        "mean",
        /* :: */[
          "min",
          /* :: */[
            "max",
            /* :: */[
              "unique",
              /* :: */[
                "first",
                /* :: */[
                  "last",
                  /* :: */[
                    "sum",
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var excludedFilterAttributes = /* :: */[
  "timestamp",
  /* :: */[
    "_tx",
    /* :: */[
      "timestamp.received",
      /* [] */0
    ]
  ]
];

function create(path, universeName, projectName, name, fold, factor, aperture, setId, param) {
  var group = Curry._1(Crdb$BsConsole.Factor.toCrdbString, factor);
  var newFold = Curry._1(Crdb$BsConsole.Fold.fromArray, Belt_Array.keep(Curry._1(Crdb$BsConsole.Fold.toArray, fold), (function (agg) {
              var operation = Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg));
              return Belt_List.has(scalars, operation, (function (a, b) {
                            return a === b;
                          }));
            })));
  var newFilters = Curry._1(Crdb$BsConsole.Filters.fromArray, Belt_Array.keep(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
              var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
              return !Belt_List.has(excludedFilterAttributes, attribute, (function (a, b) {
                            return a === b;
                          }));
            })));
  var queryJson = group === "NONE" ? Json_encode.object_(/* :: */[
          /* tuple */[
            "fold",
            Curry._1(Crdb$BsConsole.Fold.toCrdb, fold)
          ],
          /* :: */[
            /* tuple */[
              "filter",
              [Curry._1(Crdb$BsConsole.Filters.toCrdb, newFilters)]
            ],
            /* [] */0
          ]
        ]) : Json_encode.object_(/* :: */[
          /* tuple */[
            "fold",
            Curry._1(Crdb$BsConsole.Fold.toCrdb, newFold)
          ],
          /* :: */[
            /* tuple */[
              "group",
              [Curry._1(Crdb$BsConsole.Factor.toCrdb, factor)]
            ],
            /* :: */[
              /* tuple */[
                "filter",
                [Curry._1(Crdb$BsConsole.Filters.toCrdb, newFilters)]
              ],
              /* [] */0
            ]
          ]
        ]);
  ExporterMetrics$BsConsole.send(/* MetricsCreate */9);
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          name
        ],
        /* :: */[
          /* tuple */[
            "query",
            JSON.stringify(queryJson)
          ],
          /* :: */[
            /* tuple */[
              "metrics_set_id",
              setId
            ],
            /* [] */0
          ]
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Post */Block.__(0, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [metricFromJson(respBag.json)]);
              }), undefined, undefined);
}

function update(path, universeName, projectName, id, name, query, param) {
  var queryString = JSON.stringify(Crdb$BsConsole.QueryManager.toCrdbTimeless(query));
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          name
        ],
        /* :: */[
          /* tuple */[
            "query",
            queryString
          ],
          /* [] */0
        ]
      ]);
  ExporterMetrics$BsConsole.send(/* MetricsUpdate */10);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Put */Block.__(1, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [respBag.json]);
              }), undefined, undefined);
}

function get(path, universeName, projectName, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                var result = Json_decode.list(metricFromJson, respBag.json);
                return /* Ok */Block.__(0, [result]);
              }), undefined, undefined);
}

function $$delete(path, universeName, projectName, id, param) {
  ExporterMetrics$BsConsole.send(/* MetricsDelete */11);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Delete */1, undefined, (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

exports.resource = resource;
exports.metricFromJson = metricFromJson;
exports.scalars = scalars;
exports.excludedFilterAttributes = excludedFilterAttributes;
exports.create = create;
exports.update = update;
exports.get = get;
exports.$$delete = $$delete;
/* Crdb-BsConsole Not a pure module */
