// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function Make(F) {
  var handleRemote = function (remote) {
    if (typeof remote === "number") {
      if (remote === /* NotAsked */0) {
        return /* NotAsked */0;
      } else {
        return /* Loading */1;
      }
    }
    if (remote.tag) {
      return /* Failure */Block.__(1, [remote[0]]);
    }
    var result = Belt_List.head(remote[0]);
    if (result !== undefined) {
      return /* Success */Block.__(0, [Caml_option.valFromOption(result)]);
    } else {
      return /* SuccessEmpty */2;
    }
  };
  return {
          handleRemote: handleRemote
        };
}

exports.Make = Make;
/* No side effect */
