// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decode(json) {
  return {
          total: Json_decode.field("total_time", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          filter: Json_decode.at(/* :: */[
                  "filter",
                  /* :: */[
                    "time",
                    /* [] */0
                  ]
                ], (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }))(json),
          group_by: Json_decode.at(/* :: */[
                  "group_by",
                  /* :: */[
                    "time",
                    /* [] */0
                  ]
                ], (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }))(json)
        };
}

exports.decode = decode;
/* No side effect */
