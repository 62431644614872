// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("./BtTypography.js");

var typography = Css.style(/* :: */[
      Css.padding(Css.px(25)),
      /* :: */[
        Css.color(/* `hex */[
              5194459,
              Colors$BsConsole.grey0
            ]),
        /* [] */0
      ]
    ]);

var Style = {
  typography: typography
};

function FeatureService$Unavailable(Props) {
  var serviceName = Props.serviceName;
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: typography,
              children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "This feature is only available if the ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " service is installed.",
                                    /* End_of_format */0
                                  ])
                              ])
                          ]),
                        "This feature is only available if the %s service is installed."
                      ]), serviceName)
            });
}

function make(serviceName, children) {
  return ReasonReactCompat.wrapReactForReasonReact(FeatureService$Unavailable, {
              serviceName: serviceName
            }, children);
}

var Re = {
  make: make
};

var Unavailable = {
  make: FeatureService$Unavailable,
  Re: Re
};

function FeatureService$UnavailableVersion(Props) {
  var serviceName = Props.serviceName;
  var minimumRequiredVersion = Props.minimumRequiredVersion;
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: typography,
              children: Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "This feature is only available if the ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " service version is at least ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* "." */46,
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ])
                              ])
                          ]),
                        "This feature is only available if the %s service version is at least %s."
                      ]), serviceName, minimumRequiredVersion)
            });
}

function make$1(serviceName, minimumRequiredVersion, children) {
  return ReasonReactCompat.wrapReactForReasonReact(FeatureService$UnavailableVersion, {
              serviceName: serviceName,
              minimumRequiredVersion: minimumRequiredVersion
            }, children);
}

var Re$1 = {
  make: make$1
};

var UnavailableVersion = {
  make: FeatureService$UnavailableVersion,
  Re: Re$1
};

exports.Style = Style;
exports.Unavailable = Unavailable;
exports.UnavailableVersion = UnavailableVersion;
/* typography Not a pure module */
