// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                t.name
              ],
              /* :: */[
                /* tuple */[
                  "width",
                  t.width
                ],
                /* [] */0
              ]
            ]);
}

function ofJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          width: Json_decode.field("width", Json_decode.$$int, json)
        };
}

exports.toJson = toJson;
exports.ofJson = ofJson;
/* No side effect */
