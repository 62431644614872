// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var BugVariablesTree$BsConsole = require("./BugVariablesTree.js");

function BugGlobalVariables(Props) {
  var globalVariables = Props.globalVariables;
  var variablesTree = React.useMemo((function () {
          return BugVariablesTree$BsConsole.makeTree(undefined, globalVariables);
        }), [globalVariables]);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.fontSize(Css.px(12)),
                    /* :: */[
                      Css.fontFamily("Inconsolata"),
                      /* :: */[
                        Css.padding2(Css.px(4), Css.zero),
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement(BugVariablesTree$BsConsole.make, {
                  annotationsDict: { },
                  title: "Global variables",
                  tree: variablesTree,
                  setSelectedVariable: (function (prim) {
                      
                    }),
                  initialExpanded: true,
                  paneFocused: false
                }));
}

var make = BugGlobalVariables;

exports.make = make;
/* Css Not a pure module */
