// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var BtOutlineSelect$BsConsole = require("./BtOutlineSelect.js");
var MenuItem = require("@material-ui/core/MenuItem");

var label = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.position(/* absolute */-1013592457),
          /* [] */0
        ]
      ]
    ]);

var select = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* [] */0
      ]
    ]);

var iconContainer = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* :: */[
          Css.marginLeft(Css.rem(0.25)),
          /* [] */0
        ]
      ]
    ]);

var loading = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* [] */0
    ]);

var menuItem = Css.style(/* :: */[
      Css.selector(".value", /* :: */[
            Css.marginRight(Css.rem(1)),
            /* [] */0
          ]),
      /* :: */[
        Css.selector(".count", /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey4)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItemClasses = {
  root: Css.style(/* :: */[
        Css.hover(/* :: */[
              Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
              /* [] */0
            ]),
        /* :: */[
          Css.paddingLeft(Css.px(32)),
          /* :: */[
            Css.paddingRight(Css.px(32)),
            /* :: */[
              Css.position(/* relative */903134412),
              /* [] */0
            ]
          ]
        ]
      ]),
  selected: Css.style(/* :: */[
        Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
        /* :: */[
          Css.before(/* :: */[
                Css.fontFamily("'Material Icons'"),
                /* :: */[
                  Css.unsafe("content", "'check'"),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.accent)),
                    /* :: */[
                      Css.position(/* absolute */-1013592457),
                      /* :: */[
                        Css.left(Css.px(8)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]),
          /* [] */0
        ]
      ])
};

var Style = {
  label: label,
  select: select,
  iconContainer: iconContainer,
  loading: loading,
  menuItem: menuItem,
  menuItemClasses: menuItemClasses
};

function removeAssigneeFilters(aperture) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filters = Curry._2(Crdb$BsConsole.Filters.keep, (function (filter) {
          var match = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
          var match$1 = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
          switch (match) {
            case "fingerprint;issues;assignee" :
                return false;
            case "fingerprint;issues;ticket" :
                if (match$1.tag === /* NotContains */3) {
                  return false;
                } else {
                  return true;
                }
            default:
              return true;
          }
        }), __x);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
}

function addFilter(aperture, filter) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filters = Curry._2(Crdb$BsConsole.Filters.add, filter, __x);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
}

function makeQuery(aperture, username) {
  var queryAperture = addFilter(removeAssigneeFilters(aperture), Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
            "fingerprint;issues;assignee",
            /* AssignedTo */Block.__(10, [/* `String */[
                  -976970511,
                  username
                ]])
          ]));
  var __x = Crdb$BsConsole.Fold.empty;
  var fold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "fingerprint",
            /* Unique */4
          ]), __x);
  return /* Aggregate */Block.__(0, [
            queryAperture,
            /* Custom */["None"],
            fold,
            undefined,
            undefined,
            undefined
          ]);
}

function getValueFromAperture(aperture, username) {
  var filterArray = Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
  var statusFilter = Belt_Array.getBy(filterArray, (function (filter) {
          var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
          var operator = Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter));
          if (attribute === "fingerprint;issues;assignee") {
            return operator === "assigned-to";
          } else {
            return false;
          }
        }));
  if (statusFilter !== undefined) {
    var value = Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, Caml_option.valFromOption(statusFilter)))));
    if (value === username) {
      return "Assigned to me";
    } else {
      return value;
    }
  }
  var unassigned = Belt_Array.getBy(filterArray, (function (filter) {
          var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
          var operator = Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter));
          var value = Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))));
          if (attribute === "fingerprint;issues;ticket" && operator === "not-contains") {
            return value === "username";
          } else {
            return false;
          }
        }));
  return Belt_Option.getWithDefault(Belt_Option.map(unassigned, (function (param) {
                    return "Unassigned";
                  })), "All");
}

function TriageAssigneeSelect(Props) {
  var aperture = Props.aperture;
  var username = Props.username;
  var handleSetAperture = Props.handleSetAperture;
  var className = Props.className;
  var view = Props.view;
  var value = React.useMemo((function () {
          return getValueFromAperture(aperture, username);
        }), /* tuple */[
        aperture,
        username
      ]);
  var handleOnChange = function ($$event, _jsEvent) {
    var value = $$event.target.value;
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* FilterSelect */Block.__(5, [
                view,
                "assignee"
              ])]));
    switch (value) {
      case "All" :
          return Curry._1(handleSetAperture, removeAssigneeFilters(aperture));
      case "Assigned to me" :
          return Curry._1(handleSetAperture, addFilter(removeAssigneeFilters(aperture), Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                              "fingerprint;issues;assignee",
                              /* AssignedTo */Block.__(10, [/* `String */[
                                    -976970511,
                                    username
                                  ]])
                            ])));
      case "Unassigned" :
          return Curry._1(handleSetAperture, addFilter(removeAssigneeFilters(aperture), Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                              "fingerprint;issues;ticket",
                              /* NotContains */Block.__(3, [/* `String */[
                                    -976970511,
                                    "username"
                                  ]])
                            ])));
      default:
        return ;
    }
  };
  var tmp = {
    value: value,
    name: "assignee-filter-select",
    renderValue: (function (value) {
        return React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    children: null
                  }, React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        className: iconContainer,
                        children: React.createElement(MuiIcons$BsConsole.Assignee.make, {
                              color: "inherit",
                              fontSize: "inherit"
                            })
                      }), I18N$BsConsole.show(undefined, value));
      }),
    onChange: handleOnChange,
    label: "Assignee",
    children: null
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(BtOutlineSelect$BsConsole.make, tmp, React.createElement(MenuItem.default, {
                  disableRipple: true,
                  classes: menuItemClasses,
                  value: "All",
                  className: menuItem,
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: "value",
                        children: I18N$BsConsole.show(undefined, "All")
                      })
                }), React.createElement(MenuItem.default, {
                  disableRipple: true,
                  classes: menuItemClasses,
                  value: "Unassigned",
                  className: menuItem,
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: "value",
                        children: I18N$BsConsole.show(undefined, "Unassigned")
                      })
                }), React.createElement(MenuItem.default, {
                  disableRipple: true,
                  classes: menuItemClasses,
                  value: "Assigned to me",
                  className: menuItem,
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: "value",
                        children: I18N$BsConsole.show(undefined, "Assigned to me")
                      })
                }));
}

var make = TriageAssigneeSelect;

exports.Style = Style;
exports.removeAssigneeFilters = removeAssigneeFilters;
exports.addFilter = addFilter;
exports.makeQuery = makeQuery;
exports.getValueFromAperture = getValueFromAperture;
exports.make = make;
/* label Not a pure module */
