// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ReasonReactRouter = require("reason-react/lib/js/src/ReasonReactRouter.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var UnityErrorPage$BsConsole = require("./UnityErrorPage.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var BacktraceErrorPage$BsConsole = require("./BacktraceErrorPage.js");

function NotFound(Props) {
  var path = Props.path;
  var err = Props.err;
  var reportErr = Belt_Option.getWithDefault(err, /* `string */[
        288368849,
        "NOT_FOUND"
      ]);
  React.useEffect((function () {
          var __x = Backtrace$BsConsole.Attributes.addString("src_module", "NotFound/useEffect0", Backtrace$BsConsole.Attributes.addString("path", path, Backtrace$BsConsole.Attributes.make(undefined)));
          Backtrace$BsConsole.Client.send(__x, reportErr);
          
        }), ([]));
  var errorSupportTextCallback = function (primaryMain) {
    var homeLink = Css.style(/* :: */[
          Css.color(Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
          /* :: */[
            Css.cursor(/* pointer */-786317123),
            /* [] */0
          ]
        ]);
    return React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "We cannot find this page. It may have moved or been deleted. Take me "), React.createElement("span", {
                    className: homeLink,
                    onClick: (function (param) {
                        return ReasonReactRouter.push("/");
                      })
                  }, I18N$BsConsole.show(undefined, "home")), I18N$BsConsole.show(undefined, "."));
  };
  if (IdentityVariant$BsConsole.current >= 2) {
    return React.createElement(UnityErrorPage$BsConsole.make, {
                errorCode: I18N$BsConsole.show(undefined, "404"),
                errorMessage: I18N$BsConsole.show(undefined, "Page not found"),
                errorSupportText: I18N$BsConsole.show(undefined, "The page you're looking for has been moved or doesn't exist")
              });
  } else {
    return React.createElement(BacktraceErrorPage$BsConsole.make, {
                errorCode: I18N$BsConsole.show(undefined, "404"),
                errorMessage: I18N$BsConsole.show(undefined, "Page Not Found"),
                errorSupportTextCallback: errorSupportTextCallback
              });
  }
}

var component = ReasonReact.statelessComponent("NotFound");

function make(path, err, children) {
  var tmp = {
    path: path
  };
  if (err !== undefined) {
    tmp.err = Caml_option.valFromOption(err);
  }
  return ReasonReactCompat.wrapReactForReasonReact(NotFound, tmp, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = NotFound;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
