// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function providerToJs(param) {
  return {
          url: param.url
        };
}

function providerFromJs(param) {
  return {
          url: param.url
        };
}

var $$window = typeof window === "undefined" ? undefined : window;

var provider;

if ($$window !== undefined) {
  var exit = 0;
  var sp;
  try {
    sp = $$window._BACKTRACE_CORONER_SAML.provider;
    exit = 1;
  }
  catch (exn){
    provider = undefined;
  }
  if (exit === 1) {
    provider = {
      url: sp.url
    };
  }
  
} else {
  provider = undefined;
}

exports.providerToJs = providerToJs;
exports.providerFromJs = providerFromJs;
exports.provider = provider;
/* window Not a pure module */
