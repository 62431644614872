// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Typeahead$BsConsole = require("./Typeahead.js");
var InboxUtils$BsConsole = require("../inbox/InboxUtils.js");
var InboxWorkflow$BsConsole = require("../inbox/InboxWorkflow.js");

var component = ReasonReact.statelessComponent("AttributeTypeaheadForDetail-BsConsole");

function make(name, triage, checked, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.display(/* flex */-1010954439),
                                  /* :: */[
                                    Css.justifyContent(/* spaceBetween */516682146),
                                    /* :: */[
                                      Css.alignItems(/* center */98248149),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                      undefined,
                                      checked,
                                      undefined,
                                      /* Primary */-791844958,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      []
                                    ]))), React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.flexGrow(3.0),
                                    /* [] */0
                                  ])
                            }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [Belt_Option.getWithDefault(Belt_Option.map(triage ? InboxWorkflow$BsConsole.Folders.getInboxWorkflowAttributeName(name) : undefined, I18N$BsConsole.showSkip), I18N$BsConsole.showSkip(name))])))));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var AttributeItemForDetail = {
  component: component,
  make: make
};

function toString(t$prime) {
  return t$prime.attribute.name;
}

function getCount(param) {
  
}

var renderItem = (function (t$prime) {
    return ReasonReact.element(undefined, undefined, make(t$prime.attribute.name, InboxUtils$BsConsole.isNestedIssues(t$prime.attribute.name), /* `Bool */[
                    737456202,
                    t$prime.checked
                  ], []));
  });

var placeholder = I18N$BsConsole.get(undefined, "Search");

var fuzz = Util$BsConsole.Fuzz.filterAttributeForDetail;

var AttributeTypeaheadForDetail = Typeahead$BsConsole.Make({
      toString: toString,
      getCount: getCount,
      renderItem: renderItem,
      placeholder: placeholder,
      fuzz: fuzz
    });

var Fuzz = AttributeTypeaheadForDetail.Fuzz;

var Styles = AttributeTypeaheadForDetail.Styles;

var component$1 = AttributeTypeaheadForDetail.component;

var make$1 = AttributeTypeaheadForDetail.make;

exports.AttributeItemForDetail = AttributeItemForDetail;
exports.AttributeTypeaheadForDetail = AttributeTypeaheadForDetail;
exports.Fuzz = Fuzz;
exports.Styles = Styles;
exports.component = component$1;
exports.make = make$1;
/* component Not a pure module */
