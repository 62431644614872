// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");

var parentWindow = window.parent;

var currentWindow = window;

var isIFrame;

try {
  isIFrame = Caml_obj.caml_notequal(parentWindow, currentWindow);
}
catch (raw_error){
  var error = Caml_js_exceptions.internalToOCamlException(raw_error);
  if (error[0] === Js_exn.$$Error) {
    var match = error[1].name;
    isIFrame = match === "SecurityError" ? true : false;
  } else {
    isIFrame = false;
  }
}

function fromJs(jsObj) {
  var type_ = jsObj.type;
  var payload = jsObj.payload;
  if (!(type_ == null) && !(payload == null)) {
    return {
            type_: type_,
            payload: payload
          };
  }
  
}

function toJs(t) {
  var messageJs = { };
  messageJs["type"] = t.type_;
  messageJs["payload"] = t.payload;
  return messageJs;
}

var Message = {
  fromJs: fromJs,
  toJs: toJs
};

function postMessage(message) {
  if (!isIFrame) {
    return ;
  }
  var parentWindow = window.parent;
  var messageData = toJs(message);
  parentWindow.postMessage(messageData, "*");
  
}

exports.parentWindow = parentWindow;
exports.currentWindow = currentWindow;
exports.isIFrame = isIFrame;
exports.Message = Message;
exports.postMessage = postMessage;
/* parentWindow Not a pure module */
