// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Styles$BsConsole = require("./utilities/Styles.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var CopyToClipboard$BsConsole = require("./CopyToClipboard.js");

var isWindows = navigator.platform.toLowerCase().includes("win");

var H = {
  isWindows: isWindows
};

var component = ReasonReact.statelessComponent("CallstackCopyButton-BsConsole");

function renderLineNumber(doubleDigits, digit) {
  if (doubleDigits && digit < 10) {
    return "[ 0" + (String(digit) + " ]");
  } else {
    return "[ " + (String(digit) + " ]");
  }
}

function getBaseName(name) {
  return Belt_Option.flatMap(Belt_List.head(Belt_List.reverse(Belt_List.fromArray(name.split("\\")))), (function (str) {
                return Belt_List.head(Belt_List.reverse(Belt_List.fromArray(str.split("/"))));
              }));
}

function padWithSpaces(n) {
  return Belt_Array.make(n, " ").join("");
}

function getFormattedText(frames) {
  switch (frames.tag | 0) {
    case /* Frames */0 :
        var frames$1 = frames[0];
        var doubleDigits = frames$1.length >= 10;
        return Belt_Array.mapWithIndex(frames$1, (function (i, frame) {
                        return renderLineNumber(doubleDigits, i) + (" " + frame);
                      })).join(isWindows ? "\r\n" : "\n");
    case /* Callstack */1 :
        var callstackFrames = frames[0];
        var doubleDigits$1 = callstackFrames.length >= 10;
        var maxJsonFuncNameLength = Belt_Array.reduce(Belt_Array.keepMap(callstackFrames, (function (frame) {
                    if (frame.tag) {
                      return ;
                    } else {
                      return Belt_Option.map(frame[0].funcName, (function (prim) {
                                    return prim.length;
                                  }));
                    }
                  })), 0, (function (prim, prim$1) {
                if (prim > prim$1) {
                  return prim;
                } else {
                  return prim$1;
                }
              }));
        var maxBttBaseNameLength = Belt_Array.reduce(Belt_Array.keepMap(callstackFrames, (function (frame) {
                    if (frame.tag) {
                      return Belt_Option.map(frame[0].basename, (function (prim) {
                                    return prim.length;
                                  }));
                    }
                    
                  })), 0, (function (prim, prim$1) {
                if (prim > prim$1) {
                  return prim;
                } else {
                  return prim$1;
                }
              }));
        var maxBttSymbolLength = Belt_Array.reduce(Belt_Array.keepMap(callstackFrames, (function (frame) {
                    if (frame.tag) {
                      return frame[0].symbol.length;
                    }
                    
                  })), 0, (function (prim, prim$1) {
                if (prim > prim$1) {
                  return prim;
                } else {
                  return prim$1;
                }
              }));
        return Belt_Array.mapWithIndex(callstackFrames, (function (i, frame) {
                        if (frame.tag) {
                          var frame$1 = frame[0];
                          var lineNumber = renderLineNumber(doubleDigits$1, i);
                          var basename = Belt_Option.getWithDefault(frame$1.basename, "(undefined)");
                          var symbol = frame$1.symbol;
                          var match = frame$1.path;
                          var match$1 = frame$1.line;
                          var path = match !== undefined ? (
                              match$1 !== undefined ? match + (":" + match$1) : match
                            ) : undefined;
                          return lineNumber + (" " + (basename + (padWithSpaces((maxBttBaseNameLength - basename.length | 0) + 2 | 0) + (symbol + (padWithSpaces((maxBttSymbolLength - symbol.length | 0) + 2 | 0) + (
                                            path !== undefined ? "( " + (path + " )") : ""
                                          ))))));
                        }
                        var frame$2 = frame[0];
                        var lineNumber$1 = renderLineNumber(doubleDigits$1, i);
                        var renderFileLineNumber = function (lineNumber) {
                          if (lineNumber !== undefined) {
                            return ":" + String(lineNumber);
                          } else {
                            return "";
                          }
                        };
                        var match$2 = frame$2.funcName;
                        var match$3 = frame$2.address;
                        var funcName = match$2 !== undefined ? match$2 : (
                            match$3 !== undefined ? match$3 : "(undefined)"
                          );
                        var libraryFileName = Belt_Option.flatMap(frame$2.library, getBaseName);
                        var sourceCodeFileName = Belt_Option.flatMap(Belt_Option.flatMap(frame$2.sourceCode, (function (sourceCode) {
                                    return sourceCode.path;
                                  })), getBaseName);
                        var fileName;
                        var exit = 0;
                        var fileName$1;
                        if (libraryFileName !== undefined) {
                          if (sourceCodeFileName !== undefined) {
                            fileName$1 = sourceCodeFileName;
                            exit = 1;
                          } else {
                            fileName$1 = libraryFileName;
                            exit = 1;
                          }
                        } else if (sourceCodeFileName !== undefined) {
                          fileName$1 = sourceCodeFileName;
                          exit = 1;
                        } else {
                          fileName = undefined;
                        }
                        if (exit === 1) {
                          fileName = "( " + (fileName$1 + (renderFileLineNumber(frame$2.line) + " )"));
                        }
                        return lineNumber$1 + (" " + (funcName + (
                                    fileName !== undefined ? padWithSpaces((maxJsonFuncNameLength - funcName.length | 0) + 2 | 0) + fileName : ""
                                  )));
                      })).join(isWindows ? "\r\n" : "\n");
    case /* Generic */2 :
        var callstackFrames$1 = frames[0];
        var doubleDigits$2 = callstackFrames$1.length >= 10;
        return Belt_Array.mapWithIndex(callstackFrames$1, (function (i, frame) {
                        var lineNumber = renderLineNumber(doubleDigits$2, i);
                        var match = Belt_Option.flatMap(frame.path, (function (prim) {
                                if (prim == null) {
                                  return ;
                                } else {
                                  return Caml_option.some(prim);
                                }
                              }));
                        var match$1 = frame.line;
                        var path = match !== undefined ? (
                            match$1 !== undefined ? match + (":" + String(match$1)) : match
                          ) : undefined;
                        var match$2 = frame.funcName;
                        var match$3 = frame.address;
                        var funcName = (match$2 == null) ? (
                            (match$3 == null) ? "(undefined)" : match$3
                          ) : match$2;
                        return lineNumber + (" " + (funcName + (
                                    path !== undefined ? " ( " + (path + " )") : ""
                                  )));
                      })).join(isWindows ? "\r\n" : "\n");
    case /* Btt */3 :
        var callstackFrames$2 = frames[0];
        var doubleDigits$3 = callstackFrames$2.length >= 10;
        var maxBttBaseNameLength$1 = Belt_Array.reduce(Belt_Array.keepMap(callstackFrames$2, (function (frame) {
                    return frame.basename.length;
                  })), 0, (function (prim, prim$1) {
                if (prim > prim$1) {
                  return prim;
                } else {
                  return prim$1;
                }
              }));
        var maxBttSymbolLength$1 = Belt_Array.reduce(Belt_Array.keepMap(callstackFrames$2, (function (frame) {
                    return frame.symbol.length;
                  })), 0, (function (prim, prim$1) {
                if (prim > prim$1) {
                  return prim;
                } else {
                  return prim$1;
                }
              }));
        return Belt_Array.mapWithIndex(callstackFrames$2, (function (i, frame) {
                        var lineNumber = renderLineNumber(doubleDigits$3, i);
                        var basename = frame.basename;
                        var symbol = frame.symbol;
                        var match = frame.path;
                        var match$1 = frame.line;
                        var path = match !== undefined ? (
                            match$1 !== undefined ? match + (":" + match$1) : match
                          ) : undefined;
                        return lineNumber + (" " + (basename + (padWithSpaces((maxBttBaseNameLength$1 - basename.length | 0) + 2 | 0) + (symbol + (padWithSpaces((maxBttSymbolLength$1 - symbol.length | 0) + 2 | 0) + (
                                          path !== undefined ? "( " + (path + " )") : ""
                                        ))))));
                      })).join(isWindows ? "\r\n" : "\n");
    
  }
}

function make(callstack, target, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var formattedCallstack = getFormattedText(callstack);
              return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                }, I18N$BsConsole.show(undefined, "Copy callstack to clipboard")), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, CopyToClipboard$BsConsole.make(formattedCallstack, (function (param) {
                                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Callstack copied to clipboard"));
                                        }), [target]))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.callstack, reactProps.target, []);
      }));

var Jsx3 = {
  make: make$1
};

var RR;

exports.RR = RR;
exports.H = H;
exports.component = component;
exports.renderLineNumber = renderLineNumber;
exports.getBaseName = getBaseName;
exports.padWithSpaces = padWithSpaces;
exports.getFormattedText = getFormattedText;
exports.make = make;
exports.Jsx3 = Jsx3;
/* isWindows Not a pure module */
