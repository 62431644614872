// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Bug$BsConsole = require("./Bug.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BugLink$BsConsole = require("./BugLink.js");
var BugCache$BsConsole = require("./data/BugCache.js");
var BugError$BsConsole = require("./data/BugError.js");
var BugUtils$BsConsole = require("./data/BugUtils.js");
var BugLoader$BsConsole = require("./BugLoader.js");
var BugFailure$BsConsole = require("./BugFailure.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var Button = require("@material-ui/core/Button");
var GetApp = require("@material-ui/icons/GetApp");
var BugDownloadError$BsConsole = require("./BugDownloadError.js");
var BugCrdbAttributes$BsConsole = require("./data/BugCrdbAttributes.js");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");

function BugShell(Props) {
  var config = Props.config;
  var aperture = Props.aperture;
  var w = Props.width;
  var h = Props.height;
  var errorInfo = Props.errorInfo;
  var initialSelection = Props.initialSelection;
  var view = Props.view;
  var token = Props.token;
  var error = BugCache$BsConsole.useError(errorInfo.errorId, config);
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var crdbRowRemote = CoronerdGate$BsConsole.isEnoughCoronerdVersion(BugCrdbAttributes$BsConsole.selectAllAttributesVersion) ? BugCrdbAttributes$BsConsole.use(Util$BsConsole.hexToDec(errorInfo.errorId), token, projectName) : undefined;
  var exit = 0;
  var err;
  var exit$1 = 0;
  var exit$2 = 0;
  if (error !== undefined) {
    if (error.tag) {
      exit$2 = 4;
    } else {
      var error$1 = error[0];
      if (crdbRowRemote === undefined) {
        return React.createElement(Bug$BsConsole.make, {
                    config: config,
                    token: token,
                    initialSelection: initialSelection,
                    aperture: aperture,
                    error: error$1,
                    crdbRow: /* [] */0,
                    view: view,
                    width: w,
                    height: h,
                    key: BugError$BsConsole.getErrorId(error$1)
                  });
      }
      if (typeof crdbRowRemote === "number") {
        exit$2 = 4;
      } else {
        if (!crdbRowRemote.tag) {
          return React.createElement(Bug$BsConsole.make, {
                      config: config,
                      token: token,
                      initialSelection: initialSelection,
                      aperture: aperture,
                      error: error$1,
                      crdbRow: crdbRowRemote[0][1],
                      view: view,
                      width: w,
                      height: h,
                      key: BugError$BsConsole.getErrorId(error$1)
                    });
        }
        err = crdbRowRemote[0];
        exit = 2;
      }
    }
  } else {
    exit = 1;
  }
  if (exit$2 === 4) {
    if (crdbRowRemote !== undefined && typeof crdbRowRemote === "number") {
      exit = 1;
    } else {
      exit$1 = 3;
    }
  }
  if (exit$1 === 3) {
    var err$1 = error[0];
    if (err$1[0] === Task2$BsConsole.CoronerError) {
      var match$1 = err$1[1].code;
      if (typeof match$1 === "number" || match$1[0] !== 3654863) {
        err = err$1;
        exit = 2;
      } else {
        if (match$1[1] === 7) {
          return React.createElement(BugFailure$BsConsole.make, {
                      children: null
                    }, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "This error has been deleted.")), React.createElement(BugLink$BsConsole.make, {
                          route: /* ProjectDebugger2 */Block.__(19, [
                              projectName,
                              {
                                aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              "_deleted",
                                              /* Equal */Block.__(0, [/* `Int */[
                                                    3654863,
                                                    Caml_int64.zero
                                                  ]])
                                            ]), Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                  "fingerprint",
                                                  /* Equal */Block.__(0, [/* `String */[
                                                        -976970511,
                                                        errorInfo.fingerprint
                                                      ]])
                                                ]), Crdb$BsConsole.Filters.empty)), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                                /* Floating */0,
                                                /* All */6
                                              ])), aperture)),
                                state: undefined
                              }
                            ]),
                          handleChangeUrl: match.handleChangeUrl,
                          className: Css.style(/* :: */[
                                Css.important(Css.color(Css.hex(Colors$BsConsole.blue))),
                                /* :: */[
                                  Css.important(Css.textDecoration(Css.underline)),
                                  /* :: */[
                                    Css.cursor(/* pointer */-786317123),
                                    /* :: */[
                                      Css.paddingTop(Css.px(4)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Look for others like it.")
                        }));
        }
        err = err$1;
        exit = 2;
      }
    } else {
      err = err$1;
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        return React.createElement(BugLoader$BsConsole.make, {
                    children: null
                  }, React.createElement("span", undefined, I18N$BsConsole.show(undefined, "Loading ")), React.createElement("span", {
                        className: Css.style(/* :: */[
                              Css.fontWeight(Css.medium),
                              /* [] */0
                            ])
                      }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                /* String_literal */Block.__(11, [
                                    "error ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ]),
                                "error %s"
                              ]), errorInfo.errorId)), I18N$BsConsole.show(undefined, " of "), React.createElement("span", {
                        className: Css.style(/* :: */[
                              Css.fontWeight(Css.medium),
                              /* [] */0
                            ])
                      }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                /* String_literal */Block.__(11, [
                                    "fingerprint ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ]),
                                "fingerprint %s"
                              ]), BugUtils$BsConsole.formatFingerprint(errorInfo.fingerprint))));
    case 2 :
        return React.createElement(BugFailure$BsConsole.make, {
                    children: null
                  }, React.createElement("div", undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, err))), React.createElement(BugDownloadError$BsConsole.make, {
                        token: token,
                        projectName: projectName,
                        errorId: errorInfo.errorId,
                        children: (function (onClick) {
                            return React.createElement(Button.default, {
                                        variant: "contained",
                                        color: "primary",
                                        className: Css.style(/* :: */[
                                              Css.marginTop(Css.rem(1)),
                                              /* [] */0
                                            ]),
                                        onClick: onClick,
                                        children: React.createElement(Row2$BsConsole.make, {
                                              alignItems: /* center */98248149,
                                              children: null
                                            }, I18N$BsConsole.show(undefined, "Download error"), React.createElement(GetApp.default, {
                                                  className: Css.style(/* :: */[
                                                        Css.marginLeft(Css.px(8)),
                                                        /* [] */0
                                                      ]),
                                                  color: "inherit",
                                                  fontSize: "inherit"
                                                }))
                                      });
                          })
                      }));
    
  }
}

var make = BugShell;

exports.make = make;
/* Css Not a pure module */
