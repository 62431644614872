// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var TxTask$BsConsole = require("./TxTask.js");
var BtPaper$BsConsole = require("./BtPaper.js");
var Current$BsConsole = require("./saved-queries/Current.js");
var ViewNav$BsConsole = require("./ViewNav.js");
var ObjectID$BsConsole = require("./ObjectID.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var TriageData$BsConsole = require("./TriageData.js");
var WfLinkUtil$BsConsole = require("./workflow/WfLinkUtil.js");
var TriageUtils$BsConsole = require("./TriageUtils.js");
var ViewLoading$BsConsole = require("./ViewLoading.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var TriageHeader$BsConsole = require("./TriageHeader.js");
var Paper = require("@material-ui/core/Paper");
var Save = require("@material-ui/icons/Save");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var TriageDetails$BsConsole = require("./TriageDetails.js");
var Button = require("@material-ui/core/Button");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var InboxMultiTags$BsConsole = require("./inbox/InboxMultiTags.js");
var DemoProjectPrompt$BsConsole = require("./DemoProjectPrompt.js");
var SavedQueriesModel$BsConsole = require("./saved-queries/SavedQueriesModel.js");
var SavedQueriesContainer$BsConsole = require("./saved-queries/SavedQueriesContainer.js");
var ErrorsOverTimeBinChart$BsConsole = require("./ErrorsOverTimeBinChart.js");
var FingerprintDescription$BsConsole = require("./FingerprintDescription.js");
var UniqueAggregationsTask$BsConsole = require("./UniqueAggregationsTask.js");
var FetchAvailableWorkflows$BsConsole = require("./FetchAvailableWorkflows.js");
var SavedQueryBookmarkSelect$BsConsole = require("./saved-queries/SavedQueryBookmarkSelect.js");

var Style = {
  paper: BtPaper$BsConsole.mainViewMargins
};

function getDetailsFingerprintFromAperture(aperture) {
  return Belt_Array.get(Belt_Array.keepMap(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
                    if (Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) === "fingerprint") {
                      return Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))));
                    }
                    
                  })), 0);
}

function ProjectTriageDetails(Props) {
  var fold = Props.fold;
  var attributes = Props.attributes;
  var havings = Props.havings;
  var aperture = Props.aperture;
  var config = Props.config;
  var sort = Props.sort;
  var stats = Props.stats;
  var token = Props.token;
  var handleTask = Props.handleTask;
  var projectName = Props.projectName;
  Props.universe;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var route = Props.route;
  var normBy = Props.normBy;
  var similarity = Props.similarity;
  var metricsAttributesOpt = Props.metricsAttributes;
  var maybeWfLinkTx = Props.maybeWfLinkTx;
  var metricsAttributes = metricsAttributesOpt !== undefined ? metricsAttributesOpt : /* [] */0;
  var handleSetAperture = function (aperture$prime) {
    var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                  projectName,
                  {
                    aperture: aperture$prime,
                    sort: sort,
                    stats: init.stats,
                    havings: havings,
                    fold: fold,
                    normBy: normBy,
                    similarity: init.similarity
                  },
                  undefined
                ]));
  };
  var match = React.useState((function () {
          if (Belt_Option.getWithDefault(similarity, false)) {
            return /* SimilarityTab */1;
          }
          
        }));
  var setDetailsTab = match[1];
  var detailsTab = match[0];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setSelection = match$1[1];
  var apertureForQuery = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var query = TriageUtils$BsConsole.makeQuery(stats, apertureForQuery, havings, /* Details */1, sort, attributes, fold);
  var queryKey = JSON.stringify(Curry._1(Crdb$BsConsole.Query.toJson, query));
  var maybeCurrentProject = Belt_List.getBy(config.projects, (function (p) {
          return p.name === projectName;
        }));
  var uniqueCount = UniqueAggregationsTask$BsConsole.useCount(query, projectName, config, token, /* Identity */0);
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setFingerprintDescriptionRemote = match$2[1];
  var fingerprintDescriptionRemote = match$2[0];
  var match$3 = TriageData$BsConsole.useTableData(projectName, token, query, normBy, sort, attributes, uniqueCount);
  var fetchIssues = match$3[2];
  var tableDataRemote = match$3[0];
  var match$4 = TxTask$BsConsole.use(token, projectName);
  var txRemote = match$4[0];
  var match$5 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setFingerprintOfTxRemote = match$5[1];
  var fingerprintOfTxRemote = match$5[0];
  var workflows;
  if (maybeCurrentProject !== undefined) {
    workflows = FetchAvailableWorkflows$BsConsole.use(config, maybeCurrentProject, token);
  } else {
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ProjectTriage/workflows", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `string */[
          288368849,
          "Project name " + (projectName + " is not a valid project.")
        ]);
    workflows = [];
  }
  var match$6 = InboxMultiTags$BsConsole.useAllTags(projectName, token);
  var allTagsRemote = match$6[0];
  var allTags;
  allTags = typeof allTagsRemote === "number" ? [] : (
      allTagsRemote.tag ? [] : allTagsRemote[0]
    );
  var cbFailure = function (param) {
    return Curry._1(setFingerprintOfTxRemote, (function (param) {
                  return /* Success */Block.__(0, [undefined]);
                }));
  };
  var cbSuccess = function (fingerprint) {
    return Curry._1(setFingerprintOfTxRemote, (function (param) {
                  return /* Success */Block.__(0, [fingerprint]);
                }));
  };
  React.useEffect((function () {
          if (typeof tableDataRemote !== "number" && !tableDataRemote.tag) {
            var match = tableDataRemote[0];
            var requestQuery = match.requestQuery;
            FingerprintDescription$BsConsole.queryDescription(token, match.meta.tx, match.page, projectName, Curry._1(Crdb$BsConsole.Query.getAperture, requestQuery), Curry._1(Crdb$BsConsole.Query.getSort, requestQuery), Curry._1(Crdb$BsConsole.Query.getHavings, requestQuery), (function (descriptionMap) {
                    return Curry._1(setFingerprintDescriptionRemote, (function (param) {
                                  return /* Success */Block.__(0, [descriptionMap]);
                                }));
                  }), (function (err) {
                    return Curry._1(setFingerprintDescriptionRemote, (function (param) {
                                  return /* Failure */Block.__(1, [err]);
                                }));
                  }));
          }
          
        }), [tableDataRemote]);
  React.useEffect((function () {
          if (maybeWfLinkTx !== undefined) {
            Curry._1(setFingerprintOfTxRemote, (function (param) {
                    return /* Loading */1;
                  }));
            WfLinkUtil$BsConsole.getFingerprintOfTx(token, projectName, maybeWfLinkTx, cbSuccess, cbFailure);
          } else {
            Curry._1(setFingerprintOfTxRemote, (function (param) {
                    return /* Success */Block.__(0, [undefined]);
                  }));
          }
          
        }), [maybeWfLinkTx]);
  React.useEffect((function () {
          if (typeof fingerprintOfTxRemote !== "number" && !fingerprintOfTxRemote.tag && typeof tableDataRemote !== "number" && !tableDataRemote.tag) {
            var maybeTxFingerprint = fingerprintOfTxRemote[0];
            var maybeDetailsFingerprint = getDetailsFingerprintFromAperture(Curry._1(Crdb$BsConsole.Query.getAperture, tableDataRemote[0].requestQuery));
            if (maybeTxFingerprint !== undefined && maybeDetailsFingerprint !== undefined) {
              if (maybeTxFingerprint !== maybeDetailsFingerprint) {
                MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                        /* WorkflowLinkFingerprintMismatch */134,
                        Json_encode.object_(/* :: */[
                              /* tuple */[
                                "link_tx",
                                maybeWfLinkTx !== undefined ? ObjectID$BsConsole.toHexString(maybeWfLinkTx) : "n/a"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "link_correctFingerprint",
                                  maybeTxFingerprint
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "link_providedFingerprint",
                                    maybeDetailsFingerprint
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ])
                      ]));
                var __x = Curry._1(Crdb$BsConsole.Filters.fromArray, Belt_Array.keep(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
                            var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                            var filterOperation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
                            if (filterAttribute !== "fingerprint") {
                              return true;
                            }
                            if (filterOperation.tag) {
                              return true;
                            }
                            var match = filterOperation[0];
                            if (typeof match === "number") {
                              return true;
                            } else {
                              return match[0] !== -976970511;
                            }
                          })));
                handleSetAperture(Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                  "fingerprint",
                                  /* Equal */Block.__(0, [/* `String */[
                                        -976970511,
                                        maybeTxFingerprint
                                      ]])
                                ]), __x), aperture));
                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Fingerprint mismatch detected. The details view has been updated to show the correct fingerprint."));
              } else {
                Curry._1(setFingerprintOfTxRemote, (function (param) {
                        return /* Success */Block.__(0, [undefined]);
                      }));
                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* WorkflowLinkFingerprintMatch */133]));
              }
            }
            
          }
          
        }), /* tuple */[
        fingerprintOfTxRemote,
        tableDataRemote
      ]);
  React.useEffect((function () {
          Curry._1(setSelection, (function (param) {
                  return [];
                }));
          
        }), [queryKey]);
  BacktraceHooks$BsConsole.useSortRecover(sort, query, (function (param) {
          return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                        projectName,
                        Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture), Caml_option.some(havings), undefined, Caml_option.some(fold), Crdb$BsConsole.Sort.defaultAggregate, normBy, undefined, undefined),
                        undefined
                      ]));
        }));
  React.useEffect((function () {
          if (typeof tableDataRemote !== "number" && !tableDataRemote.tag) {
            var match = tableDataRemote[0];
            var rows = match.rows;
            var detailsFingerprint = getDetailsFingerprintFromAperture(Curry._1(Crdb$BsConsole.Query.getAperture, match.requestQuery));
            var selectedRow = Belt_Option.flatMap(detailsFingerprint, (function (fingerprint) {
                    return Belt_Array.get(Belt_Array.keep(rows, (function (row) {
                                      return row.fingerprint === fingerprint;
                                    })), 0);
                  }));
            if (Belt_Option.isSome(detailsFingerprint) && Belt_Option.isNone(selectedRow) && rows.length !== 0) {
              Belt_Option.map(Belt_Option.map(Belt_Array.get(rows, 0), (function (row) {
                          return row.fingerprint;
                        })), (function (fingerprint) {
                      var __x = Curry._1(Crdb$BsConsole.Filters.fromArray, Belt_Array.keep(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
                                  var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                                  var filterOperation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
                                  if (filterAttribute !== "fingerprint") {
                                    return true;
                                  }
                                  if (filterOperation.tag) {
                                    return true;
                                  }
                                  var match = filterOperation[0];
                                  if (typeof match === "number") {
                                    return true;
                                  } else {
                                    return match[0] !== -976970511;
                                  }
                                })));
                      return handleSetAperture(Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              "fingerprint",
                                              /* Equal */Block.__(0, [/* `String */[
                                                    -976970511,
                                                    fingerprint
                                                  ]])
                                            ]), __x), aperture));
                    }));
            }
            
          }
          
        }), [tableDataRemote]);
  React.useEffect((function () {
          var savedAttrs = Belt_Option.getWithDefault(Belt_Option.flatMap(config.userSettings, (function (settings) {
                      return settings.fingerprintDetailsAttrs;
                    })), /* [] */0);
          Belt_List.forEach(savedAttrs, (function (attr) {
                  if (Belt_List.has(stats, attr, (function (prim, prim$1) {
                            return prim === prim$1;
                          }))) {
                    return ;
                  }
                  var newStats = Util$BsConsole.removeDuplicates(Belt_List.concat(stats, savedAttrs));
                  return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                projectName,
                                Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture), undefined, newStats, undefined, undefined, undefined, undefined, undefined),
                                undefined
                              ]));
                }));
          
        }), ([]));
  var match$7 = React.useMemo((function () {
          if (typeof tableDataRemote === "number") {
            return /* tuple */[
                    [],
                    []
                  ];
          }
          if (tableDataRemote.tag) {
            return /* tuple */[
                    [],
                    []
                  ];
          }
          var match = tableDataRemote[0];
          return /* tuple */[
                  match.projectUsers,
                  match.rows
                ];
        }), [tableDataRemote]);
  var mode = SavedQueriesModel$BsConsole.modeOfStringExn("inbox");
  var queryForSavedQueryContainer = TriageUtils$BsConsole.makeQuery(stats, aperture, havings, /* Details */1, sort, attributes, fold);
  var tmp;
  tmp = typeof txRemote === "number" || txRemote.tag || txRemote[0] !== 1 ? null : React.createElement(DemoProjectPrompt$BsConsole.make, {
          config: config,
          token: token,
          bannerVariant: /* Triage */2,
          handleChangeUrl: handleChangeUrl,
          projectName: projectName,
          styles: Css.style(/* :: */[
                Css.transform(Css.translateY(Css.px(15))),
                /* :: */[
                  Css.marginTop(Css.px(5)),
                  /* :: */[
                    Css.marginRight(Css.px(16)),
                    /* :: */[
                      Css.marginLeft(Css.px(16)),
                      /* [] */0
                    ]
                  ]
                ]
              ])
        });
  var tmp$1;
  var exit = 0;
  var msg;
  var exit$1 = 0;
  if (typeof tableDataRemote === "number") {
    exit = 1;
  } else if (tableDataRemote.tag) {
    exit$1 = 3;
  } else {
    var match$8 = tableDataRemote[0];
    var rows = match$8.rows;
    if (typeof fingerprintOfTxRemote === "number") {
      exit$1 = 3;
    } else if (fingerprintOfTxRemote.tag) {
      msg = fingerprintOfTxRemote[0];
      exit = 2;
    } else {
      var selectedRow = Belt_Option.flatMap(getDetailsFingerprintFromAperture(Curry._1(Crdb$BsConsole.Query.getAperture, match$8.requestQuery)), (function (fingerprint) {
              return Belt_Array.get(Belt_Array.keep(rows, (function (row) {
                                return row.fingerprint === fingerprint;
                              })), 0);
            }));
      var match$9 = rows.length;
      var match$10 = Belt_List.get(match$8.groups, 0);
      var tmp$2;
      if (selectedRow !== undefined) {
        if (match$10 !== undefined && match$9 > 0) {
          var tmp$3;
          if (typeof fingerprintDescriptionRemote === "number" || fingerprintDescriptionRemote.tag) {
            tmp$3 = selectedRow;
          } else {
            var maybeDescription = Belt_MapString.get(fingerprintDescriptionRemote[0], selectedRow.fingerprint);
            tmp$3 = {
              count: selectedRow.count,
              fingerprint: selectedRow.fingerprint,
              description: maybeDescription,
              callstack: selectedRow.callstack,
              timestampRange: selectedRow.timestampRange,
              timestampBin: selectedRow.timestampBin,
              firstSeen: selectedRow.firstSeen,
              aggregations: selectedRow.aggregations,
              issues: selectedRow.issues
            };
          }
          tmp$2 = React.createElement(TriageDetails$BsConsole.Jsx3.make, {
                triageGroup: tmp$3,
                fetchIssues: fetchIssues,
                projectName: projectName,
                token: token,
                config: config,
                columns: match$8.columnDescs,
                group: match$10,
                handleTask: handleTask,
                stats: stats,
                tab: detailsTab !== undefined ? detailsTab : /* OverviewTab */0,
                handleChangeUrl: handleChangeUrl,
                aperture: aperture,
                handleAddFilters: handleAddFilters,
                handleSetDetailsTab: (function (tab) {
                    return Curry._1(setDetailsTab, (function (param) {
                                  return tab;
                                }));
                  })
              });
        } else {
          tmp$2 = I18N$BsConsole.show(undefined, "No group found");
        }
      } else {
        tmp$2 = match$10 !== undefined && match$9 > 0 ? React.createElement(ViewLoading$BsConsole.Jsx3.make, {
                label: "Fetching triage data"
              }) : I18N$BsConsole.show(undefined, "No group found");
      }
      tmp$1 = React.createElement(React.Fragment, undefined, tmp$2);
    }
  }
  if (exit$1 === 3) {
    if (typeof fingerprintOfTxRemote === "number") {
      exit = 1;
    } else {
      msg = tableDataRemote[0];
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        tmp$1 = React.createElement(ViewLoading$BsConsole.Jsx3.make, {
              label: "Fetching triage data"
            });
        break;
    case 2 :
        tmp$1 = React.createElement(Col2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* spaceAround */-485895757,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.minHeight(Css.px(400)),
                      /* :: */[
                        Css.paddingBottom(Css.px(8)),
                        /* :: */[
                          Css.fontSize(Css.px(20)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              children: I18N$BsConsole.dynamic(msg)
            });
        break;
    
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                  className: Css.merge(/* :: */[
                        BtPaper$BsConsole.mainViewMargins,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]),
                  elevation: BtPaper$BsConsole.elevation,
                  children: null
                }, React.createElement(ViewNav$BsConsole.make, {
                      config: config,
                      aperture: aperture,
                      route: route,
                      handleChangeUrl: handleChangeUrl,
                      projectName: projectName
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                              /* :: */[
                                Css.padding2(Css.px(4), Css.px(16)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: React.createElement(SavedQueriesContainer$BsConsole.make, {
                            name: projectName,
                            token: token,
                            handleTask: handleTask,
                            handleChangeUrl: handleChangeUrl,
                            config: config,
                            query: queryForSavedQueryContainer,
                            mode: mode,
                            normBy: normBy,
                            renderTrigger: (function (param) {
                                var queries = param.queries;
                                var handleOpen = param.handleOpen;
                                var disabled = Belt_Option.getWithDefault(Belt_Option.map(queries, (function (savedQueries) {
                                            var mode = SavedQueriesModel$BsConsole.modeOfStringExn("inbox");
                                            var query = TriageUtils$BsConsole.makeQuery(stats, aperture, havings, /* Details */1, sort, attributes, fold);
                                            var match = Current$BsConsole.get(mode, query, savedQueries);
                                            if (match) {
                                              return true;
                                            } else {
                                              return false;
                                            }
                                          })), false);
                                var tmp = {
                                  projectBookmarkedViews: param.projectBookmarkedViews,
                                  userBookmarkedViews: param.userBookmarkedViews,
                                  projectDefault: param.projectDefault,
                                  userDefault: param.userDefault,
                                  queries: queries,
                                  handleOpen: (function (param) {
                                      return Curry._2(handleOpen, undefined, undefined);
                                    }),
                                  handleChangeUrl: handleChangeUrl,
                                  currentMode: SavedQueriesModel$BsConsole.modeOfStringExn("inbox"),
                                  projectName: projectName
                                };
                                if (queryForSavedQueryContainer !== undefined) {
                                  tmp.query = Caml_option.valFromOption(queryForSavedQueryContainer);
                                }
                                return React.createElement(React.Fragment, undefined, React.createElement(SavedQueryBookmarkSelect$BsConsole.make, tmp), React.createElement(Button.default, {
                                                color: "primary",
                                                className: Css.style(/* :: */[
                                                      Css.marginRight(Css.px(8)),
                                                      /* :: */[
                                                        Css.display(/* flex */-1010954439),
                                                        /* :: */[
                                                          Css.alignItems(/* center */98248149),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]),
                                                disabled: disabled,
                                                onClick: (function (param) {
                                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ViewManagerSaveView */120]));
                                                    return Curry._2(handleOpen, undefined, /* Save */0);
                                                  }),
                                                children: null
                                              }, React.createElement(Save.default, {
                                                    className: Css.style(/* :: */[
                                                          Css.marginRight(Css.px(8)),
                                                          /* [] */0
                                                        ]),
                                                    color: "inherit"
                                                  }), I18N$BsConsole.show(undefined, "Save view")));
                              })
                          })
                    })), tmp, React.createElement(Paper.default, {
                  className: BtPaper$BsConsole.mainViewMargins,
                  elevation: BtPaper$BsConsole.elevation,
                  children: null
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.position(/* relative */903134412),
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(TriageHeader$BsConsole.MultiSelectRow.Jsx3.make, {
                          selection: match$1[0],
                          setSelection: (function (selection) {
                              return Curry._1(setSelection, (function (param) {
                                            return selection;
                                          }));
                            }),
                          aperture: aperture,
                          config: config,
                          projectUsers: match$7[0],
                          allTags: allTags,
                          token: token,
                          fetchIssues: fetchIssues,
                          projectName: projectName,
                          triageGroups: match$7[1],
                          goToDetails: (function (fingerprint) {
                              return Curry._1(handleChangeUrl, Curry._1(handleAddFilters, /* :: */[
                                              /* tuple */[
                                                "fingerprint",
                                                /* Equal */Block.__(0, [/* `String */[
                                                      -976970511,
                                                      fingerprint
                                                    ]])
                                              ],
                                              /* [] */0
                                            ]));
                            }),
                          workflows: workflows,
                          handleChangeUrl: handleChangeUrl,
                          refreshAllTags: match$6[1],
                          sort: sort
                        }), React.createElement(ErrorsOverTimeBinChart$BsConsole.make, {
                          token: token,
                          projectName: projectName,
                          aperture: apertureForQuery,
                          onRangeChange: (function (range) {
                              return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                            projectName,
                                            Route$BsConsole.getInboxParams(projectName, Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture)), Caml_option.some(havings), undefined, Caml_option.some(fold), sort, normBy, undefined, undefined),
                                            undefined
                                          ]));
                            }),
                          className: Css.style(/* :: */[
                                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                                /* [] */0
                              ]),
                          normBy: normBy,
                          metricsAttributes: metricsAttributes
                        })), tmp$1));
}

var make = ProjectTriageDetails;

exports.Style = Style;
exports.getDetailsFingerprintFromAperture = getDetailsFingerprintFromAperture;
exports.make = make;
/* Css Not a pure module */
