// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Validator = require("validator");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var Format$BsConsole = require("../bs-crdb-response/src/Format.js");
var BugLink$BsConsole = require("./BugLink.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var Clipboard$BsConsole = require("../bindings/Clipboard.js");
var Attributes$BsConsole = require("../bs-crdb-response/src/Attributes.js");
var FormatType$BsConsole = require("../bs-crdb-response/src/FormatType.js");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");

function hasPopover(t) {
  return Belt_Option.isSome(t.popover);
}

function isHovered(t) {
  return t.isHovered;
}

function isProjectAttribute(t) {
  return Belt_Option.isSome(t.projectAttribute);
}

function stringOfNumber(n) {
  var nStr = Pervasives.string_of_float(n);
  if (nStr.endsWith(".")) {
    return nStr.slice(0, -1);
  } else {
    return nStr;
  }
}

function getAttributeValueString(projectAttribute, attributeName, attributeValue) {
  var t = Belt_Option.flatMap(projectAttribute, (function (pa) {
          return Format$BsConsole.parse2PointOh(Attributes$BsConsole.type_FromJs(pa.type_), FormatType$BsConsole.fromString(pa.format), attributeName, attributeValue);
        }));
  if (t !== undefined) {
    return Format$BsConsole.toString(t);
  }
  var s = Js_json.classify(attributeValue);
  if (typeof s === "number") {
    switch (s) {
      case /* JSONFalse */0 :
          return "false";
      case /* JSONTrue */1 :
          return "true";
      case /* JSONNull */2 :
          return "null";
      
    }
  } else {
    switch (s.tag | 0) {
      case /* JSONString */0 :
          return s[0];
      case /* JSONNumber */1 :
          return stringOfNumber(s[0]);
      case /* JSONObject */2 :
      case /* JSONArray */3 :
          return Belt_Option.getWithDefault(JSON.stringify(s[0]), "");
      
    }
  }
}

function getAttributeRawValueString(attributeValue) {
  var s = Js_json.classify(attributeValue);
  if (typeof s === "number") {
    switch (s) {
      case /* JSONFalse */0 :
          return "false";
      case /* JSONTrue */1 :
          return "true";
      case /* JSONNull */2 :
          return "null";
      
    }
  } else {
    switch (s.tag | 0) {
      case /* JSONString */0 :
          return s[0];
      case /* JSONNumber */1 :
          return stringOfNumber(s[0]);
      case /* JSONObject */2 :
      case /* JSONArray */3 :
          return Belt_Option.getWithDefault(JSON.stringify(s[0]), "");
      
    }
  }
}

function clipboardMessage(valueString) {
  return Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Attribute copied to clipboard ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* End_of_format */0
                        ])
                    ]),
                  "Attribute copied to clipboard %s"
                ]), valueString);
}

var Model = {
  hasPopover: hasPopover,
  isHovered: isHovered,
  isProjectAttribute: isProjectAttribute,
  stringOfNumber: stringOfNumber,
  getAttributeValueString: getAttributeValueString,
  getAttributeRawValueString: getAttributeRawValueString,
  clipboardMessage: clipboardMessage
};

function update(action) {
  if (typeof action === "number") {
    switch (action) {
      case /* MouseEnter */0 :
          return (function (model) {
              return {
                      attributeName: model.attributeName,
                      attributeValue: model.attributeValue,
                      handleChangeUrl: model.handleChangeUrl,
                      handleAddFilter: model.handleAddFilter,
                      isHovered: true,
                      popover: model.popover,
                      projectAttribute: model.projectAttribute,
                      projectName: model.projectName,
                      route: model.route
                    };
            });
      case /* MouseLeave */1 :
          return (function (model) {
              return {
                      attributeName: model.attributeName,
                      attributeValue: model.attributeValue,
                      handleChangeUrl: model.handleChangeUrl,
                      handleAddFilter: model.handleAddFilter,
                      isHovered: false,
                      popover: model.popover,
                      projectAttribute: model.projectAttribute,
                      projectName: model.projectName,
                      route: model.route
                    };
            });
      case /* PopoverClose */2 :
          return (function (model) {
              return {
                      attributeName: model.attributeName,
                      attributeValue: model.attributeValue,
                      handleChangeUrl: model.handleChangeUrl,
                      handleAddFilter: model.handleAddFilter,
                      isHovered: model.isHovered,
                      popover: undefined,
                      projectAttribute: model.projectAttribute,
                      projectName: model.projectName,
                      route: model.route
                    };
            });
      case /* ValueCopy */3 :
          return (function (model) {
              var match = model.attributeName;
              var textToCopy = match === "_tx" ? getAttributeValueString(model.projectAttribute, model.attributeName, model.attributeValue) : getAttributeRawValueString(model.attributeValue);
              Clipboard$BsConsole.copy(textToCopy, undefined, undefined);
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, clipboardMessage(textToCopy));
              return {
                      attributeName: model.attributeName,
                      attributeValue: model.attributeValue,
                      handleChangeUrl: model.handleChangeUrl,
                      handleAddFilter: model.handleAddFilter,
                      isHovered: model.isHovered,
                      popover: undefined,
                      projectAttribute: model.projectAttribute,
                      projectName: model.projectName,
                      route: model.route
                    };
            });
      case /* ValueFilterAgainst */4 :
          return (function (model) {
              var filter = Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                    model.attributeName,
                    /* NotEqual */Block.__(1, [/* `String */[
                          -976970511,
                          getAttributeRawValueString(model.attributeValue)
                        ]])
                  ]);
              Curry._1(model.handleAddFilter, filter);
              return {
                      attributeName: model.attributeName,
                      attributeValue: model.attributeValue,
                      handleChangeUrl: model.handleChangeUrl,
                      handleAddFilter: model.handleAddFilter,
                      isHovered: model.isHovered,
                      popover: undefined,
                      projectAttribute: model.projectAttribute,
                      projectName: model.projectName,
                      route: model.route
                    };
            });
      case /* ValueFilterTo */5 :
          return (function (model) {
              var match = model.attributeName;
              var filterToString = match === "_tx" ? getAttributeValueString(model.projectAttribute, model.attributeName, model.attributeValue) : getAttributeRawValueString(model.attributeValue);
              var filter = Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                    model.attributeName,
                    /* Equal */Block.__(0, [/* `String */[
                          -976970511,
                          filterToString
                        ]])
                  ]);
              Curry._1(model.handleAddFilter, filter);
              return {
                      attributeName: model.attributeName,
                      attributeValue: model.attributeValue,
                      handleChangeUrl: model.handleChangeUrl,
                      handleAddFilter: model.handleAddFilter,
                      isHovered: model.isHovered,
                      popover: undefined,
                      projectAttribute: model.projectAttribute,
                      projectName: model.projectName,
                      route: model.route
                    };
            });
      case /* ValueVisitUrl */6 :
          return (function (model) {
              Util$BsConsole.openUrl(getAttributeRawValueString(model.attributeValue));
              return {
                      attributeName: model.attributeName,
                      attributeValue: model.attributeValue,
                      handleChangeUrl: model.handleChangeUrl,
                      handleAddFilter: model.handleAddFilter,
                      isHovered: model.isHovered,
                      popover: undefined,
                      projectAttribute: model.projectAttribute,
                      projectName: model.projectName,
                      route: model.route
                    };
            });
      
    }
  } else {
    var popover = action[0];
    return (function (model) {
        return {
                attributeName: model.attributeName,
                attributeValue: model.attributeValue,
                handleChangeUrl: model.handleChangeUrl,
                handleAddFilter: model.handleAddFilter,
                isHovered: model.isHovered,
                popover: Caml_option.some(popover),
                projectAttribute: model.projectAttribute,
                projectName: model.projectName,
                route: model.route
              };
      });
  }
}

function popoverOpen($$event) {
  return /* PopoverOpen */[$$event.target];
}

var Action = {
  update: update,
  popoverOpen: popoverOpen
};

function attributeName(model) {
  return Css.style(/* :: */[
              Css.color(Css.hex(Belt_Option.isSome(model.projectAttribute) ? Colors$BsConsole.grey95 : Colors$BsConsole.grey45)),
              /* :: */[
                model.isHovered ? Css.fontWeight(/* bolder */-534575822) : Css.fontWeight(/* medium */-20425611),
                /* :: */[
                  Css.overflow(Css.hidden),
                  /* :: */[
                    Css.padding2(Css.px(4), Css.zero),
                    /* :: */[
                      Css.textOverflow(Css.ellipsis),
                      /* :: */[
                        Css.whiteSpace(Css.nowrap),
                        /* :: */[
                          Css.width(Css.px(175)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function attributeValue(model) {
  return Css.style(/* :: */[
              Css.color(Css.hex(model.isHovered ? Colors$BsConsole.grey0 : Colors$BsConsole.grey2)),
              /* :: */[
                Css.overflow(Css.hidden),
                /* :: */[
                  Css.textOverflow(Css.ellipsis),
                  /* :: */[
                    Css.whiteSpace(Css.nowrap),
                    /* :: */[
                      Css.paddingLeft(Css.px(16)),
                      /* :: */[
                        Css.padding2(Css.px(4), Css.zero),
                        /* :: */[
                          BugStyles$BsConsole.monospaceFont,
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var bugLink = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.blue))),
      /* :: */[
        Css.cursor(/* pointer */-786317123),
        /* :: */[
          Css.paddingTop(Css.px(3)),
          /* :: */[
            Css.important(Css.textDecoration(Css.underline)),
            /* [] */0
          ]
        ]
      ]
    ]);

var container = Css.style(/* :: */[
      Css.cursor(/* pointer */-786317123),
      /* :: */[
        Css.display(Css.contents),
        /* [] */0
      ]
    ]);

var menuListUnknownColumn = Css.style(/* :: */[
      Css.fontSize(Css.px(12)),
      /* :: */[
        Css.padding2(Css.px(3), Css.px(9)),
        /* [] */0
      ]
    ]);

var popoverPaper = Css.style(/* :: */[
      Css.maxWidth(Css.px(180)),
      /* [] */0
    ]);

var Style = {
  attributeName: attributeName,
  attributeValue: attributeValue,
  bugLink: bugLink,
  container: container,
  menuListUnknownColumn: menuListUnknownColumn,
  popoverPaper: popoverPaper
};

function renderVisitUrlMenuListItem(dispatch, model) {
  var attributeValue = getAttributeRawValueString(model.attributeValue);
  if (Validator.isURL(attributeValue, {
          require_protocol: true
        })) {
    return React.createElement(MenuItem.default, {
                onClick: (function (param) {
                    return Curry._1(dispatch, /* ValueVisitUrl */6);
                  }),
                children: I18N$BsConsole.show(undefined, "Visit url")
              });
  } else {
    return null;
  }
}

function renderProjectAttributeMenuList(dispatch, attribute, model) {
  return React.createElement(MenuList.default, {
              children: null
            }, React.createElement(MenuItem.default, {
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* ValueCopy */3);
                    }),
                  children: I18N$BsConsole.show(undefined, "Copy")
                }), renderVisitUrlMenuListItem(dispatch, model), React.createElement(MenuItem.default, {
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* ValueFilterTo */5);
                    }),
                  children: I18N$BsConsole.show(undefined, "Filter to")
                }), React.createElement(MenuItem.default, {
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* ValueFilterAgainst */4);
                    }),
                  children: I18N$BsConsole.show(undefined, "Filter against")
                }), React.createElement(Divider.default, { }), React.createElement(MenuList.default, {
                  children: React.createElement(Col2$BsConsole.make, {
                        className: menuListUnknownColumn,
                        children: React.createElement("div", undefined, I18N$BsConsole.dynamic(attribute.description))
                      })
                }));
}

function renderProjectAttributeMenuListWithoutFilters(dispatch, attribute, model) {
  return React.createElement(MenuList.default, {
              children: null
            }, React.createElement(MenuItem.default, {
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* ValueCopy */3);
                    }),
                  children: I18N$BsConsole.show(undefined, "Copy")
                }), renderVisitUrlMenuListItem(dispatch, model), React.createElement(Divider.default, { }), React.createElement(MenuList.default, {
                  children: React.createElement(Col2$BsConsole.make, {
                        className: menuListUnknownColumn,
                        children: React.createElement("div", undefined, I18N$BsConsole.dynamic(attribute.description))
                      })
                }));
}

function renderUnknownAttributeMenuList(dispatch, model) {
  return React.createElement(React.Fragment, undefined, React.createElement(MenuList.default, {
                  children: null
                }, React.createElement(MenuItem.default, {
                      onClick: (function (param) {
                          return Curry._1(dispatch, /* ValueCopy */3);
                        }),
                      children: I18N$BsConsole.show(undefined, "Copy")
                    }), renderVisitUrlMenuListItem(dispatch, model)), React.createElement(Divider.default, { }), React.createElement(MenuList.default, {
                  children: React.createElement(Col2$BsConsole.make, {
                        className: menuListUnknownColumn,
                        children: null
                      }, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "This is a non-indexed attribute and can not be queried.")), React.createElement(BugLink$BsConsole.make, {
                            route: model.route,
                            handleChangeUrl: model.handleChangeUrl,
                            className: bugLink,
                            children: I18N$BsConsole.show(undefined, "Add it as a custom attribute.")
                          }))
                }));
}

function render(dispatch, model) {
  var attr = model.projectAttribute;
  var match = model.projectAttribute;
  var match$1 = Js_json.classify(model.attributeValue);
  var tmp;
  if (match !== undefined) {
    if (typeof match$1 === "number") {
      tmp = match$1 === /* JSONNull */2 ? renderProjectAttributeMenuListWithoutFilters(dispatch, match, model) : renderProjectAttributeMenuList(dispatch, match, model);
    } else {
      switch (match$1.tag | 0) {
        case /* JSONObject */2 :
        case /* JSONArray */3 :
            tmp = renderProjectAttributeMenuListWithoutFilters(dispatch, match, model);
            break;
        default:
          tmp = renderProjectAttributeMenuList(dispatch, match, model);
      }
    }
  } else {
    tmp = renderUnknownAttributeMenuList(dispatch, model);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: container,
                  onClick: (function ($$event) {
                      return Curry._1(dispatch, /* PopoverOpen */[$$event.target]);
                    }),
                  onMouseEnter: (function (param) {
                      return Curry._1(dispatch, /* MouseEnter */0);
                    }),
                  onMouseLeave: (function (param) {
                      return Curry._1(dispatch, /* MouseLeave */1);
                    })
                }, React.createElement(Tooltip.default, {
                      placement: "top-start",
                      enterDelay: 750,
                      children: React.createElement("div", {
                            className: attributeName(model)
                          }, I18N$BsConsole.dynamic(model.attributeName)),
                      title: attr !== undefined ? attr.description : Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String_literal */Block.__(11, [
                                          " is a non-indexed attribute and can not be queried. Click for steps to index.",
                                          /* End_of_format */0
                                        ])
                                    ]),
                                  "%s is a non-indexed attribute and can not be queried. Click for steps to index."
                                ]), model.attributeName)
                    }), React.createElement(Tooltip.default, {
                      placement: "top-start",
                      enterDelay: 750,
                      children: React.createElement("div", {
                            className: attributeValue(model)
                          }, I18N$BsConsole.dynamic(getAttributeValueString(model.projectAttribute, model.attributeName, model.attributeValue))),
                      title: getAttributeValueString(model.projectAttribute, model.attributeName, model.attributeValue)
                    })), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: model.popover,
                  open: Belt_Option.isSome(model.popover),
                  classes: {
                    paper: popoverPaper
                  },
                  onClose: (function (param) {
                      return Curry._1(dispatch, /* PopoverClose */2);
                    }),
                  children: tmp
                }));
}

function BugAttributeRow(Props) {
  var onAddFilter = Props.onAddFilter;
  var attributeName = Props.attributeName;
  var value = Props.value;
  var projectAttribute = Props.projectAttribute;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var handleChangeUrl = match.handleChangeUrl;
  var match$1 = React.useState((function () {
          return {
                  attributeName: attributeName,
                  attributeValue: value,
                  handleChangeUrl: handleChangeUrl,
                  handleAddFilter: onAddFilter,
                  isHovered: false,
                  popover: undefined,
                  projectAttribute: projectAttribute,
                  projectName: projectName,
                  route: /* ProjectSettingsAttributes */Block.__(34, [
                      projectName,
                      {
                        attribute: attributeName
                      }
                    ])
                };
        }));
  var setModel = match$1[1];
  var dispatch = function (action) {
    return Curry._1(setModel, update(action));
  };
  return render(dispatch, match$1[0]);
}

var make = BugAttributeRow;

exports.Model = Model;
exports.Action = Action;
exports.Style = Style;
exports.renderVisitUrlMenuListItem = renderVisitUrlMenuListItem;
exports.renderProjectAttributeMenuList = renderProjectAttributeMenuList;
exports.renderProjectAttributeMenuListWithoutFilters = renderProjectAttributeMenuListWithoutFilters;
exports.renderUnknownAttributeMenuList = renderUnknownAttributeMenuList;
exports.render = render;
exports.make = make;
/* bugLink Not a pure module */
