// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var Services$BsConsole = require("../Services.js");
var StorageFetch$BsConsole = require("../StorageFetch.js");
var StorageBanner$BsConsole = require("../StorageBanner.js");
var BannerPurveyor$BsConsole = require("../utilities/BannerPurveyor.js");
var IdentityVariant$BsConsole = require("../IdentityVariant.js");
var ApiBillingLimits$BsConsole = require("../ApiBillingLimits.js");

function percentConsumed(used, limit) {
  if (limit <= 0.0) {
    return 100;
  } else {
    return used * 100 / limit;
  }
}

function showErrorNotification(percentage, config) {
  if (percentage >= 75 && SubUtil$BsConsole.canModifyNemesisPlans(config)) {
    return IdentityVariant$BsConsole.current !== /* Unity */2;
  } else {
    return false;
  }
}

function getPercentUsageForErrors(counter, limit) {
  var errorsUsed = counter;
  var errorLimit = limit;
  return percentConsumed(errorsUsed, errorLimit);
}

function getStorageUsed(limits, universe) {
  var storage = limits.storage;
  if (storage !== undefined) {
    return storage.counter;
  } else {
    return Curry._1(StorageFetch$BsConsole.StorageData.Usage.asBytes, Belt_Array.reduce(universe.projects, 0, (function (acc, project) {
                      return acc + project.total;
                    })));
  }
}

function getErrorUsage(limits, config) {
  var limit = limits.submissions.limit;
  if (!limit) {
    return ;
  }
  var percentUsage = getPercentUsageForErrors(limits.submissions.counter, limit[0]);
  var rejected = limits.submissions.rejected;
  if (showErrorNotification(percentUsage, config)) {
    return /* tuple */[
            percentUsage,
            rejected
          ];
  }
  
}

function getMonthlyErrorUsageNotification(handleChangeUrl, errorUsage) {
  var cta = {
    title: I18N$BsConsole.get(undefined, "Upgrade plan"),
    action: (function (param) {
        return Curry._1(handleChangeUrl, /* SettingsSubscriptions */20);
      })
  };
  if (errorUsage === undefined) {
    return ;
  }
  var daysFloatUntilNewMonth = SubUtil$BsConsole.getDaysUntilNextMonth(Date.now());
  var daysUntilNewMonth = daysFloatUntilNewMonth | 0;
  var message = Curry._3(I18N$BsConsole.getf(undefined, /* Format */[
            /* String_literal */Block.__(11, [
                "You have used ",
                /* Float */Block.__(8, [
                    /* Float_f */0,
                    /* No_padding */0,
                    /* Lit_precision */[0],
                    /* Char_literal */Block.__(12, [
                        /* "%" */37,
                        /* String_literal */Block.__(11, [
                            " of your monthly error usage and have ",
                            /* Int */Block.__(4, [
                                /* Int_d */0,
                                /* No_padding */0,
                                /* No_precision */0,
                                /* String_literal */Block.__(11, [
                                    " error rejections. You have ",
                                    /* Int */Block.__(4, [
                                        /* Int_d */0,
                                        /* No_padding */0,
                                        /* No_precision */0,
                                        /* String_literal */Block.__(11, [
                                            " more days until this resets.",
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])
              ]),
            "You have used %.0f%% of your monthly error usage and have %d error rejections. You have %d more days until this resets."
          ]), errorUsage[0], errorUsage[1], daysUntilNewMonth);
  return {
          bannerItem: {
            message: message,
            cta: cta
          },
          cache: {
            id: "error_limit_near_capacity",
            minutes: 1440
          },
          el: undefined
        };
}

function getStorageUsageNotification(param) {
  var handleChangeUrl = param[3];
  var storageRejected = param[2];
  var totalStorageUsed = param[1];
  var storageLimit = param[0];
  return {
          bannerItem: undefined,
          cache: totalStorageUsed >= storageLimit ? ({
                id: "storage_limit_at_capacity",
                minutes: 1440
              }) : ({
                id: "storage_limit_near_capacity",
                minutes: 1440
              }),
          el: (function (onClose) {
              return ReasonReact.element(undefined, undefined, StorageBanner$BsConsole.Jsx2.make(totalStorageUsed, storageLimit, storageRejected, handleChangeUrl, onClose, []));
            })
        };
}

function enqueueBanner(bannerMaybe) {
  if (bannerMaybe !== undefined) {
    return BannerPurveyor$BsConsole.enqueue(bannerMaybe);
  }
  
}

function errorBannerOnly(config, limits, handleChangeUrl) {
  var errorUsage = getErrorUsage(limits, config);
  return enqueueBanner(getMonthlyErrorUsageNotification(handleChangeUrl, errorUsage));
}

function enqueueUsageLimitsNotifications(config, token, limits, handleChangeUrl) {
  var showStorageNotification = function (percentage) {
    if (percentage >= 75) {
      return IdentityVariant$BsConsole.current !== /* Unity */2;
    } else {
      return false;
    }
  };
  var endpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "storage");
  if (endpoint === undefined) {
    return errorBannerOnly(config, limits, handleChangeUrl);
  }
  var arg = StorageFetch$BsConsole.makeTask(endpoint + "/universe");
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (resp) {
                if (resp.tag) {
                  return errorBannerOnly(config, limits, handleChangeUrl);
                }
                var totalStorageUsed = getStorageUsed(limits, resp[0]);
                var storageLimitMaybe = ApiBillingLimits$BsConsole.getStorageLimitInBytes(limits);
                var storageRejected = ApiBillingLimits$BsConsole.getStorageRejected(limits);
                if (storageLimitMaybe === undefined) {
                  return errorBannerOnly(config, limits, handleChangeUrl);
                }
                var percentUsageForStorage = percentConsumed(totalStorageUsed, storageLimitMaybe);
                var notificationMaybe = showStorageNotification(percentUsageForStorage) ? getStorageUsageNotification(/* tuple */[
                        storageLimitMaybe,
                        totalStorageUsed,
                        storageRejected,
                        handleChangeUrl
                      ]) : undefined;
                errorBannerOnly(config, limits, handleChangeUrl);
                return enqueueBanner(notificationMaybe);
              }));
}

exports.percentConsumed = percentConsumed;
exports.showErrorNotification = showErrorNotification;
exports.getPercentUsageForErrors = getPercentUsageForErrors;
exports.getStorageUsed = getStorageUsed;
exports.getErrorUsage = getErrorUsage;
exports.getMonthlyErrorUsageNotification = getMonthlyErrorUsageNotification;
exports.getStorageUsageNotification = getStorageUsageNotification;
exports.enqueueBanner = enqueueBanner;
exports.errorBannerOnly = errorBannerOnly;
exports.enqueueUsageLimitsNotifications = enqueueUsageLimitsNotifications;
/* ReasonReact Not a pure module */
