// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Fuzz$BsConsole = require("./bindings/Fuzz.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var BtSelect$BsConsole = require("./BtSelect.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var Paper = require("@material-ui/core/Paper");
var InboxDescribe$BsConsole = require("./inbox/InboxDescribe.js");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");
var Button = require("@material-ui/core/Button");
var Popover = require("@material-ui/core/Popover");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var TextField = require("@material-ui/core/TextField");
var AttributeTypeahead$BsConsole = require("./typeahead/AttributeTypeahead.js");
var ListItemText = require("@material-ui/core/ListItemText");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown");

function setRef(theRef, param) {
  param.state.myRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
  
}

function focusChildInput(inputRef) {
  var r = inputRef.contents;
  if (r === undefined) {
    return ;
  }
  var domElement = ReactDom.findDOMNode(Caml_option.valFromOption(r));
  domElement.querySelector("input").focus();
  domElement.querySelector("input").select();
  
}

var component = ReasonReact.reducerComponent("FactorSelect-BsConsole");

function make(children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              setTimeout((function (param) {
                      return focusChildInput(self.state.myRef);
                    }), 300);
              
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return Curry._1(children, Curry._1(self.handle, setRef));
            }),
          initialState: (function (param) {
              return {
                      myRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          myRef: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var AutoFocusInput = {
  setRef: setRef,
  focusChildInput: focusChildInput,
  component: component,
  make: make
};

var smellyNone = {
  custom: false,
  aggregate: /* [] */0,
  description: "Group by none",
  filter: /* [] */0,
  format: "",
  group: true,
  nestedIssues: false,
  name: "None",
  type_: "",
  statistics: {
    used: true
  }
};

var toolbar = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.unsafe("backgroundColor", "inherit"),
          /* [] */0
        ]
      ]
    ]);

var iconContainer = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* [] */0
      ]
    ]);

var label = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.position(/* absolute */-1013592457),
          /* [] */0
        ]
      ]
    ]);

var menuItemClasses = {
  root: Css.style(/* :: */[
        Css.paddingLeft(Css.px(32)),
        /* :: */[
          Css.paddingRight(Css.px(32)),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.justifyContent(/* spaceBetween */516682146),
              /* :: */[
                Css.hover(/* :: */[
                      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
                      /* [] */0
                    ]),
                /* :: */[
                  Css.focus(/* :: */[
                        Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ])
};

var currentFactorMenuItemClasses = {
  root: Css.style(/* :: */[
        Css.paddingLeft(Css.px(32)),
        /* :: */[
          Css.paddingRight(Css.px(32)),
          /* :: */[
            Css.position(/* relative */903134412),
            /* :: */[
              Css.opacity(1),
              /* :: */[
                Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
                /* :: */[
                  Css.before(/* :: */[
                        Css.fontFamily("'Material Icons'"),
                        /* :: */[
                          Css.unsafe("content", "'check'"),
                          /* :: */[
                            Css.color(Css.hex(Colors$BsConsole.accent)),
                            /* :: */[
                              Css.position(/* absolute */-1013592457),
                              /* :: */[
                                Css.left(Css.px(8)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ])
};

var chip = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(8)),
      /* :: */[
        Css.borderRadius(Css.px(20)),
        /* :: */[
          Css.fontSize(Css.px(10)),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  400
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var defaultChip = Css.merge(/* :: */[
      chip,
      /* :: */[
        Css.style(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey3)),
              /* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.grey675)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var customChip = Css.merge(/* :: */[
      chip,
      /* :: */[
        Css.style(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.white)),
              /* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.blue)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var Style = {
  toolbar: toolbar,
  iconContainer: iconContainer,
  label: label,
  menuItemClasses: menuItemClasses,
  currentFactorMenuItemClasses: currentFactorMenuItemClasses,
  chip: chip,
  defaultChip: defaultChip,
  customChip: customChip
};

var component$1 = ReasonReact.statelessComponent("FactorSelect-BsConsole");

function make$1(attributes, fold, havings, aperture, handleChangeUrl, normBy, projectName, factor, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              var tmp;
              if (factor) {
                var factor$prime = factor[0];
                var name = InboxWorkflow$BsConsole.Folders.getInboxWorkflowAttributeName(factor$prime);
                tmp = name !== undefined ? name : factor$prime;
              } else {
                tmp = "fingerprint";
              }
              var value;
              if (factor) {
                var factor$prime$1 = factor[0];
                var name$1 = InboxWorkflow$BsConsole.Folders.getInboxWorkflowAttributeName(factor$prime$1);
                value = name$1 !== undefined ? name$1 : factor$prime$1;
              } else {
                value = "fingerprint";
              }
              var tmp$1;
              if (factor) {
                var factor$prime$2 = factor[0];
                var name$2 = InboxWorkflow$BsConsole.Folders.getInboxWorkflowAttributeName(factor$prime$2);
                tmp$1 = name$2 !== undefined ? name$2 : factor$prime$2;
              } else {
                tmp$1 = "fingerprint";
              }
              return ReasonReact.element(tmp, undefined, BtSelect$BsConsole.make((function (_e, _v) {
                                
                              }), /* `String */[
                              -976970511,
                              value
                            ], Css.style(/* :: */[
                                  Css.marginRight(Css.rem(0.5)),
                                  /* :: */[
                                    Css.unsafe("backgroundColor", "inherit"),
                                    /* [] */0
                                  ]
                                ]), I18N$BsConsole.get(undefined, "Group by"), (function (value) {
                                return React.createElement("span", undefined, I18N$BsConsole.showSkip(value));
                              }), undefined, undefined, undefined, undefined, undefined, [
                              ReasonReact.element("current-selection", undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, factor ? /* `String */[
                                          -976970511,
                                          factor[0]
                                        ] : /* `String */[
                                          -976970511,
                                          "fingerprint"
                                        ], true, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Current selection")), Caml_option.some(I18N$BsConsole.showSkip(tmp$1)), []))])),
                              ReasonReact.element("input", undefined, make((function (setRef) {
                                          return ReasonReact.element(undefined, setRef, Curry.app(AttributeTypeahead$BsConsole.make, [
                                                          undefined,
                                                          undefined,
                                                          List.filter(InboxDescribe$BsConsole.group)(List.filter((function (attribute) {
                                                                        return attribute.group;
                                                                      }))(/* :: */[
                                                                    smellyNone,
                                                                    attributes
                                                                  ])),
                                                          (function (attr) {
                                                              var custom = attr.name;
                                                              var factor = custom === "fingerprint" ? /* Identity */0 : /* Custom */[custom];
                                                              var init = Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), Caml_option.some(havings), projectName, undefined, Caml_option.some(fold), undefined, normBy, undefined, undefined);
                                                              return Curry._1(handleChangeUrl, /* ProjectQueryBuilder */Block.__(13, [
                                                                            projectName,
                                                                            {
                                                                              aperture: init.aperture,
                                                                              factor: factor,
                                                                              havings: init.havings,
                                                                              fold: init.fold,
                                                                              sort: init.sort,
                                                                              normBy: init.normBy,
                                                                              highlightExporter: init.highlightExporter
                                                                            }
                                                                          ]));
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          []
                                                        ]));
                                        })))
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

function prepareAttributeForSort(attribute) {
  var match = attribute.name;
  var match$1 = attribute.custom;
  if (match === "None") {
    return "AAAA_" + attribute.name;
  } else if (match$1) {
    return "AA_" + attribute.name;
  } else {
    return "_" + attribute.name;
  }
}

function FactorSelect$Jsx3(Props) {
  var onFactorUpdate = Props.onFactorUpdate;
  var currentFactor = Props.currentFactor;
  var attributes = Props.attributes;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var availableRelevantAttributes = React.useMemo((function () {
          return Belt_List.keep(Belt_List.sort(Belt_List.concat(/* :: */[
                              smellyNone,
                              /* [] */0
                            ], attributes), (function (attrA, attrB) {
                            return $$String.compare(prepareAttributeForSort(attrA), prepareAttributeForSort(attrB));
                          })), (function (attribute) {
                        if (InboxDescribe$BsConsole.group(attribute)) {
                          return attribute.statistics.used;
                        } else {
                          return false;
                        }
                      }));
        }), [currentFactor]);
  var match = React.useState((function () {
          return availableRelevantAttributes;
        }));
  var setConsiderations = match[1];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setInputValue = match$1[1];
  var inputValue = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setAnchorEl = match$2[1];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setMenuOpen = match$3[1];
  React.useEffect((function () {
          var nextConsiderations = inputValue.trim() !== "" ? Belt_List.fromArray(Fuzz$BsConsole.fuzz(inputValue, Belt_List.toArray(availableRelevantAttributes), (function (attribute) {
                        return attribute.name;
                      }))) : availableRelevantAttributes;
          Curry._1(setConsiderations, (function (param) {
                  return nextConsiderations;
                }));
          
        }), /* tuple */[
        inputValue,
        attributes
      ]);
  var handleClose = function (_event) {
    Curry._1(setInputValue, (function (param) {
            return "";
          }));
    return Curry._1(setMenuOpen, (function (param) {
                  return false;
                }));
  };
  var currentAttribute = React.useMemo((function () {
          return Belt_List.getBy(Belt_List.concat(/* :: */[
                          smellyNone,
                          /* [] */0
                        ], attributes), (function (attribute) {
                        return attribute.name === currentFactor;
                      }));
        }), [currentFactor]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: (function (el) {
                      return Curry._1(setAnchorEl, (function (param) {
                                    if (el == null) {
                                      return ;
                                    } else {
                                      return Caml_option.some(el);
                                    }
                                  }));
                    }),
                  className: className
                }, React.createElement("label", {
                      className: label
                    }, I18N$BsConsole.show(undefined, "Group errors by")), React.createElement(Button.default, {
                      disableRipple: true,
                      variant: "outlined",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.px(16)),
                              /* :: */[
                                Css.padding4(Css.px(8), Css.px(32), Css.px(8), Css.px(12)),
                                /* :: */[
                                  Css.position(/* relative */903134412),
                                  /* :: */[
                                    Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                    /* :: */[
                                      Css.borderRadius(Css.px(4)),
                                      /* :: */[
                                        Css.selector(".select-icon", /* :: */[
                                              Css.color(Css.rgba(0, 0, 0, 0.54)),
                                              /* [] */0
                                            ]),
                                        /* :: */[
                                          Css.hover(/* :: */[
                                                Css.unsafe("backgroundColor", "inherit"),
                                                /* [] */0
                                              ]),
                                          /* :: */[
                                            Css.minWidth(Css.px(120)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]),
                        label: Css.style(/* :: */[
                              Css.fontWeight(/* `num */[
                                    5496390,
                                    400
                                  ]),
                              /* :: */[
                                Css.fontSize(Css.px(16)),
                                /* :: */[
                                  Css.lineHeight(Css.px(19)),
                                  /* :: */[
                                    Css.unsafe("justifyContent", "unset"),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      },
                      onClick: (function (_event) {
                          return Curry._1(setMenuOpen, (function (prev) {
                                        return !prev;
                                      }));
                        }),
                      children: null
                    }, React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              className: iconContainer,
                              children: React.createElement(MuiIcons$BsConsole.OutlineBug.make, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  })
                            }), I18N$BsConsole.showSkip(currentFactor)), React.createElement(ArrowDropDown.default, {
                          className: Css.merge(/* :: */[
                                Css.style(/* :: */[
                                      Css.position(/* absolute */-1013592457),
                                      /* :: */[
                                        Css.right(Css.px(5)),
                                        /* [] */0
                                      ]
                                    ]),
                                /* :: */[
                                  "select-icon",
                                  /* [] */0
                                ]
                              ])
                        }))), React.createElement(Popover.default, {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  anchorEl: match$2[0],
                  open: match$3[0],
                  className: Css.style(/* :: */[
                        Css.selector("ul", /* :: */[
                              Css.paddingTop(/* zero */-789508312),
                              /* :: */[
                                Css.paddingBottom(/* zero */-789508312),
                                /* [] */0
                              ]
                            ]),
                        /* :: */[
                          Css.maxHeight(Css.px(500)),
                          /* [] */0
                        ]
                      ]),
                  onClose: handleClose,
                  transitionDuration: 0,
                  children: React.createElement(Paper.default, {
                        children: React.createElement(MenuList.default, {
                              children: null
                            }, React.createElement(MenuItem.default, {
                                  disableRipple: true,
                                  onClick: (function ($$event) {
                                      return $$event.preventDefault();
                                    }),
                                  value: "bt-factor-select-input",
                                  className: Css.style(/* :: */[
                                        Css.position(/* sticky */188263721),
                                        /* :: */[
                                          Css.top(Css.px(0)),
                                          /* :: */[
                                            Css.important(Css.backgroundColor(Css.white)),
                                            /* :: */[
                                              Css.zIndex(100),
                                              /* :: */[
                                                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                                                /* :: */[
                                                  Css.padding2(Css.px(11), Css.px(32)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]),
                                  children: React.createElement(TextField.default, {
                                        placeholder: "Search attributes",
                                        value: inputValue,
                                        color: "primary",
                                        variant: "standard",
                                        className: Css.style(/* :: */[
                                              Css.width(Css.pct(100)),
                                              /* :: */[
                                                Css.selector("input", /* :: */[
                                                      Css.padding4(Css.px(6), Css.px(8), Css.px(7), Css.px(8)),
                                                      /* [] */0
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]),
                                        inputRef: (function (input) {
                                            Belt_Option.map((input == null) ? undefined : Caml_option.some(input), (function (r) {
                                                    var domElement = ReactDom.findDOMNode(r);
                                                    return setTimeout((function (param) {
                                                                  return domElement.focus();
                                                                }), 100);
                                                  }));
                                            
                                          }),
                                        onChange: (function ($$event) {
                                            var value = $$event.target.value;
                                            return Curry._1(setInputValue, (function (param) {
                                                          return value;
                                                        }));
                                          })
                                      })
                                }), React.createElement(MenuItem.default, {
                                  disabled: true,
                                  classes: currentFactorMenuItemClasses,
                                  value: currentFactor,
                                  children: currentAttribute !== undefined ? React.createElement(ListItemText.default, {
                                          primary: I18N$BsConsole.showSkip(currentAttribute.name),
                                          secondary: I18N$BsConsole.show(undefined, currentAttribute.description)
                                        }) : I18N$BsConsole.showSkip(currentFactor)
                                }), Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.keep(match[0], (function (attribute) {
                                            return attribute.name !== Belt_Option.getWithDefault(Belt_Option.map(currentAttribute, (function (attr) {
                                                              return attr.name;
                                                            })), "");
                                          })), (function (idx, attribute) {
                                        var partial_arg = attribute.name;
                                        return React.createElement(MenuItem.default, {
                                                    disableRipple: true,
                                                    classes: menuItemClasses,
                                                    onClick: (function (param) {
                                                        Curry._1(onFactorUpdate, partial_arg);
                                                        Curry._1(setInputValue, (function (param) {
                                                                return "";
                                                              }));
                                                        return Curry._1(setMenuOpen, (function (param) {
                                                                      return false;
                                                                    }));
                                                      }),
                                                    value: attribute.name,
                                                    children: null,
                                                    key: attribute.name + ("_" + (attribute.description + ("-" + String(idx))))
                                                  }, React.createElement(ListItemText.default, {
                                                        primary: I18N$BsConsole.showSkip(attribute.name),
                                                        secondary: I18N$BsConsole.show(undefined, attribute.description)
                                                      }), attribute.custom ? React.createElement("div", {
                                                          className: customChip
                                                        }, I18N$BsConsole.show(undefined, "Custom")) : React.createElement("div", {
                                                          className: defaultChip
                                                        }, attribute.name === "None" ? I18N$BsConsole.show(undefined, "None") : I18N$BsConsole.show(undefined, "Default")));
                                      }))))
                      })
                }));
}

var Jsx3 = {
  prepareAttributeForSort: prepareAttributeForSort,
  make: FactorSelect$Jsx3
};

exports.AutoFocusInput = AutoFocusInput;
exports.smellyNone = smellyNone;
exports.Style = Style;
exports.component = component$1;
exports.make = make$1;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
