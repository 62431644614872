// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var ApiWf$BsConsole = require("../workflows-api/ApiWf.js");
var Image$BsConsole = require("../../components/Image.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WfRouter$BsConsole = require("../WfRouter.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var WarningMessage$BsConsole = require("../../WarningMessage.js");
var Launch = require("@material-ui/icons/Launch");
var WorkflowVersion$BsConsole = require("../WorkflowVersion.js");
var MenuItem = require("@material-ui/core/MenuItem");
var ApiWfConnections$BsConsole = require("../workflows-api/ApiWfConnections.js");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var ResetScrollOnRouteUpdate$BsConsole = require("../../ResetScrollOnRouteUpdate.js");

var container = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* [] */0
    ]);

var h1 = Css.style(/* :: */[
      Css.paddingBottom(Css.px(29)),
      /* :: */[
        Css.fontSize(Css.rem(1.5)),
        /* :: */[
          Css.margin(Css.px(0)),
          /* [] */0
        ]
      ]
    ]);

var h2 = Css.style(/* :: */[
      Css.fontSize(Css.rem(1.125)),
      /* :: */[
        Css.fontWeight(/* medium */-20425611),
        /* :: */[
          Css.marginBottom(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var body = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.width(Css.px(80)),
      /* :: */[
        Css.height(Css.px(80)),
        /* [] */0
      ]
    ]);

var logoContainer = Css.style(/* :: */[
      Css.width(Css.rem(8)),
      /* :: */[
        Css.height(Css.rem(8)),
        /* :: */[
          Css.minWidth(Css.rem(8)),
          /* :: */[
            Css.minHeight(Css.rem(8)),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var textContainer = Css.style(/* :: */[
      Css.marginLeft(Css.px(29)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var description = Css.style(/* :: */[
      Css.fontSize(Css.rem(1)),
      /* :: */[
        Css.marginTop(Css.px(0)),
        /* :: */[
          Css.marginBottom(Css.rem(1.5)),
          /* [] */0
        ]
      ]
    ]);

var benefits = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* :: */[
        Css.paddingBottom(Css.px(26)),
        /* :: */[
          Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
          /* [] */0
        ]
      ]
    ]);

var benefitsHeader = Css.style(/* :: */[
      Css.fontWeight(/* medium */-20425611),
      /* :: */[
        Css.marginBottom(Css.px(0)),
        /* [] */0
      ]
    ]);

var ul = Css.style(/* :: */[
      Css.marginTop(Css.px(8)),
      /* :: */[
        Css.marginBottom(Css.px(0)),
        /* :: */[
          Css.paddingLeft(Css.rem(1.25)),
          /* [] */0
        ]
      ]
    ]);

var listItem = Css.style(/* :: */[
      Css.marginBottom(Css.px(8)),
      /* :: */[
        Css.fontSize(Css.rem(0.875)),
        /* [] */0
      ]
    ]);

var orderedList = Css.style(/* :: */[
      Css.marginTop(Css.px(0)),
      /* :: */[
        Css.marginBottom(Css.px(8)),
        /* :: */[
          Css.paddingLeft(Css.rem(1.25)),
          /* [] */0
        ]
      ]
    ]);

var buttonsContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.rem(3.125)),
          /* [] */0
        ]
      ]
    ]);

var icon = Css.style(/* :: */[
      Css.marginLeft(Css.px(3)),
      /* :: */[
        Css.fontSize(Css.rem(1)),
        /* [] */0
      ]
    ]);

var select = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* :: */[
        Css.paddingTop(Css.px(8)),
        /* :: */[
          Css.paddingBottom(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var selectMenuItem = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* [] */0
    ]);

var Styles = {
  container: container,
  h1: h1,
  h2: h2,
  body: body,
  logo: logo,
  logoContainer: logoContainer,
  textContainer: textContainer,
  description: description,
  benefits: benefits,
  benefitsHeader: benefitsHeader,
  ul: ul,
  listItem: listItem,
  orderedList: orderedList,
  buttonsContainer: buttonsContainer,
  icon: icon,
  select: select,
  selectMenuItem: selectMenuItem
};

function slackAppLink(config) {
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
    return "/workflows/service_unavailable";
  } else {
    return baseUrl[0] + "/plugins/slack/external/slack/install";
  }
}

var Links = {
  slackAppLink: slackAppLink
};

function SlackConnection$PluginSummary(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: description
                }, I18N$BsConsole.show(undefined, "Linking Backtrace and Slack provides you instant access to user crashes errors.")), React.createElement("div", {
                  className: benefits
                }, React.createElement("p", {
                      className: benefitsHeader
                    }, I18N$BsConsole.show(undefined, "Benefits of Backtrace + Slack")), React.createElement("ul", {
                      className: ul
                    }, React.createElement("li", {
                          className: listItem
                        }, I18N$BsConsole.show(undefined, "Create automated alerts to post user errors in Slack")), React.createElement("li", {
                          className: listItem
                        }, I18N$BsConsole.show(undefined, "Assign team members and modify status with Slack actions")))));
}

var PluginSummary = {
  make: SlackConnection$PluginSummary
};

function SlackConnection$NextSteps(Props) {
  var config = Props.config;
  return React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: h2
                }, I18N$BsConsole.show(undefined, "Next Steps")), React.createElement("ol", {
                  className: orderedList
                }, React.createElement("li", {
                      className: listItem
                    }, I18N$BsConsole.show(undefined, "Go to "), React.createElement("a", {
                          href: slackAppLink(config),
                          rel: "noopener noreferrer",
                          target: "_blank",
                          onClick: (function (param) {
                              return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Connection_InstallLinkClick */Block.__(6, ["slack"])]));
                            })
                        }, I18N$BsConsole.show(undefined, "Slack app store")), I18N$BsConsole.show(undefined, " and install the app.")), React.createElement("li", {
                      className: listItem
                    }, I18N$BsConsole.show(undefined, "After the app is installed, you will be redirected to Backtrace to complete the integration."))));
}

var NextSteps = {
  make: SlackConnection$NextSteps
};

function SlackConnection$SelectConnection(Props) {
  var connections = Props.connections;
  var selectedConnection = Props.selectedConnection;
  var handleChangeSelection = Props.handleChangeSelection;
  return React.createElement(Select.default, {
              value: selectedConnection,
              onChange: (function ($$event, param) {
                  var value = $$event.target.value;
                  if (!(value == null)) {
                    return Curry._1(handleChangeSelection, value);
                  }
                  
                }),
              input: React.createElement(OutlinedInput.default, {
                    labelWidth: 0
                  }),
              classes: {
                select: select
              },
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                transitionDuration: 0
              },
              children: connections.length !== 0 ? Belt_Array.map(connections, (function (connection) {
                        return React.createElement(MenuItem.default, {
                                    classes: {
                                      root: selectMenuItem
                                    },
                                    value: connection.id,
                                    children: I18N$BsConsole.showSkip(connection.name),
                                    key: String(connection.id)
                                  });
                      })) : [React.createElement(MenuItem.default, {
                        children: I18N$BsConsole.show(undefined, "No options available")
                      })]
            });
}

var SelectConnection = {
  make: SlackConnection$SelectConnection
};

var buttonsContainer$1 = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.rem(3.125)),
          /* [] */0
        ]
      ]
    ]);

var title = Css.style(/* :: */[
      Css.fontSize(Css.rem(1.25)),
      /* :: */[
        Css.fontWeight(/* medium */-20425611),
        /* :: */[
          Css.marginBottom(Css.px(0)),
          /* [] */0
        ]
      ]
    ]);

var text = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* [] */0
    ]);

var selectConnection = Css.style(/* :: */[
      Css.marginBottom(Css.px(15)),
      /* :: */[
        Css.marginTop(Css.px(10)),
        /* :: */[
          Css.fontWeight(/* medium */-20425611),
          /* [] */0
        ]
      ]
    ]);

var Styles$1 = {
  buttonsContainer: buttonsContainer$1,
  title: title,
  text: text,
  selectConnection: selectConnection
};

function SlackConnection$PluginFound(Props) {
  var config = Props.config;
  var connections = Props.connections;
  var selectedConnection = Props.selectedConnection;
  var handleChangeSelection = Props.handleChangeSelection;
  return React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: title
                }, I18N$BsConsole.show(undefined, "App installation found!")), React.createElement("p", {
                  className: text
                }, I18N$BsConsole.show(undefined, "Looks like you already have the app installed. You can continue to configuration, or "), React.createElement("a", {
                      href: slackAppLink(config),
                      rel: "noopener noreferrer",
                      target: "_blank",
                      onClick: (function (param) {
                          return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Connection_InstallLinkClick */Block.__(6, ["slack"])]));
                        })
                    }, I18N$BsConsole.show(undefined, "re-install the app."))), React.createElement("p", {
                  className: selectConnection
                }, I18N$BsConsole.show(undefined, "Select a Slack Instance")), React.createElement(SlackConnection$SelectConnection, {
                  connections: connections,
                  selectedConnection: selectedConnection,
                  handleChangeSelection: handleChangeSelection
                }));
}

var PluginFound = {
  Styles: Styles$1,
  make: SlackConnection$PluginFound
};

function SlackConnection$NextButton(Props) {
  var config = Props.config;
  var pluginFound = Props.pluginFound;
  var selectedConnection = Props.selectedConnection;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  if (pluginFound) {
    return React.createElement(Button.default, {
                variant: "contained",
                size: "medium",
                color: "primary",
                onClick: (function (param) {
                    var param_pluginId = "slack";
                    var param$1 = {
                      pluginId: param_pluginId,
                      connectionId: selectedConnection,
                      connectionCreated: undefined,
                      integrationId: undefined,
                      referrer: undefined,
                      error: undefined
                    };
                    return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                  projectName,
                                  WfRouter$BsConsole.root,
                                  param$1
                                ]));
                  }),
                children: I18N$BsConsole.show(undefined, "Continue")
              });
  } else {
    return React.createElement(Button.default, {
                variant: "contained",
                size: "medium",
                color: "primary",
                onClick: (function (param) {
                    return Util$BsConsole.openUrl(slackAppLink(config));
                  }),
                children: null
              }, I18N$BsConsole.show(undefined, "Install Slack app"), React.createElement(Launch.default, {
                    className: icon,
                    color: "inherit",
                    fontSize: "inherit"
                  }));
  }
}

var NextButton = {
  make: SlackConnection$NextButton
};

function SlackConnection$PageButtons(Props) {
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var pluginFound = Props.pluginFound;
  var selectedConnection = Props.selectedConnection;
  return React.createElement("div", {
              className: buttonsContainer
            }, React.createElement(Button.default, {
                  variant: "flat",
                  size: "medium",
                  color: "primary",
                  className: Css.style(/* :: */[
                        Css.marginRight(Css.rem(0.3125)),
                        /* [] */0
                      ]),
                  onClick: (function (param) {
                      return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                    projectName,
                                    /* WatchersList */2,
                                    undefined
                                  ]));
                    }),
                  children: I18N$BsConsole.show(undefined, "Cancel")
                }), React.createElement(SlackConnection$NextButton, {
                  config: config,
                  pluginFound: pluginFound,
                  selectedConnection: selectedConnection,
                  handleChangeUrl: handleChangeUrl,
                  projectName: projectName
                }));
}

var PageButtons = {
  make: SlackConnection$PageButtons
};

function useConnectionData(token, config, projectName) {
  var match = React.useState((function () {
          return [];
        }));
  var setConnections = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setSelectedConnection = match$1[1];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setErrorMessage = match$2[1];
  React.useEffect((function () {
          var onSuccess = function (param) {
            var body = param.body;
            var firstConnectionId = Belt_Option.map(Belt_Array.get(body, 0), (function (connection) {
                    return connection.id;
                  }));
            Curry._1(setConnections, (function (param) {
                    return body;
                  }));
            return Curry._1(setSelectedConnection, (function (param) {
                          return firstConnectionId;
                        }));
          };
          var onFailure = function (err) {
            return Curry._1(setErrorMessage, (function (param) {
                          return err;
                        }));
          };
          var maybeTask = ApiWfConnections$BsConsole.QueryConnections.queryConnectionsTask(config, projectName, "slack");
          if (maybeTask !== undefined) {
            Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                    return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                  }));
          } else {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
          }
          
        }), ([]));
  return /* tuple */[
          match[0],
          match$1[0],
          match$2[0],
          setSelectedConnection
        ];
}

function SlackConnection(Props) {
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var project = Props.project;
  var config = Props.config;
  var match = useConnectionData(token, config, project.name);
  var setSelectedConnection = match[3];
  var errorMessage = match[2];
  var selectedConnection = match[1];
  var connections = match[0];
  var pluginFound = connections.length !== 0;
  return React.createElement(ResetScrollOnRouteUpdate$BsConsole.make, {
              route: /* Settings */4,
              children: React.createElement(BtSettings$BsConsole.Container.make, {
                    children: null
                  }, errorMessage.length > 0 ? React.createElement(WarningMessage$BsConsole.make, {
                          errorMessage: I18N$BsConsole.show(undefined, errorMessage)
                        }) : null, React.createElement(Paper.default, {
                        classes: {
                          root: container
                        },
                        children: null
                      }, React.createElement("p", {
                            className: h1
                          }, I18N$BsConsole.show(undefined, "Integration for Slack")), React.createElement("div", {
                            className: body
                          }, React.createElement("div", {
                                className: logoContainer
                              }, React.createElement(Image$BsConsole.make, {
                                    className: logo,
                                    src: "https://res.cloudinary.com/backtrace/image/upload/v1644361956/workflows_slack.svg",
                                    alt: "Slack Logo"
                                  })), React.createElement("div", {
                                className: textContainer
                              }, React.createElement(SlackConnection$PluginSummary, { }), pluginFound ? React.createElement(SlackConnection$PluginFound, {
                                      config: config,
                                      connections: connections,
                                      selectedConnection: selectedConnection,
                                      handleChangeSelection: (function (newSelection) {
                                          return Curry._1(setSelectedConnection, (function (param) {
                                                        return newSelection;
                                                      }));
                                        })
                                    }) : React.createElement(SlackConnection$NextSteps, {
                                      config: config
                                    }), React.createElement(SlackConnection$PageButtons, {
                                    config: config,
                                    handleChangeUrl: handleChangeUrl,
                                    projectName: project.name,
                                    pluginFound: pluginFound,
                                    selectedConnection: selectedConnection
                                  })))))
            });
}

var component = ReasonReact.statelessComponent("SlackConnection");

function make(token, config, handleChangeUrl, project, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SlackConnection, {
              token: token,
              handleChangeUrl: handleChangeUrl,
              project: project,
              config: config
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = SlackConnection;

exports.Styles = Styles;
exports.Links = Links;
exports.PluginSummary = PluginSummary;
exports.NextSteps = NextSteps;
exports.SelectConnection = SelectConnection;
exports.PluginFound = PluginFound;
exports.NextButton = NextButton;
exports.PageButtons = PageButtons;
exports.useConnectionData = useConnectionData;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* container Not a pure module */
