// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Form$BsConsole = require("./Form.js");
var I18N$BsConsole = require("./I18N.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("./util.js");
var Table$BsConsole = require("./table.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var BpgController$BsConsole = require("./BpgController.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");

var containerGrid = Css.style(/* :: */[
      Css.minWidth(Css.px(550)),
      /* :: */[
        Css.maxWidth(Css.px(900)),
        /* :: */[
          Css.marginLeft(/* auto */-1065951377),
          /* :: */[
            Css.marginRight(/* auto */-1065951377),
            /* :: */[
              Css.marginTop(Css.em(3)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var menu = Css.style(/* :: */[
      Css.textAlign(/* right */-379319332),
      /* [] */0
    ]);

var radioGroup = Css.style(/* :: */[
      Css.marginTop(Css.px(16)),
      /* [] */0
    ]);

var Styles = {
  containerGrid: containerGrid,
  menu: menu,
  radioGroup: radioGroup
};

var component = ReasonReact.reducerComponent("SettingsWhiteListPage");

function make(config, token, handleTask, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state.dialog;
              var tmp;
              if (match !== undefined) {
                if (match) {
                  var entity = match[0];
                  tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.SignupWhitelist.Delete.make, (function (_result, _updated) {
                              Curry._1(self.send, /* Dialog */[undefined]);
                              return Curry._1(self.send, /* BustCache */0);
                            }), handleTask, token, (function (state, handleSubmit, _toKey) {
                              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                              undefined,
                                              true,
                                              (function (_e) {
                                                  return Curry._1(self.send, /* Dialog */[undefined]);
                                                }),
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              [
                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Delete allowlisted domain")])),
                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    undefined,
                                                                    undefined,
                                                                    (function (_event) {
                                                                        return Curry._1(self.send, /* Dialog */[undefined]);
                                                                      }),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    undefined,
                                                                    undefined,
                                                                    (function (_event) {
                                                                        return Curry._1(handleSubmit, /* tuple */[
                                                                                    entity,
                                                                                    Util$BsConsole.identity
                                                                                  ]);
                                                                      }),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    state.remote === /* Loading */1,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Delete")]
                                                                  ]))
                                                        ]))
                                              ]
                                            ]));
                            })));
                } else {
                  tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.SignupWhitelist.Create.make, (function (_result, _updated) {
                              Curry._1(self.send, /* Dialog */[undefined]);
                              return Curry._1(self.send, /* BustCache */0);
                            }), handleTask, token, (function (state, handleSubmit, _toKey) {
                              return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.EditSingupWhitelistForm.make, (function (param) {
                                                var values = param.values;
                                                return Curry._1(handleSubmit, /* tuple */[
                                                            {
                                                              domain: values.domain,
                                                              universe: config.universe.id,
                                                              role: values.role,
                                                              method_: values.method_,
                                                              __state: undefined,
                                                              __create: undefined,
                                                              __modify: undefined
                                                            },
                                                            Util$BsConsole.identity,
                                                            param.setError,
                                                            param.setSubmitting,
                                                            param.resetFormState
                                                          ]);
                                              }), undefined, undefined, {
                                              domain: "",
                                              role: "member",
                                              method_: "password"
                                            }, /* :: */[
                                              /* tuple */[
                                                /* Domain */-501930652,
                                                /* Required */0
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  /* Role */914888598,
                                                  /* Required */0
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    /* Method */1039100673,
                                                    /* Required */0
                                                  ],
                                                  /* [] */0
                                                ]
                                              ]
                                            ], undefined, (function (param) {
                                                var getErrorForField = param.getErrorForField;
                                                var handleSubmit = param.handleSubmit;
                                                var handleChange = param.handleChange;
                                                var form = param.form;
                                                var partial_arg = Curry._1(handleChange, /* Domain */-501930652);
                                                var _msg = state.remote;
                                                var tmp;
                                                tmp = typeof _msg === "number" || _msg.tag !== /* Failure */1 ? Belt_Option.isSome(Curry._1(getErrorForField, /* Domain */-501930652)) : true;
                                                var msg = state.remote;
                                                var tmp$1;
                                                var exit = 0;
                                                if (typeof msg === "number" || msg.tag !== /* Failure */1) {
                                                  exit = 1;
                                                } else {
                                                  tmp$1 = I18N$BsConsole.dynamic(msg[0]);
                                                }
                                                if (exit === 1) {
                                                  var __x = Curry._1(getErrorForField, /* Domain */-501930652);
                                                  var __x$1 = Belt_Option.map(__x, I18N$BsConsole.dynamic);
                                                  tmp$1 = Belt_Option.getWithDefault(__x$1, I18N$BsConsole.showEmpty(undefined));
                                                }
                                                var _msg$1 = state.remote;
                                                var tmp$2;
                                                tmp$2 = typeof _msg$1 === "number" || _msg$1.tag !== /* Failure */1 ? false : true;
                                                return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                                                undefined,
                                                                true,
                                                                (function (_e) {
                                                                    return Curry._1(self.send, /* Dialog */[undefined]);
                                                                  }),
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                [
                                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Add allowlisted domain")])),
                                                                  ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      true,
                                                                                      (function (param) {
                                                                                          return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                        }),
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      tmp,
                                                                                      Caml_option.some(tmp$1),
                                                                                      undefined,
                                                                                      /* `String */[
                                                                                        -976970511,
                                                                                        form.values.domain
                                                                                      ],
                                                                                      undefined,
                                                                                      true,
                                                                                      Caml_option.some(I18N$BsConsole.show(undefined, "domain")),
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      []
                                                                                    ])),
                                                                            ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, radioGroup, tmp$2, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormLabel.Jsx2.make, undefined, [
                                                                                                I18N$BsConsole.show(undefined, "Method"),
                                                                                                ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, form.values.method_, (function (_event, value) {
                                                                                                            return Curry._2(handleChange, /* Method */1039100673, value);
                                                                                                          }), undefined, [
                                                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                    "password",
                                                                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "password")),
                                                                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  []
                                                                                                                                ]))),
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    []
                                                                                                                  ])),
                                                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                    "saml",
                                                                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "saml")),
                                                                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  []
                                                                                                                                ]))),
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    []
                                                                                                                  ])),
                                                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                    "pam",
                                                                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "pam")),
                                                                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  []
                                                                                                                                ]))),
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    []
                                                                                                                  ]))
                                                                                                        ]))
                                                                                              ]))])),
                                                                            ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, radioGroup, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormLabel.Jsx2.make, undefined, [
                                                                                                I18N$BsConsole.show(undefined, "Role"),
                                                                                                ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, form.values.role, (function (_event, value) {
                                                                                                            return Curry._2(handleChange, /* Role */914888598, value);
                                                                                                          }), undefined, [
                                                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                    "member",
                                                                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "member")),
                                                                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  []
                                                                                                                                ]))),
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    []
                                                                                                                  ])),
                                                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                    "guest",
                                                                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "guest")),
                                                                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  []
                                                                                                                                ]))),
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    []
                                                                                                                  ]))
                                                                                                        ]))
                                                                                              ]))]))
                                                                          ])),
                                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                      undefined,
                                                                                      undefined,
                                                                                      (function (_event) {
                                                                                          return Curry._1(self.send, /* Dialog */[undefined]);
                                                                                        }),
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                    ])),
                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                      undefined,
                                                                                      undefined,
                                                                                      (function (_event) {
                                                                                          return Curry._1(handleSubmit, undefined);
                                                                                        }),
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      state.remote === /* Loading */1 || form.values.domain === "",
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      [I18N$BsConsole.show(undefined, "Create")]
                                                                                    ]))
                                                                          ]))
                                                                ]
                                                              ]));
                                              })));
                            })));
                }
              } else {
                tmp = null;
              }
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(1)),
                                            /* [] */0
                                          ]), [
                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                  undefined,
                                                  Css.style(/* :: */[
                                                        Css.marginRight(Css.rem(0.5)),
                                                        /* [] */0
                                                      ]),
                                                  (function (_event) {
                                                      return Curry._1(handleChangeUrl, /* SettingsUsers */6);
                                                    }),
                                                  undefined,
                                                  undefined,
                                                  /* Outlined */-28821822,
                                                  /* Primary */-791844958,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [
                                                    ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.KeyboardBackspace.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                    I18N$BsConsole.show(undefined, "Back to user settings")
                                                  ]
                                                ])),
                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                  undefined,
                                                  undefined,
                                                  (function (_event) {
                                                      return Curry._1(self.send, /* Dialog */[/* Add */0]);
                                                    }),
                                                  undefined,
                                                  undefined,
                                                  /* Contained */-515484397,
                                                  /* Primary */-791844958,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [
                                                    ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                    I18N$BsConsole.show(undefined, "Add domain")
                                                  ]
                                                ]))
                                      ])),
                              ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(0.5)),
                                            /* [] */0
                                          ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Permitted domains")])),
                              ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body2 */9, undefined, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(1)),
                                            /* [] */0
                                          ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Users will be able to sign up without invitations from allowlisted domains")])),
                              ReasonReact.element(String(self.state.cacheKey), undefined, Curry._4(BpgController$BsConsole.SignupWhitelist.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                          var msg = state.remote;
                                          if (typeof msg === "number") {
                                            return null;
                                          }
                                          if (msg.tag) {
                                            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                          /* String_literal */Block.__(11, [
                                                              "Error: ",
                                                              /* String */Block.__(2, [
                                                                  /* No_padding */0,
                                                                  /* End_of_format */0
                                                                ])
                                                            ]),
                                                          "Error: %s"
                                                        ]), I18N$BsConsole.dynamicGet(msg[0])));
                                            return null;
                                          }
                                          var results = msg[0];
                                          return ReasonReact.element(undefined, undefined, Table$BsConsole.Pagination.make(results, (function (aperture, page, rowsPerPage, onChangePage, onChangeRowsPerPage) {
                                                            return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                                                                                      ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "domain")])),
                                                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                                                        ]))])),
                                                                                      ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [Curry._1(aperture, $$Array.of_list(List.map((function (result) {
                                                                                                                return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(result.domain)])),
                                                                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, menu, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (onClose) {
                                                                                                                                                      return [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                                          Curry._1(self.send, /* Dialog */[/* Delete */[result]]);
                                                                                                                                                                          return Curry._1(onClose, undefined);
                                                                                                                                                                        }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete")]))];
                                                                                                                                                    }), undefined, []))]))
                                                                                                                              ]));
                                                                                                              }), results)))])),
                                                                                      ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableFooter.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TablePagination.Jsx2.make, [
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          onChangePage,
                                                                                                          undefined,
                                                                                                          onChangeRowsPerPage,
                                                                                                          /* `Int */[
                                                                                                            3654863,
                                                                                                            page
                                                                                                          ],
                                                                                                          undefined,
                                                                                                          /* `Int */[
                                                                                                            3654863,
                                                                                                            List.length(results)
                                                                                                          ],
                                                                                                          /* `Int */[
                                                                                                            3654863,
                                                                                                            rowsPerPage
                                                                                                          ],
                                                                                                          /* `Int */[
                                                                                                            3654863,
                                                                                                            2
                                                                                                          ],
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          []
                                                                                                        ]))]))
                                                                                    ]))]));
                                                          })));
                                        }))),
                              tmp
                            ]));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined,
                      cacheKey: 0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            dialog: action[0],
                            cacheKey: state.cacheKey
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            dialog: state.dialog,
                            cacheKey: state.cacheKey + 1 | 0
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* containerGrid Not a pure module */
