// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Dialog = require("@material-ui/core/Dialog");

var component = RR$BsConsole.statelessComponent("PrModal-BsConsole");

function make(isOpen, onClose, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              isOpen,
                              (function (param) {
                                  return Curry._1(onClose, undefined);
                                }),
                              {
                                paper: Css.style(/* :: */[
                                      Css.maxWidth(/* none */-922086728),
                                      /* :: */[
                                        Css.overflow(/* hidden */-862584982),
                                        /* [] */0
                                      ]
                                    ])
                              },
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              children
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

function PrModal$Jsx3(Props) {
  var isOpen = Props.isOpen;
  var onClose = Props.onClose;
  var children = Props.children;
  return React.createElement(Dialog.default, {
              open: isOpen,
              classes: {
                paper: Css.style(/* :: */[
                      Css.maxWidth(/* none */-922086728),
                      /* :: */[
                        Css.overflow(/* hidden */-862584982),
                        /* [] */0
                      ]
                    ])
              },
              onClose: (function (param) {
                  return Curry._1(onClose, undefined);
                }),
              children: children
            });
}

var Jsx3 = {
  make: PrModal$Jsx3
};

exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
