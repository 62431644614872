// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReactStripeCheckout = require("react-stripe-checkout");

var StripeBillingObject = { };

function make(name, description, amount, token, stripeKey, zipCode, billingAddress, panelLabel, children) {
  var tmp = { };
  if (name !== undefined) {
    tmp.name = Caml_option.valFromOption(name);
  }
  if (description !== undefined) {
    tmp.description = Caml_option.valFromOption(description);
  }
  if (amount !== undefined) {
    tmp.amount = Caml_option.valFromOption(amount);
  }
  if (token !== undefined) {
    tmp.token = Caml_option.valFromOption(token);
  }
  if (stripeKey !== undefined) {
    tmp.stripeKey = Caml_option.valFromOption(stripeKey);
  }
  if (zipCode !== undefined) {
    tmp.zipCode = Caml_option.valFromOption(zipCode);
  }
  if (billingAddress !== undefined) {
    tmp.billingAddress = Caml_option.valFromOption(billingAddress);
  }
  if (panelLabel !== undefined) {
    tmp.panelLabel = Caml_option.valFromOption(panelLabel);
  }
  return ReasonReact.wrapJsForReason(ReactStripeCheckout.default, tmp, children);
}

exports.StripeBillingObject = StripeBillingObject;
exports.make = make;
/* ReasonReact Not a pure module */
