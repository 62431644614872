// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");

function BugTabCount(Props) {
  var label = Props.label;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginLeft(Css.px(6)),
                    /* :: */[
                      Css.padding(Css.px(3)),
                      /* :: */[
                        Css.minWidth(Css.px(18)),
                        /* :: */[
                          Css.height(Css.px(18)),
                          /* :: */[
                            Css.background(Css.hex(Colors$BsConsole.grey7)),
                            /* :: */[
                              Css.fontSize(Css.px(10)),
                              /* :: */[
                                Css.display(/* flex */-1010954439),
                                /* :: */[
                                  Css.alignItems(Css.center),
                                  /* :: */[
                                    Css.justifyContent(Css.center),
                                    /* :: */[
                                      Css.marginTop(Css.px(-2)),
                                      /* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement("div", undefined, I18N$BsConsole.showSkip(label)));
}

var make = BugTabCount;

exports.make = make;
/* Css Not a pure module */
