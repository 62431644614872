// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var BugTree$BsConsole = require("./BugTree.js");
var BugTreeNode$BsConsole = require("./BugTreeNode.js");

function BugSystem(Props) {
  var system = Props.system;
  var jsonTrees = React.useMemo((function () {
          return Belt_Array.map(Js_dict.entries(system), (function (param) {
                        return BugTreeNode$BsConsole.fromJson(param[0], undefined, undefined, param[1]);
                      }));
        }), [system]);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.padding(Css.px(8)),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* :: */[
                        Css.overflowY(Css.auto),
                        /* [] */0
                      ]
                    ]
                  ])
            }, Belt_Array.map(jsonTrees, (function (node) {
                    return React.createElement(BugTree$BsConsole.make, {
                                node: node
                              });
                  })));
}

var make = BugSystem;

exports.make = make;
/* Css Not a pure module */
