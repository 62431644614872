// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");

function filterFirstSeen(attribute) {
  return !(attribute.name.includes("first_seen") && !attribute.name.includes("fingerprint"));
}

var javaScriptSdks = [
  "/browser",
  "/electron",
  "/nestjs",
  "/node",
  "/react-native",
  "/react"
];

function isAJavaScriptSdk(agent) {
  return Belt_Array.some(javaScriptSdks, (function (jsSdk) {
                return agent.includes(jsSdk);
              }));
}

function getSDKVariant(attributes) {
  var agent = Belt_Option.flatMap(Js_dict.get(attributes, "backtrace.agent"), Js_json.decodeString);
  if (agent !== undefined && isAJavaScriptSdk(agent)) {
    return /* JavaScript */0;
  } else {
    return /* Other */1;
  }
}

var SDKVariant = {
  javaScriptSdks: javaScriptSdks,
  isAJavaScriptSdk: isAJavaScriptSdk,
  getSDKVariant: getSDKVariant
};

var alwaysIncludeAttrs = [
  "_compressed",
  "_deleted"
];

var includeIfUsedAttrs = ["fault.address"];

function attrIsUsed(activeAttributes, key) {
  var projectAttribute = Belt_List.getBy(activeAttributes, (function (pa) {
          return pa.name === key;
        }));
  if (projectAttribute !== undefined) {
    return projectAttribute.statistics.used;
  } else {
    return false;
  }
}

function stringOfNumber(n) {
  var nStr = Pervasives.string_of_float(n);
  if (nStr.endsWith(".")) {
    return nStr.slice(0, -1);
  } else {
    return nStr;
  }
}

function getAttributeRawValueString(value) {
  var s = Js_json.classify(value);
  if (typeof s === "number") {
    switch (s) {
      case /* JSONFalse */0 :
          return "false";
      case /* JSONTrue */1 :
          return "true";
      case /* JSONNull */2 :
          return "null";
      
    }
  } else {
    switch (s.tag | 0) {
      case /* JSONString */0 :
          return s[0];
      case /* JSONNumber */1 :
          return stringOfNumber(s[0]);
      case /* JSONObject */2 :
      case /* JSONArray */3 :
          return Belt_Option.getWithDefault(JSON.stringify(s[0]), "");
      
    }
  }
}

function isNonNullZeroOrEmptyWhenDefault(isCustom, value) {
  var match = getAttributeRawValueString(value);
  if (isCustom) {
    return true;
  }
  switch (match) {
    case "" :
    case "0" :
    case "null" :
        return false;
    default:
      return true;
  }
}

function contains(arr, key) {
  return Belt_Array.getBy(arr, (function (attr) {
                return Caml_obj.caml_equal(attr, key);
              })) !== undefined;
}

function onAllowList(activeAttributes, key) {
  var match = contains(alwaysIncludeAttrs, key);
  var match$1 = contains(includeIfUsedAttrs, key);
  if (match) {
    return true;
  } else if (match$1) {
    return attrIsUsed(activeAttributes, key);
  } else {
    return false;
  }
}

function includeAttr(isCustom, activeAttributes, param) {
  if (onAllowList(activeAttributes, param[0])) {
    return true;
  } else {
    return isNonNullZeroOrEmptyWhenDefault(isCustom, param[1]);
  }
}

function isCustomAttribute(describedAttributes, key) {
  var match = Belt_List.getBy(describedAttributes.active, (function (pa) {
          return pa.name === key;
        }));
  var match$1 = Belt_List.getBy(describedAttributes.disabled, (function (pa) {
          return pa.name === key;
        }));
  if (match !== undefined) {
    return match.custom;
  } else if (match$1 !== undefined) {
    return match$1.custom;
  } else {
    return false;
  }
}

function isRelevantAttribute(describedAttributes, param) {
  var key = param[0];
  var isCustom = isCustomAttribute(describedAttributes, key);
  return includeAttr(isCustom, describedAttributes.active, /* tuple */[
              key,
              param[1]
            ]);
}

var RelevantAttributes = {
  alwaysIncludeAttrs: alwaysIncludeAttrs,
  includeIfUsedAttrs: includeIfUsedAttrs,
  attrIsUsed: attrIsUsed,
  stringOfNumber: stringOfNumber,
  getAttributeRawValueString: getAttributeRawValueString,
  isNonNullZeroOrEmptyWhenDefault: isNonNullZeroOrEmptyWhenDefault,
  contains: contains,
  onAllowList: onAllowList,
  includeAttr: includeAttr,
  isCustomAttribute: isCustomAttribute,
  isRelevantAttribute: isRelevantAttribute
};

var rowCount = {
  custom: false,
  aggregate: /* [] */0,
  description: "number of errors",
  filter: /* [] */0,
  format: "string",
  group: false,
  nestedIssues: false,
  name: "count;",
  type_: "string",
  statistics: {
    used: true
  }
};

exports.filterFirstSeen = filterFirstSeen;
exports.rowCount = rowCount;
exports.SDKVariant = SDKVariant;
exports.RelevantAttributes = RelevantAttributes;
/* No side effect */
