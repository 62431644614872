'use strict';

var NodeRe = require("./NodeRe.js");
var DomTypesRe = require("../DomTypesRe.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ParentNodeRe = require("./ParentNodeRe.js");
var EventTargetRe = require("../events/EventTargetRe.js");
var DocumentOrShadowRootRe = require("./DocumentOrShadowRootRe.js");
var NonElementParentNodeRe = require("./NonElementParentNodeRe.js");

function Impl(T) {
  var asHtmlDocument = (function (document) {
      return document.doctype.name === "html" ?  document : null;
    });
  var asHtmlDocument$1 = function (self) {
    return Caml_option.null_to_opt(asHtmlDocument(self));
  };
  var ofNode = function (node) {
    if (NodeRe.nodeType(node) === /* Document */8) {
      return Caml_option.some(node);
    }
    
  };
  var compatMode = function (self) {
    return DomTypesRe.decodeCompatMode(self.compatMode);
  };
  var visibilityState = function (self) {
    return DomTypesRe.decodeVisibilityState(self.visibilityState);
  };
  return {
          asHtmlDocument: asHtmlDocument$1,
          ofNode: ofNode,
          compatMode: compatMode,
          visibilityState: visibilityState
        };
}

var include = NodeRe.Impl({ });

EventTargetRe.Impl({ });

NonElementParentNodeRe.Impl({ });

DocumentOrShadowRootRe.Impl({ });

ParentNodeRe.Impl({ });

var asHtmlDocument = (function (document) {
      return document.doctype.name === "html" ?  document : null;
    });

function asHtmlDocument$1(self) {
  return Caml_option.null_to_opt(asHtmlDocument(self));
}

function ofNode(node) {
  if (NodeRe.nodeType(node) === /* Document */8) {
    return Caml_option.some(node);
  }
  
}

function compatMode(self) {
  return DomTypesRe.decodeCompatMode(self.compatMode);
}

function visibilityState(self) {
  return DomTypesRe.decodeVisibilityState(self.visibilityState);
}

var nodeType = include.nodeType;

exports.Impl = Impl;
exports.nodeType = nodeType;
exports.asHtmlDocument = asHtmlDocument$1;
exports.ofNode = ofNode;
exports.compatMode = compatMode;
exports.visibilityState = visibilityState;
/* include Not a pure module */
