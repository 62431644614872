// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var Tooltip = require("@material-ui/core/Tooltip");
var TableSortLabel = require("@material-ui/core/TableSortLabel");

var visiuallyHidden = Css.style(/* :: */[
      Css.height(Css.px(1)),
      /* :: */[
        Css.width(Css.px(1)),
        /* :: */[
          Css.margin(Css.px(-1)),
          /* :: */[
            Css.padding(/* zero */-789508312),
            /* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.overflow(/* hidden */-862584982),
                /* :: */[
                  Css.top(Css.px(20)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function rootStyle(active) {
  var className = active ? "" : Css.style(/* :: */[
          Css.selector("> svg", /* :: */[
                Css.position(/* absolute */-1013592457),
                /* [] */0
              ]),
          /* [] */0
        ]);
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.hover(/* :: */[
                            Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                            /* [] */0
                          ]),
                      /* :: */[
                        Css.padding(Css.px(4)),
                        /* :: */[
                          Css.borderRadius(Css.px(3)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                className,
                /* [] */0
              ]
            ]);
}

var jsMapperConstantArray = [
  /* tuple */[
    3258129,
    "asc"
  ],
  /* tuple */[
    759138929,
    "desc"
  ]
];

function directionToJs(param) {
  return Js_mapperRt.binarySearch(2, param, jsMapperConstantArray);
}

function directionFromJs(param) {
  return Js_mapperRt.revSearch(2, jsMapperConstantArray, param);
}

var SortingState = {
  directionToJs: directionToJs,
  directionFromJs: directionFromJs
};

function BtTableSort$Label(Props) {
  var sortingState = Props.sortingState;
  var columnName = Props.columnName;
  var onSortChange = Props.onSortChange;
  var classNameOpt = Props.className;
  var alignOpt = Props.align;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var align = alignOpt !== undefined ? alignOpt : /* left */-944764921;
  var alignDirection = align !== -944764921 && align === -379319332 ? /* rowReverse */378951208 : /* row */5693978;
  var alignmentStyle = Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.important(Css.flexDirection(alignDirection)),
              /* [] */0
            ]),
        /* :: */[
          "BtTableSortLabel",
          /* [] */0
        ]
      ]);
  var active = sortingState !== undefined ? sortingState.columnName === columnName : false;
  return React.createElement(Tooltip.default, {
              children: React.createElement(TableSortLabel.default, {
                    active: active,
                    classes: {
                      root: Css.merge(/* :: */[
                            rootStyle(active),
                            /* :: */[
                              className,
                              /* :: */[
                                alignmentStyle,
                                /* [] */0
                              ]
                            ]
                          ])
                    },
                    onClick: (function (_event) {
                        var direction = sortingState !== undefined && sortingState.columnName === columnName && sortingState.direction === /* Desc */759138929 ? /* Asc */3258129 : /* Desc */759138929;
                        return Curry._1(onSortChange, {
                                    columnName: columnName,
                                    direction: direction
                                  });
                      }),
                    direction: sortingState !== undefined ? Js_mapperRt.binarySearch(2, sortingState.direction, jsMapperConstantArray) : "desc",
                    children: null
                  }, React.createElement("span", {
                        className: Css.merge(/* :: */[
                              Css.style(/* :: */[
                                    Css.whiteSpace(/* nowrap */867913355),
                                    /* :: */[
                                      Css.overflow(/* hidden */-862584982),
                                      /* :: */[
                                        Css.textOverflow(/* ellipsis */166888785),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              /* [] */0
                            ])
                      }, children), sortingState !== undefined ? React.createElement("span", {
                          className: visiuallyHidden
                        }, sortingState.direction === /* Desc */759138929 ? I18N$BsConsole.show(undefined, "sorted descending") : I18N$BsConsole.show(undefined, "sorted ascending")) : null),
              title: I18N$BsConsole.get(undefined, "Sort")
            });
}

var Label = {
  make: BtTableSort$Label
};

exports.visiuallyHidden = visiuallyHidden;
exports.rootStyle = rootStyle;
exports.SortingState = SortingState;
exports.Label = Label;
/* visiuallyHidden Not a pure module */
