// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ApiBillingSubscription$BsConsole = require("./ApiBillingSubscription.js");

function ofJson(json) {
  return {
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          label: Json_decode.optional((function (param) {
                  return Json_decode.field("label", Json_decode.string, param);
                }), json),
          text: Json_decode.optional((function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json)
        };
}

var Value = {
  ofJson: ofJson
};

function ofJson$1(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          label: Json_decode.field("label", Json_decode.string, json),
          values: Json_decode.field("values", (function (param) {
                  return Json_decode.array(ofJson, param);
                }), json)
        };
}

var Feature = {
  ofJson: ofJson$1
};

function _ofJson(json) {
  return {
          features: Json_decode.field("features", (function (param) {
                  return Json_decode.array(ofJson$1, param);
                }), json),
          plans: Json_decode.field("plans", (function (param) {
                  return Json_decode.array(ApiBillingSubscription$BsConsole.Plan.ofJson, param);
                }), json)
        };
}

function ofJson$2(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("data", _ofJson, param);
              }), json);
}

exports.Value = Value;
exports.Feature = Feature;
exports._ofJson = _ofJson;
exports.ofJson = ofJson$2;
/* No side effect */
