// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");

function tableContainer(h, w) {
  return Css.style(/* :: */[
              Css.width(w),
              /* :: */[
                Css.maxHeight(h),
                /* :: */[
                  Css.overflowX(/* auto */-1065951377),
                  /* :: */[
                    Css.position(/* relative */903134412),
                    /* :: */[
                      Css.unsafe("backgroundColor", "inherit"),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

var make = React.forwardRef((function (Props, theRef) {
        var classNameOpt = Props.className;
        var heightOpt = Props.height;
        var widthOpt = Props.width;
        var children = Props.children;
        var className = classNameOpt !== undefined ? classNameOpt : "";
        var height = heightOpt !== undefined ? heightOpt : Css.px(500);
        var width = widthOpt !== undefined ? widthOpt : Css.pct(100);
        return React.createElement("div", {
                    ref: theRef,
                    className: Css.merge(/* :: */[
                          tableContainer(height, width),
                          /* :: */[
                            className,
                            /* :: */[
                              "BtTableContainer",
                              /* [] */0
                            ]
                          ]
                        ])
                  }, children);
      }));

exports.tableContainer = tableContainer;
exports.make = make;
/* make Not a pure module */
